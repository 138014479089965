// src/context/GlobalContext.js
import React, { createContext, ReactNode } from 'react';
import useAuth, { UserData } from '../hooks/auth';

const GlobalContext = createContext<{
	authenticated: boolean;
	userData: UserData | null;
	shouldLogin: boolean;
	internal: boolean;
	accessToOtherStores: any;
	newVersionAvailable: boolean;
}>({
	authenticated: false,
	userData: null,
	shouldLogin: false,
	internal: false,
	accessToOtherStores: null,
	newVersionAvailable: false
});

function GlobalProvider({ children }: { children: ReactNode }) {
	const { authenticated, userData, shouldLogin, internal, accessToOtherStores, newVersionAvailable } = useAuth();

	return <GlobalContext.Provider value={{ authenticated, userData, shouldLogin, internal, accessToOtherStores, newVersionAvailable }}>{children}</GlobalContext.Provider>;
}

export { GlobalContext, GlobalProvider };
