import React, { useContext, useEffect, useState } from "react";
import 'firebase/compat/firestore';
import auth from '../auth'
import firestore from '../firestore'
import Logo from "../components/Logo";
import { Button } from "../shadcn/Button";
import { GlobalContext } from "../context/GlobalContext";
import Input from "../components/Input";
import { Lock, Mail } from "lucide-react";
import { GetDev2FANumbers } from "../hooks/firebase";
import Notice from "../components/Notice";
import TwoFactorAuth from "../components/2FA";

const LoginForm = () => {
  const { authenticated, shouldLogin } = useContext(GlobalContext);

  const enable_2fa = !window.location.hostname.includes('localhost') && !window.location.hostname.includes('admin.krece.app');
  // const enable_2fa = true;
  const dev_2fa_numbers = GetDev2FANumbers(enable_2fa);
  const [twoFactorSuccess, setTwoFactorSuccess] = useState(false);

  const [loginData, setLoginData] = useState({
    storeEmail: '',
    password: '',
    password2: ''
  });
  const [loginError, setLoginError] = useState('');
  const [infoMessage, setInfoMessage] = useState('');
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (authenticated || !shouldLogin) {
      setIsLoading(true);
    }
  }, [authenticated, shouldLogin]);

  const handleLoginInputChange = (e: any) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  const handleSignUp = () => {
    try {
      if (!loginData.storeEmail || !loginData.password || !loginData.password2) {
        setLoginError('Por favor, rellene todas las casillas.');
        return;
      } else if (loginData.password !== loginData.password2) {
        setLoginError('Las contraseñas no coinciden.');
        return;
      }

      setLoginError('')
      setIsLoading(true)

      firestore.collection('Stores').doc(loginData.storeEmail.toLowerCase()).get()
        .then((docSnapshot) => {
          if (docSnapshot.exists) {
            auth.createUserWithEmailAndPassword(loginData.storeEmail.toLowerCase(), loginData.password)
              .catch((error) => {
                var errorMessage = error.message;
                if (errorMessage.includes('another')) {
                  errorMessage = "Ya existe una tienda con este email. Por favor inicie sesión."
                } else {
                  errorMessage = "Tiene problemas de conexión, vuelva a intentar más tarde."
                }
                setIsLoading(false)
                setLoginError(errorMessage);
              });
          } else {
            setLoginError("Esta tienda no ha sido autorizada por Krece. Para afiliar su tienda por favor comuníquese con nosotros al +58 4149022115.");
            setIsLoading(false)
          }
        });
    } catch (err) {
      setLoginError("Ocuriió un problema, probablemente tenga mala conexión.")
      setIsLoading(false)
    }
  }

  const handleLogin = () => {
    try {
      if (!loginData.storeEmail || !loginData.password) {
        setLoginError('Por favor, rellene todas las casillas.');
        return;
      }

      setLoginError('');
      setIsLoading(true)

      firestore.collection('Stores').doc(loginData.storeEmail.toLowerCase()).get()
        .then((docSnapshot) => {
          if (docSnapshot.exists) {
            auth.signInWithEmailAndPassword(loginData.storeEmail.toLowerCase(), loginData.password)
              .catch((error) => {
                const errorMessage = error.message;
                if (errorMessage.includes('network')) {
                  setLoginError("Mala conexión: Por favor chequee su conexión a internet.")
                } else {
                  setLoginError("Email o contraseña incorrecta.")
                }
                setIsLoading(false)
              });
          } else {
            setLoginError("Esta tienda no tiene cuenta con Krece, por favor cree una cuenta.");
            setIsLoading(false)
          }
        });
    } catch (err) {
      setLoginError("Ocurrió un problema, probablemente tenga mala conexión.")
      setIsLoading(false)
    }
  };

  const handleResetPassword = () => {
    if (!loginData.storeEmail) {
      setLoginError("Por favor, rellene todas las casillas");
      return;
    }

    setLoginError('')
    setIsLoading(true)

    try {
      auth.sendPasswordResetEmail(loginData.storeEmail.toLowerCase()).then(function () {
        setInfoMessage('Le enviamos un email para resetear su contraseña.');
      }).catch(function (error) {
        setLoginError("No existe una cuenta con este email.");
      });
    } catch (err) {
      setLoginError("Ocurrió un problema, probablemente tenga mala conexión.");
    } finally {
      setIsLoading(false)
    }
  }

  const goToPage = (x: number) => {
    setInfoMessage('')
    setLoginError('')
    setLoginData({
      storeEmail: '',
      password: '',
      password2: ''
    });
    setPage(x);
  }

  return (
    <>
		<div className={`m-auto flex min-h-full min-w-full flex-col items-center ${!enable_2fa ? 'justify-center' : 'justify-start'}`} id="login">
      <div className="w-full max-w-md px-6 py-8">
        <div className="mb-8 flex justify-center">
          <Logo className="w-48 h-auto" fill="fill-primary-500" />
        </div>

        <div className="bg-neutral-950/50 md:bg-background rounded-xl border border-neutral-800 shadow-xl px-8 py-10">
          <div className="text-center mb-8">
            <h2 className="text-2xl font-semibold text-neutral-100 tracking-tighter">
              {page === 1 && "Iniciar Sesión"}
              {page === 2 && "Recuperar Contraseña"}
              {page === 3 && "Registrarse"}
            </h2>
            <p className="text-neutral-400">
              {page === 1 && "Ingresa tus credenciales para continuar"}
              {page === 2 && "Te enviaremos un correo para restablecer tu contraseña"}
              {page === 3 && "Crea una nueva cuenta para comenzar"}
            </p>
          </div>

          <form className="space-y-4" onSubmit={(e) => {
            e.preventDefault();
            if (page === 1 && enable_2fa && !twoFactorSuccess) return;

            page === 1 ? handleLogin() : page === 2 ? handleResetPassword() : handleSignUp()
          }}>
            <Input
              Icon={<Mail className="size-4 text-neutral-300" />}
              name="storeEmail"
              type="email"
              value={loginData.storeEmail}
              onChange={(value) => handleLoginInputChange({ target: { name: 'storeEmail', value } })}
              placeholder="nombre@empresa.com"
              floatingLabel="Email de la tienda"
            />

            {(page === 1 || page === 3) && (
              <Input
                Icon={<Lock className="size-4 text-neutral-300" />}
                name="password"
                type="password"
                value={loginData.password}
                onChange={(value) => handleLoginInputChange({ target: { name: 'password', value } })}
                placeholder="••••••••"
                floatingLabel="Contraseña"
              />
            )}

            {page === 3 && (
              <Input
                Icon={<Lock className="size-4 text-neutral-300" />}
                name="password2"
                type="password"
                value={loginData.password2}
                onChange={(value) => handleLoginInputChange({ target: { name: 'password2', value } })}
                placeholder="••••••••"
                floatingLabel="Repetir contraseña"
              />
            )}

            {page === 1 && enable_2fa && <TwoFactorAuth select_phone_numbers={dev_2fa_numbers} country={'rd'} onSuccess={() => setTwoFactorSuccess(true)} />}

            {loginError && (
              <Notice message={loginError} variant="error" />
            )}

            {page === 2 && infoMessage && (
              <Notice message={infoMessage} variant="success" />
            )}

            <Button
              disabled={page === 1 && enable_2fa && !twoFactorSuccess}
              type="submit"
              loading={isLoading}
              onClick={page === 1 ? handleLogin : page === 2 ? handleResetPassword : handleSignUp}
              className="w-full flex justify-center py-3 px-4 rounded-lg text-sm font-medium text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 focus:ring-offset-neutral-900 transition-colors"
            >
              {page === 1 ? "Ingresar" : page === 2 ? "Enviar" : "Registrarse"}
            </Button>

            {page === 1 ? (
              <div className="mt-8 flex flex-col space-y-3">
                <button
                  type="button"
                  onClick={() => goToPage(3)}
                  className="text-sm text-neutral-400 hover:text-neutral-200 transition-colors"
                >
                  ¿Cuenta nueva? <span className="text-primary-400">Crear contraseña</span>
                </button>

                <button
                  type="button"
                  onClick={() => goToPage(2)}
                  className="text-sm text-neutral-400 hover:text-neutral-200 transition-colors"
                >
                  ¿Olvidaste tu contraseña? <span className="text-primary-400">Recupérala</span>
                </button>
              </div>
            ) : (
              <div className="mt-8 flex justify-center">
                <button
                  type="button"
                  onClick={() => goToPage(1)}
                  className="flex items-center gap-2 text-sm text-neutral-400 hover:text-neutral-200 transition-colors"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                  </svg>
                  {page === 2 ? "Volver a inicio de sesión" : "Ya tengo una cuenta"}
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
    </>
  )
}

export default LoginForm;
