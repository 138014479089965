import React, { useState, useEffect } from 'react';
import { Check, ChevronLeft, ChevronRight } from 'lucide-react';

const Stepper = ({ currentPage, steps }) => {
    // Get current step based on page number
    const getCurrentStep = (page) => {
        return steps.findIndex(step => step.pages ? step.pages.includes(page) : step.page === page) + 1;
    };

    // Show different number of steps based on screen size
    const [screenSize, setScreenSize] = useState(() => {
        const width = window.innerWidth;
        if (width < 500) return 'sm';
        if (width < 768) return 'md';
        if (width < 1200) return 'lg';
        return 'xl';
    });

    const visibleSteps = screenSize === 'sm' ? 2 : screenSize === 'md' ? 3 : screenSize === 'lg' ? 4 : 5;

    // Calculate which steps to show based on current page
    const currentStepIndex = getCurrentStep(currentPage) - 1;
    const [startIndex, setStartIndex] = useState(Math.max(0, Math.min(currentStepIndex - Math.floor(visibleSteps / 2), steps.length - visibleSteps)));
    const displayedSteps = steps.slice(startIndex, startIndex + visibleSteps);

    const canGoBack = startIndex > 0;
    const canGoForward = startIndex + visibleSteps < steps.length;

    const handlePrevious = () => {
        if (canGoBack) {
            setStartIndex(prev => Math.max(0, prev - 1));
        }
    };

    const handleNext = () => {
        if (canGoForward) {
            setStartIndex(prev => Math.min(steps.length - visibleSteps, prev + 1));
        }
    };

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            if (width < 500) setScreenSize('sm');
            else if (width < 768) setScreenSize('md');
            else if (width < 1200) setScreenSize('lg');
            else setScreenSize('xl');
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        // Update startIndex when currentStepIndex changes
        setStartIndex(Math.max(0, Math.min(currentStepIndex - Math.floor(visibleSteps / 2), steps.length - visibleSteps)));
    }, [currentStepIndex, steps.length, visibleSteps]);

    return (
        <div className="z-10 w-full max-w-[min(97.5%,72rem)] select-none mx-auto shrink-0 sticky top-0 backdrop-blur-md bg-neutral-900/30 light:bg-neutral-1000 light:border-neutral-900 light:border-b-[1.75px] light:border-r-[1.75px] light:border-l-[1.75px] rounded-b-xl p-3 lg:p-4 shadow-lg light:shadow-none border-b border-l border-r border-white/5">
            <div className="flex items-center">
                {canGoBack && (
                    <button
                        onClick={handlePrevious}
                        className="p-1 mr-2 rounded-full hover:bg-neutral-800 transition-colors"
                    >
                        <ChevronLeft className="size-5 text-neutral-400" />
                    </button>
                )}

                {displayedSteps.map((step, index) => (
                    <div key={index} className={`flex items-center ${index === displayedSteps.length - 1 ? '' : 'flex-1'}`}>
                        <div className="flex items-center flex-shrink-0 bg-foreground/60 p-2 rounded-lg lg:bg-transparent lg:p-0 lg:rounded-none h-[3rem] lg:h-auto">
                            <div className={`flex items-center justify-center w-6 h-6 lg:w-8 lg:h-8 rounded-full border-2 
                ${getCurrentStep(currentPage) === steps.indexOf(step) + 1
                                    ? 'border-primary-500 bg-primary-500/10 text-primary-500'
                                    : getCurrentStep(currentPage) > steps.indexOf(step) + 1
                                        ? 'border-primary-500 bg-primary-500 text-black'
                                        : 'border-neutral-700 text-neutral-500'
                                }`}>
                                {getCurrentStep(currentPage) > steps.indexOf(step) + 1 ? (
                                    <Check className="size-4 lg:size-5 text-primary-100" />
                                ) : (
                                    <span className="text-sm font-medium">{steps.indexOf(step) + 1}</span>
                                )}
                            </div>

                            <span className={`lg:min-w-min ml-1.5 lg:ml-2 text-xs lg:text-sm font-medium max-w-16 md:max-w-14 lg:max-w-max ${step.label.trim().includes(" ") ? 'line-clamp-2' : 'truncate'}
                ${getCurrentStep(currentPage) === steps.indexOf(step) + 1
                                    ? 'text-primary-500'
                                    : getCurrentStep(currentPage) > steps.indexOf(step) + 1
                                        ? 'text-neutral-200'
                                        : 'text-neutral-500'
                                }`}>
                                {step.label}
                            </span>
                        </div>

                        {index < displayedSteps.length - 1 && (
                            <div className={` min-w-[6px] w-full h-[2px] mx-2 lg:mx-4
                ${getCurrentStep(currentPage) > steps.indexOf(step) + 1
                                    ? 'bg-primary-500'
                                    : 'bg-neutral-700'
                                }`}
                            />
                        )}
                    </div>
                ))}

                {canGoForward && (
                    <button
                        onClick={handleNext}
                        className="p-1 ml-2 rounded-full hover:bg-neutral-800 transition-colors"
                    >
                        <ChevronRight className="size-5 text-neutral-400" />
                    </button>
                )}
            </div>
        </div>
    );
};

export default Stepper;