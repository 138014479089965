import React, { useState, useContext, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import firestore from '../firestore';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../context/GlobalContext';
import { ContainerSpinner } from '../components/Spinner';
import Select from '../components/Select';
import { ucfirst } from '../utils/formatters';
import { Dialog } from '../components/Dialog';
import { DatePicker } from '../shadcn/DatePicker';
import Stepper from '../components/Stepper';
import { Button } from '../shadcn/Button';
import Input from '../components/Input';
import { AlertTriangle, ArrowLeft, CheckCircle, ChevronDown, ChevronUp, List, Search, Wallet, CircleCheck } from 'lucide-react';
import Notice from '../components/Notice';
import classNames from 'classnames';
import { GetBanks } from '../hooks/firebase';

const Pay = () => {
	const { authenticated, userData } = useContext(GlobalContext);

	const [isLoading, setIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [success, setSuccess] = useState(false);

	const country = userData?.country;
	const banks = GetBanks('venezuela');

	const [page, setPage] = useState(1);
	const [cedula, setCedula] = useState('');
	const [showTokenField, setShowTokenField] = useState(false);
	const [loadingButton, setLoadingButton] = useState(false);
	const [payments, setPayments] = useState([]);
	const [amountLeftToPay, setAmountLeftToPay] = useState(0);
	const [email, setEmail] = useState('');
	const [token, setToken] = useState(process.env.REACT_APP_API_TOKEN);
	const [showPayments, setShowPayments] = useState({});
	const [selectedPaymentId, setSelectedPaymentId] = useState('');
	const [paymentDocId, setPaymentDocId] = useState('');
	const [amountToPayDollars, setAmountToPayDollars] = useState('');
	const [paymentDocRef, setPaymentDocRef] = useState('');
	const [exchangeRate, setExchangeRate] = useState('');
	const [payMethod, setPayMethod] = useState('');
	const [confirmationNumber, setConfirmationNumber] = useState('');
	const [amountPaid, setAmountPaid] = useState('');
	const [clientName, setClientName] = useState('');
	const [paymentDate, setPaymentDate] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [bank, setBank] = useState('');
	const [pagoMovilData, setPagoMovilData] = useState({});
	const [transferData, setTransferData] = useState({});
	const [countrySettings, setCountrySettings] = useState({});
	const [admins, setAdmins] = useState({});
	const [selectedAdmin, setSelectedAdmin] = useState('');
	const [twofaNeeded, setTwoFaNeeded] = useState({});
	const [amountReceived, setAmountReceived] = useState('');
	const [subStore, setSubStore] = useState('');
	const [phoneToken, setPhoneToken] = useState('');
	const [subStoreList, setSubStoreList] = useState([]);
	const [tokenVerified, setTokenVerified] = useState(false);
	const [dialogMessage, setDialogMessage] = useState('');
	const [showDialog, setShowDialog] = useState(false);
	const [showConfirmDialog, setShowConfirmDialog] = useState(false);
	const [pendingConfirmAction, setPendingConfirmAction] = useState(null);

	const formatNumber = (number) => {
		return new Intl.NumberFormat('en-US', {
			style: 'decimal',
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		}).format(number);
	};

	const navigate = useNavigate();

	const showAlert = (message) => {
		setDialogMessage(message);
		setShowDialog(true);
	};

	const showConfirm = (message, onConfirm) => {
		setDialogMessage(message);
		setPendingConfirmAction(() => onConfirm);
		setShowConfirmDialog(true);
	};

	useEffect(() => {
		if (success) {
			setPage(4);
		} else if (paymentDocId && exchangeRate) {
			setPage(3);
		} else if (payments && Object.keys(payments).length > 0) {
			setPage(2);
		} else {
			setPage(1);
		}
	}, [success, paymentDocId, exchangeRate, payments]);

	useEffect(() => {
		if (!authenticated) return;

		try {
			if (!userData.master_user) {
				setSubStoreList([userData.store_name]);
			} else {
				setSubStoreList(userData.sub_stores);
			}
		} catch (err) {
			console.log(err);
			showAlert('Ocurrió un problema');
		}

		const getMasterStoreData = async () => {
			try {
				// Fetch all documents from the `Stores` collection and store their IDs in a Set
				const storesSnapshot = await firestore
					.collection('Stores')
					.doc(userData.master_store.id)
					.get()
					.then((doc) => {
						const data = doc.data();
						setAdmins(data.subStoreAdminPhoneNumbers);
						setTwoFaNeeded(data['2fa_needed']);
					});
				console.log(storesSnapshot);
				const storeIdsSet = new Set(storesSnapshot.docs.map((doc) => doc.id));

				// Fetch all documents from the `StoreInfo` collection
				const storeInfoSnapshot = await firestore.collection('StoreInfo').get();

				// Loop through each document in the `StoreInfo` collection
				storeInfoSnapshot.forEach((storeInfoDoc) => {
					// If the document ID from `StoreInfo` is not found in the `Stores` collection, print it
					if (!storeIdsSet.has(storeInfoDoc.id)) {
						console.log(`Unmatched StoreInfo Document ID: ${storeInfoDoc.id}`);
					}
				});
			} catch (error) {
				console.error('Error finding unmatched StoreInfo documents: ', error);
			}
		};
		getMasterStoreData();
	}, []);

	const sendPostRequest = async (data, endpoint, token2) => {
		try {
			const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${await firebase.auth().currentUser.getIdToken(true)}`,
					'x-master-store-email': userData.master_store.id,
					'x-user-email': userData.email
				},
				body: JSON.stringify({ ...data, country: country })
			});
			const responseData = await response.json();
			if (response.status === '401') {
				const tokenData = await requestToken();
				await setToken('Token ' + tokenData.token);

				const resData = await sendPostRequest(data, endpoint, 'Token ' + tokenData.token);
				return resData;
			}
			return responseData;
		} catch (error) {
			console.error('Error:', error.message);
			return null;
		}
	};

	const requestToken = async () => {
		const loginData = {
			username: process.env.REACT_APP_TOKEN_USERNAME,
			password: process.env.REACT_APP_TOKEN_PASSWORD
		};
		try {
			const response = await fetch(process.env.REACT_APP_API_URL + 'api/token/', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(loginData)
			});

			const responseData = await response.json();
			return responseData;
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setIsLoading(true);
		setErrorMessage('');

		try {
			const countryDoc = await firestore.collection('AppSetting').doc('CountrySettings').get();
			const countryData = countryDoc.data();
			setCountrySettings(countryData[country]);

			const cedulaData = {
				identification_number: cedula
			};
			const response = await sendPostRequest(cedulaData, 'registration/usercheck/', token); //status, success, data
			if (response?.success) {
				if (response.data.is_user_registered) {
					if (response?.status === 'El usuario se registro en otro pais. Contacte a soporte tecnico.') {
						setErrorMessage(response.status);
						setIsLoading(false);
					} else {
						await getPayments();
					}
						
				} else {
					setErrorMessage(`No encontramos ningun cliente asociado a ${country === 'peru' ? 'este DNI' : 'esta cédula'}.`);
					setIsLoading(false);
				}
			} else if (response?.success === false) {
				setErrorMessage(response.status);
				setIsLoading(false);
			} else {
				setErrorMessage('Estamos teniendo problemas para conectarnos.');
				setIsLoading(false);
			}
		} catch (error) {
			setErrorMessage('Estamos teniendo problemas para conectarnos.');
			setIsLoading(false);
		}
	};

	const fetchDeviceDetails = async (deviceIds, paymentDetails) => {
		try {
			const promises = deviceIds.map(async (id) => {
				const deviceDoc = await firestore.collection('Devices').doc(id).get();
				const deviceData = deviceDoc.data();
				paymentDetails[id].name = deviceData.device_commercial_name
					? deviceData.device_commercial_name
					: `${deviceData.device_manufacturer} ${deviceData.device_model}`;
				paymentDetails[id].date = formatDate(deviceData.date_bought);
			});
			await Promise.all(promises);
		} catch (err) {
			setErrorMessage('Ocurrio un error: ' + err.message);
			setIsLoading(false);
		}
	};

	const getPayments = async () => {
		const paymentsCollection = firestore.collection('Payments');

		// Get a reference to the Firestore collection for users
		const usersCollection = firestore.collection('Users');

		// Query the collection based on the "user" field reference
		try {
			const queryUser = usersCollection.where('identification_number', '==', cedula).limit(1);
			await queryUser
				.get()
				.then((querySnapshot) => {
					querySnapshot.forEach((doc) => {
						setEmail(doc.id);
						setClientName(doc.data()['first_name'] + ' ' + doc.data()['last_name']);
						const showPayments2 = {};
						let isDevicePaymentsShown = true;
						const query = paymentsCollection
							.where('user', '==', usersCollection.doc(doc.id))
							.where('is_active', '==', true)
							.orderBy('dueDate', 'asc');
						const unsubscribe = query.onSnapshot(async (querySnapshot) => {
							const paymentDetails = {};
							let amountLeft = 0;

							const deviceIds = [];
							const showPayments2 = {};
							let isDevicePaymentsShown = true;

							try {
								const promises = querySnapshot.docs.map(async (doc) => {
									const data = doc.data();
									amountLeft += data.actualAmount - data.amountPaid;

									if (paymentDetails.hasOwnProperty(data.device.id)) {
										paymentDetails[data.device.id].payments.push({ ...data, id: doc.id });
									} else {
										paymentDetails[data.device.id] = {
											payments: [{ ...data, id: doc.id }],
											name: data.device.id,
											date: '',
											isDelayed: false
										};
										deviceIds.push(data.device.id);
										if (isDevicePaymentsShown) {
											showPayments2[data.device.id] = true;
											isDevicePaymentsShown = false;
										} else {
											showPayments2[data.device.id] = false;
										}
									}

									const dueDate = data.dueDate.toDate();
									dueDate.setDate(dueDate.getDate() + 1);
									if (!data.paid && dueDate < new Date()) {
										paymentDetails[data.device.id].isDelayed = true;
									}
								});

								await Promise.all(promises);
								await fetchDeviceDetails(deviceIds, paymentDetails);

								setPayments(paymentDetails);
								setShowPayments(showPayments2);
								setAmountLeftToPay(amountLeft);
								setIsLoading(false);

								if (typeof paymentDetails !== 'object' || !Object.keys(paymentDetails).length) {
									showAlert(
										'No tienes cuotas pendientes. Dirígete a una de nuestras tiendas aliadas para obtener tu equipo a cuotas HOY!'
									);
								}
							} catch (err) {
								console.error('Error processing payments:', err.message);
								setErrorMessage('Ocurrió un problema, intente de nuevo más tarde.');
								setIsLoading(false);
							}
						});
					});
				})
				.catch((error) => {
					setIsLoading(false);
					showAlert('Error: ' + error.message);
				});
		} catch (error) {
			setIsLoading(false);
			showAlert('Error: ' + error.message);
		}
	};

	const showPaymentScreen = (paymentId) => {
		setIsLoading(true);

		// Query the collection based on the "user" field reference
		firestore
			.collection('Payments')
			.doc(paymentId)
			.get()
			.then(async (doc) => {
				// Access the document data
				if (
					(doc.data().country !== userData.country ||
						(doc.data().self_financed && doc.data().related_store !== userData.master_store.id)) &&
					!userData.cobranza_access
				) {
					showAlert(
						'Este pago corresponde a una venta realizada en ' +
							doc.data().sub_store +
							', para pagar esta cuota el cliente debe realizarlo en la tienda donde compro el equipo.'
					);
					setIsLoading(false);
					return;
				}
				const amountToPay = doc.data()['actualAmount'] - doc.data()['amountPaid'];
				setPaymentDocId(doc.id);
				setAmountToPayDollars(amountToPay);
				setPaymentDocRef(doc);

				let paymentReceiver = 'default';
				if (doc.data().store_financed === true) {
					paymentReceiver = doc.data().related_store;
				}

				firestore
					.collection('StorePaymentMethods')
					.doc(paymentReceiver)
					.onSnapshot(async (doc) => {
						if (doc.exists) {
							setPagoMovilData(doc.data()['pagomovil']);
							setTransferData(doc.data()['transferBs']);
						}
						setIsLoading(false);
					});

				const docRef = await firestore.collection('DollarPrice').doc('dollarPrice');
				docRef.get().then((doc) => {
					if (doc.exists) {
						setExchangeRate(doc.data()['bcv']);
					} else {
						showAlert('Ocurrió un problema, intente de nuevo más tarde.');
						setIsLoading(false);
					}
				});
			});
	};

	function convertToDotDecimal(str) {
		if (/^[+-]?(\d{1,3}(,\d{3})*|\d+)(,\d+)?$/.test(str)) {
			return str.replace(/,/g, '.');
		} else {
			return str;
		}
	}

	const isNumber = async (value) => {
		return /^[+-]?(\d{1,3}(,\d{3})*|\d+)(\.\d+)?(,\d+)?$/.test(value);
	};

	const confirmPayment = async () => {
		await updatePaymentDoc(amountReceived);
	};

	const updatePaymentDoc = async () => {
		try {
			const response = await sendStorePaymentConfirmation(paymentDocId);
			if (response?.success) {
				setSuccess(true);
				setAmountReceived('');
				setIsLoading(false);
			} else if (response?.success == false) {
				setIsLoading(false);
				setErrorMessage(response.status);
			} else {
				setIsLoading(false);
				setErrorMessage('Ocurrió un problema');
			}
		} catch (err) {
			showAlert('Ocurrio un error');
			setIsLoading(false);
		}
	};

	const sendStorePaymentConfirmation = async (doc) => {
		const data = {
			payment_object_id: doc,
			payment_amount: parseFloat(convertToDotDecimal(amountReceived)),
			store_name: userData.master_store.id,
			sub_store: subStore,
			identification_number: cedula,
			store_user: userData.email
		};
		return await sendPostRequest(data, 'payments/processstorepayment/', token); //status, success, data
	};

	const handlePaymentSubmit = async (e) => {
		e.preventDefault();
		if (payMethod === 'cash') {
			if (!amountReceived || !subStore) {
				setErrorMessage('Llene todos los datos para continuar.');
				return;
			} else if ((await isNumber(amountReceived)) === false) {
				setErrorMessage('El monto a pagar debe ser expresado en números.');
				return;
			} else if (amountReceived > countrySettings.max_cuota_payment) {
				setErrorMessage(
					'El monto máximo por registro es de ' +
						countrySettings.currency_code +
						countrySettings.max_cuota_payment +
						', si desea registrar más del máximo puede realizarlo en dos (o más) registros.'
				);
				return;
			} else if (twofaNeeded && !tokenVerified) {
				setErrorMessage('Por favor complete la verificación de 2FA para continuar');
				return;
			}

			showConfirm(`¿Deseas continuar con este registro de ${countrySettings.currency_code}${amountReceived}?`, async () => {
				setIsLoading(true);
				try {
					await confirmPayment();
				} catch (error) {
					setErrorMessage('Estamos teniendo problemas para conectarnos.');
					setIsLoading(false);
				}
			});
			return;
		}
		if (!confirmationNumber || !amountPaid || !paymentDate || !payMethod) {
			showAlert('Llene todos los datos para continuar.');
			return;
		} else {
			setIsLoading(true);
			try {
				const response = await sendPaymentConfirmation();
				if (response?.success) {
					setIsLoading(false);
					showAlert('Su pago fue confirmado exitosamente');
				} else if (response?.success == false) {
					setIsLoading(false);
					showAlert('Error: ' + response?.status);
				} else {
					showAlert('Ocurrió un problema intente más tarde');
					setIsLoading(false);
				}
			} catch (err) {
				showAlert(err.message);
				setIsLoading(false);
			}
		}
	};

	function formatForAPI(date) {
		// Format the date as yyyy/mm/dd
		const formattedDate = date
			.toLocaleString('en-CA', {
				year: 'numeric',
				month: '2-digit',
				day: '2-digit',
				timeZone: 'America/New_York' // GMT-4 timezone (Eastern Time)
			})
			.replace(/\//g, '-'); // Replace slashes with dashes

		return formattedDate;
	}

	function replaceFirstCharWith58(originalString) {
		if (typeof originalString !== 'string' || originalString.length === 0) {
			// Handle invalid input or empty string
			return originalString;
		}

		const modifiedString = '58' + originalString.substring(1);
		return modifiedString;
	}

	const sendPaymentConfirmation = async () => {
		//todo: check if amountPaid is numerical before sending the call
		let data;
		if (payMethod === 'transfer') {
			data = {
				payment_object_id: paymentDocId,
				reference: confirmationNumber,
				amount: convertToDotDecimal(amountPaid),
				payment_type: 'TRANSFER',
				date: formatForAPI(paymentDate),
				api_type: 'banesco'
			};
		} else {
			data = {
				payment_object_id: paymentDocId,
				reference: confirmationNumber,
				amount: convertToDotDecimal(amountPaid),
				payment_type: 'P2C',
				phone_num: replaceFirstCharWith58(phoneNumber),
				bank_code: bank.split(' - ')[0],
				date: formatForAPI(paymentDate),
				api_type: 'banesco'
			};
		}
		try {
			const tokenData = await requestToken();
			const response = await fetch('https://api.krece.app/process_payment/', {
				//agregar un catch aqui
				method: 'POST',
				// mode: 'cors',
				headers: {
					'Content-Type': 'application/json',
					Authorization: 'Token ' + tokenData.token
				},
				body: JSON.stringify(data)
			});
			const responseData = await response.json();
			return responseData;
		} catch (error) {
			console.error('Error:', error.message);
			return null;
		}
	};

	const fillToken = (token) => {
		setPhoneToken(token);
		if (token.length === 6) {
			setShowTokenField(false);
			setLoadingButton(true);
			confirmPhoneTokenFromUser(token);
		} else if (token.length > 6) {
			showAlert('El Token debe ser de 6 digitos.');
		}
	};

	const confirmPhoneTokenFromUser = async (ptoken) => {
		setErrorMessage('');
		if (!ptoken) {
			setErrorMessage('Llene todos los datos para continuar.');
			return;
		}
		try {
			const codeData = {
				confirmation_code: parseInt(ptoken)
			};
			const response = await sendPostRequest(codeData, 'notifications/confirmauthcode/', token);
			if (response?.success) {
				setLoadingButton(false);
				setTokenVerified(true);
				showAlert('Token confirmado exitosamente');
			} else if (response?.success === false) {
				showAlert(response.status);
				setLoadingButton(false);
				setShowTokenField(true);
			} else {
				showAlert('Estamos teniendo problemas para conectarnos.');
				setLoadingButton(false);
				setShowTokenField(true);
			}
		} catch (error) {
			alert('Estamos teniendo problemas para conectarnos.');
			setLoadingButton(false);
			setShowTokenField(true);
		}
	};

	const sendPhoneToken = async () => {
		setErrorMessage('');
		setShowTokenField(false);
		setLoadingButton(true);
		try {
			if (!selectedAdmin) {
				showAlert('Por favor introducir número de teléfono.');
				setIsLoading(false);
				return;
			}
			const codeData = {
				phone_number: admins[selectedAdmin],
				code_type: 'sms'
			};
			showAlert('Se ha enviado un token por SMS al ' + admins[selectedAdmin]);
			const response = await sendPostRequest(codeData, 'notifications/requestauthcode/', token); //status, success, data
			if (response?.success) {
				setShowTokenField(true);
				setLoadingButton(false);
			} else if (response?.success === false) {
				alert(response.status);
				setLoadingButton(false);
			} else {
				alert('Estamos teniendo problemas para conectarnos.');
				setLoadingButton(false);
			}
		} catch (error) {
			alert('Estamos teniendo problemas para conectarnos.');
			setLoadingButton(false);
		}
	};

	const formatDate = (timestamp) => {
		const date = timestamp.toDate(); // Convert Firestore timestamp to JavaScript Date object
		const month = date.toLocaleDateString('es-ES', { month: 'short' }); // Get the short month name
		const day = date.getDate(); // Get the day of the
		return `${day} de ${month.charAt(0).toUpperCase() + month.slice(1)}`;
	};

	const GetPaymentObjects = (paymentList) => {
		var isFuturePayment = true;
		var checkedByInternal = false;
		var isFirst = true;
		const paym = [];
		paymentList.forEach((item, index) => {
			if (item.paid && item.checked_by_internal_team) {
				checkedByInternal = true;
				var PayObj = (
					<div className="flex justify-center">
						<span className="text-sm text-primary-500">PAGADO</span>
					</div>
				);
			} else if (item.paid && !item.checked_by_internal_team) {
				checkedByInternal = false;
				var PayObj = (
					<div className="flex justify-center">
						<span className="text-sm text-neutral-400">Por Confirmar</span>
					</div>
				);
			} else {
				var PayObj = <div className="flex justify-center"></div>;
			}
			if (isFuturePayment && !item.paid && checkedByInternal) {
				PayObj = (
					<Button
						disabled={isLoading}
						loading={isLoading && item.id === selectedPaymentId}
						onClick={() => {
							setSelectedPaymentId(item.id);
							showPaymentScreen(item.id);
						}}
						variant="primary"
						className="!h-8 !w-min !justify-self-center"
					>
						PAGAR
					</Button>
				);
				isFuturePayment = false;
				checkedByInternal = true;
			}
			if (isFirst && !item.paid) {
				PayObj = (
					<Button
						disabled={isLoading}
						loading={isLoading && item.id === selectedPaymentId}
						onClick={() => {
							setSelectedPaymentId(item.id);
							showPaymentScreen(item.id);
						}}
						variant="primary"
						className="!h-8 !w-min !justify-self-center"
					>
						PAGAR
					</Button>
				);
			}
			isFirst = false;
			paym.push(
				<div key={index} className="grid grid-cols-3 items-center px-2 py-3">
					<span className="text-left text-sm text-neutral-300">{formatDate(item.dueDate)}</span>
					{PayObj}
					<span className="text-right text-sm text-neutral-300">
						{countrySettings.currency_code + (item.actualAmount - item.amountPaid).toFixed(2)}
					</span>
				</div>
			);
		});
		return paym;
	};

	const steps = [
		{ label: 'Buscar Cliente', page: 1 },
		{ label: 'Seleccionar Cuota', page: 2 },
		{ label: 'Realizar Pago', page: 3 },
		{ label: 'Pago Exitoso', page: 4 }
	];

	if (!authenticated || !userData) return <ContainerSpinner size={3} />;

	return (
		<div className="m-auto flex min-h-full min-w-full flex-col items-center justify-start">
			<Stepper currentPage={page} steps={steps} />

			<div className="m-auto flex h-full w-full flex-col items-center justify-center p-4 md:p-6">
				<div
					className={classNames(
						'w-[500px] max-w-full rounded-xl border-[1.5px] border-neutral-900 bg-neutral-1000/25 p-6 shadow-lg duration-300 animate-in fade-in slide-in-from-bottom-5 light:bg-white light:shadow-none md:bg-background/50 md:p-8',
						page === 2 && 'w-[650px]'
					)}
				>
					{page === 1 && (
						<>
							{render_page_header('Buscar Cliente', `Por favor ingrese ${country === 'peru' ? 'el DNI' : 'la cédula'} del cliente que deseas buscar`, <Search />)}

							<form className="space-y-4" onSubmit={handleSubmit}>
								<Input
									name="cedula"
									floatingLabel={`${country === 'peru' ? 'DNI' : 'Cédula'} del cliente`}
									value={cedula}
									onChange={(value) => setCedula(value)}
									placeholder={`Ingrese ${country === 'peru' ? 'el DNI' : 'la cédula'} del cliente`}
									allowedChars="numbers_only"
								/>

								{errorMessage && <Notice message={errorMessage} variant="error" />}

								<Button
									type="submit"
									onClick={handleSubmit}
									loading={isLoading}
									disabled={isLoading || !cedula}
									variant="primary"
								>
									Continuar
								</Button>
							</form>
						</>
					)}

					{page === 2 && (
						<>
							{render_page_header('Mis Cuotas', null, <List />)}

							{Object.keys(payments).length > 0 && (
								<div className="space-y-4">
									<div className="flex items-center justify-center gap-3 rounded-xl border border-neutral-700/50 bg-neutral-800/50 p-3 text-center light:bg-background sm:gap-6 md:p-4">
										<div className="flex w-[65%] flex-col items-center">
											<span className="self-start text-sm font-medium text-neutral-400">Cliente</span>
											<span
												className="w-full truncate text-left font-semibold text-primary-400 light:text-primary-600 md:text-lg"
												title={clientName}
											>
												{clientName}
											</span>
										</div>

										<div className="h-8 w-[1px] bg-neutral-700/50"></div>

										<div className="flex w-[35%] flex-col items-center">
											<span className="self-end text-sm font-medium text-neutral-400">{country === 'peru' ? 'DNI' : 'Cédula'}</span>
											<span
												className="w-full truncate text-right font-semibold text-primary-400 light:text-primary-600 md:text-lg"
												title={cedula}
											>
												{cedula}
											</span>
										</div>
									</div>

									{Object.keys(payments).map((item) => (
										<div
											key={item}
											className="rounded-xl border border-neutral-700/50 bg-neutral-800/50 p-4 transition-colors hover:border-neutral-600/50 light:bg-background"
										>
											<button
												className="group flex w-full items-center justify-between"
												onClick={() => setShowPayments({ ...showPayments, [item]: !showPayments[item] })}
											>
												<div className="flex flex-col items-start">
													<span className="text-lg font-medium text-neutral-0 transition-colors group-hover:text-primary-400">
														{payments[item].name}
													</span>
													<span className="text-sm text-neutral-400">{'Comprado el ' + payments[item].date}</span>
												</div>

												<div className="flex items-center gap-3">
													{payments[item].isDelayed && (
														<div className="flex animate-pulse flex-col items-center">
															<AlertTriangle className="size-5 text-red-500" />
															<span className="text-xs font-medium text-red-500">Cuota vencida</span>
														</div>
													)}

													{showPayments[item] ? (
														<ChevronUp className="size-5 text-neutral-400 transition-colors group-hover:text-primary-400" />
													) : (
														<ChevronDown className="size-5 text-neutral-400 transition-colors group-hover:text-primary-400" />
													)}
												</div>
											</button>

											{showPayments[item] && (
												<div className="mt-4 space-y-4">
													<div className="grid grid-cols-3 border-b border-neutral-700/50 px-2 pb-2 text-sm font-medium text-neutral-400">
														<span className="text-left">Vence</span>
														<span className="text-center">Estado</span>
														<span className="text-right">Debe</span>
													</div>

													<div className="divide-y divide-neutral-700/30">{GetPaymentObjects(payments[item].payments)}</div>

													<div className="flex justify-between border-t border-neutral-700 pt-4">
														<span className="font-medium text-neutral-200">Saldo restante</span>
														<span className="font-medium text-primary-400">
															{countrySettings.currency_code +
																formatNumber(
																	payments[item].payments
																		.reduce((total, payment) => total + (payment.actualAmount - payment.amountPaid), 0)
																		.toFixed(2)
																)}
														</span>
													</div>
												</div>
											)}
										</div>
									))}
								</div>
							)}

							<Button
								onClick={() => (window.location.href = '/buscarcliente')}
								Icon={<ArrowLeft className="size-5" />}
								variant="secondary"
								className="mt-4 w-full"
							>
								Atrás
							</Button>
						</>
					)}

					{page === 3 && (
						<>
							{render_page_header('Pago de Cuota', 'Ingresa los detalles del pago', <Wallet />)}

							<div className="space-y-6">
								<div className="-mb-2 flex items-center justify-center gap-3 rounded-xl border border-neutral-700/50 bg-neutral-800/50 p-3 text-center light:bg-background sm:gap-6 md:p-4">
									<div className="flex w-[65%] flex-col items-center">
										<span className="self-start text-sm font-medium text-neutral-400">Cliente</span>
										<span
											className="w-full truncate text-left font-semibold text-primary-400 light:text-primary-600 md:text-lg"
											title={clientName}
										>
											{clientName}
										</span>
									</div>

									<div className="h-8 w-[1px] bg-neutral-700/50"></div>

									<div className="flex w-[35%] flex-col items-center">
										<span className="self-end text-sm font-medium text-neutral-400">{country === 'peru' ? 'DNI' : 'Cédula'}</span>
										<span
											className="w-full truncate text-right font-semibold text-primary-400 light:text-primary-600 md:text-lg"
											title={cedula}
										>
											{cedula}
										</span>
									</div>
								</div>
								<div className={`rounded-xl border border-neutral-700/50 bg-neutral-800/50 p-4 light:bg-neutral-1000 ${country === 'venezuela' ? '!-mb-2' : ''}`}>
									<span className="text-sm text-neutral-400">Monto a pagar</span>
									<div className="mt-1 text-lg font-medium text-primary-400">
										{countrySettings.currency_code +
											amountToPayDollars.toFixed(2) +
											(country === 'venezuela' ? ' / Bs. ' + (amountToPayDollars * exchangeRate).toFixed(2) + ' ' : '')}
										{country === 'venezuela' && <span className="text-sm text-neutral-400">(A tasa BCV)</span>}
									</div>
								</div>

								{country === 'venezuela' && (
									<div className=" rounded-xl border border-neutral-700/50 bg-neutral-800/50 p-4 light:bg-neutral-1000">
										<h2 className="mb-4 text-lg font-medium text-neutral-100">Detalles de las cuentas a pagar</h2>

										<div className="space-y-4">
											<div>
												<h3 className="mb-2 text-sm font-medium text-primary-400">PagoMovil</h3>
												<div className="space-y-1 text-sm text-neutral-300">
													<p>Banco: {pagoMovilData.bank}</p>
													<p>Rif: {pagoMovilData.rif}</p>
													<p>Teléfono: {pagoMovilData.phoneNumber}</p>
												</div>
											</div>
											<div>
												<h3 className="mb-2 text-sm font-medium text-primary-400">Transferencia</h3>
												<div className="space-y-1 text-sm text-neutral-300">
													<p>Cuenta: {transferData.account}</p>
													<p>Rif: {transferData.rif}</p>
													<p>Banco: {transferData.bank}</p>
													<p>Tipo: {ucfirst(transferData?.type || '')}</p>
												</div>
											</div>
										</div>
									</div>
								)}

								<form className="space-y-4" onSubmit={handlePaymentSubmit}>
									<div className="flex flex-wrap gap-4">
										<Select
											floatingLabel="Método de pago"
											options={[
												...(!userData.cobranza_access ? [{ label: 'Pago en tienda', value: 'cash' }] : []),
												...(country === 'venezuela' ? [{ label: 'Pago Movil', value: 'pagomovil' }] : []),
												...(country === 'venezuela' ? [{ label: 'Transferencia', value: 'transfer' }] : [])
											]}
											defaultValue={payMethod}
											onSelectedChange={(value) => setPayMethod(value)}
											placeholder="Seleccionar método"
										/>
										{payMethod === 'pagomovil' && (
											<Select
												floatingLabel="Banco de origen del Pagomovil"
												options={banks.map((item) => ({ label: item, value: item }))}
												onSelectedChange={(value) => setBank(value)}
												placeholder="Seleccionar banco"
												containerClassName="flex-grow min-w-[200px]"
											/>
										)}
										{payMethod === 'cash' && (
											<Select
												floatingLabel="Local"
												options={subStoreList.map((item) => ({ label: item, value: item }))}
												defaultValue={subStore}
												onSelectedChange={(value) => setSubStore(value)}
												placeholder="Seleccionar local"
											/>
										)}
									</div>

									{payMethod === 'cash' && (
										<>
											<Input
												floatingLabel={`Monto recibido (en ${countrySettings.currency_name})`}
												value={amountReceived}
												onChange={(value) => setAmountReceived(value)}
												allowedChars="numeric"
												prefix={countrySettings.currency_code}
												placeholder={`0.00`}
											/>

											{twofaNeeded && (
												<div className="space-y-4 rounded-xl border border-neutral-700/50 bg-neutral-800/50 p-4 light:bg-neutral-1000">
													<h2 className="text-lg font-medium text-neutral-100">Verificación 2FA</h2>

													<div className="space-y-1">
														<label className="text-sm font-medium text-neutral-300">Admin</label>
														<Select
															options={Object.keys(admins).map((item) => ({
																label: `${item} - ******${admins[item].substring(admins[item].length - 4)}`,
																value: item
															}))}
															defaultValue={selectedAdmin}
															onSelectedChange={(value) => setSelectedAdmin(value)}
															placeholder="Seleccionar admin"
														/>
													</div>

													<div className="flex items-center gap-4">
														{tokenVerified ? (
															<div className="flex items-center gap-2 text-primary-400">
																<CheckCircle className="size-5" />
																<span className="text-sm font-medium">Verificado</span>
															</div>
														) : (
															<button
																onClick={sendPhoneToken}
																disabled={tokenVerified}
																className="rounded-lg bg-primary-500 px-4 py-2 font-medium text-black transition-colors hover:bg-primary-600"
															>
																Enviar Token
															</button>
														)}
													</div>

													{showTokenField && (
														<Input
															floatingLabel="Token"
															value={phoneToken}
															placeholder="Ingrese el token"
															onChange={(value) => fillToken(value)}
														/>
													)}

													{loadingButton && (
														<div className="flex justify-center">
															<div className="h-8 w-8 animate-spin rounded-full border-b-2 border-primary-500"></div>
														</div>
													)}
												</div>
											)}
										</>
									)}

									{payMethod !== 'cash' && payMethod && country === 'venezuela' && (
										<>
											<Input
												floatingLabel="Número de referencia"
												value={confirmationNumber}
												onChange={(value) => setConfirmationNumber(value.trim())}
												placeholder="Ingrese el número de referencia"
											/>
											<Input
												floatingLabel="Monto del pago (en Bolivares)"
												value={amountPaid}
												onChange={(value) => setAmountPaid(value.trim())}
												allowedChars="numeric"
												placeholder={`0.00`}
											/>
											{payMethod === 'pagomovil' && (
												<Input
													type="tel"
													floatingLabel="Teléfono de origen"
													value={phoneNumber}
													onChange={(value) => setPhoneNumber(value.trim())}
													placeholder="Ejemplo: 04141234567"
													allowedChars="numbers_only"
												/>
											)}

											<DatePicker floatingLabel="Fecha del pago" onDateChange={(date) => setPaymentDate(date)} />
										</>
									)}

									{errorMessage && <Notice message={errorMessage} variant="error" />}

									<div className="flex flex-wrap gap-2 md:flex-row-reverse md:flex-nowrap">
										<Button onClick={handlePaymentSubmit} loading={isLoading} disabled={isLoading} variant="primary">
											Enviar
										</Button>

										<Button
											disabled={isLoading}
											onClick={() => {
												setPaymentDocId('');
											}}
											Icon={<ArrowLeft className="size-5" />}
											variant="secondary"
										>
											Atrás
										</Button>
									</div>
								</form>
							</div>
						</>
					)}

					{page === 4 && (
						<>
							{render_page_header('Pago Exitoso', '¡El pago se ha completado correctamente!', <CircleCheck />)}

							<div className="flex gap-2">
								<Button
									Icon={<ArrowLeft className="size-5" />}
									onClick={() => {
										setSuccess(false);
										setPaymentDocId('');
									}}
									variant="primary"
								>
									Realizar otro pago
								</Button>
								<Button
									onClick={() => {
										window.location.href = '/buscarcliente';
									}}
									variant="secondary"
								>
									Buscar otro cliente
								</Button>
							</div>
						</>
					)}
				</div>
			</div>

			{showDialog && <Dialog onClose={() => setShowDialog(false)}>{dialogMessage}</Dialog>}

			{showConfirmDialog && (
				<Dialog closable={false} title="Confirmar" onClose={() => setShowConfirmDialog(false)}>
					<div className="flex flex-col gap-4">
						<div dangerouslySetInnerHTML={{ __html: dialogMessage }} />
						<div className="flex justify-end gap-2">
							<button
								onClick={() => setShowConfirmDialog(false)}
								className="inline-flex h-[35px] items-center justify-center rounded-lg px-[15px] font-medium leading-none text-neutral-600 transition-colors hover:bg-neutral-900/50 focus:outline-none disabled:pointer-events-none disabled:opacity-50"
							>
								Cancelar
							</button>
							<button
								onClick={() => {
									setShowConfirmDialog(false);
									pendingConfirmAction();
								}}
								className="inline-flex h-[35px] items-center justify-center rounded-lg bg-primary-500 px-[15px] font-medium leading-none text-black transition-colors hover:bg-primary-600 focus:outline-none disabled:pointer-events-none disabled:opacity-50"
							>
								Confirmar
							</button>
						</div>
					</div>
				</Dialog>
			)}
		</div>
	);

	function render_page_header(title, description, icon) {
		return (
			<div className="mb-4 flex flex-col items-center gap-2 md:mb-6 md:gap-3">
				<div className="relative">
					<div className="absolute -inset-1 rounded-full bg-gradient-to-r from-primary-400 to-primary-600 opacity-10 blur"></div>
					<div className="relative rounded-full bg-neutral-900 p-4 light:bg-neutral-1000 light:ring-2 light:ring-primary-500/10">
						{icon && React.cloneElement(icon, { className: 'h-6 w-6 text-primary-500' })}
					</div>
				</div>
				<div className="space-y-1 text-center">
					<h1 className="text-2xl font-semibold tracking-tighter text-neutral-0 md:text-3xl">{title}</h1>
					{description && <p className="text-neutral-300">{description}</p>}
				</div>
			</div>
		);
	}
};

export default Pay;
