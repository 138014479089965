import classNames from 'classnames';

export default function Spinner({ size = 1.75 }) {
	return (
		<span className="spinner" style={{ width: `${size}rem`, height: `${size}rem`, borderWidth: `${size * 0.133}rem` }}></span>
	);
}

export function ContainerSpinner({ size = 1.75, className = '', children = null }) {
	return (
		<div className={classNames('flex h-full w-full items-center justify-center', className)}>
			<span className="spinner" style={{ width: `${size}rem`, height: `${size}rem`, borderWidth: `${size * 0.133}rem` }}></span>
			{children}
		</div>
	);
}
