import { useNavigate } from 'react-router-dom';
import { Dialog } from './Dialog';

export default function ErrorRedirectDialog({ message, redirect = true }: { message?: string, redirect?: boolean }) {
	const navigate = useNavigate();

	return (
		<Dialog title="¡Error!" onClose={() => redirect ? navigate('/') : undefined}>
			{message || 'Ha ocurrido un error cargando esta página. Será redirigido a Inicio.'}
		</Dialog>
	);
}
