import * as RSelect from '@radix-ui/react-select';
import classNames from 'classnames';
import { CheckIcon, ChevronDown, ChevronUp } from 'lucide-react';
import { ReactElement, useEffect, useState } from 'react';

export default function Select({
	options,
	defaultValue,
	onSelectedChange,
	title,
	disabled,
	className = '',
	containerClassName = '',
	Icon,
	placeholder,
	floatingLabel
}: {
	options: { label: string; value: string | boolean }[];
	defaultValue?: string | boolean;
	onSelectedChange: (selected: string | boolean | null) => any;
	title?: string;
	disabled?: boolean;
	className?: string;
	containerClassName?: string;
	Icon?: ReactElement;
	placeholder?: string;
	floatingLabel?: string;
}) {
	const [open, setOpen] = useState(false);

	const [selectedOption, setSelectedOption] = useState(defaultValue);

	useEffect(() => {
		setSelectedOption(defaultValue);
	}, [defaultValue]);

	return (
		<RSelect.Root
			open={open}
			onOpenChange={setOpen}
			value={selectedOption?.toString()}
			onValueChange={(selected) => {
				const selectedOption = selected === 'true' ? true : selected === 'false' ? false : selected;
				setSelectedOption(selectedOption);
				onSelectedChange(selected === defaultValue?.toString() ? null : selectedOption);
			}}
		>
			<div className={classNames(containerClassName, 'space-y-1')}>
				{title && <h3 className="font-medium tracking-tight text-neutral-300">{title}</h3>}
				<div className={classNames(
					'relative',
					{ 'bg-neutral-925 border border-neutral-800 w-full rounded-lg light:bg-neutral-950': floatingLabel }
				)}>
					<RSelect.Trigger
						disabled={disabled}
						onPointerUp={() => setOpen((open) => !open)}
						onPointerDown={(e) => e.preventDefault()}
						className={classNames(
							'inline-flex h-10 w-full shrink-0 select-none items-center justify-between gap-2 text-nowrap rounded-lg px-3 text-sm font-medium outline-none transition-colors hover:bg-primary-600 disabled:pointer-events-none disabled:opacity-60',
							{
								'ring-[2.5px] ring-primary-500': open && floatingLabel,
								'font-normal text-neutral-300': !selectedOption,
								'bg-neutral-1000 light:bg-neutral-900/70': !floatingLabel,
								'!bg-transparent !text-base !h-12': floatingLabel,
								'!text-neutral-400 !font-normal': floatingLabel && typeof selectedOption === 'undefined',
								'!text-neutral-100 !font-medium': floatingLabel && typeof selectedOption !== 'undefined'
							},
							className
						)}
					>
						<div className="flex items-center gap-1 truncate">
							{Icon && <div className="shrink-0">{Icon}</div>}
							<RSelect.Value placeholder={placeholder} />
						</div>
						<div className="flex items-center justify-end gap-0.5">
							<RSelect.Icon className="shrink-0">
								<ChevronDown className="size-4 shrink-0 !text-neutral-100" strokeWidth={2.5} />
							</RSelect.Icon>
						</div>
					</RSelect.Trigger>
					{floatingLabel && (
					<label className="absolute -top-2 left-2 px-1 text-xs font-medium text-primary-200/80 bg-neutral-1000 rounded light:text-primary-900 light:bg-primary-300 overflow-hidden overflow-ellipsis text-nowrap max-w-[90%]">
							{floatingLabel}
						</label>
					)}
				</div>
			</div>
			<RSelect.Portal>
				<RSelect.Content className="z-50 overflow-hidden rounded-lg bg-primary-600 text-sm font-medium max-w-[97.5%] mx-auto">
					<RSelect.ScrollUpButton className="flex h-6 cursor-pointer items-center justify-center bg-primary-500 text-primary-50">
						<ChevronUp className="size-4" strokeWidth={2.5} />
					</RSelect.ScrollUpButton>
					<RSelect.Viewport className="p-[5px]">
						{options.map((option, idx) => (
							<RSelect.Item
								key={idx}
								value={option.value.toString()}
								className="relative flex h-10 py-2 cursor-pointer select-none items-center rounded pl-[25px] pr-[35px] text-primary-200 data-[highlighted]:bg-primary-500 data-[highlighted]:text-primary-50 data-[state='checked']:text-primary-50 data-[highlighted]:outline-none"
							>
								<RSelect.ItemText>{option.label}</RSelect.ItemText>
								<RSelect.ItemIndicator className="absolute left-0 inline-flex w-[25px] items-center justify-center">
									<CheckIcon className="size-4" strokeWidth={2.5} />
								</RSelect.ItemIndicator>
							</RSelect.Item>
						))}
					</RSelect.Viewport>
					<RSelect.ScrollDownButton className="flex h-6 cursor-pointer items-center justify-center bg-primary-500 text-primary-50">
						<ChevronDown className="size-4" strokeWidth={2.5} />
					</RSelect.ScrollDownButton>
				</RSelect.Content>
			</RSelect.Portal>
		</RSelect.Root>
	);
}
