import { useState, useMemo } from 'react';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import { ChevronDown, Search } from 'lucide-react';
import classNames from 'classnames';
import Input from './Input';

interface OfflineTableProps {
    headers: string[];
    rows: (string | React.ReactElement)[][];
    initial_sort?: {
        column_index: number;
        direction: 'asc' | 'desc';
    };
}

export default function OfflineTable({ headers, rows, initial_sort }: OfflineTableProps) {
    const [search_term, set_search_term] = useState('');
    const [sort_config, set_sort_config] = useState<{
        key: number;
        direction: 'asc' | 'desc';
    } | null>(initial_sort ? {
        key: initial_sort.column_index,
        direction: initial_sort.direction
    } : null);

    const sorted_and_filtered_rows = useMemo(() => {
        let filtered_data = [...rows];

        // Global search filter
        if (search_term) {
            filtered_data = filtered_data.filter(row =>
                row.some(cell =>
                    typeof cell === 'string'
                        ? cell.toLowerCase().includes(search_term.toLowerCase())
                        : false
                )
            );
        }

        // Sort
        if (sort_config) {
            filtered_data.sort((a, b) => {
                const a_value = a[sort_config.key];
                const b_value = b[sort_config.key];

                if (a_value < b_value) {
                    return sort_config.direction === 'asc' ? -1 : 1;
                }
                if (a_value > b_value) {
                    return sort_config.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }

        return filtered_data;
    }, [rows, search_term, sort_config]);

    const request_sort = (column_index: number) => {
        set_sort_config(current => {
            if (!current || current.key !== column_index) {
                return { key: column_index, direction: 'asc' };
            }
            if (current.direction === 'asc') {
                return { key: column_index, direction: 'desc' };
            }
            return null;
        });
    };

    return (
        <div className="flex h-full w-full flex-col gap-4 text-xs md:text-base">
            <Input
                placeholder="Buscar..."
                value={search_term}
                onChange={set_search_term}
                Icon={<Search className="size-4" />}
            />

            <ScrollArea.Root type="always" className="relative h-full w-full overflow-hidden min-h-[150px]">
                <ScrollArea.Viewport className="h-full w-full rounded-lg border-[1.5px] border-neutral-900 bg-foreground md:bg-[none]">
                    <article style={{ gridTemplateColumns: `repeat(${headers.length},auto)` }} className="grid">
                        <ol className="contents select-none">
                            {headers.map((header, index) => (
                                <li
                                    key={index}
                                    className={classNames(
                                        'group pointer-events-none sticky top-0 z-10 flex h-16 min-w-max select-none items-center justify-between gap-1 bg-neutral-950 px-2 text-left font-medium leading-none text-neutral-400 transition-colors md:px-6',
                                        {
                                            '!pointer-events-auto cursor-pointer !bg-neutral-925 !text-neutral-100': sort_config?.key === index
                                        }
                                    )}
                                    onClick={() => request_sort(index)}
                                >
                                    <span className="text-nowrap">{header}</span>
                                    {sort_config?.key === index && (
                                        <ChevronDown
                                            className={classNames('size-4 shrink-0 text-neutral-300 transition-all group-hover:text-neutral-100', {
                                                'rotate-180': sort_config.direction === 'asc'
                                            })}
                                            strokeWidth={2.5}
                                        />
                                    )}
                                </li>
                            ))}
                        </ol>

                        {sorted_and_filtered_rows.map((row, rowIdx) => (
                            <ol key={rowIdx} className="group contents [&>li]:border-b-[1.5px] [&>li]:border-neutral-900">
                                {row.map((cell, idx) => (
                                    <li
                                        key={idx}
                                        className={classNames(
                                            'text-left min-w-max gap-[0.15rem] overflow-hidden text-ellipsis text-nowrap px-3 py-4 text-neutral-200 transition-colors group-hover:bg-background md:pr-[6vw]',
                                            { '!border-b-0': sorted_and_filtered_rows.length - 1 === rowIdx }
                                        )}
                                    >
                                        {cell}
                                    </li>
                                ))}
                            </ol>
                        ))}

                        {sorted_and_filtered_rows.length === 0 && (
                            <div className="top-8 absolute z-10 flex h-[calc(100%-5px)] w-[calc(100%-5px)] select-none items-center justify-center text-lg text-neutral-300">
                                No results found
                            </div>
                        )}
                    </article>
                </ScrollArea.Viewport>
                <ScrollArea.Scrollbar
                    className="duration-[160ms] flex scale-x-[0.995] touch-none select-none ease-out data-[orientation=horizontal]:h-1.5 data-[orientation=vertical]:w-1.5 data-[orientation=horizontal]:flex-col"
                    orientation="horizontal"
                >
                    <ScrollArea.Thumb className="relative flex-1 cursor-pointer rounded-[10px] bg-neutral-800 transition-colors before:absolute before:left-1/2 before:top-1/2 before:h-full before:min-h-[44px] before:w-full before:min-w-[44px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-[''] hover:bg-neutral-700" />
                </ScrollArea.Scrollbar>
                <ScrollArea.Scrollbar
                    className="duration-[160ms] mt-16 flex h-[calc(100%-4rem)] scale-y-[0.975] touch-none select-none ease-out data-[orientation=horizontal]:h-1.5 data-[orientation=vertical]:w-1.5 data-[orientation=horizontal]:flex-col"
                    orientation="vertical"
                >
                    <ScrollArea.Thumb className="relative flex-1 cursor-pointer rounded-[10px] bg-neutral-800 transition-colors before:absolute before:left-1/2 before:top-1/2 before:h-full before:min-h-[44px] before:w-full before:min-w-[44px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-[''] hover:bg-neutral-700" />
                </ScrollArea.Scrollbar>
            </ScrollArea.Root>
        </div>
    );
}
