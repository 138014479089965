import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../context/GlobalContext';
import { ContainerSpinner } from '../components/Spinner';
import { Hash, Info, Landmark, Lock, Pencil, PlusCircle, Smartphone, Unlock, UserCircle2 } from 'lucide-react';
import useFetch from '../hooks/api';
import { ucfirst } from '../utils/formatters';
import ErrorRedirectDialog from '../components/ErrorRedirectDialog';
import { Dialog } from '../components/Dialog';
import Input from '../components/Input';
import { Button } from '../shadcn/Button';
import Select from '../components/Select';
import { validate_rif } from '../utils/validate';
import { Navigate } from 'react-router-dom';
import { GetBanks } from '../hooks/firebase';

export default function MetodosDePago() {
	const { userData } = useContext(GlobalContext);
	const { master_store } = userData!;

	if (userData?.country !== 'venezuela' || userData?.financed_by_krece || !userData?.master_user) Navigate({ to: '/' });

	const banks = GetBanks(userData?.country || null);

	const { data, loading, error, refetch } = useFetch('https://api.krece.app/storeprofile/store_payment_methods/', 'GET', true, {
		email: master_store.id
	});

	if (data?.pagomovil)
		data.pagomovil = {
			bank: data.pagomovil?.bank || '',
			phoneNumber: data.pagomovil?.phoneNumber || '',
			rif: data.pagomovil?.rif || ''
		};

	if (data?.transferBs)
		data.transferBs = {
			account: data.transferBs?.account || '',
			titular: data.transferBs?.titular || '',
			type: data.transferBs?.type || '',
			bank: data.transferBs?.bank || '',
			rif: data.transferBs?.rif || ''
		};

	const [transferBs, setTransferBs] = useState<NonNullable<typeof data>['transferBs'] | null>(null);
	const [pagomovil, setPagomovil] = useState<NonNullable<typeof data>['pagomovil'] | null>(null);

	const EditTransferenciaDialog = () => {
		const [submit, setSubmit] = useState(false);
		const { loading, error, setPayload, fetched } = useFetch(
			'https://api.krece.app/storeprofile/store_payment_methods/',
			'PUT',
			submit
		);

		const [validationError, setValidationError] = useState(false);
		const accNumberValidation = (value: string) => value.trim().length === 20;
		const accNameValidation = (value: string) => value.trim().length >= 4;

		function submitChanges() {
			if (!transferBs) return;

			if (
				!accNumberValidation(transferBs.account) ||
				!accNameValidation(transferBs.titular) ||
				!validate_rif(transferBs.rif?.replace('J', '')?.replace('J-', ''), userData?.country || '') ||
				!transferBs.type
			) {
				setValidationError(true);
			} else {
				setPayload({
					master_store_email: master_store.id,
					...(data && data),
					...(data?.pagomovil && { pagomovil: { ...data.pagomovil, rif: transferBs.rif, bank: transferBs.bank } }),
					transferBs
				});
				setSubmit(true);
			}
		}

		useEffect(() => {
			if (fetched && !error) {
				setTransferBs(null);
				refetch();
				// window.location.reload();
			}
		}, [fetched, error]);

		if (!transferBs) return null;

		return (
			<>
				{validationError && (
					<Dialog title="¡Error!" onClose={() => setValidationError(false)} zIndex={51}>
						<p>Los siguientes campos están vacíos o son inválidos:</p>
						<ul className="text-red-400">
							{!accNameValidation(transferBs.titular) && <li className="list-inside list-disc">Titular</li>}
							{!accNumberValidation(transferBs.account) && <li className="list-inside list-disc">Número de Cuenta</li>}
							{!transferBs.type && <li className="list-inside list-disc">Tipo de Cuenta</li>}
							{!validate_rif(transferBs.rif?.replace('J', '')?.replace('J-', ''), userData?.country || '') && <li className="list-inside list-disc">RIF</li>}
						</ul>
					</Dialog>
				)}
				{error && (
					<Dialog title="¡Error!" zIndex={52}>
						<p>{typeof error === 'string' ? error : 'Ha ocurrido un error. Intente más tarde.'}</p>
					</Dialog>
				)}
				<Dialog title="Transferencia" closable={false}>
					{loading && (
						<div className="absolute inset-0 z-50 flex h-full w-full items-center justify-center rounded-xl bg-black/70">
							<ContainerSpinner size={2.75} />
						</div>
					)}
					<form className="space-y-6" onSubmit={(e) => e.preventDefault()}>
						<div className="flex flex-col gap-3">
							<Input
								allowedChars="alphanumeric"
								maxLength={255}
								placeholder="Titular..."
								value={transferBs.titular}
								Icon={<UserCircle2 className="size-4" />}
								validation={accNameValidation}
								onChange={(value) => (transferBs.titular = value.trim())}
							/>
							<Select
								className="h-12 w-full bg-neutral-950"
								options={banks.map((e) => ({ label: e, value: e }))}
								defaultValue={transferBs.bank || undefined}
								onSelectedChange={(value) => (transferBs.bank = typeof value === 'string' ? value.trim() : '')}
								Icon={<Landmark className="size-4" />}
								placeholder="Banco..."
							/>
							<p className="-mt-2 flex items-center gap-2 text-[0.8rem] text-neutral-300">
								<Info className="size-3.5 shrink-0 text-neutral-500" />
								El banco también será modificado en Pago Móvil
							</p>
							<div className="flex flex-col gap-3 sm:flex-row sm:gap-2">
								<div className="w-full">
									<Input
										allowedChars="numbers_only"
										maxLength={255}
										placeholder="Número de Cuenta..."
										value={transferBs.account}
										Icon={<Hash className="size-4" />}
										validation={accNumberValidation}
										onChange={(value) => (transferBs.account = value.trim())}
									/>
								</div>
								<div className="w-full shrink-0 sm:w-auto">
									<Select
										className="h-12 w-full bg-neutral-950 sm:w-auto"
										options={['corriente', 'ahorro'].map((e) => ({ label: ucfirst(e), value: e }))}
										defaultValue={transferBs.type || undefined}
										onSelectedChange={(value) => (transferBs.type = typeof value === 'string' ? value.trim() : '')}
										placeholder="Tipo"
									/>
								</div>
							</div>
							<Input
								maxLength={255}
								placeholder="Ingresar..."
								value={transferBs.rif?.replace('J', '')?.replace('J-', '')}
								Icon={<span className="font-bold tracking-tight">RIF</span>}
								prefix={'J-'}
								validation={(value) => validate_rif(value, userData?.country || '')}
								allowedChars='numbers_only'
								onChange={(value) => (transferBs.rif = "J" + value.trim())}
							/>
							<p className="-mt-2 flex items-center gap-2 text-[0.8rem] text-neutral-300">
								<Info className="size-3.5 shrink-0 text-neutral-500" />
								El RIF también será modificado en Pago Móvil
							</p>
						</div>
						<div className="flex justify-end gap-2">
							<Button variant="default" className="bg-neutral-900 hover:bg-neutral-900/70" onClick={() => setTransferBs(null)}>
								Cancelar
							</Button>
							<Button type="submit" variant="default" className="bg-sky-600 hover:bg-sky-800" onClick={submitChanges}>
								Confirmar
							</Button>
						</div>
					</form>
				</Dialog>
			</>
		);
	};

	const EditPagoMovilDialog = () => {
		const [submit, setSubmit] = useState(false);
		const { loading, error, setPayload, fetched } = useFetch(
			'https://api.krece.app/storeprofile/store_payment_methods/',
			'PUT',
			submit
		);

		const [validationError, setValidationError] = useState(false);
		const phoneValidation = (value: string) => value.trim().length >= 7;

		function submitChanges() {
			if (!pagomovil) return;

			if (!phoneValidation(pagomovil.phoneNumber) || !validate_rif(pagomovil.rif?.replace('J', '')?.replace('J-', ''), userData?.country || '')) {
				setValidationError(true);
			} else {
				setPayload({
					master_store_email: master_store.id,
					...(data && data),
					...(data?.transferBs && { transferBs: { ...data.transferBs, rif: pagomovil.rif, bank: pagomovil.bank } }),
					pagomovil
				});
				setSubmit(true);
			}
		}

		useEffect(() => {
			if (fetched && !error) {
				setPagomovil(null);
				refetch();
				// window.location.reload();
			}
		}, [fetched, error]);

		if (!pagomovil) return null;

		return (
			<>
				{validationError && (
					<Dialog title="¡Error!" onClose={() => setValidationError(false)} zIndex={51}>
						<p>Los siguientes campos están vacíos o son inválidos:</p>
						<ul className="text-red-400">
							{!validate_rif(pagomovil.rif?.replace('J', '')?.replace('J-', ''), userData?.country || '') && <li className="list-inside list-disc">RIF</li>}{' '}
							{!phoneValidation(pagomovil.phoneNumber) && <li className="list-inside list-disc">Número de Teléfono</li>}
						</ul>
					</Dialog>
				)}
				{error && (
					<Dialog title="¡Error!" zIndex={52}>
						<p>{typeof error === 'string' ? error : 'Ha ocurrido un error. Intente más tarde.'}</p>
					</Dialog>
				)}
				<Dialog title="Pago Móvil" closable={false}>
					{loading && (
						<div className="absolute inset-0 z-50 flex h-full w-full items-center justify-center rounded-xl bg-black/70">
							<ContainerSpinner size={2.75} />
						</div>
					)}
					<form className="space-y-6" onSubmit={(e) => e.preventDefault()}>
						<div className="flex flex-col gap-3">
							<Select
								className="h-12 w-full bg-neutral-950"
								options={banks.map((e) => ({ label: e, value: e }))}
								defaultValue={pagomovil.bank || undefined}
								onSelectedChange={(value) => (pagomovil.bank = typeof value === 'string' ? value.trim() : '')}
								Icon={<Landmark className="size-4" />}
								placeholder="Banco..."
							/>
							<p className="-mt-2 flex items-center gap-2 text-[0.8rem] text-neutral-300">
								<Info className="size-3.5 shrink-0 text-neutral-500" />
								El banco también será modificado en Transferencia
							</p>
							<Input
								allowedChars="numbers_only"
								maxLength={255}
								placeholder="Ingresar..."
								value={pagomovil.phoneNumber}
								Icon={<Smartphone className="size-4" />}
								validation={phoneValidation}
								onChange={(value) => (pagomovil.phoneNumber = value.trim())}
							/>
							<Input
								maxLength={255}
								placeholder="Ingresar..."
								value={pagomovil.rif?.replace('J', '')?.replace('J-', '')}
								Icon={<span className="font-bold tracking-tight">RIF</span>}
								prefix={'J-'}
								validation={(value) => validate_rif(value, userData?.country || '')}
								allowedChars='numbers_only'
								onChange={(value) => (pagomovil.rif = "J" + value.trim())}
							/>
							<p className="-mt-2 flex items-center gap-2 text-[0.8rem] text-neutral-300">
								<Info className="size-3.5 shrink-0 text-neutral-500" />
								El RIF también será modificado en Transferencia
							</p>
						</div>
						<div className="flex justify-end gap-2">
							<Button variant="default" className="bg-neutral-900 hover:bg-neutral-900/70" onClick={() => setPagomovil(null)}>
								Cancelar
							</Button>
							<Button type="submit" variant="default" className="bg-sky-600 hover:bg-sky-800" onClick={submitChanges}>
								Confirmar
							</Button>
						</div>
					</form>
				</Dialog>
			</>
		);
	};

	return (
		<>
			{loading && <ContainerSpinner size={3} />}
			{error && <ErrorRedirectDialog />}

			{((!userData?.financed_by_krece && data?.pagomovil && data?.transferBs) || (userData?.financed_by_krece && data?.transferBs)) && (
				<section className="flex h-full w-full max-w-5xl m-auto">
					<div className="h-full w-full overflow-auto p-4 md:p-6 flex items-center justify-center gap-4 md:gap-6 flex-wrap">
						{!userData?.financed_by_krece && userData?.country === 'venezuela' && (
							<article className="shrink-0 lg:h-[340px] lg:w-[calc(50%-1.5rem)] w-full group relative overflow-hidden rounded-2xl bg-gradient-to-br from-neutral-900/60 to-neutral-900/20 p-6 shadow-lg backdrop-blur-sm transition-all hover:shadow-xl">
								<div className="absolute inset-0 -z-10 bg-gradient-to-br from-sky-500/10 to-indigo-500/10 opacity-0 transition-opacity group-hover:opacity-100" />

								<header className="mb-6 flex items-center justify-between">
									<h1 className="flex items-center gap-2 text-xl font-medium">
										<Smartphone className="size-5 text-sky-400" />
										Pago Móvil
									</h1>
									<div className="flex items-center gap-2">
										<button
											className={`text-neutral-400 hover:text-neutral-200 transition-colors ${!data?.pagomovil?.phoneNumber && 'opacity-50 cursor-not-allowed'}`}
											disabled={!data?.pagomovil?.phoneNumber}
											onClick={() => setPagomovil(data.pagomovil || { phoneNumber: '', rif: '', bank: '' })}
										>
											<Pencil className="size-5" />
										</button>
										<span className="text-neutral-400">
											{data?.pagomovil?.phoneNumber ? <Unlock className="size-5" /> : <Lock className="size-5" />}
										</span>
									</div>
								</header>

								{data?.pagomovil?.phoneNumber ? (
									<div className="space-y-4">
										<div className="rounded-lg bg-neutral-900/50 p-3 group-hover:bg-neutral-800 transition-all">
											<p className="text-sm text-neutral-400">Banco</p>
											<p className="font-medium">{data?.pagomovil?.bank}</p>
										</div>

										<div className="flex gap-4">
											<div className="flex-1 rounded-lg bg-neutral-900/50 p-3 group-hover:bg-neutral-800 transition-all">
												<p className="text-sm text-neutral-400">Teléfono</p>
												<p className="font-mono">{data?.pagomovil?.phoneNumber}</p>
											</div>
											<div className="flex-1 rounded-lg bg-neutral-900/50 p-3 group-hover:bg-neutral-800 transition-all">
												<p className="text-sm text-neutral-400">RIF</p>
												<p className="font-mono">{data?.pagomovil?.rif}</p>
											</div>
										</div>
									</div>
								) : (
									<button
										onClick={() => setPagomovil(data.pagomovil || { phoneNumber: '', rif: '', bank: '' })}
										className="mt-0 lg:mt-20 flex w-full items-center justify-center gap-2 rounded-lg bg-neutral-800/50 p-8 text-neutral-400 transition-colors hover:bg-neutral-800 hover:text-neutral-200"
									>
										<PlusCircle className="size-5" />
										Ingresar información de pago móvil
									</button>
								)}
							</article>
						)}

						<article className="shrink-0 lg:h-[340px] lg:w-[calc(50%-1.5rem)] w-full group relative overflow-hidden rounded-2xl bg-gradient-to-br from-neutral-900/60 to-neutral-900/20 p-6 shadow-lg backdrop-blur-sm transition-all hover:shadow-xl">
							<div className="absolute inset-0 -z-10 bg-gradient-to-br from-emerald-500/10 to-teal-500/10 opacity-0 transition-opacity group-hover:opacity-100" />

							<header className="mb-6 flex items-center justify-between">
								<h1 className="flex items-center gap-2 text-xl font-medium">
									<Landmark className="size-5 text-emerald-400" />
									Transferencia
								</h1>
								<div className="flex items-center gap-2">
									<button
										className={`text-neutral-400 hover:text-neutral-200 transition-colors ${!data?.transferBs?.account && 'opacity-50 cursor-not-allowed'}`}
										disabled={!data?.transferBs?.account}
										onClick={() => setTransferBs(data.transferBs || { rif: '', type: '', bank: '', account: '', titular: '' })}
									>
										<Pencil className="size-5" />
									</button>
									<span className="text-neutral-400">
										{data?.transferBs?.account ? <Unlock className="size-5" /> : <Lock className="size-5" />}
									</span>
								</div>
							</header>

							{data?.transferBs?.account ? (
								<div className="space-y-4">
									<div className="rounded-lg bg-neutral-900/50 p-3 group-hover:bg-neutral-800 transition-all">
										<p className="text-sm text-neutral-400">Banco</p>
										<p className="font-medium">{data?.transferBs?.bank}</p>
									</div>

									<div className="rounded-lg bg-neutral-900/50 p-3 group-hover:bg-neutral-800 transition-all">
										<p className="text-sm text-neutral-400">Titular</p>
										<p className="font-medium">{data?.transferBs?.titular}</p>
									</div>

									<div className="flex gap-4">
										<div className="flex-1 rounded-lg bg-neutral-900/50 p-3 group-hover:bg-neutral-800 transition-all">
											<p className="text-sm text-neutral-400">Cuenta {ucfirst(data?.transferBs?.type)}</p>
											<p className="font-mono text-sm break-all">{data?.transferBs?.account}</p>
										</div>
										<div className="flex-1 rounded-lg bg-neutral-900/50 p-3 group-hover:bg-neutral-800 transition-all">
											<p className="text-sm text-neutral-400">RIF</p>
											<p className="font-mono">{data?.transferBs?.rif}</p>
										</div>
									</div>
								</div>
							) : (
								<button
									onClick={() => setTransferBs(data.transferBs || { rif: '', type: '', bank: '', account: '', titular: '' })}
									className="mt-0 lg:mt-20 flex w-full items-center justify-center gap-2 rounded-lg bg-neutral-800/50 p-8 text-neutral-400 transition-colors hover:bg-neutral-800 hover:text-neutral-200"
								>
									<PlusCircle className="size-5" />
									Ingresar información bancaria
								</button>
							)}
						</article>
					</div>
				</section>
			)}

			{transferBs && <EditTransferenciaDialog />}
			{pagomovil && <EditPagoMovilDialog />}
		</>
	);
}
