import { useEffect, useState } from "react";
import firestore from "../firestore";

export function GetBanks(country: string | null) {
    const [banks, setBanks] = useState<string[]>([]);

    useEffect(() => {
        if (!country) return;
        (async () => {
            const document = (await firestore.collection('AppSetting').doc('CountrySettings').get()).data();

            if (document?.[country]?.banks?.length) {
                document[country].banks.sort((a: string, b: string) => a.localeCompare(b));
                setBanks(document?.[country]?.banks.filter((bank: string, index: number, self: string[]) => self.indexOf(bank) === index));
            }
        })();
    }, [country]);

    return banks;
}

export function GetCities(country: string | null) {
    const [cities, setCities] = useState<string[]>([]);

    useEffect(() => {
        if (!country) return;
        (async () => {
            const document = (await firestore.collection('AppSetting').doc('CountrySettings').get()).data();
            if (document?.[country]?.cities?.length) {
                document[country].cities.sort((a: string, b: string) => a.localeCompare(b));
                setCities(document?.[country]?.cities.filter((city: string, index: number, self: string[]) => self.indexOf(city) === index));
            }
        })();
    }, [country]);

    return cities;
}

export function GetDev2FANumbers(get_numbers: boolean = false) {
    const [dev2FANumbers, setDev2FANumbers] = useState<{ country: string, phone_number: string }[]>([]);

    useEffect(() => {
        if (!get_numbers) return;
        (async () => {
            const document = (await firestore.collection('AppSetting').doc('AppSettings').get()).data();
            if (document?.dev_2fa_phone_numbers?.length) {
                setDev2FANumbers(document['dev_2fa_phone_numbers']);
            }
        })();
    }, [get_numbers]);

    return dev2FANumbers;
}

export function GetATACountryEmail(country: string | null) {
    const [ataEmail, setAtaEmail] = useState<string | null>(null);

    useEffect(() => {
        if (!country) return;
        (async () => {
            const document = (await firestore.collection('AppSetting').doc('CountrySettings').get()).data();
            if (document?.[country]?.ata_shared_email) {
                setAtaEmail(document[country].ata_shared_email);
            }
        })();
    }, [country]);

    return ataEmail;
}

export function GetHelpVideos(country: string | null) {
    const [helpVideos, setHelpVideos] = useState<{ title: string, subtitle?: string, description?: string, thumbnail?: string, url: string }[]>([]);

    useEffect(() => {
        if (!country) return;
        (async () => {
            const document = (await firestore.collection('AppSetting').doc('CountrySettings').get()).data();
            if (document?.[country]?.instructions?.length) {
                setHelpVideos(document[country].instructions);
            }
        })();
    }, [country]);

    return helpVideos;
}

export function GetStorePromotions(country: string | null) {
    const [storePromotions, setStorePromotions] = useState<{ 
        global?: { initial_percentage: boolean | number },
        [key: string]: { initial_percentage: boolean | number } | undefined 
    }>({});

    useEffect(() => {
        if (!country) return;
        (async () => {
            const document = (await firestore.collection('AppSetting').doc('CountrySettings').get()).data();
            if (document?.[country]?.store_promotions) {
                setStorePromotions(document[country].store_promotions);
            }
        })();
    }, [country]);

    return storePromotions;
}
