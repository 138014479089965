import React from 'react';

export default function Logo({ className = '', fill = '', size = 'full' as 'full' | 'min' }) {
	return size === 'full' ? (
		<svg className={className} viewBox="108.301 156.002 916.603 188.059">
			<path
				className={fill ? fill : 'fill-primary-400 light:fill-primary-500'}
				d="M 252.366 156.837 L 150.435 156.837 C 128.116 156.837 110.017 174.937 110.017 197.255 L 110.017 299.186 C 110.017 321.505 128.117 339.604 150.435 339.604 L 252.366 339.604 C 274.685 339.604 292.766 321.504 292.766 299.186 L 292.766 197.255 C 292.766 174.936 274.684 156.837 252.366 156.837 L 252.366 156.837 Z M 221.81 310.51 L 185.593 264.941 L 159.457 293.962 C 156.882 296.848 152.097 295.003 152.097 291.131 L 152.097 186.095 L 175.785 186.095 L 175.785 232.613 C 175.785 237.088 181.429 239.06 184.223 235.553 L 223.618 186.094 L 249.754 186.094 C 250.996 186.094 251.672 187.555 250.868 188.505 L 200.094 249.598 L 252.347 310.508 L 221.81 310.508 L 221.81 310.51 Z"
			/>
			<g className="fill-neutral-0" transform="matrix(1, 0, 0, 1, 66.73050789133471, 32.99950839578372)">
				<path d="M395.052,226.591c-5.834-15.545-20.697-25.843-37.301-25.843h-10.5l56.552-60.033c6.016-6.386,1.488-16.871-7.285-16.871h-16.15c-.564,0-1.102,.237-1.483,.653l-82.275,89.941v-80.81c0-5.404-4.38-9.784-9.784-9.784h-13.698c-5.404,0-9.784,4.38-9.784,9.784v163.189c0,5.404,4.38,9.784,9.784,9.784h13.698c5.404,0,9.784-4.38,9.784-9.784v-42.312l26.861-28.514h20.66c9.971,0,18.902,6.168,22.433,15.492l22.825,60.272c1.104,2.917,3.898,4.846,7.017,4.846h12.611c7.77,0,13.148-7.762,10.418-15.037l-24.383-64.974Z" />
				<path d="M519.79,186.974v-7.358c0-5.138-4.179-9.253-9.318-9.259-16.698-.022-32.322,7.134-39.697,22.628-4.098,8.609-6.953,17.822-9.006,27.2v-39.299c0-5.404-4.38-9.784-9.784-9.784h-13.698c-5.404,0-9.784,4.38-9.784,9.784v115.931c0,5.404,4.381,9.784,9.784,9.784h13.698c5.404,0,9.784-4.38,9.784-9.784v-58.191c1.599-7.562,4.769-17.804,10.971-25.947,3.463-4.547,8.123-8.017,13.334-10.366,5.553-2.503,14.563-5.727,26.639-7.318,4.038-.532,7.077-3.948,7.077-8.021Z" />
				<path d="M633.404,187.795c-11.032-12.073-27.926-19.634-51.34-20.218-41.624,.986-66.389,28.273-67.559,67.558v7.47c1.169,41.057,30.245,67.559,67.559,67.559,28.93,0,54.317-10.758,63.705-38.72,2.046-6.1-2.594-12.401-9.022-12.401h-12.639c-1.004,0-1.899,.639-2.246,1.589-5.881,15.397-19.506,25.588-39.066,24.748-15.671,0-28.419-9.954-35.688-24.108-3.963-7.689,1.626-16.839,10.264-16.839h83.631c4.767,0,8.639-3.89,8.621-8.657v-.639c0-2.667-.109-5.315-.329-7.89-1.278-15.433-6.447-29.094-15.89-39.45Zm-17.132,33.368c-.511,.968-1.132,1.845-1.863,2.63-.749,.785-1.607,1.461-2.575,2.009-.639,.365-1.315,.657-2.027,.895-1.096,.347-2.265,.548-3.525,.548h-48.162c-8.584,0-14.081-9.442-9.552-16.748,.146-.219,.292-.457,.457-.676,.073-.128,.146-.274,.237-.402,.146-.219,.292-.457,.475-.676,.146-.237,.311-.493,.511-.731,.42-.621,.877-1.242,1.37-1.826,.53-.676,1.078-1.333,1.68-1.972,.256-.274,.511-.566,.767-.822,.255-.274,.511-.53,.785-.785,.128-.164,.256-.274,.365-.365,.566-.566,1.151-1.078,1.735-1.589,.566-.511,1.169-.986,1.772-1.425,.109-.11,.237-.201,.347-.256,.511-.402,1.023-.767,1.552-1.096,.146-.128,.311-.237,.457-.329,.585-.384,1.187-.749,1.79-1.078,1.169-.694,2.356-1.297,3.598-1.826,.384-.183,.767-.347,1.151-.493,.383-.164,.767-.31,1.151-.457,.785-.292,1.607-.548,2.429-.785,.365-.11,.713-.219,1.078-.311,.457-.128,.913-.237,1.388-.329,.292-.073,.566-.128,.858-.183,1.059-.219,2.155-.402,3.269-.53,.383-.037,.749-.091,1.132-.11,.183-.018,.365-.037,.548-.055,.585-.055,1.187-.073,1.79-.091,.511-.037,1.023-.037,1.534-.037,13.972,0,25.405,6.94,32.893,18.483,2.411,3.708,2.338,8.018,.584,11.415Z" />
				<path d="M915.191,187.795c-11.032-12.073-27.926-19.634-51.34-20.218-41.624,.986-66.39,28.273-67.559,67.558v7.47c1.169,41.057,30.245,67.559,67.559,67.559,28.93,0,54.317-10.758,63.705-38.72,2.046-6.1-2.593-12.401-9.022-12.401h-12.639c-1.004,0-1.899,.639-2.246,1.589-5.881,15.397-19.506,25.588-39.066,24.748-15.671,0-28.419-9.954-35.688-24.108-3.963-7.689,1.626-16.839,10.265-16.839h83.63c4.767,0,8.639-3.89,8.621-8.657v-.639c0-2.667-.11-5.315-.329-7.89-1.278-15.433-6.447-29.094-15.89-39.45Zm-17.131,33.368c-.511,.968-1.133,1.845-1.863,2.63-.749,.785-1.607,1.461-2.576,2.009-.639,.365-1.315,.657-2.027,.895-1.096,.347-2.265,.548-3.525,.548h-48.162c-8.584,0-14.081-9.442-9.552-16.748,.146-.219,.292-.457,.457-.676,.073-.128,.146-.274,.237-.402,.146-.219,.293-.457,.475-.676,.146-.237,.311-.493,.511-.731,.42-.621,.877-1.242,1.37-1.826,.53-.676,1.078-1.333,1.681-1.972,.255-.274,.511-.566,.767-.822,.255-.274,.511-.53,.785-.785,.128-.164,.256-.274,.365-.365,.566-.566,1.151-1.078,1.735-1.589,.566-.511,1.169-.986,1.772-1.425,.109-.11,.237-.201,.347-.256,.511-.402,1.023-.767,1.552-1.096,.146-.128,.311-.237,.457-.329,.585-.384,1.187-.749,1.79-1.078,1.169-.694,2.356-1.297,3.598-1.826,.383-.183,.767-.347,1.15-.493,.383-.164,.767-.31,1.151-.457,.785-.292,1.607-.548,2.429-.785,.366-.11,.713-.219,1.078-.311,.457-.128,.913-.237,1.388-.329,.292-.073,.566-.128,.858-.183,1.059-.219,2.155-.402,3.269-.53,.383-.037,.749-.091,1.132-.11,.183-.018,.366-.037,.548-.055,.585-.055,1.187-.073,1.79-.091,.511-.037,1.023-.037,1.534-.037,13.972,0,25.405,6.94,32.893,18.483,2.411,3.708,2.338,8.018,.585,11.415Z" />
				<path d="M780.55,250.933h-13.506c-2.304,0-4.288,1.592-4.826,3.833-4.294,17.901-16.481,31.106-34.043,31.106-23.469,.438-38.227-19.78-39.176-45.861-.822-22.812,8.895-41.715,27.323-47.121,5.443-1.607,11.251-1.68,16.821-.548,14.975,3.041,24.047,13.301,28.174,27.625,.849,2.947,3.626,4.921,6.693,4.921h12.358c6.865,0,11.784-6.642,9.783-13.209-7.888-25.883-28.389-44.103-64.058-44.103-45.55,0-67.851,31.925-67.851,71.302s24.163,71.284,67.851,71.284c37.35-1.069,57.422-19.442,64.665-45.827,1.85-6.74-3.219-13.403-10.208-13.403Z" />
				<path d="M944.265,167.432c-6.865,0-12.449,5.584-12.449,12.451s5.584,12.449,12.449,12.449,12.449-5.584,12.449-12.449-5.584-12.451-12.449-12.451Zm0,22.71c-5.656,0-10.259-4.603-10.259-10.259s4.603-10.261,10.259-10.261,10.259,4.603,10.259,10.261-4.603,10.259-10.259,10.259Z" />
				<path d="M947.556,179.645c.357-.51,.537-1.095,.537-1.751v-.81c0-.986-.394-1.793-1.181-2.42-.785-.628-1.789-.942-3.009-.942h-3.995v12.321h2.429v-4.769h1.689l2.147,4.769h2.447l-2.534-5.21c.621-.282,1.111-.678,1.47-1.188Zm-1.892-1.611c0,.41-.168,.738-.503,.985-.334,.248-.765,.371-1.293,.371h-1.53v-3.785h1.53c.528,0,.96,.123,1.293,.369,.335,.246,.503,.569,.503,.968v1.092Z" />
			</g>
		</svg>
	) : (
		<svg className={className} viewBox="105.71 155.299 184.772 185.595">
			<path
				className={fill ? fill : 'fill-primary-400 light:fill-primary-500'}
				d="M 248.769 156.837 L 146.838 156.837 C 124.519 156.837 106.42 174.937 106.42 197.255 L 106.42 299.186 C 106.42 321.505 124.52 339.604 146.838 339.604 L 248.769 339.604 C 271.088 339.604 289.169 321.504 289.169 299.186 L 289.169 197.255 C 289.169 174.936 271.087 156.837 248.769 156.837 Z M 218.213 310.51 L 181.996 264.941 L 155.86 293.962 C 153.285 296.848 148.5 295.003 148.5 291.131 L 148.5 186.095 L 172.188 186.095 L 172.188 232.613 C 172.188 237.088 177.832 239.06 180.626 235.553 L 220.021 186.094 L 246.157 186.094 C 247.399 186.094 248.075 187.555 247.271 188.505 L 196.497 249.598 L 248.75 310.508 L 218.213 310.508 L 218.213 310.51 Z"
			/>
		</svg>
	);
}
