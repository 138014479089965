import classNames from 'classnames';
import { AlertCircle, Building2, CheckCircle, CheckCircle2, ChevronRight, CircleAlert, LogOut, Upload, User } from 'lucide-react';
import { useContext, useEffect, useState } from 'react';
import { ContainerSpinner } from './Spinner';
import Spinner from './Spinner';
import { GlobalContext } from '../context/GlobalContext';
import useFetch from '../hooks/api';
import { Dialog } from './Dialog';
import firestore from '../firestore';
import { Button } from '../shadcn/Button';
import Input from './Input';
import { logout, UserData } from '../hooks/auth';
import BankAccount from './BankAccount';
import Sucursales from '../pages/sucursales';
import Firebase from '../firebase_setup';
import { ucfirst } from '../utils/formatters';
import { Navigate } from 'react-router-dom';
import ErrorRedirectDialog from './ErrorRedirectDialog';
import { validate_identity_number } from '../utils/validate';
import TwoFactorAuth from './2FA';
import Select from './Select';

function GetTerms(country: string | null, selfFinanced: boolean, registration_steps: UserData['registration_steps']) {
	const [terms, setTerms] = useState<string>();

	useEffect(() => {
		if (!country) return;
		if (registration_steps?.terms_accepted) return;
		(async () => {
			const document = (await firestore.collection('AppSetting').doc('CountrySettings').get()).data();
			if (!selfFinanced && document?.[country]?.legal?.financed_by_krece_terms) {
				setTerms(document?.[country]?.legal?.financed_by_krece_terms);
			} else if (selfFinanced && document?.[country]?.legal?.self_financed_terms) {
				setTerms(document?.[country]?.legal?.self_financed_terms);
			}
		})();
	}, [country]);

	return terms;
}

export default function Onboarding() {
	const { userData } = useContext(GlobalContext);
	const { master_store, self_financed, registration_steps, country } = userData!;

	const terms = GetTerms(country, self_financed, registration_steps);

	const [companyName, setCompanyName] = useState('');
	const [representativeName, setRepresentativeName] = useState('');
	const [representativeLastname, setRepresentativeLastname] = useState('');
	const [identityNumber, setIdentityNumber] = useState('');
	const [identityNumber2, setIdentityNumber2] = useState('');
	const [companyType, setCompanyType] = useState('Compañía anónima (C.A)');
	const [twoFactorSuccessPhoneNumber, setTwoFactorSuccessPhoneNumber] = useState('');

	const [docUrl, setDocUrl] = useState<string | null>(null);
	const [uploadingDoc, setUploadingDoc] = useState<boolean>(false);
	const [docUrl2, setDocUrl2] = useState<string | null>(null);
	const [uploadingDoc2, setUploadingDoc2] = useState<boolean>(false);

	const [showDialog, setShowDialog] = useState(false);
	const [dialogTitle, setDialogTitle] = useState('');
	const [dialogHtml, setDialogHtml] = useState<React.ReactNode>();

	const [loading, setLoading] = useState(false);

	const [updateRegistrationSteps, setUpdateRegistrationSteps] = useState(false);
	const { setPayload: setUpdateRegistrationStepsPayload, error: updateRegistrationStepsError } = useFetch(
		'https://api.krece.app/storeprofile/update_registration_steps/',
		'POST',
		updateRegistrationSteps,
		undefined,
		setLoading
	);

	const [submitInformation, setSubmitInformation] = useState(false);
	const {
		setPayload: setSubmitInformationPayload,
		error: submitInformationError,
		fetched: submittedInformation,
		reset: resetSubmitInformation
	} = useFetch('https://api.krece.app/storeprofile/masteruser/', 'PUT', submitInformation, undefined, setLoading);

	useEffect(() => {
		if (submittedInformation && !submitInformationError) {
			setUpdateRegistrationSteps(true);
			setUpdateRegistrationStepsPayload({
				master_store_email: master_store.id,
				registration_steps: {
					...registration_steps,
					...(!registration_steps?.company_info ? { company_info: true } : { bank_created: true })
				}
			});
			resetSubmitInformation();
		}
	}, [submittedInformation]);

	// useEffect(() => {
	//         setUpdateRegistrationSteps(true);
	//         setUpdateRegistrationStepsPayload({
	//         master_store_email: master_store.id,
	//         registration_steps: { ...registration_steps, bank_created: false, company_info: true }
	//     });
	// }, []);

	if (updateRegistrationStepsError || submitInformationError) {
		return (
			<ErrorRedirectDialog
				message={'Ha ocurrido un error. Por favor contacte al equipo de Krece. Disculpe las molestias.'}
				redirect={true}
			/>
		);
	}

	const isCompanyNameValid = (value: string) =>
		country === 'venezuela' && companyType !== 'Otro tipo'	
			? value.trim().length >= 7 && (/\s(CA|C\.A\.?|C\.A)$/.test(value.trim()) || /\s(FP|F\.P\.?|F\.P)$/.test(value.trim()))
			: value.trim().length >= 4;
	const isRepresentativeValid = (value: string) => value.trim().length >= 3;

	if (registration_steps && Object.values(registration_steps).every((step) => step === true)) Navigate({ to: '/' });

	const isFormValid =
		isCompanyNameValid(companyName) &&
		isRepresentativeValid(representativeName) &&
		isRepresentativeValid(representativeLastname) &&
		validate_identity_number(identityNumber, country === 'venezuela' ? 'rif' : country === 'rd' ? 'cedula' : 'ruc', country) &&
		twoFactorSuccessPhoneNumber &&
		docUrl &&
		(country === 'rd' && identityNumber2 ? validate_identity_number(identityNumber2, 'rnc', 'rd') && docUrl2 : true) &&
		(country === 'rd' && docUrl2 ? validate_identity_number(identityNumber2, 'rnc', 'rd') : true);

	const getInvalidFields = () => {
		const invalidFields = [];
		if (!isCompanyNameValid(companyName)) invalidFields.push('Nombre de la Empresa');
		if (!isRepresentativeValid(representativeName)) invalidFields.push('Nombre del Representante Legal');
		if (!isRepresentativeValid(representativeLastname)) invalidFields.push('Apellido del Representante Legal');
		if (!validate_identity_number(identityNumber, country === 'venezuela' ? 'rif' : country === 'rd' ? 'cedula' : 'ruc', country))
			invalidFields.push(country === 'venezuela' ? 'RIF' : country === 'rd' ? 'Cédula' : 'Ficha RUC');
		if (country === 'rd' && identityNumber2 ? !(validate_identity_number(identityNumber2, 'rnc', 'rd') && docUrl2) : false)
			invalidFields.push('RNC');
		else if (country === 'rd' && docUrl2 ? !validate_identity_number(identityNumber2, 'rnc', 'rd') : false)
			invalidFields.push('RNC');
		if (!twoFactorSuccessPhoneNumber) invalidFields.push('Teléfono');
		if (!docUrl) invalidFields.push('Documento de Identidad');
		return invalidFields;
	};

	if (!registration_steps?.terms_accepted && !terms) return <ContainerSpinner size={3} />;

	return (
		<>
			{!registration_steps?.terms_accepted && (
				<Dialog title="Términos y Condiciones" closable={false}>
					<div className="flex h-full w-full flex-col justify-between gap-6">
						<div className="space-y-4">
							<p className="text-lg text-neutral-200">Para continuar con el registro, acepta los términos y condiciones.</p>
							{terms && (
								<div className="rounded-lg border border-neutral-700 bg-neutral-900 p-1">
									<embed src={terms} type="application/pdf" className="min-h-[500px] w-full rounded" />
								</div>
							)}
						</div>
						<Button
							loading={loading}
							onClick={() => {
								setUpdateRegistrationStepsPayload({
									master_store_email: master_store.id,
									registration_steps: { ...registration_steps, terms_accepted: true }
								});
								setUpdateRegistrationSteps(true);
							}}
							className="w-full rounded-lg bg-primary-500 px-6 py-3 font-medium text-white transition-all duration-200 hover:bg-primary-600 hover:shadow-lg hover:shadow-primary-500/20"
						>
							Aceptar Términos y Condiciones
						</Button>
					</div>
				</Dialog>
			)}

			<div className="mx-auto h-full w-full space-y-6 md:h-auto">
				<div className="mx-auto w-full max-w-full space-y-6 p-6">
					<div className="relative mb-8 space-y-2">
						<button
							onClick={() => logout()}
							className="absolute right-0 top-0 flex items-center justify-center gap-1 rounded-lg border border-neutral-700 bg-neutral-900 p-1.5 text-sm text-neutral-200 transition-opacity hover:opacity-75 light:bg-neutral-925"
						>
							Salir
							<LogOut className="size-4" strokeWidth={2.5} />
						</button>
						<h1 className="bg-gradient-to-r from-primary-400 to-primary-600 bg-clip-text text-4xl font-semibold text-transparent">
							¡Bienvenido!
						</h1>
						<h2 className="text-2xl text-neutral-300">Complete su registro para comenzar</h2>
					</div>

					<div className="flex flex-col items-center justify-center gap-4">
						<div
							className={classNames(
								'w-full max-w-[800px] rounded-xl bg-neutral-800/30 light:bg-foreground',
								registration_steps?.company_info ? '!max-w-[500px] p-4' : 'p-6'
							)}
						>
							<div className="flex items-center gap-3">
								<div
									className={classNames(
										'flex size-8 shrink-0 items-center justify-center rounded-full',
										registration_steps?.company_info ? 'bg-green-500/20' : 'bg-primary-500/20'
									)}
								>
									{registration_steps?.company_info ? (
										<CheckCircle2 className="size-5 text-green-500" />
									) : (
										<CircleAlert className="size-5 text-primary-400" />
									)}
								</div>
								<h3
									className={classNames(
										'select-none text-lg font-medium',
										registration_steps?.company_info && 'text-neutral-500'
									)}
								>
									Información Requerida
								</h3>
							</div>

							{!registration_steps?.company_info && (
								<>
									<div className="mt-6 grid gap-6 md:grid-cols-2">
										<Input
											value={companyName}
											onChange={(value) => setCompanyName(value)}
											type="text"
											placeholder={`Ingrese el nombre de su ${companyType === 'Firma personal (F.P)' ? 'firma' : 'empresa'}`}
											validation={isCompanyNameValid}
											Icon={<Building2 className="size-4" />}
											allowedChars="alphanumeric"
											transformation={(value) => value.toUpperCase()}
											floatingLabel={`Nombre de su ${companyType === 'Firma personal (F.P)' ? 'firma personal' : 'empresa'}`}
										/>
										<div className="flex w-full flex-col gap-2 md:-mt-8">
											<h3 className="relative font-medium tracking-tight text-neutral-300 md:bottom-1">Representante Legal</h3>
											<div className="[&>*]:shrink-1 flex flex-wrap items-center gap-2 md:flex-nowrap [&>*]:w-full">
												<Input
													value={representativeName}
													onChange={(value) => setRepresentativeName(value)}
													placeholder="Nombre"
													floatingLabel="Nombre"
													validation={isRepresentativeValid}
													allowedChars="alphanumeric"
													Icon={<User className="size-4" />}
													transformation={(value) => ucfirst(value)}
												/>
												<Input
													value={representativeLastname}
													onChange={(value) => setRepresentativeLastname(value)}
													floatingLabel="Apellidos"
													placeholder="Apellidos"
													allowedChars="alphanumeric"
													Icon={<User className="size-4" />}
													transformation={(value) => ucfirst(value)}
													validation={isRepresentativeValid}
												/>
											</div>
										</div>

										<TwoFactorAuth country={country} onSuccess={(phone_number) => setTwoFactorSuccessPhoneNumber(phone_number)} />

										<div className="flex flex-col gap-2">
											{country !== 'venezuela' ? (
												<Input
													value={identityNumber}
													onChange={(value) => setIdentityNumber(value)}
													type="text"
													placeholder="Ingresar"
													allowedChars="numbers_only"
													validation={(value) => validate_identity_number(value, country === 'rd' ? 'cedula' : 'ruc', country)}
													floatingLabel={`${country === 'rd' ? 'Cédula' : 'Ficha RUC'}`}
												/>
											) : (
												<div className="flex flex-col gap-4">
													<Select
														options={['Compañía anónima (C.A)', 'Firma personal (F.P)', 'Otro tipo'].map((e) => ({
															label: e,
															value: e
														}))}
														defaultValue={companyType}
														onSelectedChange={(value) => setCompanyType(value as string)}
														floatingLabel="Tipo de compañía"
														placeholder="Seleccionar"
													/>
													<Input
														value={identityNumber}
														onChange={(value) => setIdentityNumber(value)}
														type="text"
														placeholder="Ingresar"
														allowedChars="numbers_only"
														prefix={companyType === 'Firma personal (F.P)' ? 'V-' : 'J-'}
														validation={(value) => validate_identity_number(value, 'rif', country)}
														floatingLabel={`RIF de la ${companyType === 'Firma personal (F.P)' ? 'firma personal' : 'empresa'}`}
													/>
												</div>
											)}

											<div className="flex flex-col gap-2 md:col-start-2">
												<input
													onChange={async (e) => {
														if (uploadingDoc) return;
														setUploadingDoc(true);
														setDocUrl(null);

														const DOC_TYPES = [
															'application/pdf',
															'image/jpeg',
															'image/png',
															'image/bmp',
															'image/tiff',
															'image/webp'
														];

														try {
															const doc = e.currentTarget.files?.[0];
															if (!doc) throw new Error();

															const is_valid_doc = doc.size && DOC_TYPES.includes(doc.type);
															const extension = doc.name.split('.')?.pop()?.toLowerCase();
															const size = doc.size;

															if (!is_valid_doc || !extension) {
																setDialogHtml('Debe subir un documento válido.');
																setShowDialog(true);
																throw new Error();
															} else if (size > 5000000) {
																setDialogHtml('El tamaño del documento seleccionado no puede superar los 5MB.');
																setShowDialog(true);
																throw new Error();
															}

															const storage = Firebase.storage();
															const storageRef = storage.ref();
															const fileName = `onboarding_id_${Date.now()}.${extension}`;
															const fileRef = storageRef.child(fileName);
															await fileRef.put(doc);

															const downloadURL = await fileRef.getDownloadURL();
															if (downloadURL) {
																setDocUrl(downloadURL);
															} else {
																throw new Error();
															}
														} catch (error) {
															setDialogHtml('Error al subir el documento.');
															setShowDialog(true);
														} finally {
															setUploadingDoc(false);
														}
													}}
													type="file"
													className="hidden"
													id="doc-upload"
													accept=".pdf,.jpg,.jpeg,.png,.tiff,.webp,.bmp"
												/>
												<label
													htmlFor="doc-upload"
													className={classNames(
														'hover:bg-neutral-750 flex cursor-pointer items-center gap-2 rounded-lg border border-neutral-700 bg-neutral-800 px-4 py-2.5 text-sm transition-colors duration-200 light:bg-neutral-900',
														uploadingDoc && 'cursor-not-allowed opacity-50'
													)}
												>
													{uploadingDoc ? (
														<>
															<Spinner size={1} />
															<span>Subiendo documento...</span>
														</>
													) : (
														<>
															{docUrl ? (
																<>
																	<CheckCircle className="size-4 text-green-500" />
																	<span>Documento subido exitosamente</span>
																</>
															) : (
																<>
																	<Upload className="size-4" />
																	{docUrl
																		? 'Volver a subir'
																		: `Subir ${country === 'venezuela' ? 'foto del RIF' : country === 'rd' ? 'foto de la Cédula' : 'la Ficha RUC'}`}
																</>
															)}
														</>
													)}
												</label>
												<span className="text-xs text-neutral-400">PDF, JPG, PNG, WEBP (máx. 5MB)</span>
											</div>
											{country === 'rd' && (
												<>
													<Input
														containerClassName="mt-4"
														value={identityNumber2}
														onChange={(value) => setIdentityNumber2(value)}
														type="text"
														placeholder="Ingresar RNC (opcional)"
														allowedChars="numbers_only"
														validation={(value) => validate_identity_number(value, 'rnc', 'rd')}
														floatingLabel={`RNC (opcional)`}
													/>
													<div className="flex flex-col gap-2">
														<input
															onChange={async (e) => {
																if (uploadingDoc2) return;
																setUploadingDoc2(true);
																setDocUrl2(null);

																const DOC_TYPES = [
																	'application/pdf',
																	'image/jpeg',
																	'image/png',
																	'image/bmp',
																	'image/tiff',
																	'image/webp'
																];

																try {
																	const doc = e.currentTarget.files?.[0];
																	if (!doc) throw new Error();

																	const is_valid_doc = doc.size && DOC_TYPES.includes(doc.type);
																	const extension = doc.name.split('.')?.pop()?.toLowerCase();
																	const size = doc.size;

																	if (!is_valid_doc || !extension) {
																		setDialogHtml('Debe subir un documento válido.');
																		setShowDialog(true);
																		throw new Error();
																	} else if (size > 5000000) {
																		setDialogHtml('El tamaño del documento seleccionado no puede superar los 5MB.');
																		setShowDialog(true);
																		throw new Error();
																	}

																	const storage = Firebase.storage();
																	const storageRef = storage.ref();
																	const fileName = `onboarding_id_${Date.now()}.${extension}`;
																	const fileRef = storageRef.child(fileName);
																	await fileRef.put(doc);

																	const downloadURL = await fileRef.getDownloadURL();
																	if (downloadURL) {
																		setDocUrl2(downloadURL);
																	} else {
																		throw new Error();
																	}
																} catch (error) {
																	setDialogHtml('Error al subir el documento.');
																	setShowDialog(true);
																} finally {
																	setUploadingDoc2(false);
																}
															}}
															type="file"
															className="hidden"
															id="doc-upload2"
															accept=".pdf,.jpg,.jpeg,.png,.tiff,.webp,.bmp"
														/>
														<label
															htmlFor="doc-upload2"
															className={classNames(
																'hover:bg-neutral-750 flex cursor-pointer items-center gap-2 rounded-lg border border-neutral-700 bg-neutral-800 px-4 py-2.5 text-sm transition-colors duration-200 light:bg-neutral-900',
																uploadingDoc2 && 'cursor-not-allowed opacity-50'
															)}
														>
															{uploadingDoc2 ? (
																<>
																	<Spinner size={1} />
																	<span>Subiendo documento...</span>
																</>
															) : (
																<>
																	{docUrl2 ? (
																		<>
																			<CheckCircle className="size-4 text-green-500" />
																			<span>Documento subido exitosamente</span>
																		</>
																	) : (
																		<>
																			<Upload className="size-4" />
																			{docUrl2 ? 'Volver a subir' : `Subir foto del RNC`}
																		</>
																	)}
																</>
															)}
														</label>
														<span className="text-xs text-neutral-400">PDF, JPG, PNG, WEBP (máx. 5MB)</span>
													</div>
												</>
											)}
										</div>
									</div>

									<div className="flex justify-end pt-6">
										<Button
											variant={isFormValid ? 'primary' : 'secondary'}
											loading={loading}
											className="!w-auto"
											onClick={() => {
												if (!isFormValid) {
													setShowDialog(true);
													setDialogTitle('Campos Requeridos');
													setDialogHtml(
														<div className="space-y-4">
															<p>Por favor complete los siguientes campos correctamente:</p>
															<ul className="list-disc space-y-2 pl-6">
																{getInvalidFields().map((field) => (
																	<li key={field} className="text-red-400">
																		{field}
																	</li>
																))}
															</ul>
															{country === 'venezuela' && (
																<p className="text-sm text-neutral-400">
																	El nombre de la empresa debe tener al menos 7 caracteres y terminar en CA, C.A, C.A., FP, F.P o
																	F.P.
																</p>
															)}
														</div>
													);
												} else {
													setSubmitInformation(true);
													setSubmitInformationPayload({
														email: master_store.id,
														company_name: companyName,
														first_name: representativeName,
														last_name: representativeLastname,
														phone_number: twoFactorSuccessPhoneNumber,
														identification_number: identityNumber,
														identification_url: docUrl!,
														identification_type: country === 'venezuela' ? 'rif' : country === 'rd' ? 'cedula' : 'ruc',
														...(docUrl2 &&
															identityNumber2 && {
																identification_number_2: identityNumber2,
																identification_url_2: docUrl2,
																identification_type_2: 'rnc'
															})
													});
												}
											}}
										>
											<div className="flex items-center gap-2">
												{isFormValid ? (
													<CheckCircle2 className="size-4 text-primary-100" />
												) : (
													<AlertCircle className="size-4 text-red-500" />
												)}
												Enviar Información
											</div>
										</Button>
									</div>
								</>
							)}
						</div>

						{registration_steps?.company_info && (
							<div
								className={classNames(
									'w-full rounded-xl bg-neutral-800/30 light:bg-foreground sm:min-w-[400px] sm:max-w-[500px]',
									registration_steps?.bank_created ? 'p-4' : 'p-6'
								)}
							>
								<div className="flex items-center gap-3">
									<div
										className={classNames(
											'flex size-8 shrink-0 items-center justify-center rounded-full',
											registration_steps?.bank_created ? 'bg-green-500/20' : 'bg-yellow-500/20'
										)}
									>
										{registration_steps?.bank_created ? (
											<CheckCircle2 className="size-5 text-green-500" />
										) : (
											<CircleAlert className="size-5 text-yellow-500" />
										)}
									</div>
									<h3
										className={classNames(
											'select-none text-lg font-medium',
											registration_steps?.bank_created && 'text-neutral-500'
										)}
									>
										Cuenta Bancaria
									</h3>
								</div>

								{!registration_steps?.bank_created && (
									<div className="mt-6 w-full">
										<BankAccount
											email={master_store.id}
											country={country}
											loading={loading}
											setLoading={setLoading}
											onSubmit={async ({ bank, type, holder, account, identity_number, identity_type, interbank_code }) => {
												setSubmitInformation(true);
												setSubmitInformationPayload({
													email: master_store.id,
													bank: bank,
													bank_account_holder: holder,
													bank_account_type: type as any,
													bank_account_number: account,
													bank_identification_number: identity_number,
													bank_identification_type: identity_type,
													...(country === 'peru' ? { interbank_code } : {})
												});
											}}
										/>
									</div>
								)}
							</div>
						)}

						{registration_steps?.bank_created && (
							<div
								className={classNames(
									'w-full rounded-xl bg-neutral-800/30 light:bg-foreground sm:min-w-[400px] sm:max-w-[500px]',
									registration_steps?.sucursal_created ? '!max-w-[500px] p-4' : 'p-6'
								)}
							>
								<div className="flex items-center gap-3">
									<div
										className={classNames(
											'flex size-8 shrink-0 items-center justify-center rounded-full',
											registration_steps?.sucursal_created ? 'bg-green-500/20' : 'bg-yellow-500/20'
										)}
									>
										{registration_steps?.sucursal_created ? (
											<CheckCircle2 className="size-5 text-green-500" />
										) : (
											<CircleAlert className="size-5 text-yellow-500" />
										)}
									</div>
									<h3
										className={classNames(
											'select-none text-lg font-medium',
											registration_steps?.sucursal_created && 'text-neutral-500'
										)}
									>
										Agregar Sucursal
									</h3>
								</div>
								{!registration_steps?.sucursal_created && (
									<Sucursales
										onboarding={true}
										updateRegistrationSteps={() => {
											setUpdateRegistrationSteps(true);
											setUpdateRegistrationStepsPayload({
												master_store_email: master_store.id,
												registration_steps: { ...registration_steps, sucursal_created: true }
											});
										}}
									/>
								)}
							</div>
						)}

						{registration_steps?.sucursal_created && (
							<div
								className={classNames(
									'w-full rounded-xl bg-neutral-800/30 light:bg-foreground sm:min-w-[400px] sm:max-w-[500px]',
									!registration_steps?.docusign_accepted ? '!max-w-[500px] p-4' : 'p-6'
								)}
							>
								<div className="flex items-center gap-3">
									<div
										className={classNames(
											'flex size-8 shrink-0 items-center justify-center rounded-full',
											!registration_steps?.docusign_accepted ? 'bg-green-500/20' : 'bg-yellow-500/20'
										)}
									>
										{!registration_steps?.docusign_accepted ? (
											<CheckCircle2 className="size-5 text-green-500" />
										) : (
											<CircleAlert className="size-5 text-yellow-500" />
										)}
									</div>
									<h3
										className={classNames(
											'select-none text-lg font-medium',
											!registration_steps?.docusign_accepted && 'text-neutral-500'
										)}
									>
										Firmar Contrato
									</h3>
								</div>
							</div>
						)}

						{registration_steps &&
							Object.entries(registration_steps).every(([key, step]) => key === 'registration_completed' || step === true) && (
								<div className="w-full rounded-xl bg-neutral-800/30 p-4 light:bg-foreground sm:min-w-[400px] sm:max-w-[500px]">
									<div className="flex items-center gap-3">
										<div className="flex size-8 shrink-0 items-center justify-center rounded-full bg-primary-500/20">
											<CheckCircle2 className="size-5 text-primary-400" />
										</div>
										<h3 className="select-none text-lg font-medium">¡Registro Completado!</h3>
									</div>
									<div className="mt-4 flex justify-center">
										<Button
											loading={loading}
											onClick={() => {
												setUpdateRegistrationSteps(true);
												setUpdateRegistrationStepsPayload({
													master_store_email: master_store.id,
													registration_steps: { ...registration_steps, registration_completed: true }
												});
											}}
											className="w-full rounded-lg bg-primary-500 px-6 py-3 font-medium text-white transition-all duration-200 hover:bg-primary-600 hover:shadow-lg hover:shadow-primary-500/20"
										>
											Comenzar a usar Krece
											<ChevronRight className="ml-2 size-5" />
										</Button>
									</div>
								</div>
							)}
					</div>
				</div>
			</div>

			{showDialog && (
				<Dialog title={dialogTitle} onClose={() => setShowDialog(false)}>
					{dialogHtml}
				</Dialog>
			)}
		</>
	);
}
