import React, { useState, useEffect, useContext } from 'react';
import { GlobalContext } from '../context/GlobalContext';
import { ContainerSpinner } from '../components/Spinner';
import { Button } from '../shadcn/Button';
import { ArrowLeft, CircleCheck, Trash2 } from 'lucide-react';
import Stepper from '../components/Stepper';
import { Dialog } from '../components/Dialog';
import Input from '../components/Input';
import Select from '../components/Select';
import Notice from '../components/Notice';

const DeleteSale = () => {
	const { authenticated, userData } = useContext(GlobalContext);
	const country = userData?.country;

	const [isLoading, setIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [success, setSuccess] = useState(false);

	const [cedula, setCedula] = useState('');
	const [imeiDelete, setImeiDelete] = useState('');
	const [token, setToken] = useState(process.env.REACT_APP_API_TOKEN);
	const [productType, setProductType] = useState('');

	const [showConfirmDialog, setShowConfirmDialog] = useState(false);
	const [pendingConfirmAction, setPendingConfirmAction] = useState(null);
	const [dialogMessage, setDialogMessage] = useState('');

	var currentDay = new Date().getDate();
	if (currentDay === 31) {
		currentDay = 30;
	}

	const sendPostRequest = async (data, endpoint) => {
		try {
			const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${userData?.firebase_token}`,
					'x-master-store-email': userData.master_store.id,
					'x-user-email': userData.email
				},
				body: JSON.stringify({ ...data, country: userData.country })
			});
			const responseData = await response.json();
			if (response.status === '401') {
				const tokenData = await requestToken();
				await setToken('Token ' + tokenData.token);
				const resData = await sendPostRequest(data, endpoint, 'Token ' + tokenData.token);
				return resData;
			}
			return responseData;
		} catch (error) {
			console.error('Error:', error.message);
			return null;
		}
	};

	const requestToken = async () => {
		const loginData = {
			username: process.env.REACT_APP_TOKEN_USERNAME,
			password: process.env.REACT_APP_TOKEN_PASSWORD
		};
		try {
			const response = await fetch(process.env.REACT_APP_API_URL + '/api/token/', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(loginData)
			});

			const responseData = await response.json();
			return responseData;
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const handleContinueDelete = async (e) => {
		e.preventDefault();

		showConfirm('¿Estás seguro que deseas eliminar este registro de venta?', async () => {
			setIsLoading(true);
			setErrorMessage('');
			if (!cedula || !imeiDelete) {
				setErrorMessage('Por favor rellene todas las casillas');
				setIsLoading(false);
				return;
			}
			try {
				if (productType !== 'other') {
					const deleteData = {
						identification_number: cedula,
						imei_no: imeiDelete,
						store_user: userData.email
					};
					const response = await sendPostRequest(deleteData, 'administration/deletedevice/', token); //status, success, data
					if (response?.device_deleted) {
						//needs work
						setIsLoading(false);
						setSuccess(true);
					} else if (response?.success === true) {
						setErrorMessage(response.status);
						setIsLoading(false);
					} else if (response?.success === false) {
						setErrorMessage(response.status);
						setIsLoading(false);
					} else {
						setErrorMessage('Ocurrió un problema intente más tarde.');
						setIsLoading(false);
					}
				} else {
					const deleteData = {
						identification_number: cedula,
						confirmation_id: imeiDelete,
						store_user: userData.email
					};
					const response = await sendPostRequest(deleteData, 'administration/deleteotherdevice/', token); //status, success, data
					if (response?.device_deleted) {
						//needs work
						setIsLoading(false);
						setSuccess(true);
					} else if (response?.success === true) {
						setErrorMessage(response.status);
						setIsLoading(false);
					} else if (response?.success === false) {
						setErrorMessage(response.status);
						setIsLoading(false);
					} else {
						setErrorMessage('Ocurrió un problema intente más tarde.');
						setIsLoading(false);
					}
				}
			} catch (error) {
				console.log(error.message);
				setErrorMessage('Ocurrió un problema intente más tarde.');
				setIsLoading(false);
			}
		});
	};

	function showConfirm(message, onConfirm) {
		setDialogMessage(message);
		setPendingConfirmAction(() => onConfirm);
		setShowConfirmDialog(true);
	}

	const [page, setPage] = useState(1);

	useEffect(() => {
		if (success) setPage(2);
	}, [success]);

	const steps = [
		{ label: 'Ingresar Datos', pages: [1] },
		{ label: 'Eliminación Exitosa', pages: [2] }
	];

	if (!authenticated || !userData?.country || !userData?.master_store?.id || !userData?.email || !userData?.firebase_token)
		return <ContainerSpinner size={3} />;

	return (
		<div className="m-auto flex min-h-full min-w-full flex-col items-center justify-start">
			<Stepper currentPage={page} steps={steps} />
			<div className="m-auto flex h-full w-full flex-col items-center justify-center p-4 md:p-6">
				<div className='w-[500px] max-w-full rounded-xl border-[1.5px] border-neutral-900 bg-neutral-1000/25 p-6 shadow-lg duration-300 animate-in fade-in slide-in-from-bottom-5 md:bg-background/50 md:p-8 light:shadow-none light:bg-white'>
					{page === 1 && (
						<>
							{render_page_header('Eliminar Venta', 'Ingresa los siguientes datos para eliminar la venta realizada', <Trash2 />)}

							<form className="space-y-4" onSubmit={handleContinueDelete}>
								<Select
									floatingLabel="Tipo de producto"
									placeholder="Seleccione el tipo de producto"
									options={[
										{ label: 'Celular', value: 'phone' },
										{ label: 'Otro Producto', value: 'other' }
									]}
									defaultValue={productType}
									onSelectedChange={(value) => setProductType(value)}
								/>
								<Input
									floatingLabel={`${country === 'peru' ? 'DNI' : 'Cédula'} del cliente`}
									value={cedula}
									onChange={(value) => setCedula(value)}
									placeholder={`Ingrese ${country === 'peru' ? 'el DNI' : 'la cédula'} del cliente`}
									allowedChars="numbers_only"
								/>
								<Input
									floatingLabel={productType !== 'other' ? 'IMEI del equipo' : 'Código de confirmación'}
									value={imeiDelete}
									onChange={(value) => setImeiDelete(value)}
									placeholder={productType !== 'other' ? 'Ingrese el IMEI del equipo' : 'Ingrese el código de confirmación'}
									allowedChars="numbers_only"
								/>

								{errorMessage && <Notice message={errorMessage} variant="error" />}

								<Button
									disabled={isLoading || !cedula || !imeiDelete || !productType}
									loading={isLoading}
									type="submit"
									onClick={handleContinueDelete}
									variant="primary"
								>
									Eliminar Registro
								</Button>
							</form>
						</>
					)}

					{page === 2 && (
						<>
							{render_page_header(
								'Eliminación Exitosa',
								'¡El registro de venta ha sido eliminado correctamente!',
								<CircleCheck />
							)}

							<Button
								Icon={<ArrowLeft className="size-5" />}
								onClick={() => (window.location.href = '/deleteSale')}
								variant="primary"
							>
								Eliminar otro registro
							</Button>
						</>
					)}
				</div>
			</div>

			{showConfirmDialog && (
				<Dialog closable={false} title="Confirmar" onClose={() => setShowConfirmDialog(false)}>
					<div className="flex flex-col gap-4">
						<div dangerouslySetInnerHTML={{ __html: dialogMessage }} />
						<div className="flex justify-end gap-2">
							<button
								onClick={() => setShowConfirmDialog(false)}
								className="inline-flex h-[35px] items-center justify-center rounded-lg px-[15px] font-medium leading-none text-neutral-600 transition-colors hover:bg-neutral-900/50 focus:outline-none disabled:pointer-events-none disabled:opacity-50"
							>
								Cancelar
							</button>
							<button
								onClick={() => {
									setShowConfirmDialog(false);
									pendingConfirmAction();
								}}
								className="inline-flex h-[35px] items-center justify-center rounded-lg bg-primary-500 px-[15px] font-medium leading-none text-black transition-colors hover:bg-primary-600 focus:outline-none disabled:pointer-events-none disabled:opacity-50"
							>
								Confirmar
							</button>
						</div>
					</div>
				</Dialog>
			)}
		</div>
	);

	function render_page_header(title, description, icon) {
		return (
			<div className="mb-4 flex flex-col items-center gap-2 md:mb-6 md:gap-3">
				<div className="relative">
					<div className="absolute -inset-1 rounded-full bg-gradient-to-r from-primary-400 to-primary-600 opacity-10 blur"></div>
					<div className="relative rounded-full bg-neutral-900 p-4 light:bg-neutral-1000 light:ring-2 light:ring-primary-500/10">
						{icon && React.cloneElement(icon, { className: 'h-6 w-6 text-primary-500' })}
					</div>
				</div>
				<div className="space-y-1 text-center">
					<h1 className="text-2xl font-semibold tracking-tighter text-neutral-0 md:text-3xl">{title}</h1>
					{description && <p className="text-neutral-300">{description}</p>}
				</div>
			</div>
		);
	}
};

export default DeleteSale;
