import * as React from 'react';
import { format, isToday, parse } from 'date-fns';
import { Calendar as CalendarIcon } from 'lucide-react';
import { Popover, PopoverContent, PopoverTrigger } from './Popover';
import { Button } from './Button';
import { cn } from './utils';
import { Calendar } from './Calendar';
import { es } from 'date-fns/locale';
import Select from '../components/Select';

export function DatePicker({
    disabled,
    className,
    onDateChange,
    title,
    floatingLabel,
    defaultDate
}: {
    disabled?: boolean;
    className?: string;
    onDateChange: (selected: string | undefined) => any;
    title?: string;
    floatingLabel?: string;
    defaultDate?: string;
}) {
    const parseDefaultDate = React.useCallback(() => {
        if (!defaultDate) return undefined;
        try {
            const parsedDate = parse(defaultDate, 'dd/MM/yyyy', new Date());
            if (isNaN(parsedDate.getTime())) return undefined;
            return parsedDate;
        } catch {
            return undefined;
        }
    }, [defaultDate]);

    const [date, setDate] = React.useState<Date | undefined>(parseDefaultDate());
    const [currentMonth, setCurrentMonth] = React.useState(date?.getMonth() ?? new Date().getMonth());
    const [currentYear, setCurrentYear] = React.useState(date?.getFullYear() ?? new Date().getFullYear());
    const [open, setOpen] = React.useState(false);

    const months = [
        { label: 'Enero', value: '0' },
        { label: 'Febrero', value: '1' },
        { label: 'Marzo', value: '2' },
        { label: 'Abril', value: '3' },
        { label: 'Mayo', value: '4' },
        { label: 'Junio', value: '5' },
        { label: 'Julio', value: '6' },
        { label: 'Agosto', value: '7' },
        { label: 'Septiembre', value: '8' },
        { label: 'Octubre', value: '9' },
        { label: 'Noviembre', value: '10' },
        { label: 'Diciembre', value: '11' }
    ];

    const years = Array.from({ length: 100 }, (_, i) => ({
        label: String(new Date().getFullYear() - 99 + i),
        value: String(new Date().getFullYear() - 99 + i)
    })).reverse();

    React.useEffect(() => {
        if (date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            onDateChange(`${year}-${month}-${day}`);
        }
    }, [date]);

    const handleTodayClick = () => {
        const today = new Date();
        setDate(today);
        setCurrentMonth(today.getMonth());
        setCurrentYear(today.getFullYear());
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        onDateChange(`${year}-${month}-${day}`);
        setOpen(false);
    };

    return (
        <div className="space-y-1">
            {title && !floatingLabel && <h3 className="font-medium tracking-tight text-neutral-300">{title}</h3>}
            <div
                data-disabled={disabled || false}
                className={cn('grid gap-2 data-[disabled=true]:pointer-events-none data-[disabled=true]:opacity-60 relative', className)}
            >
                <Popover open={open} onOpenChange={setOpen}>
                    <PopoverTrigger asChild>
                        <Button
                            id="date"
                            variant={'default'}
                            className={cn(
                                'inline-flex h-10 shrink-0 select-none items-center justify-between gap-2 text-nowrap rounded-lg bg-background light:bg-neutral-900/70 px-3 text-sm font-medium outline-none transition-colors hover:bg-primary-600 disabled:pointer-events-none disabled:opacity-60',
                                !date && 'font-normal text-neutral-300',
                                floatingLabel && 'h-12 bg-neutral-925 light:bg-neutral-950 border border-neutral-800 has-[:focus]:outline-none has-[:focus]:ring-2 has-[:focus]:ring-primary-500 has-[:focus]:border-primary-500 transition-all',
                                className
                            )}
                        >
                            <div className="flex items-center gap-1 truncate">
                                <CalendarIcon className="mr-2 h-4 w-4 shrink-0" />
                                {date ? (
                                    format(date, 'LLL dd, y', { locale: es })
                                ) : (
                                    <span>Seleccione una fecha</span>
                                )}
                            </div>
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                        <div className="flex flex-col">
                            <Calendar
                                initialFocus
                                mode="single"
                                month={new Date(currentYear, currentMonth)}
                                selected={date}
                                onSelect={(selected) => {
                                    setDate(selected);
                                    if (selected) {
                                        setCurrentMonth(selected.getMonth());
                                        setCurrentYear(selected.getFullYear());
                                        setOpen(false);
                                    } else {
                                        onDateChange(undefined);
                                    }
                                }}
                                locale={es}
                                numberOfMonths={1}
                                onMonthChange={(month) => {
                                    setCurrentMonth(month.getMonth());
                                    setCurrentYear(month.getFullYear());
                                }}
                            />
                            <div className="flex gap-2 p-3 border-t border-neutral-700">
                                <Select
                                    options={months}
                                    key={`month-${currentMonth}`}
                                    defaultValue={String(currentMonth)}
                                    onSelectedChange={(value) => {
                                        if (value !== null) {
                                            setCurrentMonth(Number(value));
                                        }
                                    }}
                                    placeholder="Mes"
                                    className="flex-1 shadow-[0_0_0_1px_hsl(var(--twc-neutral-700))] hover:shadow-none"
                                />
                                <Select
                                    options={years}
                                    key={`year-${currentYear}`}
                                    defaultValue={String(currentYear)}
                                    onSelectedChange={(value) => {
                                        if (value !== null) {
                                            setCurrentYear(Number(value));
                                        }
                                    }}
                                    placeholder="Año"
                                    className="flex-1 shadow-[0_0_0_1px_hsl(var(--twc-neutral-700))] hover:shadow-none"
                                />
                                <Button
                                    onClick={handleTodayClick}
                                    variant="outline"
                                    className={cn(
                                        "!border-none shadow-[0_0_0_1px_hsl(var(--twc-neutral-700))] hover:bg-primary-500 hover:shadow-none",
                                        date && isToday(date) && "bg-primary-500 shadow-none"
                                    )}
                                >
                                    Hoy
                                </Button>
                            </div>
                        </div>
                    </PopoverContent>
                </Popover>
                {floatingLabel && (
					<label className="absolute -top-2 left-2 px-1 text-xs font-medium text-primary-200/80 bg-neutral-1000 rounded light:text-primary-900 light:bg-primary-300 overflow-hidden overflow-ellipsis text-nowrap max-w-[90%]">
                        {floatingLabel}
                    </label>
                )}
            </div>
        </div>
    );
}
