import { useContext } from 'react';
import { GetHelpVideos } from '../hooks/firebase';
import { GlobalContext } from '../context/GlobalContext';
import { ContainerSpinner } from '../components/Spinner';

export default function Help() {
	const { userData } = useContext(GlobalContext);
	const videos = GetHelpVideos(userData?.country ?? null);

	if (!videos.length) {
		return <ContainerSpinner size={3} />;
	}

	return (
			<div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 p-6">
				{videos.map((video, index) => (
					<div 
						key={index}
						className="flex flex-col overflow-hidden rounded-xl border border-neutral-800 bg-neutral-900 light:bg-foreground transition-all hover:border-neutral-700"
					>						
						<div className="flex flex-1 flex-col gap-3 p-4">
							<div>
								<h3 className="text-lg font-medium text-neutral-100">{video.title?.trim()}</h3>
								{video.subtitle && (
									<p className="mt-1 text-sm text-neutral-400">{video.subtitle?.trim()}</p>
								)}
							</div>

							{video.description && (
								<p className="text-sm text-neutral-300">{video.description?.trim()}</p>
							)}

							<div className="mt-auto">
								<iframe
									src={video.url.replace('/share/', '/embed/')}
									allowFullScreen
									className="aspect-video w-full rounded-lg"
								/>
							</div>
						</div>
					</div>
				))}
			</div>
	);
}
