import React, { useState, useEffect, useRef, useContext } from 'react';
import { TextField, MenuItem, withStyles, Switch } from '@material-ui/core';
import { ArrowBack, ArrowForward, CheckCircle, Search, AddAPhoto } from '@material-ui/icons';
import qrCode from '../images/enrollment_qr.png';
import downloadQr from '../images/QR_app.png';
import paso1 from '../images/paso1.png';
import paso1dot2 from '../images/paso1.2.png';
import paso1dot1 from '../images/paso1.1.png';
import paso2 from '../images/paso2.png';
import paso3 from '../images/paso3.png';
import paso4 from '../images/paso4.png';
import paso5 from '../images/paso5.png';
import paso6 from '../images/paso6.png';
import paso7 from '../images/paso7.png';
import paso8 from '../images/paso8.png';
import paso13 from '../images/paso13.png';
import paso16 from '../images/paso16.png';
import homeScreen from '../images/kreceScreen.png';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import firestore from '../firestore';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import auth from '../auth';
import Spinner, { ContainerSpinner } from '../components/Spinner';
import { Dialog } from '../components/Dialog';
import Select from '../components/Select';
import {
	AlertTriangle,
	ArrowLeft,
	ArrowRight,
	Camera,
	CircleCheck,
	ClipboardList,
	FileText,
	IdCard,
	Info,
	KeyRound,
	PlusCircle,
	Receipt,
	Scan,
	ScanFace,
	Settings,
	Shield,
	Smartphone,
	UserRound
} from 'lucide-react';
import Input from '../components/Input';
import { DatePicker } from '../shadcn/DatePicker';
import Stepper from '../components/Stepper';
import { Button } from '../shadcn/Button';
import { GlobalContext } from '../context/GlobalContext';
import classNames from 'classnames';
import Notice from '../components/Notice';
import { Popover, PopoverContent, PopoverTrigger } from '@radix-ui/react-popover';
import { GetStorePromotions } from '../hooks/firebase';

// Initialize Firebase
firebase.initializeApp({
	apiKey: process.env.REACT_APP_FIREBASE_KEY,
	authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FB_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FB_APP_ID,
	measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID
});

// el flow de cuando la persona va a financiar un segundo celular:
//      no dejar que financie un segundo celular cuando ya tiene financiado uno.
//      Lo primero que se va a hacer es agregar la cedula y mandarla a registration/usercheck el cual va a devolver satus, success, data { is_user_registered: tru or false, can_be_financed, true or false }.
//          if is_user_registered === false --> entonce ssigue as usual
//          if is_user_registered === true && can_be_financed === false --> entonces se le da un mensaje que ya tiene un celular financiado
//          if is_user_registered === true && can_be_financed === true --> entonces se le rellenan los datos para que confirme y después se sigue as usual
//Tambien cuando hay un telefono con garantia:
//          Hay un boton especial que va a decir "celular aplicar garantia"
//          Lo primero que se va a hacer es agregar la cedula y mandarla a registration/usercheck y va a devolver satus, success, data { is_user_registered: tru or false, can_be_financed, true or false }.
//          if is_user_registered === true && can_be_financed === false --> entonces lo manda a un screen para registrar el device nuevo y solo se le va a pedir el IMEI y llama a replace_old_device.
//              replace_old_device va a devolver lo mismo que devuelve el device_registration endpoint
//          if is_user_registered === true && can_be_financed === true --> entonces se le rellenan los datos para que confirme y después se sigue as usual

const AdminPortal = () => {
	const { authenticated, userData } = useContext(GlobalContext);

	const navigate = useNavigate();

	const [storeEmail, setStoreEmail] = useState('');
	const [storeName, setStoreName] = useState('');
	const [securityProvider, setSecurityProvider] = useState('');
	const [applicationApprovalData, setApplicationApprovalData] = useState({
		threeMonthAmount: '',
		sixMonthAmount: ''
	});
	const [maxLoan3MonthsKrecePlus, setMaxLoan3MonthsKrecePlus] = useState('');
	const [cneData, setCneData] = useState({});
	const [downPaymentPerc, setDownPaymentPerc] = useState(null);
	//User creation data
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [email2, setEmail2] = useState('');
	const [cedula, setCedula] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [birthDate, setBirthDate] = useState('');
	const [address, setAddress] = useState('');
	const [gender, setGender] = useState('');
	const [formError, setFormError] = useState('');
	const [cedulaPhoto, setCedulaPhoto] = useState('');
	const [userPhoto, setUserPhoto] = useState('');
	const [cedulaVerified, setCedulaVerified] = useState(false);
	const [showSpinner, setShowSpinner] = useState(false);
	const [isContinueApplication, setIsContinueApplication] = useState(false);
	const [registrationStatus, setRegistrationStatus] = useState('');
	const [storeUser, setStoreUser] = useState('');
	const [userName, setUserName] = useState('');
	const [isMaster, setIsMaster] = useState(false);
	const [needsLocking, setNeedsLocking] = useState(false);
	const [allowAnyProduct, setAllowAnyProduct] = useState(false);
	const [allowIphone, setAllowIphone] = useState(false);
	const [allowLaptop, setAllowLaptop] = useState(false);
	const [selfFinanced, setSelfFinanced] = useState(false);
	const [city, setCity] = useState('');
	const [anexoAccepted, setAnexoAccepted] = useState(false);
	const [timeLeft, setTimeLeft] = useState(0);
	const [messType, setMessType] = useState('sms');

	//user application data
	const [monthlySalary, setMonthlySalary] = useState('');
	const [savings, setSavings] = useState('');
	const [educationLevel, setEducationLevel] = useState('');
	const [subStore, setSubStore] = useState('');
	const [subStoreList, setSubStoreList] = useState([]);
	const [subStorePhoneNumberMap, setSubStorePhoneNumberMap] = useState({});
	const [showTokenField, setShowTokenField] = useState(false);
	const [phoneToken, setPhoneToken] = useState('');
	const [loadingButton, setLoadingButton] = useState(false);
	const [tokenVerified, setTokenVerified] = useState(false);
	const [level, setLevel] = useState('level_1');
	const [cneIdentified, setCneIdentified] = useState(false);

	// device registration data
	const [loginError, setLoginError] = useState('');
	const [price, setPrice] = useState('');
	const [timeToPay, setTimeToPay] = useState(3);
	const [token, setToken] = useState(process.env.REACT_APP_API_TOKEN);
	const [interestFactor, setInterestFactor] = useState(0.1);
	const [deviceID, setDeviceID] = useState('');
	const [imeiNumber, setImeiNumber] = useState('');
	const [confNumber, setConfNumber] = useState('');
	const [extraInitial, setExtraInitial] = useState(0);
	const [buyingChip, setBuyingChip] = useState(false);
	const [imeiToRegister, setImeiToRegister] = useState('');
	const [imeiToRegister2, setImeiToRegister2] = useState('');
	const [tokenGenerated, setTokenGenerated] = useState(false);

	//webapp data
	const [isLoading, setIsLoading] = useState(false);
	const [isSearchingConfirmationNumber, setIsSearchingConfirmationNumber] = useState(false);
	const [country, setCountry] = useState('');
	const [loadingText, setLoadingText] = useState(false);
	const [loadingSubText, setLoadingSubText] = useState(false);
	const [showImei, setShowImei] = useState(false);
	const [showConfNumber, setShowConfNumber] = useState(false);
	const [page, setPage] = useState(2);
	const [amountSlider, setAmountSlider] = useState(50);
	const [limitReached, setLimitReached] = useState(false);
	const [userExistsCanBeFinanced, setUserExistsCanBeFinanced] = useState(false);
	const [productElegibility, setProductElegibility] = useState(false);
	const [productType, setProductType] = useState('');
	const [productName, setProductName] = useState('');
	const [switchChecked, setSwitchChecked] = useState(false);
	const [switchChecked2, setSwitchChecked2] = useState(false);
	const [blockNuovo, setBlockNuovo] = useState(false);
	const [allowOnline, setAllowOnline] = useState(false);
	const [isOnline, setIsOnline] = useState(false);
	const [documentType, setDocumentType] = useState('');
	const [countrySettings, setCountrySettings] = useState('');
	const [isInfinix, setIsInfinix] = useState(false);

	const store_promotions = GetStorePromotions(country);
	const initial_percentage_promotion = store_promotions?.[userData?.master_store?.id]?.initial_percentage || store_promotions?.global?.initial_percentage;

	//changeEmail data
	const [isAccountInactive, setIsAccountInactive] = useState('');

	const fileInputRef = useRef(null);

	const handleButtonClick = () => {
		fileInputRef.current.click();
	};

	var currentDay = new Date().getDate();
	if (currentDay === 31) {
		currentDay = 30;
	}

	function listenToAnexoDocumentByIdentificationNumber() {
		const unsubscribe = firestore
			.collection('Anexos')
			.where('identification_number', '==', cedula)
			.where('terms_accepted', '==', true)
			.where('related_device', '==', null)
			.onSnapshot((snapshot) => {
				if (!snapshot.empty) {
					console.log('Snapshot received, setting anexoAccepted to true');
					setAnexoAccepted(true);
				} else {
					console.log('No matching documents found.');
				}
			});

		// return unsubscribe; // make sure to return this if you need it for cleanup
	}

	useEffect(() => {
		setIsLoading(true);
		onAuthStateChanged(auth, (user) => {
			if (user) {
				console.log('user:', user.email);
				// User is signed in, see docs for a list of available properties
				const storeRef = firestore.collection('Stores').doc(user.email);
				setStoreUser(user.email);
				storeRef.get().then((doc) => {
					const data = doc.data();
					if (data.cobranza_access) {
						navigate('/internal');
						setIsLoading(false);
						return;
					}
					const masterStoreRef = data.master_store;
					const userName = masterStoreRef.id === user.email ? 'Master' : data.store_name;
					// const userName = data.store_name ? data.store_name : "Master"
					if (masterStoreRef.id === user.email) {
						setIsMaster(true);
					} else {
						setSubStoreList([data.store_name]);
					}
					setStoreEmail(masterStoreRef.id);
					// const storeRef = firestore.collection('Stores').doc(masterStore);
					masterStoreRef.get().then((doc) => {
						const data = doc.data();
						setCountry(data.country);
						setSelfFinanced(data.self_financed === true ? true : false);
						const ref = firestore.collection('AppSetting').doc('CountrySettings');
						ref.get().then((doc) => {
							const dat = doc.data();
							setCountrySettings(dat[data.country]);
							setInterestFactor(dat[data.country].payment_options.level_1[0].factor);
							setTimeToPay(dat[data.country].payment_options.level_1[0].installments / 2);
						});
						const storeName = data.store_name;
						if (data.allow_any_product === true) {
							setAllowAnyProduct(true);
						}
						if (data.allow_iphone === true) {
							setAllowIphone(true);
						}
						if (data.allow_laptop === true) {
							setAllowLaptop(true);
						}
						setBlockNuovo(data.only_allow_tonic === true ? true : false);
						setAllowOnline(data.allow_online === true ? true : false);
						setIsAccountInactive(!data.is_active);
						setUserName(storeName + ' - ' + userName);
						setSecurityProvider(data.security_provider);
						setStoreName(storeName);
						if (masterStoreRef.id === user.email) {
							setSubStoreList(data.sub_stores);
							setSubStorePhoneNumberMap(data.subStoreAdminPhoneNumbers);
						} else {
							if (data.subStoreAdminPhoneNumbers.hasOwnProperty('Administrador')) {
								setSubStorePhoneNumberMap({
									[userName]: data.subStoreAdminPhoneNumbers[userName],
									Administrador: data.subStoreAdminPhoneNumbers['Administrador']
								});
							} else {
								setSubStorePhoneNumberMap({ [userName]: data.subStoreAdminPhoneNumbers[userName] });
							}
						}
						setIsLoading(false);
						// setDownPaymentPerc(data['down_payment']);
					});
					setIsLoading(false);
				});
			} else {
				// User is signed out
				// ...
				navigate('/login');
				console.log('user is logged out');
				setIsLoading(false);
			}
		});

		// if (activateAnexoListener) {  // Only start listening if we have a valid ID
		//   // const unsubscribe = listenToAnexoDocumentByIdentificationNumber();
		//   firestore.collection("Anexos")
		//     .where("identification_number", "==", cedula)
		//     .where('terms_accepted', '==', true)
		//     .where('related_device', '==', null)
		//     .onSnapshot((snapshot) => {
		//       if (!snapshot.empty) {
		//         console.log("Snapshot received, setting anexoAccepted to true");
		//         setAnexoAccepted(true);
		//       } else {
		//         console.log("No matching documents found.");
		//       }
		//     });
		//   // Clean up the listener on component unmount or when identificationNumber changes
		//   // return () => unsubscribe();
		// }
	}, []);

	function getNextXDates(x) {
		const dates = [];
		let currentDate = new Date();

		// Calculate the first date as 15 days from the current date
		currentDate.setDate(currentDate.getDate() + 15);

		for (let i = 0; i < x; i++) {
			// Adjust weekends to the following Monday
			while (currentDate.getDay() === 0 || currentDate.getDay() === 6) {
				currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
			}

			dates.push([currentDate.getDate(), getMonthName(currentDate.getMonth() + 1)]);

			// Move ahead by 15 days
			currentDate.setDate(currentDate.getDate() + 15);
		}
		return dates;
	}

	const getMonthName = (monthNumber) => {
		const date = new Date(2000, monthNumber - 1); // Set any year, and subtract 1 from the month number
		const monthName = date.toLocaleString('es-ES', { month: 'short' }); // Get the full month name

		return monthName.charAt(0).toUpperCase() + monthName.slice(1);
	};

	const sendPostRequest = async (data, endpoint, token2) => {
		try {
			const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${await firebase.auth().currentUser.getIdToken(true)}`,
					'x-master-store-email': storeEmail,
					'x-user-email': storeUser
				},
				body: JSON.stringify({ ...data, country: country })
			});
			const responseData = await response.json();
			if (response.status === '401') {
				const tokenData = await requestToken();
				await setToken('Token ' + tokenData.token);
				const resData = await sendPostRequest(data, endpoint, 'Token ' + tokenData.token);
				return resData;
			}
			return responseData;
		} catch (error) {
			console.error('Error:', error.message);
			return null;
		}
	};

	const requestToken = async () => {
		const loginData = {
			username: process.env.REACT_APP_TOKEN_USERNAME,
			password: process.env.REACT_APP_TOKEN_PASSWORD
		};
		try {
			const response = await fetch(process.env.REACT_APP_API_URL + '/api/token/', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(loginData)
			});

			const responseData = await response.json();
			return responseData;
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const handleCheckCedula = async (e) => {
		e.preventDefault();
		if (isAccountInactive) {
			showAlert(`
        <div class="text-center">
          <h3 class="text-lg font-semibold text-primary-400 mb-2 light:text-primary-500">Cuenta Inactiva</h3>
          <p class="text-neutral-300">
            Esta cuenta esta inactiva para realizar ventas con Krece por los momentos, por favor contacte al equipo de Krece para entender el motivo.
          </p>
        </div>
      `);
			return;
		}
		if (!cedula) {
			showAlert(`Por favor introduzca ${country === 'peru' ? 'el DNI' : 'la cédula'}`);
			return;
		}
		const cedulaRegex = /^[0-9]+$/;
		if (!cedulaRegex.test(cedula)) {
			showAlert(`Por favor ingrese un numero de ${country === 'peru' ? 'DNI' : 'cédula'} válido (sólo números).`);
			return;
		}
		setIsLoading(true);
		setLoginError('');

		const storeRef = await firestore.collection('Stores').doc(storeEmail);
		storeRef.get().then(async (doc) => {
			setLimitReached(doc.data()['limit_reached']);
			if (doc.data()['limit_reached']) {
				showAlert(`
            <div class="text-center">
              <h3 class="text-lg font-semibold text-primary-400 mb-2 light:text-primary-500">¡Atención!</h3>
              <p class="text-neutral-300">
                Se ha llegado al límite diario. Este portal le permitirá completar la aplicación pero la venta debe ser completada en otro momento.
              </p>
            </div>
          `);
			}
			const usersCollection = firestore.collection('Users');
			const queryUser = usersCollection.where('identification_number', '==', cedula).limit(1);
			await queryUser.get().then((querySnapshot) => {
				if (querySnapshot.docs.length == 0) {
					setIsLoading(false);
					if (!allowAnyProduct && (country === 'venezuela' || country === '')) {
						showAlert(`
                <div class="text-center">
                  <h3 class="text-lg font-semibold text-primary-400 mb-2 light:text-primary-500">¡Información Importante!</h3>
                  <p class="text-neutral-300">
                    Este usuario es elegible para:
                  </p>
                  <ul class="mt-2 space-y-1 text-neutral-100">
                    <li>• CELULARES ANDROID ÚNICAMENTE</li>
                  </ul>
                  <p class="mt-3 text-sm text-primary-300 light:text-primary-600">
                    ¡Disponible con facilidades de pago a cuotas!
                  </p>
                </div>
              `);
					}
					setNeedsLocking(true);
					setPage(16);
					return;
				}
				querySnapshot.forEach(async (doc) => {
					if (doc.exists) {
						const data = doc.data();
						setEmail(doc.id);
						if (data.hasOwnProperty('max_loan_approved_3months')) {
							setApplicationApprovalData({
								threeMonthAmount: doc.get('max_loan_approved_3months')
							});
							//change after black friday
							// if (country === "venezuela") {
							//   setApplicationApprovalData({
							//     threeMonthAmount: 1125,
							//   })
							// }
							setLevel(data.level);
						}

						if (data.hasOwnProperty('max_loan_approved_3months_krece_plus')) {
							setMaxLoan3MonthsKrecePlus(data.max_loan_approved_3months_krece_plus);
						}

						// if (data.hasOwnProperty('percent_down_approved')) {
						//   setDownPaymentPerc(data.percent_down_approved)
						// }
						setDownPaymentPerc(initial_percentage_promotion ? initial_percentage_promotion : countrySettings.down_payment[data.level]);

						setRegistrationStatus(data.registration_status);
						if (
							data.registration_status === 'registered' ||
							data.registration_status === 'application_reviewed' ||
							data.registration_status === 'device_registered'
						) {
							if (!allowAnyProduct && (country === 'venezuela' || country === '')) {
								showAlert(`
                    <div class="text-center">
                      <h3 class="text-lg font-semibold text-primary-400 mb-2 light:text-primary-500">¡Información Importante!</h3>
                      <p class="text-neutral-300">
                        Este usuario es elegible para:
                      </p>
                      <ul class="mt-2 space-y-1 text-neutral-100">
                        <li>• CELULARES ANDROID ÚNICAMENTE</li>
                      </ul>
                      <p class="mt-3 text-sm text-primary-300 light:text-primary-600">
                        ¡Disponible con facilidades de pago a cuotas!
                      </p>
                    </div>
                  `);
							}
							setNeedsLocking(true);
							setIsContinueApplication(true);
							setIsLoading(false);
							setPage(17);
						} else if (data.registration_status === 'device_enrolled') {
							const cedulaData = {
								identification_number: cedula
							};
							const response = await sendPostRequest(cedulaData, 'registration/usercheck/', token); //status, success, data
							if (response.success && response?.status !== 'El usuario se registro en otro pais. Contacte a soporte tecnico.') {
								setApplicationApprovalData({
									threeMonthAmount: response.data.available_loan_approved
								});
								//change after black friday
								// if (country === "venezuela") {
								//   setApplicationApprovalData({
								//     threeMonthAmount: 1125,
								//   })
								// }
								setMaxLoan3MonthsKrecePlus(response.max_loan_approved_3months_krece_plus);

								if (response.data.can_be_financed) {
									const response = await sendPostRequest(cedulaData, 'registration/productelegibility/', token);
									if (response.success) {
										if (response.elegible) {
											if (!allowAnyProduct && (country === 'venezuela' || country === '')) {
												showAlert(`
                            <div class="text-center">
                              <h3 class="text-lg font-semibold text-primary-400 mb-2 light:text-primary-500">¡Buenas noticias!</h3>
                              <p class="text-neutral-300">
                                Este usuario es elegible para:
                              </p>
                              <ul class="mt-2 space-y-1 text-neutral-100">
                                <li>• CELULARES ANDROID</li>
                                <li>• OTROS PRODUCTOS</li>
                              </ul>
                              <p class="mt-3 text-sm text-primary-300 light:text-primary-600">
                                ¡Disponible con facilidades de pago a cuotas!
                              </p>
                            </div>
                          `);
											}
										} else {
											if (!allowAnyProduct && (country === 'venezuela' || country === '')) {
												showAlert(`
                            <div class="text-center">
                              <h3 class="text-lg font-semibold text-primary-400 mb-2 light:text-primary-500">¡Buenas noticias!</h3>
                              <p class="text-neutral-300">
                                Este usuario es elegible para:
                              </p>
                              <ul class="mt-2 space-y-1 text-neutral-100">
                                <li>• CELULARES ANDROID ÚNICAMENTE: ${response.status}</li>
                              </ul>
                              <p class="mt-3 text-sm text-primary-300 light:text-primary-600">
                                ¡Disponible con facilidades de pago a cuotas!
                              </p>
                            </div>
                          `);
											}
											setNeedsLocking(true);
										}
										setProductElegibility(response.elegible);
										setIsContinueApplication(true);
										setIsLoading(false);
										setPage(17);
									}
								} else {
									setLoginError(response.status);
									setIsLoading(false);
								}
							} else {
								setLoginError('Ocurrio un problema: ' + response.status);
								setIsLoading(false);
							}
						} else {
							setLoginError('Ocurrio un problema por favor contacte a servicio tecnico.');
							setIsLoading(false);
						}
					}
				});
			});
		});
	};

	const handleFaceSubmit = async (userPhoto) => {
		setFormError('');
		if (!userPhoto) {
			setFormError('Por favor suba una imagen.');
			setShowSpinner(false);
			return;
		}

		// Simulating API call delay
		const infoData = {
			identification_number: cedula,
			photo_id_url: userPhoto,
			existing_user: userExistsCanBeFinanced || isContinueApplication
		};
		const response = await sendPostRequest(infoData, 'authentication/face-identification/', token);
		if (storeEmail === 'test@store.com' || storeEmail === 'testrd@store.com' || storeEmail === 'testperu@store.com') {
			response.success = true;
		}
		if (response?.success) {
			if (isContinueApplication) {
				if (registrationStatus === 'registered') {
					setCedulaVerified(true);
					setShowSpinner(false);
					setTimeout(() => {
						setPage(8);
						setCedulaVerified(false);
					}, 2000);
				} else if (registrationStatus === 'application_reviewed' || registrationStatus === 'device_enrolled') {
					setCedulaVerified(true);
					setShowSpinner(false);
					setTimeout(() => {
						setPage(4);
						setCedulaVerified(false);
					}, 2000);
				} else if (registrationStatus === 'device_registered') {
					setCedulaVerified(true);
					setShowSpinner(false);
					setTimeout(() => {
						setPage(4);
						setCedulaVerified(false);
					}, 2000);
				}
			} else {
				setCedulaVerified(true);
				setShowSpinner(false);
				if (response.gender) {
					setGender(response.gender);
				}
				setTimeout(() => {
					setPage(3);
					setCedulaVerified(false);
				}, 2000);
			}
			setShowSpinner(false);
		} else if (response?.success === false) {
			setFormError(response.status);
			setShowSpinner(false);
		} else {
			setFormError('Estamos teniendo problemas para conectarnos. Por favor chequee su conexión');
			setShowSpinner(false);
		}
	};

	function convertDateFormat(dateString) {
		// Split the input string into day, month, and year components
		const [day, month, year] = dateString.split('/');

		// Create a new Date object with the parsed components
		const parsedDate = new Date(`${year}-${month}-${day}`);

		// Extract the year, month, and day components in yyyy-MM-dd format
		const formattedDate = parsedDate.toISOString().split('T')[0];

		return formattedDate;
	}

	function getUserInput() {
		function getInputRecursive() {
			const userInput = window.prompt(`No pudimos identificar ${country === 'peru' ? 'el DNI' : 'la cédula'} por favor introduzca el número aquí:`);
			if (userInput === null) {
				// User clicked cancel or closed the prompt
				return getInputRecursive(); // Ask again
			} else if (!/^[0-9]+$/.test(userInput)) {
				// Invalid input (doesn't match the regex)
				showAlert(`Por favor introduzca un número de ${country === 'peru' ? 'DNI' : 'cédula'} válido.`);
				return getInputRecursive(); // Ask again
			} else {
				// Valid input
				return userInput;
			}
		}

		return getInputRecursive();
	}

	const handleIdentificationSubmit = async (cedulaPhoto) => {
		setFormError('');
		if (!cedulaPhoto) {
			setFormError('Por favor suba una imagen.');
			setShowSpinner(false);
			return;
		}
		if (!documentType) {
			setFormError('Seleccione el tipo de documento.');
			setShowSpinner(false);
			return;
		}

		const infoData = {
			identification_id_url: cedulaPhoto,
			document_type: documentType.code,
			document_country: documentType.country
		};
		const response = await sendPostRequest(infoData, 'authentication/document-identification/', token);
		if (response?.success) {
			const userData = response.data;
			if (response.data.identification_number === false || !response.identity_verified) {
				const userInput = getUserInput();
				response.data.identification_number = userInput;
				if (userInput !== cedula) {
					setFormError('La cedula que se subio no coincide con el número de cedula escrito en la pantalla anterior.');
					setShowSpinner(false);
					return;
				}
				setCedula(userInput);
				userData.identification_number = userInput;
			} else {
				if (cedula !== userData.identification_number.replaceAll('-', '')) {
					setFormError('La cedula que se subio no coincide con el número de cedula escrito en la pantalla anterior.');
					setShowSpinner(false);
					return;
				}
			}

			if (userData.cne_identified) {
				setCneIdentified(true);
				setCneData({
					centro: userData.centro,
					direccion: userData.direccion,
					estado: userData.estado,
					municipio: userData.municipio,
					parroquia: userData.parroquia
				});
			}
			setGender(userData.gender ? userData.gender : '');
			setFirstName(userData.first_name ? userData.first_name : ''); 
			setLastName(userData.last_name ? userData.last_name : ''); 
			setBirthDate(userData.date_of_birth ? userData.date_of_birth : ''); 
			const expDate =
				userData.expiration_date !== false
					? userData.expiration_date.split('/').length === 3
						? userData.expiration_date.split('/')[2]
						: userData.expiration_date.split('/')[1]
					: false;
			if (userData.expiration_date !== false && parseInt(new Date().getFullYear()) - expDate.split('/')[1] > 2) {
				setFormError(`${country === 'peru' ? 'El DNI' : 'La cédula'} esta expirada más de dos años. No podemos seguir con este registro.`);
				setShowSpinner(false);
				return;
			}
			setTimeout(() => {
				setCedulaVerified(false);
				setPage(17);
			}, 2000);
			setShowSpinner(false);
			setCedulaVerified(true);
		} else if (response?.success === false) {
			setFormError(response.status);
			setShowSpinner(false);
		} else {
			setFormError('Estamos teniendo problemas para conectarnos. Por favor chequee su conexión');
			setShowSpinner(false);
		}
	};

	const handleInformationSubmit = async (e) => {
		e.preventDefault();

		if (!firstName || !lastName || !email || !email2 || !cedula || !phoneNumber || !birthDate || !address || !gender) {
			setFormError('Por favor complete todas las casillas.');
			return;
		}

		if (isUnder18(birthDate)) {
			setFormError(
				'El cliente tiene menos de 18 años y no es elegible para Krece, si creen que hay un error ajuste la fecha de nacimiento.'
			);
			return;
		}

		if (email !== email2) {
			setFormError('Los emails intoducidos no coinciden.');
			return;
		}

		// Validate email format
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (!emailRegex.test(email)) {
			setFormError('Por favor ingrese un email válido.');
			return;
		}

		// Validate phone number format
		if (country === 'peru') {
			const phoneRegex = /^\d{9}$/;
			if (!phoneRegex.test(phoneNumber)) {
				setFormError('Por favor ingrese un número de teléfono de 9 digitos.');
				return;
			}
		} else {
			const phoneRegex = /^\d{10,11}$/;
			if (!phoneRegex.test(phoneNumber)) {
				setFormError('Por favor ingrese un número de teléfono de 10 digitos.');
				return;
			}
		}

		// Validate birth date format
		const birthDateRegex = /^\d{4}-\d{2}-\d{2}$/;
		if (!birthDateRegex.test(birthDate)) {
			setFormError('Por favor ingrese una fecha válida.');
			return;
		}

		//validate cedula format
		const cedulaRegex = /^[0-9]+$/;
		if (!cedulaRegex.test(cedula)) {
			setFormError(`Por favor ingrese un numero de ${country === 'peru' ? 'DNI' : 'cédula'} válido (sólo números).`);
			return;
		}

		// if (storeEmail === 'test@store.com' || storeEmail === 'testrd@store.com' || storeEmail === 'testperu@store.com') {
		//   setIsLoading(false);
		//   setFormError('');
		//   setPage(8)
		//   return;
		// }

		if (!tokenVerified) {
			setFormError('Por favor realice la verificación de Token para poder continuar.');
			return;
		}

		// Form validation passed, submit the form or perform other actions
		setFormError('');
		// ...
		setIsLoading(true);

		// Simulating API call delay
		const infoData = {
			email: email,
			first_name: firstName,
			last_name: lastName,
			date_of_birth: birthDate,
			sex: gender,
			address: address,
			identification_number: cedula,
			photo_id_url: userPhoto,
			identification_id_url: cedulaPhoto,
			phone_number: phoneNumber,
			seller_store: storeEmail,
			store_user: storeUser,
			cne_identified: cneIdentified,
			...cneData
		};
		const response = await sendPostRequest(infoData, 'registration/userregistration/', token);
		if (response?.success) {
			if (!response.user_exists || userExistsCanBeFinanced) {
				setIsLoading(false);
				setPage(8);
			} else {
				setFormError('Ya existe un usuario con este email.');
				setIsLoading(false);
			}
		} else if (response?.success === false) {
			setFormError(response.status);
			setTokenVerified(false);
			setShowTokenField(true);
			setIsLoading(false);
		} else {
			setFormError('Estamos teniendo problemas para conectarnos. Por favor chequee su conexión');
			setIsLoading(false);
		}
	};

	function isUnder18(dateString) {
		// Convert the date string to a Date object
		const birthDate = new Date(dateString);

		// Get the current date
		const currentDate = new Date();

		// Calculate the age
		const age = currentDate.getFullYear() - birthDate.getFullYear();

		// Check if the birthday has occurred this year
		const hasBirthdayOccurred =
			currentDate.getMonth() > birthDate.getMonth() ||
			(currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() >= birthDate.getDate());

		// Determine if the person is under 18
		const isUnderage = age < 18 || (age === 18 && !hasBirthdayOccurred);

		return isUnderage;
	}

	const handleRegistrationSubmit = (e) => {
		e.preventDefault();
		if (switchChecked && price && price < 100) {
			setFormError('El precio para la promoción debe ser al menos 100.');
			return;
		}

		setExtraInitial(0);
		if (
			!subStore ||
			!price ||
			!timeToPay ||
			((productElegibility || allowAnyProduct) && !productType) ||
			(productType === 'other' && !productName)
		) {
			setFormError('Por favor complete todas las casillas.');
			return;
		}
		// if (timeToPay === 2) {
		//   setInterestFactor(0.15)
		//   if (level === 'level_3') {
		//     setInterestFactor(0.1375)
		//   } else if (level === 'level_4') {
		//     setInterestFactor(0.13)
		//   }
		// } else {
		//   setInterestFactor(0.1167)
		//   if (level === 'level_3') {
		//     setInterestFactor(0.1084)
		//   } else if (level === 'level_4') {
		//     setInterestFactor(0.1034)
		//   }
		// }

		const escapedMin = countrySettings.price_min.toString().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
		const escapedMax = countrySettings.price_max.toString().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

		// Create the regex string
		const priceRegex = new RegExp(`^(${escapedMin}|[1-9]\\d{0,${escapedMax.length - 1}}|${escapedMax})$`);

		// const priceRegex = /^(3[5-9]|[4-9]\d|[1-9]\d{2}|1[0-4]\d{2}|1500)$/;
		if (!priceRegex.test(price)) {
			setFormError('Por favor ingrese el precio correcto del dispositivo.');
			return;
		}

		// if (switchChecked2 && price < 120) {
		//   setFormError('El precio para la promoción debe ser mayor a 120.');
		//   return;
		// }

		if (!switchChecked && (switchChecked2 ? 300 : applicationApprovalData.threeMonthAmount) / (1 - downPaymentPerc) < price) {
			setPrice(Math.round((switchChecked2 ? 300 : applicationApprovalData.threeMonthAmount) / (1 - downPaymentPerc)));
			setExtraInitial(
				parseInt(price) - Math.round((switchChecked2 ? 300 : applicationApprovalData.threeMonthAmount) / (1 - downPaymentPerc))
			);
		}

		if (allowAnyProduct && productType === 'other') {
			if (!switchChecked && maxLoan3MonthsKrecePlus / (1 - 0.5) < price) {
				setPrice(Math.round(maxLoan3MonthsKrecePlus / (1 - 0.5)));
				setExtraInitial(parseInt(price) - Math.round(maxLoan3MonthsKrecePlus / (1 - 0.5)));
			}
		}

		// Form validation passed, submit the form or perform other actions
		setFormError('');
		setPage(6);
	};

	const handleApplicationSubmit = async (e) => {
		e.preventDefault();
		if (!educationLevel || !monthlySalary || !savings || !city) {
			setFormError('Por favor complete todas las casillas.');
			return;
		}

		// Validate monthly salary format
		const salaryRegex = /^[0-9]+$/;
		if (!salaryRegex.test(monthlySalary)) {
			setFormError('Por favor ingrese un salario válido (sólo números).');
			return;
		}

		// Form validation passed, submit the form or perform other actions
		setFormError('');
		// ...
		setIsLoading(true);
		const applicationData = {
			user_document_id: email,
			monthly_income_dollars: monthlySalary,
			savings: savings,
			education_level: educationLevel,
			seller_store: storeEmail,
			store_user: storeUser,
			city: city
		};

		const response = await sendPostRequest(applicationData, 'registration/reviewuserapplication/', token); //status, success, data
		if (response?.success) {
			setApplicationApprovalData({
				threeMonthAmount: response.data.max_loan_approved_3months,
				sixMonthAmount: response.data.max_loan_approved_6months
			});
			//change after black friday
			// if (country === "venezuela") {
			//   setApplicationApprovalData({
			//     threeMonthAmount: 1125,
			//   })
			// }

			setMaxLoan3MonthsKrecePlus(response.max_loan_approved_3months_krece_plus);
			setLimitReached(response.data.limit_reached);
			// setInterestFactor(response.data.interest_rate)
			// setInterestFactor(0.1167);
			setDownPaymentPerc(countrySettings.down_payment.level_1);
			setIsLoading(false);
			setPage(4);
		} else if (response?.success === false) {
			setFormError(response.status);
			setIsLoading(false);
		} else {
			setFormError('Estamos teniendo problemas para conectarnos. ');
			setIsLoading(false);
		}
	};

	const generateAnexo = async (e) => {
		setFormError('');
		setIsLoading(true);
		// const paymentAmount = Math.round((Math.round((((parseInt(price) * (1 - downPaymentPerc)) * interestFactor) / (parseInt(timeToPay) * 2)) * 100)) / 100);
		let paymentAmount = Math.round(
			Math.round(((parseInt(price) * (1 - downPaymentPerc) * interestFactor) / (parseInt(timeToPay) * 2)) * 100) / 100
		);
		if (switchChecked) {
			paymentAmount = Math.round((parseInt(price) * 0.5 * 1.25) / (timeToPay * 2));
		}
		let amountFinanced = parseInt(price) + extraInitial - Math.round(parseInt(price) * downPaymentPerc + extraInitial);

		if (switchChecked) {
			amountFinanced = Math.round(parseInt(price) * 0.5);
		}
		const data = {
			user_document_id: email,
			seller_store: storeEmail,
			store_user: storeUser,
			device_type: productType,
			sub_store: subStore,
			device_imei: imeiToRegister,
			loan_duration: timeToPay,
			payment_amount: paymentAmount,
			self_financed: selfFinanced,
			device_price: (parseInt(price) + extraInitial).toFixed(2),
			amount_financed: amountFinanced,
			loan_payment_cycle: 15
		};
		const resp2 = await sendPostRequest(data, 'registration/anexoregistration/', token); //to-do: Necesito mandar la cedula? ese token como se va a guardar en la base de datos?
		if (resp2?.success) {
			showAlert(`
        <div class="text-center">
          <h3 class="text-lg font-semibold text-primary-400 mb-2 light:text-primary-500">¡Contrato Generado!</h3>
          <p class="text-neutral-300">
            Contrato generado en app exitosamente. El usuario debe abrir la app Krece para aceptar el contrato.
          </p>
        </div>
      `);
			setTokenGenerated(true);
			setIsLoading(false);
		} else if (resp2.success === false) {
			setFormError(resp2.status);
			setIsLoading(false);
		} else {
			setFormError('Ocurrió un problema generando el contrato, por favor vuelva a intentar.');
			setIsLoading(false);
		}
	};

	const handleSendDeviceID = async (e) => {
		try {
			setFormError('');
			setLoginError('');
			if (!deviceID) {
				setFormError('Por favor introduzca el código de confirmación.');
				return;
			}
			if (deviceID.length !== 7) {
				setFormError('El número de confirmación debe tener 7 digitos.');
				return;
			}
			setIsLoading(true);
			if (!productElegibility && allowAnyProduct && productType == 'other') {
				const devData = {
					device_imei: imeiToRegister,
					seller_store: storeEmail,
					sub_store: subStore,
					device_management_provider: 'nuovo',
					user_document_id: email,
					store_user: storeUser,
					device_id: deviceID
				};
				const response = await sendPostRequest(devData, 'anclar_device/', token); //status, success, data
				if (response?.success) {
					await registerOtherProduct();
					setIsLoading(false);
					setLoadingText('');
					setLoadingSubText('');
				} else if (response?.success === false) {
					setFormError(response.status);
					setIsLoading(false);
				} else {
					setFormError(
						'Ocurrió un problema, por favor vuelva a hacer clic en Siguiente, si el problema persiste contacte a servicio técnico.'
					);
					setIsLoading(false);
				}
				return;
			}
			const paymentAmount = Math.round(
				Math.round(((parseInt(price) * (1 - downPaymentPerc) * interestFactor) / (parseInt(timeToPay) * 2)) * 100) / 100
			);
			const deviceData = {
				user_document_id: email,
				device_id: deviceID,
				seller_store: storeEmail,
				device_price: (parseInt(price) + extraInitial).toFixed(2),
				loan_duration: timeToPay,
				payment_data: {
					loan_payment_cycle: 15,
					payment_amount: paymentAmount
				},
				amount_financed: parseInt(price) + extraInitial - Math.round(parseInt(price) * downPaymentPerc + extraInitial),
				// amount_financed: Math.round(parseInt(price) * (1 - downPaymentPerc) * 100) / 100,
				sub_store: subStore,
				new_chip_acquired: buyingChip === 'Si' ? true : false,
				store_user: storeUser,
				device_type: productType,
				promotional_code: switchChecked ? 'promo_samsung' : switchChecked2 ? 'promo_infinix' : '',
				online_purchase: isOnline
			};
			const response = await sendPostRequest(deviceData, 'registration/deviceregistration/', token);
			if (response?.success) {
				setPage(15);
				setShowConfNumber(false);
				setShowImei(false);
				setIsLoading(false);
			} else if (response?.status.includes('Error getting device')) {
				setFormError('Número de confirmación incorrecto. Vuelva a intentar con otro número.');
				setIsLoading(false);
			} else if (response?.success === false) {
				setFormError(response.status);
				setIsLoading(false);
			} else {
				setFormError('Ocurrió un problema, intente más tarde.');
				setIsLoading(false);
			}
		} catch (error) {
			setFormError('Estamos teniendo problemas para conectarnos.');
			setIsLoading(false);
		}
	};

	const handleNext = () => {
		setFormError('');
		if (page === 4 && (switchChecked || switchChecked2)) setProductType('phone');
		if (amountSlider) setPrice(amountSlider);
		setPage(5);
	};

	const handlePhotoChange = async (event) => {
		try {
			setShowSpinner(true);
			const file = event.target.files[0];

			// Create a storage reference with a unique filename
			const storage = firebase.storage();
			const storageRef = storage.ref();
			const fileName = `${Date.now()}_${file.name}`;
			const fileRef = storageRef.child(fileName);
			await fileRef.put(file);
			// Upload the file to Firebase Storage
			const downloadURL = await fileRef.getDownloadURL();
			setCedulaPhoto(downloadURL);
			handleIdentificationSubmit(downloadURL);
		} catch (error) {
			setCedulaPhoto('not available');
			setShowSpinner(false);
		}
	};

	const handlePhotoChange2 = async (event) => {
		try {
			setShowSpinner(true);
			const file = event.target.files[0];

			// Create a storage reference with a unique filename
			const storage = firebase.storage();
			const storageRef = storage.ref();
			const fileName = `${Date.now()}_${file.name}`;
			const fileRef = storageRef.child(fileName);
			await fileRef.put(file);
			// Upload the file to Firebase Storage
			const downloadURL = await fileRef.getDownloadURL();
			setUserPhoto(downloadURL);
			handleFaceSubmit(downloadURL);
		} catch (error) {
			setUserPhoto('not available');
		}
	};

	const registerOtherProduct = async () => {
		try {
			let paymentAmount =
				allowAnyProduct && productType === 'other'
					? ((parseInt(price) * (1 - 0.5) * 1) / (parseInt(timeToPay) * 2)).toFixed(2)
					: Math.round((parseInt(price) * (1 - downPaymentPerc) * interestFactor) / (parseInt(timeToPay) * 2));
			// if (allowAnyProduct && productType === 'other') {
			//   paymentAmount = Math.round((Math.round((((parseInt(price) * (1 - downPaymentPerc)) * interestFactor) / (parseInt(timeToPay) * 2)) * 100)) / 100);
			// }

			const deviceData = {
				user_document_id: email,
				seller_store: storeEmail,
				device_price: (parseInt(price) + extraInitial).toFixed(2),
				loan_duration: timeToPay,
				payment_data: {
					loan_payment_cycle: 15,
					payment_amount: paymentAmount
				},
				amount_financed:
					parseInt(price) +
					extraInitial -
					(allowAnyProduct && productType === 'other'
						? parseInt(price) * 0.5 + extraInitial
						: Math.round(parseInt(price) * downPaymentPerc + extraInitial)),
				sub_store: subStore,
				new_chip_acquired: false,
				store_user: storeUser,
				device_type: productType,
				product_description: productName
			};
			const response = await sendPostRequest(deviceData, 'registration/deviceregistration/', token);
			if (response?.success) {
				setPage(15);
				setShowConfNumber(false);
				setShowImei(false);
				setIsLoading(false);
			} else if (response?.status.includes('Error getting device')) {
				setFormError('Número de confirmación incorrecto. Vuelva a intentar con otro número.');
				setIsLoading(false);
			} else if (response?.success === false) {
				setFormError(response.status);
				setIsLoading(false);
			} else {
				setFormError('Ocurrió un problema, intente más tarde.');
				setIsLoading(false);
			}
		} catch (err) {
			setFormError('Ocurrió un problema, intente más tarde.');
			setIsLoading(false);
		}
	};

	const handleTermsAccepted = async () => {
		// to-do test this agregar un field del nombre del producto
		if (allowAnyProduct && productType == 'other') {
			setIsLoading(true);
			await registerOtherProduct();
		} else if (productElegibility && productType == 'other') {
			setIsLoading(true);
			await registerOtherProduct();
		} else if (!productElegibility && allowAnyProduct && productType == 'other') {
			// To-do -> agregar otro else if !productElegibility && allowAnyProduct && productType == 'other' entonces se hace el anclaje del celular y despues se corre el codigo que esta aqui abajo
			const imeiInput = window.prompt(
				'A continuación vamos a anclar el celular Android del cliente, el cual servira como garantia virtual para la compra de este producto, para comenzar introduce el número IMEI del equipo:'
			);
			const imeiData = {
				device_imei: imeiInput
			};
			const response = await sendPostRequest(imeiData, 'anclar_device_check_imei/', token); //to-do ver pq no sirve esto con yoda
			if (response?.success) {
				goToPage(7);
				setIsLoading(false);
			} else if (response?.success === false) {
				showAlert(response.status);
				setIsLoading(false);
			} else {
				showAlert('Ocurrió un problema, intente más tarde.');
				setIsLoading(false);
			}
		} else {
			goToPage(7);
		}
	};

	const goToPage = (x) => {
		setFormError('');
		setLoginError('');
		setPage(x);
	};

	const backToWelcomePage = () => {
		window.location.reload();
	};

	const searchConfirmationNumber = async () => {
		try {
			setIsSearchingConfirmationNumber(true);
			setConfNumber('');
			setShowConfNumber(false);
			setLoginError('');
			const data = {
				store_name: storeName,
				imei: imeiNumber
			};
			const response = await sendPostRequest(data, 'registration/finddevice/', token);
			if (response?.success) {
				setConfNumber(response.data.nuovo_id);
				setShowConfNumber(true);
			} else if (response?.success === false) {
				setLoginError(response.status);
			} else {
				setLoginError('Estamos teniendo problemas para conectarnos.');
			}
		} catch (e) {
		} finally {
			setIsSearchingConfirmationNumber(false);
		}
	};

	const getFactorByInstallment = (installment, data) => {
		for (const level in data) {
			for (const entry of data[level]) {
				if (entry.installments === installment) {
					return entry.factor;
				}
			}
		}
		return null; // return null if the installment is not found
	};

	function changeTimeToPay(ttp) {
		setTimeToPay(ttp);
		const factor = getFactorByInstallment(ttp * 2, countrySettings.payment_options);
		setInterestFactor(factor);
	}

	const sendPhoneToken = async () => {
		setFormError('');
		setShowTokenField(false);
		setLoadingButton(true);
		try {
			if (!phoneNumber) {
				showAlert('Por favor introducir número de teléfono.');
				setLoadingButton(false);
				return;
			}
			if (!messType) {
				showAlert('Por favor seleccionar tipo de mensaje.');
				setLoadingButton(false);
				return;
			}
			// Validate phone number format
			if (country === 'peru') {
				const phoneRegex = /^\d{9}$/;
				if (!phoneRegex.test(phoneNumber)) {
					setFormError('Por favor ingrese un número de teléfono de 9 digitos.');
					setLoadingButton(false);
					return;
				}
			} else {
				const phoneRegex = /^\d{10,11}$/;
				if (!phoneRegex.test(phoneNumber)) {
					setFormError('Por favor ingrese un número de teléfono de 10 digitos.');
					setLoadingButton(false);
					return;
				}
			}
			const codeData = {
				phone_number: phoneNumber,
				code_type: messType
			};
			const response = await sendPostRequest(codeData, 'notifications/requestauthcode/', token); //status, success, data
			if (storeEmail === 'test@store.com' || storeEmail === 'testrd@store.com' || storeEmail === 'testperu@store.com') {
				response.success = true;
			}
			if (response?.success) {
				setShowTokenField(true);
				setLoadingButton(false);
				showAlert(`
          <div class="text-center">
            <h3 class="text-lg font-semibold text-primary-400 mb-2 light:text-primary-500">¡Token Enviado!</h3>
            <p class="text-neutral-300">
              Se ha enviado un token por ${messType == 'sms' ? 'SMS' : messType} al ${phoneNumber}.
            </p>
          </div>
        `);
				setTimeLeft(30);
				const timerId = setInterval(() => {
					setTimeLeft((prevTime) => {
						if (prevTime === 1) {
							clearInterval(timerId);
						}
						return prevTime - 1;
					});
				}, 1000);
			} else if (response?.success === false) {
				showAlert(response.status);
				setLoadingButton(false);
			} else {
				showAlert('Estamos teniendo problemas para conectarnos.');
				setLoadingButton(false);
			}
		} catch (error) {
			showAlert('Estamos teniendo problemas para conectarnos.');
			setLoadingButton(false);
		}
	};

	const fillToken = (token) => {
		setPhoneToken(token);
		if (token.length === 6) {
			setShowTokenField(false);
			setLoadingButton(true);
			confirmPhoneTokenFromUser(token);
		} else if (token.length > 6) {
			showAlert('El Token debe ser de 6 digitos.');
		}
	};

	const checkDeviceReady = async () => {
		let breakLoop = false;
		let status = 'waiting';

		for (let i = 0; i < 10; i++) {
			if (breakLoop) {
				break;
			}
			const devDat = {
				device_imei: imeiToRegister,
				device_type: productType,
				device_management_provider: productType === 'laptop' ? 'absolute' : 'tonic',
				device_price: price
			};
			await new Promise((resolve) => {
				setTimeout(async () => {
					try {
						let resp = await sendPostRequest(devDat, 'registration/imeistatus/', token);
						if (resp?.success || productType === 'iphone') {
							if (resp.device_ready || productType === 'iphone' || productType === 'laptop') {
								setIsLoading(false);
								setLoadingText('');
								setLoadingSubText('');
								breakLoop = true;
								status = 'success';
								if (productType === 'laptop') {
									checkTonicStatus();
								} else {
									setPage(18);
								}
							}
						} else if (resp?.success === false) {
							if (resp?.invalid_device) {
								// setFormError("Este modelo de equipo no puede ser configurado, por favor elija otro modelo.");
								if (switchChecked) {
									setFormError(
										'Ocurrió un problema al registrar el equipo, por favor asegurese que el equipo es marca Samsung, si el problema persiste contacte a soporte técnico.'
									);
									setIsLoading(false);
									setLoadingText('');
									setLoadingSubText('');
									return;
								} else if (blockNuovo) {
									setFormError(
										'Ocurrió un problema al registrar el equipo, por favor asegurese que el equipo es marca Samsung, Infinix, Tecno, Realme, Itel, o Motorola, si el problema persiste contacte a soporte técnico.'
									);
									setIsLoading(false);
									setLoadingText('');
									setLoadingSubText('');
									return;
								}
								setSecurityProvider('nuovo');
								setIsLoading(false);
								setLoadingText('');
								setLoadingSubText('');
								return;
							} else {
								setFormError(resp.status);
							}
							setIsLoading(false);
							setLoadingText('');
							setLoadingSubText('');
							breakLoop = true;
							status = 'fail';
						} else {
							setFormError('Estamos teniendo problemas para conectarnos.');
							setIsLoading(false);
							setLoadingText('');
							setLoadingSubText('');
							breakLoop = true;
							status = 'fail';
						}
					} catch (error) {
						console.error('Error occurred:', error);
						setFormError('Error al realizar la solicitud.');
						setIsLoading(false);
						setLoadingText('');
						setLoadingSubText('');
						breakLoop = true;
						status = 'fail';
					}
					resolve();
				}, 15000);
			});
		}

		if (status === 'waiting') {
			setFormError('Se ha alcanzado el tiempo máximo de espera, por favor vuelva a intentar o contacte a servicio técnico.');
			setIsLoading(false);
			setLoadingText('');
			setLoadingSubText('');
		}
	};

	const registerWithTonic = async () => {
		setFormError('');
		if (!imeiToRegister || !imeiToRegister2) {
			setFormError('Por favor rellene todas las casillas para continuar.');
			return;
		}
		if (productType !== 'laptop' && (imeiToRegister.length < 15 || imeiToRegister2.length < 15)) {
			setFormError('IMEI ivalido, asegurese de que el IMEI tenga 15 caracteres.');
			return;
		}
		if (imeiToRegister !== imeiToRegister2) {
			setFormError('Los IMEIs introducidos no coinciden.');
			return;
		}
		try {
			setIsLoading(true);
			setLoadingText('Registrando equipo');
			setLoadingSubText(
				productType === 'laptop'
					? 'Esto puede tardar unos segundos'
					: 'Esto puede tardar unos segundos, por favor NO encienda el teléfono aún.'
			);
			const devData = {
				device_imei: imeiToRegister,
				device_type: productType,
				device_management_provider: productType === 'laptop' ? 'absolute' : 'tonic',
				device_price: price
			};
			let response;
			if (switchChecked) {
				//register_promo_imei , post, input device_imei
				response = await sendPostRequest(devData, 'registration/promo/', token);
			} else if (switchChecked2) {
				response = await sendPostRequest(devData, 'registration/promo2/', token);
			} else {
				response = await sendPostRequest(devData, 'registration/registerimei/', token);
			}
			if (response?.success) {
				if (productType === 'laptop') {
					setIsLoading(false);
					setLoadingText('');
					setLoadingSubText('');
					setPage(19);
				} else if (productType === 'iphone') {
					setIsLoading(false);
					setLoadingText('');
					setLoadingSubText('');
					setPage(18);
				} else {
					await checkDeviceReady();
				}
			} else if (response?.success === false) {
				setFormError(response.status);
				setIsLoading(false);
				setLoadingText('');
				setLoadingSubText('');
			} else {
				setFormError('Estamos teniendo problemas para conectarnos.');
				setIsLoading(false);
				setLoadingText('');
				setLoadingSubText('');
			}
		} catch (err) {
			setFormError('Error: ' + err.message);
			setIsLoading(false);
			setLoadingText('');
			setLoadingSubText('');
		}
	};

	const callDevReg = async () => {
		try {
			setIsLoading(true);
			setLoadingText('Finalizando registro');
			if (!productElegibility && allowAnyProduct && productType == 'other') {
				let devData = {
					device_imei: imeiToRegister,
					seller_store: storeEmail,
					sub_store: subStore,
					device_management_provider: 'nuovo',
					user_document_id: email,
					store_user: storeUser
				};
				if (securityProvider === 'tonic') {
					devData = {
						device_imei: imeiToRegister,
						seller_store: storeEmail,
						sub_store: subStore,
						device_management_provider: productType === 'laptop' ? 'absolute' : 'tonic',
						user_document_id: email,
						store_user: storeUser
					};
				}

				const response = await sendPostRequest(devData, 'anclar_device/', token); //status, success, data
				if (response?.success) {
					await registerOtherProduct();
					setIsLoading(false);
					setLoadingText('');
					setLoadingSubText('');
				} else if (response?.success === false) {
					setFormError(response.status);
					setIsLoading(false);
				} else {
					setFormError(
						'Ocurrió un problema, por favor vuelva a hacer clic en Siguiente, si el problema persiste contacte a servicio técnico.'
					);
					setIsLoading(false);
				}
				return;
			}
			let paymentAmount = Math.round(
				Math.round(((parseInt(price) * (1 - downPaymentPerc) * interestFactor) / (parseInt(timeToPay) * 2)) * 100) / 100
			);
			if (switchChecked) {
				paymentAmount = Math.round((parseInt(price) * 0.5 * 1.25) / (timeToPay * 2));
			}
			let amountFinanced = parseInt(price) + extraInitial - Math.round(parseInt(price) * downPaymentPerc + extraInitial);

			if (switchChecked) {
				amountFinanced = Math.round(parseInt(price) * 0.5);
			}
			const deviceData = {
				user_document_id: email,
				device_imei: imeiToRegister,
				seller_store: storeEmail,
				device_price: (parseInt(price) + extraInitial).toFixed(2),
				loan_duration: timeToPay,
				payment_data: {
					loan_payment_cycle: 15,
					payment_amount: paymentAmount
				},
				device_management_provider: productType === 'laptop' ? 'absolute' : 'tonic',
				amount_financed: amountFinanced,
				sub_store: subStore,
				new_chip_acquired: buyingChip === 'Si' ? true : false,
				store_user: storeUser,
				device_type: productType,
				promotional_code: switchChecked ? 'promo_samsung' : switchChecked2 ? 'promo_infinix' : '',
				online_purchase: isOnline
			};
			const response = await sendPostRequest(deviceData, 'registration/deviceregistration/', token); //status, success, data
			if (response?.success) {
				setPage(15);
				setIsLoading(false);
				setLoadingText('');
				setLoadingSubText('');
			} else if (response?.status.includes('Error getting device')) {
				setFormError(
					'Ocurrió un problema, por favor vuelva a hacer clic en Siguiente, si el problema persiste contacte a servicio técnico.'
				);
				setIsLoading(false);
				setLoadingText('');
				setLoadingSubText('');
			} else if (response?.success === false) {
				setFormError(response.status);
				setIsLoading(false);
				setLoadingText('');
				setLoadingSubText('');
			} else {
				setFormError(
					'Ocurrió un problema, por favor vuelva a hacer clic en Siguiente, si el problema persiste contacte a servicio técnico.'
				);
				setIsLoading(false);
				setLoadingText('');
				setLoadingSubText('');
			}
		} catch (err) {
			setFormError(
				'Ocurrió un problema, por favor vuelva a hacer clic en Siguiente, si el problema persiste contacte a servicio técnico.'
			);
			setIsLoading(false);
			setLoadingText('');
			setLoadingSubText('');
		}
	};

	const checkTonicStatus = async () => {
		if (productType === 'laptop' || productType === 'iphone') {
			setIsLoading(false);
			setLoadingText('');
			setLoadingSubText('');
			setPage(19);
			return;
		}

		setFormError('');
		let breakLoop = false;
		let status = 'waiting';
		setIsLoading(true);
		setLoadingText('Chequeando estatus del equipo');
		setLoadingSubText('Esto puede tardar unos minutos.');

		for (let i = 0; i < 10; i++) {
			if (breakLoop) {
				break;
			}
			const devDat = {
				device_imei: imeiToRegister,
				device_management_provider: productType === 'laptop' ? 'absolute' : 'tonic',
				device_type: productType
			};
			await new Promise((resolve) => {
				setTimeout(async () => {
					try {
						let resp = await sendPostRequest(devDat, 'registration/activeimei/', token);
						if (resp?.success || productType === 'iphone') {
							if (resp.device_active || productType === 'iphone') {
								setIsLoading(false);
								setLoadingText('');
								setLoadingSubText('');
								setPage(19);
								status = 'success';
								breakLoop = true;
							}
						} else if (resp?.success === false) {
							if (resp?.invalid_device) {
								setFormError('Este modelo de equipo no puede ser configurado, por favor elija otro modelo.');
							} else {
								setFormError(resp.status);
							}
							setIsLoading(false);
							setLoadingText('');
							setLoadingSubText('');
							breakLoop = true;
							status = 'fail';
						} else {
							setFormError('Estamos teniendo problemas para conectarnos.');
							setIsLoading(false);
							setLoadingText('');
							setLoadingSubText('');
							breakLoop = true;
							status = 'fail';
						}
					} catch (error) {
						console.error('Error occurred:', error);
						setFormError('Error al realizar la solicitud.');
						setIsLoading(false);
						setLoadingText('');
						setLoadingSubText('');
						breakLoop = true;
						status = 'fail';
					}
					resolve();
				}, 15000);
			});
		}
		if (status === 'waiting') {
			// setFormError("Error: El celular no fue encendido o configurado correctamente. Si cree que es un error vuelva a hacer clic en Siguiente, de lo contrario debe formatear el equipo de fabrica, prenderlo, configurarlo, y luego hacer clic en Siguiente.");
			setSecurityProvider('nuovo');
			setPage(10);
			setIsLoading(false);
			setLoadingText('');
			setLoadingSubText('');
			showAlert(`
        <div class="text-center">
          <h3 class="text-lg font-semibold text-primary-400 mb-2 light:text-primary-500">Configuración Requerida</h3>
          <p class="text-neutral-300">
            El equipo no se pudo configurar correctamente. Porfavor reseteelo de fabrica y prosiga con la instalación con QR.
          </p>
        </div>
      `);
		}
	};

	function renderDownPayments(start) {
		const result = [];
		for (let i = start; i <= 50; i += 5) {
			result.push(i);
		}
		if (initial_percentage_promotion) {
			result.unshift(initial_percentage_promotion * 100);
		}
		return result;
	}

	const confirmPhoneTokenFromUser = async (ptoken) => {
		setFormError('');
		if (!ptoken) {
			setFormError('Llene todos los datos para continuar');
			return;
		}
		try {
			const codeData = {
				confirmation_code: parseInt(ptoken)
			};
			const response = await sendPostRequest(codeData, 'notifications/confirmauthcode/', token);
			if (response?.success) {
				setLoadingButton(false);
				setTokenVerified(true);
			} else if (response?.success === false) {
				showAlert(response.status);
				setLoadingButton(false);
				setShowTokenField(true);
			} else {
				showAlert('Estamos teniendo problemas para conectarnos.');
				setLoadingButton(false);
				setShowTokenField(true);
			}
		} catch (error) {
			showAlert('Estamos teniendo problemas para conectarnos.');
			setLoadingButton(false);
			setShowTokenField(true);
		}
	};

	const extractLevelNumber = (levelString) => {
		const match = levelString.match(/level_(\d+)/);
		return match ? parseInt(match[1], 10) : null;
	};

	const renderPaymentOptions = (userLevel, data) => {
		const levelNum = extractLevelNumber(userLevel);
		let componentsToRender = [];

		for (let level = 1; level <= levelNum; level++) {
			const levelKey = `level_${level}`;
			if (data[levelKey]) {
				data[levelKey].forEach((item) => {
					componentsToRender.push(
						<MenuItem key={`${levelKey}-${item.installments}`} value={item.installments / 2}>
							{item.installments}
						</MenuItem>
					);
				});
			}
		}

		return componentsToRender;
	};

	// dialogs
	const [dialogMessage, setDialogMessage] = useState('');
	const [showDialog, setShowDialog] = useState(false);
	const [showConfirmDialog, setShowConfirmDialog] = useState(false);
	const [pendingConfirmAction, setPendingConfirmAction] = useState(null);
	function showAlert(message) {
		setDialogMessage(message);
		setShowDialog(true);
	}
	function showConfirm(message, onConfirm) {
		setDialogMessage(message);
		setPendingConfirmAction(() => onConfirm);
		setShowConfirmDialog(true);
	}
	const steps = [
		{ label: country === 'peru' ? 'DNI' : 'Cédula', pages: [2] },
		{ label: 'Verificar Identidad', pages: [16, 17] },
		{ label: 'Información', pages: [3, 8] },
		{ label: 'Decisión', pages: [4] },
		{ label: 'Producto', pages: [5, 6] },
		{ label: 'Configuración', pages: [7, 10, 11, 12, 13, 14, 18] },
		{ label: 'Contrato', pages: [19] },
		{ label: 'Finalizar', pages: [15] }
	];

	if (!authenticated || !storeName) return <ContainerSpinner size={3} />;

	return (
		<div className="m-auto flex min-h-full min-w-full flex-col items-center justify-start">
			<Stepper currentPage={page} steps={steps} />

			{(loadingText || loadingSubText) && (
				<>
					<div className="fixed inset-0 z-10 h-full w-full bg-black/30 data-[state=open]:animate-overlayShow"></div>
					<div className="fixed bottom-20 right-4 z-20 flex items-center gap-3 rounded-lg bg-neutral-900/90 p-4 shadow-lg md:bottom-8 md:right-8 md:gap-4 md:p-6">
						<div className="relative">
							<div className="absolute -inset-0.5 animate-ping rounded-full bg-primary-500 opacity-75" />
							<div className="relative size-3 rounded-full bg-primary-500" />
						</div>
						<div className="space-y-0.5">
							{loadingText && <p className="text-base font-medium text-neutral-0">{loadingText}</p>}
							{loadingSubText && <p className="text-sm text-neutral-300">{loadingSubText}</p>}
						</div>
					</div>
				</>
			)}

			<div className="m-auto flex h-full w-full flex-col items-center justify-center p-4 md:p-6">
				{page === 4 && select_promo()}
				{(page === 5 || page === 6) && activated_promo()}

				<div
					className={classNames(
						'w-[500px] max-w-full rounded-xl border-[1.5px] border-neutral-900 bg-neutral-1000/25 p-6 shadow-lg duration-300 animate-in fade-in slide-in-from-bottom-5 light:bg-white light:shadow-none md:bg-background/50 md:p-8',
						{ 'w-[650px]': page === 3 || page === 8 },
						{ 'w-[900px]': page === 4 }
					)}
				>
					{page === 2 && ( //done
						<>
							{render_page_header(
								'Nuevo Registro',
								`Por favor ingrese ${country === 'peru' ? 'el DNI' : 'la cédula'} del cliente realizando la compra`,
								<PlusCircle />
							)}

							<form className="space-y-4" onSubmit={handleCheckCedula}>
								<Input
									name="cedula"
									floatingLabel={`${country === 'peru' ? 'DNI' : 'Cédula'} del cliente`}
									value={cedula}
									onChange={(value) => setCedula(value)}
									placeholder={`Ingrese ${country === 'peru' ? 'el DNI' : 'la cédula'} del cliente`}
									allowedChars="numbers_only"
								/>

								{loginError && <Notice message={loginError} variant="error" />}

								<Button disabled={!cedula} loading={isLoading} type="submit" onClick={handleCheckCedula} variant="primary">
									Continuar
								</Button>
							</form>
						</>
					)}
					{page === 16 && ( //done
						<>
							{render_page_header(
								'Verificación de Identidad',
								'Por favor suba una foto del documento de identidad del cliente',
								<KeyRound />
							)}

							<form className="space-y-4" onSubmit={(e) => e.preventDefault()}>
								<Select
									title="Tipo de documento"
									options={countrySettings.id_types.map((item, index) => ({
										label: item.name,
										value: index
									}))}
									onSelectedChange={(value) =>
										setDocumentType({
											code: countrySettings.id_types[value].code,
											country: countrySettings.id_types[value].country
										})
									}
									placeholder="Seleccionar tipo de documento"
									className="w-full bg-foreground"
								/>

								<div className="flex justify-center">
									<div className="flex h-32 items-center justify-center">
										{showSpinner ? (
											<Spinner size={3} />
										) : !cedulaVerified ? (
											<IdCard className="size-32 scale-125 transform" strokeWidth={1} />
										) : (
											<div className="flex h-full flex-col items-center justify-center space-y-6 duration-500 animate-in fade-in-0 zoom-in-90">
												<div className="space-y-2 text-center">
													<div className="flex items-center justify-center gap-4">
														<div className="relative">
															<div className="absolute -inset-1 animate-pulse rounded-full bg-gradient-to-r from-primary-400 to-primary-600 opacity-10 blur"></div>
															<div className="relative rounded-full bg-neutral-900 p-4">
																<CircleCheck className="h-6 w-6 text-primary-500" />
															</div>
														</div>
														<h3 className="text-xl font-semibold text-primary-400">¡Verificación Exitosa!</h3>
													</div>
													<p className="text-neutral-300">La verificación de identidad se ha completado correctamente.</p>
												</div>
											</div>
										)}
									</div>
								</div>

								<div className="flex justify-center">
									<input
										name="photo"
										type="file"
										accept="image/*"
										onChange={handlePhotoChange}
										disabled={showSpinner || cedulaVerified || !documentType}
										ref={fileInputRef}
										className="peer hidden w-full bg-transparent text-sm outline-none placeholder:text-neutral-400"
									/>
									<Button
										Icon={<Camera className="size-5" />}
										disabled={showSpinner || cedulaVerified || !documentType}
										onClick={() => fileInputRef.current.click()}
										variant="primary"
										className="!w-min"
									>
										Subir Imagen
									</Button>
								</div>

								{formError && <Notice message={formError} variant="error" />}

								<div className="flex flex-wrap gap-2 md:flex-row-reverse md:flex-nowrap">
									<Button
										Icon={<ArrowBack className="size-5" />}
										disabled={showSpinner || cedulaVerified}
										onClick={backToWelcomePage}
										variant="secondary"
									>
										Atrás
									</Button>
								</div>
							</form>
						</>
					)}
					{page === 19 && ( //done
						<>
							{render_page_header('Aceptar Términos y Condiciones', null, <FileText />)}

							<form
								className="space-y-6"
								onSubmit={(e) => {
									e.preventDefault();
									callDevReg();
								}}
							>
								{productType === 'phone' && (
									<>
										<p className="text-neutral-300">
											Ahora proceda a descargar la app Krece en el equipo en venta escaneando este QR. (Este paso NO es necesario
											para equipos SAMSUNG)
										</p>
										<div className="flex items-center gap-12">
											<img className="w-[200px]" src={downloadQr} alt="Descargar" />
											<p className="text-neutral-0">O entrando a este enlace https://krece.app/apk</p>
										</div>
									</>
								)}

								{productType === 'iphone' && (
									<p className="text-neutral-300">Ahora proceda a descargar la app Krece en la AppStore en el equipo en venta.</p>
								)}

								{!isOnline && (
									<div className="space-y-4">
										<p className="text-neutral-300">
											Por último, haga clic en "Generar Contrato", el cliente debe abrir la app Krece y aceptar el contrato.
										</p>

										{!tokenGenerated ? (
											<Button loading={isLoading && !loadingText} onClick={generateAnexo} variant="primary">
												Generar Contrato
											</Button>
										) : !anexoAccepted ? (
											<Button loading={isLoading && !loadingText} onClick={generateAnexo} variant="primary">
												Generar Otro Contrato
											</Button>
										) : (
											<Notice message="Contrato Aceptado" variant="success" />
										)}
									</div>
								)}

								{formError && <Notice message={formError} variant="error" />}

								<div className="flex flex-wrap gap-2 md:flex-row-reverse md:flex-nowrap">
									<Button disabled={!isOnline && !anexoAccepted} loading={isLoading && loadingText} type="submit" variant="primary">
										Concretar Venta
									</Button>
									<Button
										Icon={<ArrowLeft className="size-5" />}
										disabled={isLoading}
										type="button"
										onClick={() => (productType === 'laptop' ? setPage(10) : setPage(18))}
										variant="secondary"
									>
										Atrás
									</Button>
								</div>
							</form>
						</>
					)}
					{page === 18 && ( //done
						<>
							{render_page_header('Configuración de Seguridad', null, <Shield />)}

							<form
								className="space-y-4"
								onSubmit={(e) => {
									e.preventDefault();
									checkTonicStatus();
								}}
							>
								{productType === 'phone' && (
									<>
										<div className="text-center text-neutral-300">
											Por favor proceda a encender el equipo, unirlo con el WIFI de la tienda, aceptar todas las condiciones y
											realizar la configuración hasta que vea la siguiente pantalla.
										</div>

										<Notice
											message="IMPORTANTE (no aplica para Samsung): Si no logra llegar a la pantalla mostrada abajo, termine la configuración e intente restaurar de fábrica. Si el equipo permite restaurar, significa que no se configuró correctamente y debe repetir el proceso. De lo contrario, si no permite restaurar, puede continuar al siguiente paso."
											variant="warning"
										/>

										<div className="flex justify-center">
											<img className="h-[448px] w-[200px]" src={homeScreen} alt="homeScreen" />
										</div>
									</>
								)}

								{productType === 'iphone' && (
									<div className="text-center text-neutral-300">
										Por favor proceda a encender el equipo, unirlo con el WIFI de la tienda, aceptar todas las condiciones y
										realizar la configuración hasta el final.
									</div>
								)}

								{formError && <Notice message={formError} variant="error" />}

								<div className="flex flex-wrap gap-2 md:flex-row-reverse md:flex-nowrap">
									<Button disabled={isLoading} loading={isLoading} type="submit" variant="primary">
										Seguir
									</Button>
									<Button
										Icon={<ArrowLeft className="size-5" />}
										disabled={isLoading}
										type="button"
										onClick={() => setPage(10)}
										variant="secondary"
									>
										Atrás
									</Button>
								</div>
							</form>
						</>
					)}
					{page === 17 && ( //done
						<>
							{render_page_header('Verificación Biométrica', 'Por favor suba una foto tipo pasaporte del cliente', <KeyRound />)}

							<form className="space-y-6" onSubmit={(e) => e.preventDefault()}>
								<div className="space-y-6">
									<div className="flex justify-center">
										<div className="flex h-32 items-center justify-center">
											{showSpinner ? (
												<Spinner size={3} />
											) : !cedulaVerified ? (
												<ScanFace className="size-28" strokeWidth={1} />
											) : (
												<div className="flex h-full flex-col items-center justify-center space-y-6 duration-500 animate-in fade-in-0 zoom-in-90">
													<div className="space-y-2 text-center">
														<div className="flex items-center justify-center gap-4">
															<div className="relative">
																<div className="absolute -inset-1 animate-pulse rounded-full bg-gradient-to-r from-primary-400 to-primary-600 opacity-10 blur"></div>
																<div className="relative rounded-full bg-neutral-900 p-4">
																	<CircleCheck className="h-6 w-6 text-primary-500" />
																</div>
															</div>
															<h3 className="text-xl font-semibold text-primary-400">¡Verificación Exitosa!</h3>
														</div>
														<p className="text-neutral-300">La verificación biométrica se ha completado correctamente.</p>
													</div>
												</div>
											)}
										</div>
									</div>

									<div className="flex justify-center">
										<input
											name="photo"
											type="file"
											accept="image/*"
											onChange={handlePhotoChange2}
											disabled={showSpinner || cedulaVerified}
											ref={fileInputRef}
											className="peer hidden w-full bg-transparent text-sm outline-none placeholder:text-neutral-400"
										/>
										<Button
											Icon={<Camera className="size-5" />}
											disabled={showSpinner || cedulaVerified}
											onClick={() => fileInputRef.current.click()}
											variant="primary"
											className="!w-min"
										>
											Subir Imagen
										</Button>
									</div>

									{formError && <Notice message={formError} variant="error" />}

									<div className="flex flex-wrap gap-2 md:flex-row-reverse md:flex-nowrap">
										<Button
											Icon={<ArrowLeft className="size-5" />}
											disabled={showSpinner || cedulaVerified}
											type="button"
											onClick={backToWelcomePage}
											variant="secondary"
										>
											Atrás
										</Button>
									</div>
								</div>
							</form>
						</>
					)}
					{page === 3 && ( //done
						<>
							{render_page_header('Información de Usuario', 'Por favor complete los datos del cliente', <UserRound />)}

							<form onSubmit={handleInformationSubmit} className="space-y-4">
								<div className="space-y-4 rounded-lg border border-neutral-800 bg-neutral-900/50 p-4 md:p-6">
									<Input
										name="firstName"
										floatingLabel="Nombre"
										value={firstName}
										onChange={setFirstName}
										placeholder="Ingrese el nombre"
									/>

									<Input
										name="lastName"
										floatingLabel="Apellidos"
										value={lastName}
										onChange={setLastName}
										placeholder="Ingrese los apellidos"
									/>

									<Input
										name="email"
										type="email"
										floatingLabel="Email"
										value={email}
										onChange={(value) => setEmail(value.toLowerCase().trim())}
										placeholder="Ingrese el email"
										readOnly={userExistsCanBeFinanced}
									/>

									<Input
										name="email2"
										type="email"
										floatingLabel="Confirmación de Email"
										value={email2}
										onChange={(value) => setEmail2(value.toLowerCase().trim())}
										placeholder="Confirme el email"
										readOnly={userExistsCanBeFinanced}
									/>

									<DatePicker
										defaultDate={birthDate || undefined}
										floatingLabel="Fecha de Nacimiento"
										onDateChange={(date) => {
											if (date) setBirthDate(date);
										}}
									/>
									

									<Select
										floatingLabel="Género"
										placeholder="Seleccione el género"
										options={[
											{ label: 'Masculino', value: 'male' },
											{ label: 'Femenino', value: 'female' }
										]}
										defaultValue={gender || undefined}
										onSelectedChange={setGender}
									/>

									<Input
										name="address"
										floatingLabel="Dirección de hogar"
										value={address}
										onChange={setAddress}
										placeholder="Ingrese la dirección"
									/>
								</div>

								<div className="space-y-4 rounded-lg border border-neutral-800 bg-neutral-900/50 p-4 md:p-6">
									<h2 className="mb-4 text-xl font-medium tracking-tighter text-neutral-0">Verificación de Número de Teléfono</h2>

									<div className="flex flex-wrap gap-4">
										<div className="min-w-[240px] flex-1 space-y-4">
											<Input
												name="phoneNumber"
												type="tel"
												floatingLabel="Número de teléfono"
												value={phoneNumber}
												onChange={setPhoneNumber}
												placeholder="Ingrese el número"
												readOnly={tokenVerified}
											/>

											{showTokenField && (
												<Input
													name="phoneToken"
													type="tel"
													floatingLabel="Token"
													value={phoneToken}
													onChange={fillToken}
													placeholder="Ingrese el token"
													allowedChars="numbers_only"
												/>
											)}
										</div>

										<div className="w-40">
											<Select
												floatingLabel="Tipo"
												placeholder="Seleccionar"
												options={[
													{ label: 'SMS', value: 'sms' },
													{ label: 'Whatsapp', value: 'whatsapp' }
												]}
												defaultValue={messType || 'sms'}
												onSelectedChange={setMessType}
											/>
										</div>
									</div>

									<div className="flex items-center justify-between">
										{tokenVerified && !loadingButton ? (
											<div className="flex items-center gap-2 text-primary-400">
												<CircleCheck className="size-5" />
												<span className="font-medium">Verificado</span>
											</div>
										) : timeLeft === 0 ? (
											<Button loading={loadingButton} type="button" onClick={sendPhoneToken} variant="primary">
												Enviar Token
											</Button>
										) : null}

										{timeLeft > 0 && <p className="text-neutral-400">Puede enviar otro token en: {timeLeft}</p>}
									</div>
								</div>

								{formError && <Notice message={formError} variant="error" />}

								<div className="flex flex-wrap gap-2 md:flex-row-reverse md:flex-nowrap">
									<Button disabled={isLoading} loading={isLoading} type="submit" variant="primary">
										Ingresar
									</Button>
									<Button
										Icon={<ArrowLeft className="size-5" />}
										disabled={loadingButton || isLoading}
										type="button"
										onClick={backToWelcomePage}
										variant="secondary"
									>
										Atrás
									</Button>
								</div>
							</form>
						</>
					)}
					{page === 8 && ( //done
						<>
							{render_page_header(
								'Datos Adicionales',
								'Por favor complete la información adicional del cliente',
								<ClipboardList />
							)}

							<form onSubmit={handleApplicationSubmit} className="space-y-4">
								<div className="space-y-4 rounded-lg border border-neutral-800 bg-neutral-900/50 p-4 md:p-6">
									<Select
										floatingLabel="Ciudad donde vives"
										placeholder="Seleccione la ciudad"
										options={countrySettings.cities.map((item) => ({
											label: item,
											value: item
										}))}
										defaultValue={city}
										onSelectedChange={(value) => setCity(value)}
									/>

									<Input
										name="monthlySalary"
										type="number"
										floatingLabel={`Salario Mensual (en ${countrySettings.currency_name})`}
										prefix={countrySettings.currency_code}
										value={monthlySalary}
										onChange={setMonthlySalary}
										placeholder="Ingrese el salario mensual"
									/>

									<Select
										floatingLabel={`Ahorros estimados (en ${countrySettings.currency_name})`}
										placeholder="Seleccione el rango"
										options={countrySettings.salary_ranges.map((item) => ({
											label: item,
											value: item.split(countrySettings.currency_code )[1]
										}))}
										defaultValue={savings}
										onSelectedChange={(value) => setSavings(value)}
									/>

									<Select
										floatingLabel="Nivel de educación"
										placeholder="Seleccione el nivel"
										options={[
											{ label: 'Primaria', value: 'Primaria' },
											{ label: 'Bachillerato', value: 'Bachillerato' },
											{ label: 'Universidad', value: 'Universidad' }
										]}
										defaultValue={educationLevel}
										onSelectedChange={(value) => setEducationLevel(value)}
									/>
								</div>

								{formError && <Notice message={formError} variant="error" />}

								<div className="flex flex-wrap gap-2 md:flex-row-reverse md:flex-nowrap">
									<Button disabled={isLoading} loading={isLoading} type="submit" variant="primary">
										Ingresar
									</Button>
									<Button
										Icon={<ArrowLeft className="size-5" />}
										disabled={isLoading}
										type="button"
										onClick={backToWelcomePage}
										variant="secondary"
									>
										Atrás
									</Button>
								</div>
							</form>
						</>
					)}
					{page === 4 && ( //done
						<>
							{render_page_header(
								'¡Felicitaciones!',
								'¡Has sido aprobado para una línea de crédito!',
								<CircleCheck className="size-6" />
							)}
							<form
								onSubmit={(e) => {
									e.preventDefault();
									if (limitReached || applicationApprovalData.threeMonthAmount == 0) {
										backToWelcomePage();
									} else {
										handleNext();
									}
								}}
								className="space-y-4"
							>
								<div className="grid grid-cols-1 gap-4 md:grid-cols-2">
									<div className="rounded-xl border border-neutral-800 bg-background p-6 transition-all duration-300 hover:border-primary-500/50">
										<div className="flex items-center gap-2">
											<span className="text-sm font-medium text-neutral-400">Línea aprobada</span>
											<Popover>
												<PopoverTrigger asChild>
													<button className="text-neutral-400 hover:text-neutral-300">
														<Info className="size-4" />
													</button>
												</PopoverTrigger>
												<PopoverContent className="w-80 rounded-xl border border-neutral-800 bg-background p-3 text-sm text-neutral-300 shadow-md">
													Si desea un equipo que requiere una línea de compra mayor, deberá pagar la diferencia como parte de la
													cuota inicial.
												</PopoverContent>
											</Popover>
										</div>
										<p className="bg-gradient-to-r from-primary-400 to-primary-600 bg-clip-text text-3xl font-bold text-transparent light:from-primary-500 md:text-4xl">
											{productType !== 'other'
												? countrySettings.currency_code + (switchChecked2 ? 200 : applicationApprovalData.threeMonthAmount)
												: countrySettings.currency_code + maxLoan3MonthsKrecePlus}
										</p>
									</div>

									<div className="rounded-xl border border-neutral-800 bg-background p-6 transition-all duration-300 hover:border-primary-500/50">
										<span className="mb-3 block text-sm font-medium text-neutral-400">Inicial requerida</span>
										<p className="bg-gradient-to-r from-primary-400 to-primary-600 bg-clip-text text-3xl font-bold text-transparent light:from-primary-500 md:text-4xl">
											{productType !== 'other' ? `${downPaymentPerc * 100}%` : '50%'}
											{productType === 'other' && (
												<span className="ml-2 text-lg font-medium text-neutral-400">SIN INTERESES</span>
											)}
										</p>
									</div>
								</div>

								<div className="rounded-xl border border-neutral-800 bg-background p-4 backdrop-blur-sm md:p-6">
									<div className="mb-6">
										<h2 className="text-center text-2xl font-bold leading-snug tracking-tight text-neutral-0">
											Simulador de Pagos
										</h2>
										<p className="text-center text-neutral-400">Calcule su inicial y cuotas mensuales</p>
									</div>

									<div className="space-y-4">
										<div className="flex flex-wrap items-start justify-center gap-4 md:gap-6">
											{!switchChecked && !switchChecked2 && (
												<Select
													title="Inicial deseada"
													options={renderDownPayments(countrySettings.down_payment[level] * 100).map((item) => ({
														label: item + '%',
														value: item / 100
													}))}
													defaultValue={downPaymentPerc?.toString()}
													onSelectedChange={(value) => setDownPaymentPerc(value ? Number.parseFloat(value) : 0)}
													className="w-40"
												/>
											)}

											<Select
												title="Número de cuotas"
												options={renderPaymentOptions(level, countrySettings.payment_options).map((item) => ({
													label: item.props.children,
													value: item.props.children / 2
												}))}
												defaultValue={timeToPay?.toString()}
												onSelectedChange={(value) => changeTimeToPay(Number.parseInt(value))}
												className="w-40"
											/>

											<div className="w-40 space-y-1">
												<Input
													title={`Precio del equipo`}
													placeholder="0.00"
													allowedChars="numeric"
													value={amountSlider?.toString()}
													onChange={(value) => setAmountSlider(value ? Number.parseFloat(value) : 0)}
													validation={(value) =>
														!value || !Number.parseFloat(value) || (switchChecked && Number.parseFloat(value) < 100)
															? false
															: true
													}
													Icon={
														countrySettings?.currency_code ? (
															<span className="text-sm text-neutral-500">{countrySettings.currency_code}</span>
														) : undefined
													}
												/>
												{switchChecked && amountSlider < 100 && (
													<p className="mt-1 overflow-visible text-nowrap text-sm text-red-500">El monto debe ser mayor a 100</p>
												)}
											</div>
										</div>

										{!switchChecked ? (
											<div className="mt-6 flex items-start justify-center gap-8">
												<div className="space-y-1">
													<span className="text-sm font-medium text-neutral-400">
														Cuota inicial (
														{productType !== 'other'
															? (
																	(((switchChecked2 ? 300 : applicationApprovalData.threeMonthAmount) / (1 - downPaymentPerc) >=
																	amountSlider
																		? amountSlider * downPaymentPerc
																		: ((switchChecked2 ? 300 : applicationApprovalData.threeMonthAmount) /
																				(1 - downPaymentPerc)) *
																				downPaymentPerc +
																			(amountSlider -
																				(switchChecked2 ? 300 : applicationApprovalData.threeMonthAmount) /
																					(1 - downPaymentPerc))) *
																		100) /
																	amountSlider
																).toFixed(0)
															: (
																	((maxLoan3MonthsKrecePlus / (1 - 0.5) >= amountSlider
																		? amountSlider * 0.5
																		: (maxLoan3MonthsKrecePlus / (1 - 0.5)) * 0.5 +
																			(amountSlider - maxLoan3MonthsKrecePlus / (1 - 0.5))) *
																		100) /
																	amountSlider
																).toFixed(0)}
														%)
													</span>
													<p className="text-center text-2xl font-bold text-primary-400 light:text-primary-500">
														{countrySettings.currency_code}
														{productType !== 'other'
															? ((switchChecked2 ? 300 : applicationApprovalData.threeMonthAmount) / (1 - downPaymentPerc) >=
																amountSlider
																	? amountSlider * downPaymentPerc
																	: ((switchChecked2 ? 300 : applicationApprovalData.threeMonthAmount) / (1 - downPaymentPerc)) *
																			downPaymentPerc +
																		(amountSlider -
																			(switchChecked2 ? 300 : applicationApprovalData.threeMonthAmount) / (1 - downPaymentPerc))
																).toFixed(0)
															: (maxLoan3MonthsKrecePlus / (1 - 0.5) >= amountSlider
																	? amountSlider * 0.5
																	: (maxLoan3MonthsKrecePlus / (1 - 0.5)) * 0.5 +
																		(amountSlider - maxLoan3MonthsKrecePlus / (1 - 0.5))
																).toFixed(2)}
													</p>
												</div>

												<div className="space-y-1">
													<span className="text-sm font-medium text-neutral-400">{timeToPay * 2} cuotas quincenales de</span>
													<p className="text-center text-2xl font-bold text-primary-400 light:text-primary-500">
														{countrySettings.currency_code}
														{productType !== 'other'
															? Math.round(
																	(((switchChecked2 ? 300 : applicationApprovalData.threeMonthAmount) / (1 - downPaymentPerc) >=
																	amountSlider
																		? amountSlider * (1 - downPaymentPerc)
																		: ((switchChecked2 ? 300 : applicationApprovalData.threeMonthAmount) /
																				(1 - downPaymentPerc)) *
																			(1 - downPaymentPerc)) *
																		interestFactor) /
																		(parseInt(timeToPay) * 2)
																).toFixed(0)
															: (
																	(maxLoan3MonthsKrecePlus / (1 - 0.5) >= amountSlider
																		? amountSlider * (1 - 0.5)
																		: (maxLoan3MonthsKrecePlus / (1 - 0.5)) * (1 - 0.5)) /
																	(parseInt(timeToPay) * 2)
																).toFixed(2)}
													</p>
												</div>
											</div>
										) : (
											<div className="mt-6 flex items-start justify-center gap-8">
												<div className="space-y-1">
													<span className="text-sm font-medium text-neutral-400">Cuota inicial (50%)</span>
													<p className="text-center text-2xl font-bold text-primary-400 light:text-primary-500">
														{countrySettings.currency_code}
														{Math.round(amountSlider / 2).toFixed(0)}
													</p>
												</div>

												<div className="space-y-1">
													<span className="text-sm font-medium text-neutral-400">{timeToPay * 2} cuotas quincenales de</span>
													<p className="text-center text-2xl font-bold text-primary-400 light:text-primary-500">
														{countrySettings.currency_code}
														{Math.round(((amountSlider / 2) * 1.25) / (timeToPay * 2))}
													</p>
												</div>
											</div>
										)}
									</div>
								</div>

								<div className="flex flex-wrap gap-2 md:flex-row-reverse md:flex-nowrap">
									<Button disabled={isLoading} loading={isLoading} type="submit" variant="primary">
										{limitReached || applicationApprovalData.threeMonthAmount == 0 ? 'Volver al inicio' : 'Registrar Dispositivo'}
									</Button>
									<Button
										Icon={<ArrowLeft className="size-5" />}
										disabled={isLoading}
										type="button"
										onClick={backToWelcomePage}
										variant="secondary"
									>
										Atrás
									</Button>
								</div>
							</form>
						</>
					)}
					{page === 5 && ( //done
						<>
							{render_page_header('Registro de Dispositivo', 'Por favor complete los detalles del dispositivo', <Smartphone />)}

							<form onSubmit={handleRegistrationSubmit} className="space-y-4">
								<Select
									placeholder="Seleccionar tipo..."
									floatingLabel="Tipo de producto"
									options={[
										{ label: 'Celular Android', value: 'phone' },
										...(allowIphone && !switchChecked && !switchChecked2 ? [{ label: 'Celular iPhone', value: 'iphone' }] : []),
										...(!switchChecked && !switchChecked2 ? [{ label: 'Tablet', value: 'tablet' }] : []),
										...(allowLaptop && !switchChecked && !switchChecked2 ? [{ label: 'Laptop', value: 'laptop' }] : []),
										...((productElegibility || allowAnyProduct) && !switchChecked && !switchChecked2
											? [{ label: 'Otro', value: 'other' }]
											: [])
									]}
									defaultValue={switchChecked || switchChecked2 ? 'phone' : productType ? productType : undefined}
									onSelectedChange={(value) => setProductType(value)}
								/>

								{(productElegibility || allowAnyProduct) && productType === 'other' && (
									<Input
										floatingLabel="Nombre del producto"
										value={productName}
										onChange={(value) => setProductName(value)}
										placeholder="Ingresar nombre..."
									/>
								)}

								<Select
									floatingLabel="Número de cuotas"
									options={renderPaymentOptions(level, countrySettings.payment_options).map((item) => ({
										label: item.props.children,
										value: item.props.children / 2
									}))}
									defaultValue={timeToPay?.toString()}
									onSelectedChange={(value) => changeTimeToPay(Number.parseInt(value))}
								/>

								{!switchChecked && !switchChecked2 && (
									<Select
										floatingLabel="Inicial deseada"
										options={renderDownPayments(countrySettings.down_payment[level] * 100).map((item) => ({
											label: item + '%',
											value: item / 100
										}))}
										defaultValue={downPaymentPerc?.toString()}
										onSelectedChange={(value) => setDownPaymentPerc(value ? Number.parseFloat(value) : 0)}
									/>
								)}

								<Select
									placeholder="Seleccionar local..."
									floatingLabel="Local de venta"
									options={subStoreList.map((item) => ({
										label: item,
										value: item
									}))}
									defaultValue={subStore}
									onSelectedChange={(value) => setSubStore(value)}
								/>

								{allowOnline && (
									<Select
										placeholder="Seleccionar tipo..."
										floatingLabel="Tipo de venta"
										options={[
											{ label: 'Online', value: true },
											{ label: 'En tienda física', value: false }
										]}
										defaultValue={isOnline}
										onSelectedChange={(value) => setIsOnline(value)}
									/>
								)}

								<Input
									floatingLabel={`Precio de venta (en ${countrySettings.currency_name})`}
									placeholder="0.00"
									allowedChars="numeric"
									value={price?.toString()}
									onChange={(value) => setPrice(value ? Number.parseFloat(value) : 0)}
									validation={(value) =>
										!value || !Number.parseFloat(value) || (switchChecked && Number.parseFloat(value) < 100) ? false : true
									}
									Icon={
										countrySettings?.currency_code ? (
											<span className="text-sm text-neutral-500">{countrySettings.currency_code}</span>
										) : undefined
									}
								/>

								{switchChecked && price < 100 && (
									<p className="mt-1 flex items-center gap-1 text-sm text-red-500">
										<svg className="h-4 w-4" viewBox="0 0 24 24" fill="none">
											<path
												d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
												stroke="currentColor"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
										El monto debe ser mayor a 100
									</p>
								)}

								{formError && <Notice message={formError} variant="error" />}

								<div className="flex flex-wrap gap-2 md:flex-row-reverse md:flex-nowrap">
									<Button
										disabled={
											isLoading ||
											!price ||
											!productType ||
											!subStore ||
											!timeToPay ||
											!downPaymentPerc ||
											(productType === 'other' && !productName)
										}
										loading={isLoading}
										type="submit"
										variant="primary"
									>
										Siguiente
									</Button>
									<Button
										Icon={<ArrowLeft className="size-5" />}
										disabled={isLoading}
										type="button"
										onClick={() => goToPage(4)}
										variant="secondary"
									>
										Atrás
									</Button>
								</div>
							</form>
						</>
					)}
					{page === 6 && ( //done
						<>
							{render_page_header(
								'Información de Pagos',
								'A continuación puedes observar todas las cuotas y fechas correspondientes',
								<Receipt />
							)}

							<form
								className="space-y-6"
								onSubmit={(e) => {
									e.preventDefault();
									handleTermsAccepted();
								}}
							>
								<div className="mx-auto max-w-3xl rounded-xl border border-neutral-700/50 bg-neutral-800/25 p-4 backdrop-blur-sm md:p-6">
									<h2 className="mb-6 text-center text-xl font-semibold text-neutral-0">Inicial + {timeToPay * 2} Cuotas</h2>

									<div className="flex items-center justify-between border-b border-neutral-700/50 py-3">
										<span className="font-bold text-neutral-300">Cuota Inicial (Hoy)</span>
										<span className="font-bold text-primary-400">
											{countrySettings.currency_code}
											{!switchChecked
												? allowAnyProduct && productType === 'other'
													? (parseInt(price) * 0.5 + extraInitial).toFixed(2)
													: Math.round(parseInt(price) * downPaymentPerc + extraInitial).toFixed(2)
												: Math.round(parseInt(price) * 0.5).toFixed(2)}
										</span>
									</div>

									<div className="divide-y divide-neutral-700/50">
										{getNextXDates(timeToPay * 2).map((item, index) => (
											<div key={index} className="flex items-center justify-between py-3">
												<span className="text-neutral-300">{item[0] + ' ' + item[1]}</span>
												<span className="font-medium text-primary-400">
													{countrySettings.currency_code}
													{!switchChecked
														? allowAnyProduct && productType === 'other'
															? ((parseInt(price) * (1 - 0.5) * 1) / (parseInt(timeToPay) * 2)).toFixed(2)
															: Math.round(
																	(parseInt(price) * (1 - downPaymentPerc) * interestFactor) / (parseInt(timeToPay) * 2)
																).toFixed(2)
														: Math.round((parseInt(price) * 0.5 * 1.25) / (timeToPay * 2)).toFixed(2)}
												</span>
											</div>
										))}
									</div>
								</div>

								{!(allowAnyProduct && productType === 'other') && (
									<div className="mx-auto mt-2 max-w-3xl space-y-4 rounded-xl border border-neutral-700/50 bg-neutral-800/25 p-4 backdrop-blur-sm md:p-6">
										<h3 className="text-lg font-semibold text-neutral-0">Detalle</h3>

										<div className="space-y-3">
											<div className="flex items-center justify-between">
												<span className="text-neutral-300">Subtotal</span>
												<span className="font-medium text-primary-400">
													{countrySettings.currency_code + (parseInt(price) + extraInitial).toFixed(2)}
												</span>
											</div>

											<div className="flex items-center justify-between">
												<span className="max-w-[70%] text-sm text-neutral-300 md:text-base">
													{country === 'venezuela'
														? 'Servicio de Integración Tecnológica y Avalúo Comercial'
														: 'Cargos Administrativos e Intereses'}
												</span>
												<span className="font-medium text-primary-400">
													{!switchChecked
														? countrySettings.currency_code +
															Math.round(
																Math.round(
																	Math.round(
																		((parseInt(price) * (1 - downPaymentPerc) * interestFactor) / (parseInt(timeToPay) * 2)) * 100
																	) / 100
																) *
																	(parseInt(timeToPay) * 2) +
																	(parseInt(price) * downPaymentPerc + extraInitial) -
																	(parseInt(price) + extraInitial)
															).toFixed(2)
														: countrySettings.currency_code +
															Math.round(
																Math.round(parseInt(price) * 0.5) +
																	Math.round((parseInt(price) * 0.5 * 1.25) / (timeToPay * 2)) * timeToPay * 2 -
																	parseInt(price)
															).toFixed(2)}
												</span>
											</div>

											<div className="flex items-center justify-between border-t border-neutral-700/50 pt-3">
												<span className="text-lg font-semibold text-neutral-0">Total</span>
												<span className="text-lg font-bold text-primary-400">
													{!switchChecked
														? countrySettings.currency_code +
															Math.round(
																Math.round(
																	Math.round(
																		((parseInt(price) * (1 - downPaymentPerc) * interestFactor) / (parseInt(timeToPay) * 2)) * 100
																	) / 100
																) *
																	(parseInt(timeToPay) * 2) +
																	(parseInt(price) * downPaymentPerc + extraInitial)
															).toFixed(2)
														: countrySettings.currency_code +
															(
																Math.round(
																	Math.round(parseInt(price) * 0.5) +
																		Math.round((parseInt(price) * 0.5 * 1.25) / (timeToPay * 2)) * timeToPay * 2 -
																		parseInt(price)
																) +
																(parseInt(price) + extraInitial)
															).toFixed(2)}
												</span>
											</div>
										</div>
									</div>
								)}

								{formError && <Notice message={formError} variant="error" />}

								<div className="flex flex-wrap gap-2 md:flex-row-reverse md:flex-nowrap">
									<Button disabled={isLoading} loading={isLoading} type="submit" variant="primary">
										{productType === 'other' ? 'Finalizar Venta' : 'Siguiente'}
									</Button>
									<Button
										Icon={<ArrowLeft className="size-5" />}
										disabled={isLoading}
										type="button"
										onClick={() => goToPage(5)}
										variant="secondary"
									>
										Atrás
									</Button>
								</div>
							</form>
						</>
					)}
					{page === 7 && ( //done
						<>
							{render_page_header(
								'Configuración de Seguridad',
								'Ahora, el encargado(a) en la tienda va a configurar nuestro sistema de bloqueo en el dispositivo, lo cual le dará permiso a Krece para bloquear y desbloquear el equipo en caso de que se atrase con sus pagos.',
								<Shield className="h-6 w-6 text-primary-500" />
							)}

							<form
								className="space-y-6"
								onSubmit={(e) => {
									e.preventDefault();
									listenToAnexoDocumentByIdentificationNumber();
									goToPage(10);
								}}
							>
								<div className="flex flex-wrap gap-2 md:flex-row-reverse md:flex-nowrap">
									<Button disabled={isLoading} loading={isLoading} type="submit" variant="primary">
										Comenzar Configuración
									</Button>

									<Button
										Icon={<ArrowLeft className="size-5" />}
										disabled={isLoading}
										type="button"
										onClick={backToWelcomePage}
										variant="secondary"
									>
										Atrás
									</Button>
								</div>
							</form>
						</>
					)}
					{page === 10 && ( //done
						<>
							{render_page_header(
								'Configuración de Seguridad',
								securityProvider === 'tonic'
									? productType === 'laptop'
										? 'Por favor siga los siguientes pasos ANTES de continuar.'
										: 'Para comenzar, asegurese que el equipo se encuentre apagado.'
									: null,
								<Shield />
							)}

							<form
								className="space-y-6"
								onSubmit={(e) => {
									e.preventDefault();
									if (securityProvider === 'tonic') {
										registerWithTonic();
									} else {
										goToPage(11);
									}
								}}
							>
								{securityProvider === 'tonic' ? (
									<div className="space-y-4">
										{productType === 'laptop' && (
											<div className={`space-y-2 text-neutral-300 ${productType === 'laptop' ? 'mb-6' : ''}`}>
												<p>1. Encienda la laptop.</p>
												<p>2. Descarga e instala el archivo en https://krece.app/laptop.</p>
												<p>3. Instale el archivo .exe y siga los pasos de instalación.</p>
												<p>4. Espere 15 MINUTOS.</p>
												<p>5. Ingrese el Serial y haga clic en siguiente.</p>
											</div>
										)}

										<Input
											floatingLabel={productType === 'laptop' ? 'Código serial' : 'IMEI del equipo'}
											type="password"
											value={imeiToRegister}
											onChange={(value) => setImeiToRegister(value)}
											placeholder={`Ingrese el ${productType === 'laptop' ? 'código' : 'IMEI'}...`}
										/>

										<Input
											floatingLabel={productType === 'laptop' ? 'Confirme el código serial' : 'Confirme el IMEI del equipo'}
											type="password"
											value={imeiToRegister2}
											onChange={(value) => setImeiToRegister2(value)}
											placeholder={`Confirme el ${productType === 'laptop' ? 'código' : 'IMEI'}...`}
											allowPaste={false}
										/>

										{formError && <Notice message={formError} variant="error" />}
									</div>
								) : (
									<div className="space-y-6">
										<div className="space-y-4">
											<p className="text-neutral-300">
												Encienda el dispositivo nuevo y en la primera pantalla cambie el idioma a Español. En esa misma pantalla,
												toque en cualquier lugar de la pantalla 6 veces para ver un escáner de código QR.
											</p>

											<div className="grid grid-cols-2 gap-4">
												<img className="w-full" src={country === 'venezuela' ? paso1 : paso1dot1} alt="Paso 1" />
												<img className="w-full" src={paso1dot2} alt="Paso 1.2" />
											</div>

											<p className="text-neutral-300">
												2. Escanee el siguiente código QR para iniciar la instalación del app de seguridad.
											</p>

											<div className="flex justify-center">
												<img className="w-[200px]" src={qrCode} alt="Enrollment QR" />
											</div>
										</div>
									</div>
								)}

								<div className="flex flex-col gap-2">
									<div className="flex flex-wrap gap-2 md:flex-row-reverse md:flex-nowrap">
										<Button disabled={isLoading} loading={isLoading} type="submit" variant="primary">
											{securityProvider === 'tonic' ? 'Siguiente' : 'Seguir'}
										</Button>

										<Button
											Icon={<ArrowLeft className="size-5" />}
											disabled={isLoading}
											type="button"
											onClick={() => goToPage(7)}
											variant="secondary"
										>
											Atrás
										</Button>
									</div>

									{securityProvider !== 'tonic' && (
										<Button
											disabled={isLoading}
											type="button"
											onClick={() => goToPage(14)}
											variant="secondary"
											className="w-full"
										>
											Saltar Instrucciones
											<ArrowRight className="size-5" />
										</Button>
									)}
								</div>
							</form>
						</>
					)}
					{page === 11 && ( //done
						<>
							{render_page_header('Instrucciones de configuración', 'Haga clic en aceptar cuando se le solicite', <Settings />)}

							<form
								className="space-y-6"
								onSubmit={(e) => {
									e.preventDefault();
									goToPage(12);
								}}
							>
								<div className="grid grid-cols-3 gap-4">
									<img className="w-full" src={paso2} alt="Paso 2" />
									<img className="w-full" src={paso3} alt="Paso 3" />
									<img className="w-full" src={paso4} alt="Paso 4" />
									<img className="w-full" src={paso5} alt="Paso 5" />
								</div>

								<div className="flex flex-col gap-2">
									<div className="flex flex-wrap gap-2 md:flex-row-reverse md:flex-nowrap">
										<Button disabled={isLoading} loading={isLoading} type="submit" variant="primary">
											Seguir
										</Button>
										<Button
											Icon={<ArrowLeft className="size-5" />}
											disabled={isLoading}
											type="button"
											onClick={() => goToPage(10)}
											variant="secondary"
										>
											Atrás
										</Button>
									</div>

									<Button disabled={isLoading} type="button" onClick={() => goToPage(14)} variant="secondary" className="w-full">
										Saltar Instrucciones
										<ArrowRight className="size-5" />
									</Button>
								</div>
							</form>
						</>
					)}
					{page === 12 && ( //done
						<>
							{render_page_header(
								'Instrucciones de configuración',
								'Haga clic en "Ajustes adicionales" y después en "PERMITR"',
								<Settings />
							)}

							<form
								className="space-y-6"
								onSubmit={(e) => {
									e.preventDefault();
									goToPage(13);
								}}
							>
								<div className="grid grid-cols-2 gap-4">
									<img className="w-full" src={paso6} alt="Paso 6" />
									<img className="w-full" src={paso7} alt="Paso 7" />
								</div>

								<div className="flex flex-col gap-2">
									<div className="flex flex-wrap gap-2 md:flex-row-reverse md:flex-nowrap">
										<Button disabled={isLoading} loading={isLoading} type="submit" variant="primary">
											Seguir
										</Button>
										<Button
											Icon={<ArrowLeft className="size-5" />}
											disabled={isLoading}
											type="button"
											onClick={() => goToPage(11)}
											variant="secondary"
										>
											Atrás
										</Button>
									</div>

									<Button disabled={isLoading} type="button" onClick={() => goToPage(14)} variant="secondary" className="w-full">
										Saltar Instrucciones
										<ArrowRight className="size-5" />
									</Button>
								</div>
							</form>
						</>
					)}
					{page === 13 && ( //done
						<>
							{render_page_header(
								'Instrucciones de configuración',
								'Siga las instrucciones que se le muestran en la pantalla y cuando esté listo haga clic en "Configuración Completa"',
								<Settings />
							)}

							<form
								className="space-y-6"
								onSubmit={(e) => {
									e.preventDefault();
									goToPage(14);
								}}
							>
								<div className="grid grid-cols-2 gap-4">
									<img className="w-full" src={paso8} alt="Paso 8" />
									<img className="w-full" src={paso13} alt="Paso 13" />
								</div>

								<div className="flex flex-wrap gap-2 md:flex-row-reverse md:flex-nowrap">
									<Button disabled={isLoading} loading={isLoading} type="submit" variant="primary">
										Seguir
									</Button>
									<Button
										Icon={<ArrowLeft className="size-5" />}
										disabled={isLoading}
										type="button"
										onClick={() => goToPage(12)}
										variant="secondary"
									>
										Atrás
									</Button>
								</div>
							</form>
						</>
					)}
					{page === 14 && ( //done
						<>
							{render_page_header(
								'Instrucciones de configuración',
								'Por favor introduzca el código de confirmación de 7 dígitos en la siguiente casilla.',
								<Settings />
							)}

							<form
								className="space-y-4"
								onSubmit={(e) => {
									e.preventDefault();
									handleSendDeviceID();
								}}
							>
								<Input
									floatingLabel="Código de confirmación"
									value={deviceID}
									onChange={(value) => setDeviceID(value)}
									placeholder="Ingrese el código de confirmación"
								/>

								<Button variant="text" onClick={() => setShowImei(!showImei)} className="!-mt-1">
									¿No encuentra el código de confirmación?
								</Button>

								{showImei && (
									<div className="space-y-4 rounded-lg border border-neutral-800 p-4">
										<Input
											floatingLabel="Número IMEI del equipo"
											value={imeiNumber}
											onChange={(value) => setImeiNumber(value)}
											placeholder="Ingrese el número IMEI"
										/>

										{loginError && <Notice message={loginError} variant="error" />}

										<Button
											Icon={<Search className="size-5" />}
											onClick={searchConfirmationNumber}
											variant="secondary"
											loading={isSearchingConfirmationNumber}
										>
											Buscar
										</Button>

										{showConfNumber && (
											<Notice message={`El código de confirmación de este equipo es: ${confNumber}`} variant="success" />
										)}
									</div>
								)}

								<div className="flex justify-center">
									<img className="w-[200px]" src={paso16} alt="Paso16" />
								</div>

								{!isOnline && (
									<>
										<div className="text-neutral-300">
											Por último, haga clic en "Generar Contrato", el cliente debe abrir la app Krece y aceptar el contrato.
										</div>

										{!tokenGenerated ? (
											<Button onClick={generateAnexo} variant="secondary" loading={isLoading && !anexoAccepted}>
												Generar Contrato
											</Button>
										) : (
											<div className="space-y-4">
												{anexoAccepted && <Notice message={'Contrato Aceptado'} variant="success" />}

												{!anexoAccepted && (
													<Button onClick={generateAnexo} variant="secondary" loading={isLoading && !anexoAccepted}>
														Generar Otro Contrato
													</Button>
												)}
											</div>
										)}
									</>
								)}

								{formError && <Notice message={formError} variant="error" />}

								<div className="flex flex-wrap gap-2 md:flex-row-reverse md:flex-nowrap">
									<Button
										disabled={!isOnline && !anexoAccepted}
										loading={(isOnline && isLoading) || (anexoAccepted && isLoading)}
										type="submit"
										variant="primary"
									>
										Concretar Venta
									</Button>
									<Button
										Icon={<ArrowLeft className="size-5" />}
										disabled={isLoading}
										type="button"
										onClick={() => goToPage(13)}
										variant="secondary"
									>
										Atrás
									</Button>
								</div>
							</form>
						</>
					)}
					{page === 15 && ( //done
						<>
							{render_page_header('¡Registro Completado!', null, <CircleCheck />)}

							<div className="mb-8 rounded-lg border border-yellow-500/20 bg-yellow-500/10 p-6">
								<div className="flex items-start gap-4">
									<div className="shrink-0">
										<AlertTriangle className="h-6 w-6 text-yellow-500" />
									</div>
									<div className="space-y-1">
										<h3 className="font-medium text-yellow-500">IMPORTANTE</h3>
										<p className="text-neutral-300">
											Antes de que el cliente se lleve su celular, asegúrese que le llegó un email de confirmación a la tienda,
											eso se utilizará como comprobante en caso de cualquier problema que ocurra.
										</p>
									</div>
								</div>
							</div>

							<form
								className="space-y-6"
								onSubmit={(e) => {
									e.preventDefault();
									backToWelcomePage();
								}}
							>
								<Button type="submit" variant="primary">
									Regresar al Inicio
								</Button>
							</form>
						</>
					)}

					{showDialog && (
						<Dialog onClose={() => setShowDialog(false)}>
							<div dangerouslySetInnerHTML={{ __html: dialogMessage }} />
						</Dialog>
					)}
					{showConfirmDialog && (
						<Dialog closable={false} title="Confirmar" onClose={() => setShowConfirmDialog(false)}>
							<div className="flex flex-col gap-4">
								<div dangerouslySetInnerHTML={{ __html: dialogMessage }} />
								<div className="flex justify-end gap-2">
									<button
										onClick={() => setShowConfirmDialog(false)}
										className="inline-flex h-[35px] items-center justify-center rounded-lg px-[15px] font-medium leading-none text-neutral-600 transition-colors hover:bg-neutral-900/50 focus:outline-none disabled:pointer-events-none disabled:opacity-50"
									>
										Cancelar
									</button>
									<button
										onClick={() => {
											setShowConfirmDialog(false);
											pendingConfirmAction();
										}}
										className="inline-flex h-[35px] items-center justify-center rounded-lg bg-primary-500 px-[15px] font-medium leading-none text-black transition-colors hover:bg-primary-600 focus:outline-none disabled:pointer-events-none disabled:opacity-50"
									>
										Confirmar
									</button>
								</div>
							</div>
						</Dialog>
					)}
				</div>
			</div>
		</div>
	);

	function select_promo() {
		return (
			<>
				{productType !== 'other' && country === 'venezuela' && (
					<div className="mb-4 flex flex-col gap-4">
						<div className="flex shrink-0 flex-wrap items-center justify-center gap-2">
							<div className="group flex w-[17.5rem] items-center justify-between rounded-lg border border-neutral-700/50 bg-neutral-800/70 py-1.5 pl-4 pr-2 transition-all duration-200 hover:bg-neutral-800/90">
								<span className="font-medium text-neutral-100">
									Activar promo<span className="font-bold text-[#1428A0]">SAMSUNG</span>
								</span>
								<Switch
									checked={switchChecked}
									onChange={() => {
										setSwitchChecked(!switchChecked);
										setSwitchChecked2(false);
										setDownPaymentPerc(!switchChecked ? 0.5 : 0.4);
									}}
									color={switchChecked ? 'primary' : 'default'}
								/>
							</div>
							{/* <div className="group flex w-[17.5rem] items-center justify-between rounded-lg border border-neutral-700/50 bg-neutral-800/70 py-1.5 pl-4 pr-2 transition-all duration-200 hover:bg-neutral-800/90">
								<span className="font-medium text-neutral-100">
									Activar promo<span className="font-bold text-[#00A1E4]">INFINIX</span>
								</span>
								<Switch
									checked={switchChecked2 && isInfinix}
									onChange={() => {
										setSwitchChecked2(!isInfinix || !switchChecked2 ? true : !switchChecked2);
										setSwitchChecked(false);
										setIsInfinix(true);
										setDownPaymentPerc(!isInfinix || !switchChecked2 ? 0.2 : 0.4);
									}}
									color={switchChecked2 ? 'primary' : 'default'}
								/>
							</div>
							<div className="group flex w-[17.5rem] items-center justify-between rounded-lg border border-neutral-700/50 bg-neutral-800/70 py-1.5 pl-4 pr-2 transition-all duration-200 hover:bg-neutral-800/90">
								<span className="font-medium text-neutral-100">
									Activar promo<span className="font-bold text-[#000000]">HONOR</span>
								</span>
								<Switch
									checked={switchChecked2 && !isInfinix}
									onChange={() => {
										setSwitchChecked2(isInfinix || !switchChecked2 ? true : !switchChecked2);
										setSwitchChecked(false);
										setIsInfinix(false);
										setDownPaymentPerc(isInfinix || !switchChecked2 ? 0.25 : 0.4);
									}}
									color={switchChecked2 ? 'primary' : 'default'}
								/>
							</div> */}
						</div>

						{(switchChecked || switchChecked2) && (
							<div className="mx-auto max-w-md rounded-lg border border-neutral-700/50 bg-neutral-800/30 p-4 shadow-lg backdrop-blur-sm">
								{switchChecked && (
									<div className="space-y-2">
										<h3 className="text-lg font-bold text-[#1428A0]">Promoción Samsung</h3>
										<p className="text-sm text-neutral-200">
											Llévate un equipo Samsung pagando un 50% de inicial sin importar tu línea de compra aprobada.
										</p>
									</div>
								)}

								{switchChecked2 && isInfinix && (
									<div className="space-y-2">
										<h3 className="text-lg font-bold text-[#00A1E4]">Promoción Infinix</h3>
										<p className="text-sm text-neutral-200">
											Llévate un Note 40, Note 40 Pro o GT 20 Pro pagando un 25% de inicial sin importar tu línea de compra
											aprobada.
										</p>
									</div>
								)}

								{switchChecked2 && !isInfinix && (
									<div className="space-y-2">
										<h3 className="text-lg font-bold text-neutral-100">Promoción Honor</h3>
										<p className="text-sm text-neutral-200">
											Llévate un Magic 6 Lite pagando un 25% de inicial sin importar tu línea de compra aprobada.
										</p>
									</div>
								)}
							</div>
						)}
					</div>
				)}
			</>
		);
	}

	function activated_promo() {
		return (
			<>
				{switchChecked && (
					<div className="group pointer-events-none mb-4 flex w-[17.5rem] items-center justify-between rounded-lg border border-neutral-700/50 bg-neutral-800/70 py-1.5 pl-4 pr-2 transition-all duration-200 hover:bg-neutral-800/90">
						<span className="font-medium text-neutral-100">
							promo<span className="font-bold text-[#1428A0]">SAMSUNG</span>
						</span>
						<Switch disabled={true} checked={true} color="primary" />
					</div>
				)}
				{switchChecked2 && isInfinix && (
					<div className="group pointer-events-none mb-4 flex w-[17.5rem] items-center justify-between rounded-lg border border-neutral-700/50 bg-neutral-800/70 py-1.5 pl-4 pr-2 transition-all duration-200 hover:bg-neutral-800/90">
						<span className="font-medium text-neutral-100">
							promo<span className="font-bold text-[#00A1E4]">INFINIX</span>
						</span>
						<Switch disabled={true} checked={true} color="primary" />
					</div>
				)}
				{switchChecked2 && !isInfinix && (
					<div className="group pointer-events-none mb-4 flex w-[17.5rem] items-center justify-between rounded-lg border border-neutral-700/50 bg-neutral-800/70 py-1.5 pl-4 pr-2 transition-all duration-200 hover:bg-neutral-800/90">
						<span className="font-medium text-neutral-100">
							promo<span className="font-bold text-[#000000]">HONOR</span>
						</span>
						<Switch disabled={true} checked={true} color="primary" />
					</div>
				)}
			</>
		);
	}

	function render_page_header(title, description, icon) {
		return (
			<div className="mb-4 flex flex-col items-center gap-2 md:mb-6 md:gap-3">
				<div className="relative">
					<div className="absolute -inset-1 rounded-full bg-gradient-to-r from-primary-400 to-primary-600 opacity-10 blur"></div>
					<div className="relative rounded-full bg-neutral-900 p-4 light:bg-neutral-1000 light:ring-2 light:ring-primary-500/10">
						{icon && React.cloneElement(icon, { className: 'h-6 w-6 text-primary-500' })}
					</div>
				</div>
				<div className="space-y-1 text-center">
					<h1 className="text-2xl font-semibold tracking-tighter text-neutral-0 md:text-3xl">{title}</h1>
					{description && <p className="text-neutral-300">{description}</p>}
				</div>
			</div>
		);
	}
};

export default AdminPortal;
