import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../context/GlobalContext';
import { UserData } from './auth';

export default function useFetch<T extends keyof API, V extends EndpointVerbs<T>>(
	endpoint: T,
	verb: V,
	condition: boolean,
	initPayload?: APIRequestBody<T, V>,
	setCustomLoading?: (value: boolean) => void,
	token_necessary = true
) {
	const { userData, accessToOtherStores } = useContext(GlobalContext);
	const token = userData?.firebase_token;

	const [refetch, setRefetch] = useState(0);

	const [payload, setPayload] = useState(initPayload ? initPayload : null);

	const [fetched, setFetched] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<boolean | string>(false);
	const [data, setData] = useState<null | APIResponse<T, V>>(null);

	const useTestAuth = accessToOtherStores && ((endpoint === 'https://api.krece.app/storeprofile/masteruser/' && verb === 'GET') || (endpoint === 'https://api.krece.app/storeprofile/get_custom_token/' && verb === 'POST'));

	useEffect(() => {
		if ((!token && token_necessary) || !condition) return;

		setCustomLoading?.(true);
		setLoading(true);
		setError(false);
		setFetched(false);

		const url = new URL(endpoint);
		if (payload && verb === 'GET') Object.keys(payload).forEach((key) => url.searchParams.append(key, (payload as any)[key]));
		fetch(url, {
			method: verb,
			...(payload && verb !== 'GET' && { body: JSON.stringify(payload) }),
			headers: {
				...(token && { Authorization: `Bearer ${useTestAuth ? accessToOtherStores?.token || token : token}` }),
				...(verb !== 'GET' && { 'Content-Type': 'application/json' }),
				...(userData?.master_store?.id && { 'X-Master-Store-Email': (!useTestAuth || !accessToOtherStores || !accessToOtherStores?.email) ? userData.master_store.id : accessToOtherStores?.email }),
				...(userData?.email && { 'X-User-Email': (!useTestAuth || !accessToOtherStores || !accessToOtherStores?.email) ? userData.email : accessToOtherStores?.email }),
				...(userData?.country && { 'X-Country': userData.country })
			}
		})
			.then(async (response) => {
				if (!response.ok) {
					const error = await response.json();
					if (typeof error === 'object' && 'status' in error && typeof error.status === 'string') throw error.status;
					else throw new Error();
				} else {
					return response.json();
				}
			})
			.then((data: APIResponse<T, V> | { success: boolean; status: string }) => {
				if (!data) return;
				if (typeof data === 'object' && 'success' in data && !data.success) {
					if (data?.status && typeof data.status === 'string') setError(data.status);
					else setError('Ha ocurrido un error. Intente más tarde.');
				} else {
					const response = typeof data === 'object' && (('data' in data) as any) ? (data as any).data : data;
					setData(response);
					if (
						endpoint === 'https://api.krece.app/storeprofile/branch/' &&
						response &&
						typeof response === 'object' &&
						Object.keys(response).length
					) {
						const sortedKeys = Object.keys(response).sort();
						const sortedData = {} as typeof response;
						sortedKeys.forEach((key) => {
							sortedData[key as keyof typeof response] = response[key as keyof typeof response];
						});
						setData(sortedData);
					}
				}
			})
			.catch((error) => {
				if (typeof error === 'string') setError(error);
				else setError('Ha ocurrido un error. Intente más tarde.');
			})
			.finally(() => {
				setCustomLoading?.(false);
				setLoading(false);
				setFetched(true);
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [payload, condition, refetch]);

	return {
		loading,
		data,
		error,
		setPayload,
		payload,
		fetched,
		refetch: () => setRefetch((value) => value + 1),
		reset: () => {
			setData(null);
			setError(false);
			setFetched(false);
			setLoading(false);
			setCustomLoading?.(false);
		}
	};
}

type HTTPVerbs = 'POST' | 'GET' | 'PUT' | 'DELETE';
type EndpointVerbs<T extends keyof API> = keyof API[T] & HTTPVerbs;
type APIRequestBody<T extends keyof API, V extends EndpointVerbs<T>> = API[T][V] extends { request: any }
	? API[T][V]['request']
	: never;
type APIResponse<T extends keyof API, V extends EndpointVerbs<T>> = API[T][V] extends { response: any }
	? API[T][V]['response']
	: API[T][V];

export interface API {
	'https://api.krece.app/notifications/requestauthcode/': {
		POST: {
			request: {
				code_type: 'whatsapp' | 'sms' | 'email';
				phone_number?: string;
				email?: string;
				country?: string;
				identification_number?: string;
			};
			response: { success: boolean; status: string };
		};
	};
	'https://api.krece.app/notifications/confirmauthcode/': {
		POST: {
			request: { confirmation_code: number; country: string };
			response: { success: boolean; status: string };
		};
	};
	'https://api.krece.app/storeprofile/brand/': {
		GET: {
			request: { email: string };
			response: {
				brand: string;
				instagram: string;
				whatsapp: string;
				logo?: string | null;
			}[];
		};
		POST: {
			request: {
				email: string;
				brands: {
					brand: string;
					instagram: string;
					whatsapp: string;
					logo?: string | null;
				}[];
			};
			response: any;
		};
		DELETE: {
			request: { brand_name: string };
			response: any;
		};
	};
	'https://api.krece.app/storeprofile/branch/': {
		GET: {
			request: { email: string };
			response: {
				[key: string]: {
					info: {
						instagram: string;
						whatsapp: string;
						logo?: string | null;
					};
					sucursales: {
						store_name: string;
						store_email: string;
						hours_of_operation: {
							[key: string]: {
								active: boolean;
								from: number;
								to: number;
							};
						};
						location: string;
						city: string;
						is_active?: boolean;
						logo?: string | null;
						seller_accounts?: {
							email: string;
							first_name: string;
							last_name: string;
						}[];
					}[];
				};
			};
		};
		POST: {
			request: {
				email: string;
				store_email: string;
				brand: string;
				store_name: string;
				city: string;
				location: string;
				hours_of_operation: {
					[key: string]: {
						active: boolean;
						from: number;
						to: number;
					};
				};
				instagram?: string;
				whatsapp?: string;
				logo?: string | null;
				is_active?: boolean;
			};
			response: any;
		};
		PUT: {
			request: {
				email: string;
				store_email: string;
				brand: string;
				store_name: string;
				city: string;
				location: string;
				hours_of_operation: {
					[key: string]: {
						active: boolean;
						from: number;
						to: number;
					};
				};
				instagram?: string;
				whatsapp?: string;
				logo?: string | null;
				is_active?: boolean;
			};
			response: any;
		};
		DELETE: {
			request: { email: string; store_email: string };
			response: any;
		};
	};
	'https://api.krece.app/storeprofile/branch/move/': {
		POST: {
			request: {
				email: string;
				store_email: string;
				current_brand: string;
				new_brand: string;
			};
			response: any;
		};
	};
	'https://api.krece.app/storeprofile/branch/seller/': {
		POST: {
			request: {
				brand: string;
				store_email: string;
				seller_account: {
					email: string;
					first_name: string;
					last_name: string;
				};
			};
			response: any;
		};
		DELETE: {
			request: {
				brand: string;
				store_email: string;
				seller_account: {
					email: string;
					first_name: string;
					last_name: string;
				};
			};
			response: any;
		};
	};
	'https://api.krece.app/storeprofile/store_payment_methods/': {
		GET: {
			request: { email: string };
			response: {
				transferBs?: {
					rif: string;
					type: string;
					bank: string;
					account: string;
					titular: string;
				};
				pagomovil?: {
					bank: string;
					rif: string;
					phoneNumber: string;
				};
				apolo_client_id?: string;
			};
		};
		PUT: {
			request: {
				master_store_email: string;
				transferBs?: {
					rif: string;
					type: string;
					bank: string;
					account: string;
					titular: string;
				};
				pagomovil?: {
					bank: string;
					rif: string;
					phoneNumber: string;
				};
				apolo_client_id?: string;
			};
			response: any;
		};
	};
	'https://api.krece.app/tables/payments/': {
		POST: {
			request: TableRequest;
			response: TableResponse;
		};
	};
	'https://api.krece.app/tables/devices/': {
		POST: {
			request: TableRequest;
			response: TableResponse;
		};
	};
	'https://api.krece.app/tables/deleted_devices/': {
		POST: {
			request: TableRequest;
			response: TableResponse;
		};
	};
	'https://api.krece.app/tables/transactions/': {
		POST: {
			request: TableRequest;
			response: TableResponse;
		};
	};
	'https://api.krece.app/tables/delinquent_users/': {
		POST: {
			request: TableRequest;
			response: TableResponse;
		};
	};
	'https://api.krece.app/tables/devicespagados/': {
		POST: {
			request: TableRequest;
			response: TableResponse;
		};
	};
	'https://api.krece.app/tables/unverified_payments/': {
		POST: {
			request: TableRequest;
			response: TableResponse;
		};
	};
	'https://api.krece.app/tables/storesummarydata/': {
		POST: {
			request: { master_store_email: string; filter_by?: [{ column_name: 'sub_store'; value: string }] };
			response: {
				column_name: 'hoy' | 'ayer' | 'semana actual' | 'semana anterior';
				ventas: number;
				monto_financiado: number;
				pagos_en_tienda: number;
				percent_diff_monto_financiado: number;
				percent_diff_pagos_en_tienda: number;
				percent_diff_ventas: number;
			}[];
		};
	};
	'https://api.krece.app/storeprofile/masteruser/': {
		GET: {
			request: undefined;
			response: string[];
		};
		PUT: {
			request: {
				email: string;

				company_name?: string;
				first_name?: string;
				last_name?: string;
				phone_number?: string;
				identification_number?: string;
				identification_url?: string;
				identification_type?: string;
				identification_number_2?: string;
				identification_url_2?: string;
				identification_type_2?: string;

				bank?: string;
				bank_account_holder?: string;
				bank_account_type?: 'corriente' | 'ahorro';
				bank_account_number?: string;
				interbank_code?: string;
				bank_identification_number?: string;
				bank_identification_type?: 'rif' | 'cedula' | 'dni' | 'rnc' | 'ruc' | 'firma_personal';
			};
			response: any;
		};
	};
	'https://api.krece.app/storeprofile/update_registration_steps/': {
		POST: {
			request: { master_store_email: string; registration_steps: UserData['registration_steps'] };
			response: any;
		};
	};
	'https://api.krece.app/payments/processunverifiedpayment/': {
		PUT: {
			request: {
				date?: string;
				amount?: number;
				unverified_payment_id: string;
				reference?: string;
				bank_id?: string;
				bank_account_number?: string;
				accept_payment?: false;
			};
			response: any;
		};
	};
	'https://api.krece.app/storeprofile/get_custom_token/': {
		POST: {
			request: { email: string };
			response: string;
		};
	};
	'https://api.krece.app/registration/is_valid_id/': {
		POST: {
			request: { id: string; object_type: 'Devices' | 'Payments' | 'Transactions' };
			response: any;
		};
	};
}

export type APIEndpoints = keyof API;

export interface TableRequest {
	master_store_email: string;
	last_object_id: null | string;
	filter_by?: {
		column_name: string;
		value: string | boolean;
	}[];
	order_by?: {
		column_name: string;
		column_order: 'ASC' | 'DESC';
	};
	search_by?: {
		column_name: string;
		value: string;
	};
	date_range?:
		| { from_date: string; to_date: string }
		| {
				custom_range: 'Ver todo' | 'Hoy' | 'Ayer' | 'Semana actual' | 'Última semana' | 'Este mes a la fecha' | 'Últimos 30 días';
		  };
	batch_size?: number;
	download_file?: boolean;
}
export interface TableResponse {
	last_object_id: null | string;
	column_names: string[];
	rows: string[][];
	order_by: {
		column_name: string;
		column_order: 'ASC' | 'DESC';
	};
	total_count: number;
	download_link?: string;
}
