import React, { useContext } from 'react';
import Table from '../../components/Table';
import { GlobalContext } from '../../context/GlobalContext';
import { Navigate } from 'react-router-dom';

export default function Eliminados() {
	const { userData } = useContext(GlobalContext);

	if (!userData?.email) return null;
	if (userData.cobranza_access) Navigate({ to: '/' });

	return (
		<div className="h-full w-full p-4 md:p-6">
			<Table
				userEmail={userData.master_store.id}
				endpoint="https://api.krece.app/tables/deleted_devices/"
				pagination={{ default: 100, batchSizes: [100] }}
				orderBy={{ columnName: 'Fecha de Compra', columnOrder: 'DESC' }}
				filters={[
					{
						column: 'Sucursal',
						options: [
							{ label: 'Todas las sucursales', value: 'Todas las sucursales' },
							...[...(userData?.sub_stores || [])].map((e) => ({ label: e, value: e }))
						],
						defaultValue: 'Todas las sucursales'
					}
				]}
				search_by={{ column: 'IMEI no', placeholder: 'Buscar IMEI...', type: 'numeric' }}
			/>
		</div>
	);
}
