import { useEffect, useState } from 'react';
import Select from './Select';
import Input from './Input';
import { Phone } from 'lucide-react';
import { validate_phone_number } from '../utils/validate';
import { cn } from '../shadcn/utils';


export default function PhoneInput({ country, onChange, disabled, className, value }: { country: string; onChange: (value: string) => void; disabled?: boolean; className?: string; value?: string }) {
  const PREFIX_LENGTH = country === 'peru' ? 1 : country === 'venezuela' ? 4 : 3;

  const [phonePrefix, setPhonePrefix] = useState(country === 'peru' ? '9' : value && validate_phone_number(value, country) ? value.slice(0, PREFIX_LENGTH) : '');
  const [phone, setPhone] = useState(value && validate_phone_number(value, country) ? value.slice(PREFIX_LENGTH) : '');

  useEffect(() => {
    if (value && validate_phone_number(value, country)) {
      setPhonePrefix(value.slice(0, PREFIX_LENGTH));
      setPhone(value.slice(PREFIX_LENGTH));
    }
  }, [value]);

  const handlePrefixChange = (prefix: string) => {
    setPhonePrefix(prefix);
    onChange?.(prefix + phone);
  };

  const handlePhoneChange = (phoneNumber: string) => {
    setPhone(phoneNumber);
    onChange?.(phonePrefix + phoneNumber);
  };

  return (
    <div className={cn('flex gap-2', className)}>
      <Select
        containerClassName="w-[115px] shrink-0"
        placeholder={`X${country === 'venezuela' ? 'XXX' : country === 'rd' ? 'XX' : ''}`}
        Icon={<Phone className="size-4" />}
        defaultValue={phonePrefix}
        onSelectedChange={(value) => handlePrefixChange(value as string)}
        options={[
          ...(country === 'venezuela'
            ? ['0414', '0424', '0412', '0416', '0426']
            : country === 'rd'
              ? ['809', '829', '849']
              : country === 'peru'
                ? ['9']
                : [])
        ].map((prefix) => ({ label: prefix, value: prefix }))}
        floatingLabel="Código de area"
        disabled={disabled}
      />
      <Input
        disabled={!phonePrefix || disabled}
        containerClassName="grow"
        value={phone}
        onChange={(value) => handlePhoneChange(value?.trim())}
        type="tel"
        placeholder={country === 'peru' ? 'XX - XXX - XXX' : `XXX - XXXX`}
        interpolation={country === 'peru' ? [{ after: 2, char: '-' }, { after: 5, char: '-' }] : [{ after: 3, char: '-' }]}
        allowedChars="numbers_only"
        validation={(value) => validate_phone_number(`${phonePrefix}${value?.trim()}`, country)}
        floatingLabel={`Teléfono (${country === 'peru' ? '8' : '7'} dígitos)`}
      />
    </div>
  );
}
