export function validate_email(email: string) {
	if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) return false;
	return true;
}

export function validate_rif(rif: string, country: string) {
	if (typeof rif !== 'string' || !rif.length) return false;

	if (country === 'venezuela') return /^\d{8,10}$/.test(rif);

	return false;
}

export function validate_cedula(cedula: string, country: string) {
	if (typeof cedula !== 'string' || !cedula.length) return false;

	if (country === 'venezuela') return /^\d{7,8}$/.test(cedula);
	if (country === 'rd') return /^\d{11}$/.test(cedula);

	return false;
}

export function validate_dni(dni: string, country: string) {
	if (typeof dni !== 'string' || !dni.length) return false;

	if (country === 'peru') return /^\d{8}$/.test(dni);

	return false;
}

export function validate_ruc(ruc: string, country: string) {
	if (typeof ruc !== 'string' || !ruc.length) return false;

	if (country === 'peru') return /^(10|20)\d{9}$/.test(ruc);

	return false;
}

export function validate_rnc(rnc: string, country: string) {
	if (typeof rnc !== 'string' || !rnc.length) return false;

	if (country === 'rd') return /^1\d{8}$/.test(rnc);

	return false;
}

export type IdentityType = 'rif' | 'rnc' | 'ruc' | 'cedula' | 'dni' | 'firma_personal';
export function validate_identity_number(identity_number: string, identity_type: IdentityType, country: string) {
	if (typeof identity_number !== 'string' || !identity_number.length || !identity_type || !country) return false;

	if (identity_type === 'rif') return validate_rif(identity_number, country);
	if (identity_type === 'firma_personal') return validate_rif(identity_number, country);
	if (identity_type === 'rnc') return validate_rnc(identity_number, country);
	if (identity_type === 'ruc') return validate_ruc(identity_number, country);
	if (identity_type === 'cedula') return validate_cedula(identity_number, country);
	if (identity_type === 'dni') return validate_dni(identity_number, country);

	return false;
}

export function validate_phone_number(phone_number: string, country: string) {
	if (typeof phone_number !== 'string' || !phone_number.length) return false;

	if (country === 'venezuela') return /^(0414|0424|0412|0416|0426)\d{7}$/.test(phone_number);
	if (country === 'rd') return /^(809|829|849)\d{7}$/.test(phone_number);
	if (country === 'peru') return /^(9)\d{8}$/.test(phone_number);

	return false;
}

export function validate_bank_account_number(bank_account_number: string, country: string) {
	if (typeof bank_account_number !== 'string' || !bank_account_number.length) return false;

	if (country === 'venezuela') return /^\d{20}$/.test(bank_account_number);
	if (country === 'peru') return /^\d{10,14}$/.test(bank_account_number);
	if (country === 'rd') return /^\d{9,10}$/.test(bank_account_number);

	return false;
}

export function validate_interbank_code(interbank_code: string, country: string) {
	if (typeof interbank_code !== 'string' || !interbank_code.length) return false;

	if (country === 'peru') return /^\d{18}$|^\d{20}$/.test(interbank_code);

	return false;
}

export const numbers_only = /[^0-9]/g;
export const numbers_dot = /[^0-9.]/g;
export const numbers_letters_dot_comma = /[^a-zA-Z0-9.,\sáéíóúüÁÉÍÓÚÜÑñ]/g;