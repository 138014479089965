import React, { useEffect, useState, useContext } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles, Button, TextField, Typography, MenuItem, AppBar, Toolbar } from '@material-ui/core';
import { Button as Button2 } from '../shadcn/Button';
import firestore from '../firestore'
import MenuButton from './MenuButton';
import firebase from 'firebase/compat/app';
import 'firebase/firestore';
import { BeatLoader } from 'react-spinners';
import { GlobalContext } from '../context/GlobalContext';
import { Link } from 'react-router-dom';
import image from '../images/Krece-logo_white.png';
import {
    LogOut,
    Search
} from 'lucide-react';
import AdminTable from '../components/AdminTable2';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import Table from './Table';
import Logo from './Logo';
import { Dialog } from './Dialog';
import Select from './Select';
import Input from './Input';
import useFetch from '../hooks/api';
import { GetBanks } from '../hooks/firebase';
import { logout } from '../hooks/auth';
import Notice from './Notice';
import { validate_bank_account_number } from '../utils/validate';

const useStyles = makeStyles({
    root: {
        width: '1500px',
        height: '1200px',
        color: 'white',
        flexGrow: 1,
        margin: '0 auto',
        paddingLeft: 20
    },
    title: {
        color: '#ffffff',
        fontSize: 20,
        marginLeft: 20
    },
    title2: {
        color: 'white',
        marginBottom: 20,
        fontSize: 26,
        fontWeight: 'bold'
    },
    cell: {
        color: 'silver',
        backgroundColor: '#1C1C1E',
    },
    font: { color: 'white' },
    welcomeButtons: {
        backgroundColor: '#45C4D6',
        color: '#2A2E32',
        marginTop: 30,
        marginBottom: 20
    },
    textSmall5: {
        fontSize: 16,
        color: '#2A2E32',
    },
    textSmall6: {
        fontSize: 16,
        color: 'white',
        fontWeight: 'bold',
    },
    textFields: {
        backgroundColor: '#2A2E32',
        color: 'white',
        borderColor: "white",
        borderBottomColor: "white",
        borderRadius: 5,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        paddingLeft: 5,
        width: 200
    },
    border: {
        borderRadius: '10px',
        border: "2px solid #ccc",
        padding: "20px",
        paddingRight: 40,
        marginBottom: 20,
        marginLeft: 20,
        width: 500
    },
    border2: {
        borderRadius: '10px',
        border: "2px solid #ccc",
        padding: "10px",
        width: 400
    },
});


const Internal = () => {
    const [users, setUsers] = useState([]);
    const [showUserData, setShowUserData] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [country, setCountry] = useState('')
    const [devices, setDevices] = useState([]);
    const [payments, setPayments] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [paymentsToCheck, setPaymentsToCheck] = useState([]);
    const [confirmedAmount, setConfirmedAmount] = useState([]);
    const [showStorePayments, setShowStorePayments] = useState(false);
    const [showPaymentsToCheck, setShowPaymentsToCheck] = useState(false);
    const [showEdit, setShowEdit] = useState(true);
    const [showDbObjects, setShowDbObjects] = useState(false);
    const [latePayments, setLatePayments] = useState([]);
    const [cedula, setCedula] = useState('');
    const [email, setEmail] = useState('');
    const [email2, setEmail2] = useState('');
    const [cedula2, setCedula2] = useState('');
    const [cedula3, setCedula3] = useState('');
    const [cedula4, setCedula4] = useState('');
    const [cedula5, setCedula5] = useState('');
    const [cedula6, setCedula6] = useState('');
    const [cedula7, setCedula7] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [storeEmail, setStoreEmail] = useState('');
    const [deviceID, setDeviceID] = useState('');
    const [devId, setDevId] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [paymentDate, setPaymentDate] = useState('');
    const [paymentDate2, setPaymentDate2] = useState('');
    const [referenceNumber, setReferenceNumber] = useState('');
    const [referenceNumber2, setReferenceNumber2] = useState('');
    const [reportedAmount, setReportedAmount] = useState('');
    const [reportedAmount2, setReportedAmount2] = useState('');
    const [oldCedula, setOldCedula] = useState('');
    const [newCedula, setNewCedula] = useState('');
    const [searchKeyType, setSearchKeyType] = useState('');
    const [searchKey, setSearchKey] = useState('');
    const [operationType, setOperationType] = useState('');
    const [newClientData, setNewClientData] = useState([]);
    const [oldClientData, setOldClientData] = useState([]);
    const [newDeviceName, setNewDeviceName] = useState('');
    const [searchedData, setSearchedData] = useState(false);
    const [dataToShow, setDataToShow] = useState(false);
    const [points, setPoints] = useState('');
    const [clientResponded, setClientResponded] = useState(null);
    const [clientStatus, setClientStatus] = useState('');
    const [followUpDate, setFollowUpDate] = useState('');
    const [clientResponded2, setClientResponded2] = useState(null);
    const [clientStatus2, setClientStatus2] = useState('');
    const [followUpDate2, setFollowUpDate2] = useState('');
    const [showPaymentToCheck, setShowPaymentToCheck] = useState(false);
    const [screenShown, setScreenShown] = useState('');
    const [showUsers, setShowUsers] = useState(true);


    const { authenticated, userData } = useContext(GlobalContext);

    const classes = useStyles();

    const isTimestampInPast = (firestoreTimestamp) => {
        const currentTimestamp = Date.now();
        const firestoreTimeMillis = firestoreTimestamp.toMillis();
        return firestoreTimeMillis < currentTimestamp;
    };

    useEffect(() => {

    }, []);


    const fetchPaymentData = async (ci) => {
        if (!cedula5) {
            alert("por favor rellene la casilla de cedula")
            return;
        }
        setPaymentsToCheck([]);
        try {
            setIsLoading(true);
            await fetchUserData(cedula5);
        } catch (error) {
            alert('Ocurrio un problema intente mas tarde: ', error.message);
            setIsLoading(false)
        }
    };

    const fetchUserData = async (ci) => {
        try {
            const querySnapshot = await firestore
                .collection('Users')
                .where('identification_number', '==', ci)
                .limit(1)
                .get();
            querySnapshot.forEach(async (doc) => {
                const data = doc.data();
                const userParam = {
                    id: doc.id,
                    is_active: data.is_active,
                    seller_store: data.seller_store,
                    registration_status: data.registration_status,
                    max_loan_approved_3months: data.max_loan_approved_3months,
                    max_loan_approved_6months: data.max_loan_approved_6months,
                    identification_number: data.identification_number,
                    interest_rate: data.interest_rate,
                    phone_number: data.phone_number,
                    guarantor_phone_number: data.guarantor_phone_number ? data.guarantor_phone_number : "No Disponible",
                    full_name: data.first_name + " " + data.last_name
                }
                const paymentCollection = firestore.collection('Payments');

                const query2 = await paymentCollection
                    .where('paid', '==', true)
                    .where('checked_by_internal_team', '==', false)
                    .where('is_active', '==', true)
                    .where('user', '==', firestore.collection('Users').doc(userParam.id))
                    .get()

                query2.forEach((doc) => {
                    // Process documents that meet the criteria from query1

                    const data = doc.data();
                    const date = data.most_recent_paid_date ? new Date(data.most_recent_paid_date.toDate()) : null;
                    const dateString = date ? date.toLocaleDateString() : '';

                    if (userParam) {
                        const phoneNumber = userParam['phone_number'];
                        const fullName = userParam['full_name'];
                        const guarantor_phone_number = userParam['guarantor_phone_number'];
                        const cedula = userParam['identification_number'];
                        paymentsToCheck.push({ dueDate: dateString, guarantor_phone_number: guarantor_phone_number, full_name: fullName, cedula: cedula, phone_number: phoneNumber, id: doc.id, actualAmount: data.actualAmount, paid: data.amountPaid, confirmationNumber: data.confirmationNumber, amount_confirmed: data.amount_confirmed, user: data.user, dateSort: date })
                    }

                    setConfirmedAmount(Array(paymentsToCheck.length).fill(0));
                    // await paymentsToCheck.sort(compareDueDate);
                    setPaymentsToCheck(paymentsToCheck);
                    setShowPaymentToCheck(true)
                    setIsLoading(false)
                })
                setIsLoading(false)
                if (paymentsToCheck.length === 0) {
                    alert('Este cliente no tiene pagos por confirmar')
                }
            });
        } catch (error) {
            console.error('Error fetching users:', error);
            setIsLoading(false)
        }
    };

    function compareDueDate(task1, task2) {
        const dueDate1 = task1.dateSort;
        const dueDate2 = task2.dateSort;

        if (dueDate1 < dueDate2) {
            return -1;
        } else if (dueDate1 > dueDate2) {
            return 1;
        } else {
            return 0;
        }
    }

    const freeDevice = async () => {
        setIsLoading(true);
        if (!devId) {
            alert('Por favor rellene todos los datos.')
            setIsLoading(false);
            return;
        }
        try {
            const data = {
                device_id: devId.trim()
            }
            const response = await sendPostRequest(data, 'release_device/', process.env.REACT_APP_API_TOKEN)

            if (response?.success) {
                alert('El equipo fue enviado a liberar exitosamente. Asegurese que el equipo tenga conexion a wifi o chip activo')
            } else if (!response?.success) {
                alert('Error: ' + response.status)
            } else {
                alert('Ocurrio un problema intente mas tarde.')
            }
            setIsLoading(false);
        } catch (err) {
            alert('Error: ' + err.message)
            setIsLoading(false);
        }
    }

    const unlockDevice = async () => {
        setIsLoading(true);
        if (!cedula4) {
            alert('Por favor rellene todos los datos.')
            setIsLoading(false);
            return;
        }
        try {
            const data = {
                device_id: cedula4.trim()
            }
            const response = await sendPostRequest(data, 'unlock_phone/', process.env.REACT_APP_API_TOKEN)

            if (response?.success) {
                alert('El equipo fue enviado a desbloquear exitosamente. Asegurese que el equipo tenga conexion a wifi o chip activo')
            } else if (!response?.success) {
                alert('Error: ' + response.status)
            } else {
                alert('Ocurrio un problema intente mas tarde.')
            }
            setIsLoading(false);
        } catch (err) {
            alert('Error: ' + err.message)
            setIsLoading(false);
        }
    }

    const reportStolenDevice = async () => {
        setIsLoading(true);
        if (!cedula3) {
            alert('Por favor rellene todos los datos.')
            setIsLoading(false);
            return;
        }
        try {
            const data = {
                device_id: cedula3.trim()
            }
            const response = await sendPostRequest(data, 'report_device_stolen/', process.env.REACT_APP_API_TOKEN)
            if (response?.success) {
                alert('El equipo fue bloqueado por robo exitosamente.')
            } else if (!response?.success) {
                alert('Error: ' + response.status)
            } else {
                alert('Ocurrio un problema intente mas tarde.')
            }
            setIsLoading(false);
        } catch (err) {
            alert('Error: ' + err.message)
            setIsLoading(false);
        }
    }

    const changePhoneNumber = async () => {
        setIsLoading(true);
        if (!cedula2 || !phoneNumber) {
            alert('Por favor rellene todos los datos.')
            setIsLoading(false);
            return;
        }
        const phoneRegex = /^\d{10,11}$/;
        if (!phoneRegex.test(phoneNumber)) {
            alert('Por favor ingrese un número de teléfono de 10 digitos.');
            setIsLoading(false);
            return;
        }
        try {
            const usersCollection = firestore.collection('Users');
            const queryUser = usersCollection
                .where('identification_number', '==', cedula2)
                .limit(1);
            await queryUser.get().then((querySnapshot) => {
                if (querySnapshot.docs.length === 0) {
                    alert("No existe un usuario con esta cedula.");
                    setIsLoading(false);
                    return;
                } else {
                    querySnapshot.forEach(async (doc) => {

                        if (doc.exists) {
                            doc.ref.update({
                                phone_number: phoneNumber
                            });
                            setIsLoading(false);
                            alert('Numero telefonico cambiado exitosamente.');
                            return;
                        } else {
                            alert("No existe un usuario con esta cedula.");
                            setIsLoading(false);
                            return;
                        }
                    })
                }
            }).catch((error) => {
                alert('Ocurrió un problema, intente de nuevo más tarde.')
                setIsLoading(false);
            });
        } catch {
            alert('Ocurrió un problema, intente de nuevo más tarde.')
            setIsLoading(false);
        }
    }

    const changeCedula = async () => {
        setIsLoading(true);
        if (!newCedula || !oldCedula) {
            alert('Por favor rellene todos los datos.')
            setIsLoading(false);
            return;
        }
        try {
            const data = {
                identification_number: oldCedula.trim(),
                identification_number_new: newCedula.trim()
            }

            const response = await sendPostRequest(data, 'change_identification_number/', process.env.REACT_APP_API_TOKEN)

            if (response?.success) {
                alert('Cedula cambiada exitosamente')
            } else if (!response?.success) {
                alert('Error: ' + response.status)
            } else {
                alert('Ocurrio un problema intente mas tarde.')
            }
            setIsLoading(false);
        } catch (err) {
            alert('Error: ' + err.message)
            setIsLoading(false);
        }

    }

    const changeUserEmail = async () => {
        setIsLoading(true);
        if (!cedula || !email) {
            alert('Por favor rellene todos los datos.')
            setIsLoading(false);
            return;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            alert('Por favor ingrese un email válido.');
            setIsLoading(false);
            return;
        }
        try {
            const usersCollection = firestore.collection('Users');
            const paymentsCollection = firestore.collection('Payments');
            const devicesCollection = firestore.collection('Devices');
            const remindersCollection = firestore.collection('Reminders');
            const transactionsCollection = firestore.collection('Transactions');

            const queryUser = usersCollection
                .where('identification_number', '==', cedula.trim())
                .limit(1);
            await queryUser.get().then((querySnapshot) => {
                if (querySnapshot.docs.length === 0) {
                    alert("No existe un usuario con esta cedula.");
                    setIsLoading(false);
                    return;
                } else {
                    querySnapshot.forEach(async (doc) => {
                        const dataToClone = doc.data();

                        if (!dataToClone) {
                            alert("No existe un usuario con esta cedula.");
                            setIsLoading(false);
                            return;
                        }
                        if (doc.id === email) {
                            alert("El correo introducido es igual al anterior, por favor introduzca un correo distinto.");
                            setIsLoading(false);
                            return;
                        }

                        // Step 4: Write data to the new document in the destination collection
                        const destinationDocumentRef = firestore.collection('Users').doc(email.toLowerCase().trim());
                        destinationDocumentRef.get()
                            .then(async (doc1) => {
                                if (doc1.exists) {
                                    alert("El correo introducido ya existe, por favor elija otro correo.");
                                    setIsLoading(false);
                                    return;
                                } else {
                                    await doc1.ref.set(dataToClone);
                                    if (dataToClone['registration_status'] === "device_enrolled") {
                                        //change "user" field in Devices for this user
                                        const deviceQuery = devicesCollection
                                            .where('user', '==', usersCollection.doc(doc.id))

                                        await deviceQuery.get().then((querySnapshot) => {
                                            querySnapshot.forEach((doc) => {
                                                doc.ref.update({
                                                    user: usersCollection.doc(email.toLowerCase().trim())
                                                })
                                            })
                                        })
                                        //change payment "user" field to the new user
                                        const paymentQuery = paymentsCollection
                                            .where('user', '==', usersCollection.doc(doc.id))
                                        await paymentQuery.get().then((querySnapshot) => {
                                            querySnapshot.forEach((doc) => {
                                                doc.ref.update({
                                                    user: usersCollection.doc(email.toLowerCase().trim())
                                                })
                                            })
                                        })
                                        //change the Reminders "user" field to the new user
                                        const reminderQuery = remindersCollection
                                            .where('user', '==', usersCollection.doc(doc.id))
                                        await reminderQuery.get().then((querySnapshot) => {
                                            querySnapshot.forEach((doc) => {
                                                doc.ref.update({
                                                    user: usersCollection.doc(email.toLowerCase().trim())
                                                })
                                            })
                                        })
                                        const transactionQuery = transactionsCollection
                                            .where('user', '==', usersCollection.doc(doc.id))
                                        await transactionQuery.get().then((querySnapshot) => {
                                            querySnapshot.forEach((doc) => {
                                                doc.ref.update({
                                                    user: usersCollection.doc(email.toLowerCase().trim())
                                                })
                                            })
                                        })
                                        doc.ref.delete();
                                        alert('El correo se cambió correctamente!')
                                        setIsLoading(false);
                                    } else {
                                        doc.ref.delete();
                                        alert('El correo se cambió correctamente!')
                                        setIsLoading(false);
                                    }
                                }
                            })
                            .catch((error) => {
                                console.error('Error getting document:', error);
                            });
                    })
                }
            }).catch((error) => {
                alert('Ocurrió un problema, intente de nuevo más tarde.')
                setIsLoading(false);
            });
        } catch (error) {
            alert('Estamos teniendo problemas para conectarnos.');
            setIsLoading(false);
        }
    }

    const sendPostRequest = async (data, endpoint, token2) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token2
                    // 'Authorization': `Bearer ${await firebase.auth().currentUser.getIdToken(true)}`,
                    // 'x-master-store-email': userData.email,
                    // 'x-user-email': null
                },
                body: JSON.stringify({ ...data, country: userData.country, store_user: userData.email })
            });
            const responseData = await response.json();
            if (response.status === '401') {
                const tokenData = await requestToken()

                const resData = await sendPostRequest(data, endpoint, 'Token ' + tokenData.token)
                return resData
            }
            return responseData;
        } catch (error) {
            console.error('Error:', error.message);
            return null
        }
    };

    const requestToken = async () => {
        const loginData = {
            username: process.env.REACT_APP_TOKEN_USERNAME,
            password: process.env.REACT_APP_TOKEN_PASSWORD
        }
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/token/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(loginData)
            });

            const responseData = await response.json();
            return responseData
        } catch (error) {
            console.error('Error:', error);
        }
    };

    async function confirmStatus(index) {
        setIsLoading(true);
        const paymentsCollection = firestore.collection('Payments');
        const paymentRef = paymentsCollection.doc(index)

        paymentRef.get().then((doc) => {
            doc.ref.update({ status: latePayments.find(obj => obj.payment_id === index)['status_change'] }).then(async () => {
                alert('Estado editado exitosamente');
                setIsLoading(false);
            }).catch((error) => {
                alert('Ocurrio un error: ' + error.message);
                setIsLoading(false);
            });
        }).catch((error) => {
            alert('Ocurrio un error: ' + error.message);
            setIsLoading(false);
        });

    }

    async function confirmAmount(index) {
        if (confirmedAmount[index] == null) {
            alert("Debe colocar un monto para confirmar")
            return;
        }
        const result = window.confirm("Deseas confirmar este pago por $" + confirmedAmount[index] + "?")
        setIsLoading(true);
        if (result) {
            const paymentsCollection = firestore.collection('Payments');
            const usersCollection = firestore.collection('Users');

            var amountToUse = parseFloat(confirmedAmount[index])
            var amountToConfirm = paymentsToCheck[index].actualAmount >= amountToUse ? amountToUse : paymentsToCheck[index].actualAmount

            await paymentsCollection.doc(paymentsToCheck[index].id).get().then((doc) => {
                doc.ref.update({ amountPaid: amountToConfirm, amount_confirmed: amountToConfirm, paid: paymentsToCheck[index].actualAmount == amountToConfirm, checked_by_internal_team: paymentsToCheck[index].actualAmount == amountToConfirm })
            })

            amountToUse = amountToUse - amountToConfirm

            if (amountToUse > 0) {
                const quotient = Math.floor(amountToUse / paymentsToCheck[index].actualAmount);
                const remainder = amountToUse % paymentsToCheck[index].actualAmount;

                const query = paymentsCollection
                    .where('user', '==', usersCollection.doc(paymentsToCheck[index].user.id))
                    .where('paid', '==', false)
                    .where('is_active', '==', true)
                    .orderBy('dueDate', 'asc')
                    .limit(quotient + 1);
                query.get().then((querySnapshot) => {
                    var objectCount = quotient
                    querySnapshot.forEach(async (doc) => {
                        if (objectCount == 0) {
                            doc.ref.update({ amountPaid: remainder, amount_confirmed: remainder, paid: false, checked_by_internal_team: remainder == paymentsToCheck[index].actualAmount }).then(async () => {
                                alert('Pago confirmado exitosamente');
                            }).catch((error) => {
                                alert('Ocurrio un error, no pudimos confirmar el pago:' + error);
                            });
                        } else {
                            doc.ref.update({ amountPaid: paymentsToCheck[index].actualAmount, amount_confirmed: paymentsToCheck[index].actualAmount, paid: true, checked_by_internal_team: true }).then(async () => {
                                alert('Pago confirmado exitosamente');
                            }).catch((error) => {
                                alert('Ocurrio un error, no pudimos confirmar el pago:' + error);
                            });
                        }
                        objectCount -= 1;
                    });
                    alert('Pago confirmado exitosamente!')
                    setPaymentsToCheck([]);
                    setShowPaymentToCheck(false);
                    setIsLoading(false);
                    setCedula5('');
                }).catch((error) => {
                    alert('Ocurrio un error, no pudimos confirmar el pago:' + error);
                });
            } else {
                alert('Pago confirmado exitosamente!')
                setPaymentsToCheck([]);
                setShowPaymentToCheck(false);
                setIsLoading(false);
                setCedula5('');
            }
        } else {
            return;
        }
    }

    const alterConfirmedAmount = (index, newValue) => {
        const newArray = [...confirmedAmount]; // Create a shallow copy of the original array
        newArray[index] = newValue; // Modify the element at the specified index

        // Update the state with the new array
        setConfirmedAmount(newArray);
    }

    const alterStatus = (id, value) => {
        setLatePayments(prevData =>
            prevData.map(row =>
                row.payment_id === id ? { ...row, status_change: value, status: value } : row
            )
        );
    }

    // const displayPaymentsToCheck = () => {
    //     const obj = [];
    //     const paymToCheck = paymentsToCheck;
    //     paymToCheck.sort(compareDueDate);
    //     paymentsToCheck.forEach((item, index) => {
    //         obj.push(
    //             <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 15, paddingTop: 15 }}>
    //                 <span className={classes.textSmall5}>{item.dueDate}</span>
    //                 <span className={classes.textSmall5}>{item.id}</span>
    //                 <span className={classes.textSmall5}>{item.full_name}</span>
    //                 <span className={classes.textSmall5}>{item.cedula}</span>
    //                 <span className={classes.textSmall5}>{item.phone_number}</span>
    //                 <span className={classes.textSmall5}>{item.guarantor_phone_number}</span>
    //                 <span className={classes.textSmall5}>{item.actualAmount}</span>
    //                 <span className={classes.textSmall5}>{item.paid}</span>
    //                 <span className={classes.textSmall5}>{item.amount_confirmed ? item.amount_confirmed : 0}</span>
    //                 <span className={classes.textSmall5}>{item.confirmationNumber}</span>
    //                 <div style={{ alignItems: 'center' }}>
    //                     <TextField
    //                         label="Monto Confirmado"
    //                         name="confirmedAmount"
    //                         value={confirmedAmount[index]}
    //                         onChange={(e) => alterConfirmedAmount(index, e.target.value.toLowerCase().trim())}
    //                         id="outlined-number"
    //                         margin="normal"
    //                         className={classes.textFields}
    //                         fullWidth  InputProps={{
    // style: {
    //     color: 'white', // Text color
    //         borderBottom: '1px solid white'
    // },
    // }}
    // InputLabelProps = {{
    //     style: {
    //         color: '#D3D3D3', // Label color
    //                         },
    // }
    // }
    //                         outlined
    //                     //   error={loginError !== ''}
    //                     //   helperText={loginError}
    //                     />
    //                     <Button
    //                         variant="contained"
    //                         className={classes.welcomeButtons}
    //                         onClick={() => confirmAmount(index)}
    //                         style={{ marginTop: '10px', marginLeft: 20 }}
    //                     >
    //                         Enviar
    //                     </Button>
    //                 </div>
    //             </div>
    //         )
    //     })
    //     return obj;
    // }

    function convertToDotDecimal(str) {
        if (/^[+-]?(\d{1,3}(,\d{3})*|\d+)(,\d+)?$/.test(str)) {
            return str.replace(/,/g, ".");
        } else {
            return str;
        }
    }

    function formatForAPI(date) {
        // Format the date as yyyy/mm/dd
        const formattedDate = date.toLocaleString('en-CA', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            timeZone: 'America/New_York', // GMT-4 timezone (Eastern Time)
        }).replace(/\//g, '-'); // Replace slashes with dashes

        return formattedDate;
    }

    const searchTransaction = async () => {
        try {

            if (!reportedAmount || !paymentDate || !referenceNumber || !deviceID) {
                alert("Por favor rellene todos los datos")
                return;
            }
            setIsLoading(true);
            const data = {
                reference: referenceNumber.trim(),
                amount: convertToDotDecimal(reportedAmount),
                date: formatForAPI(paymentDate),
            }
            const response = await sendPostRequest(data, 'find_payment/', process.env.REACT_APP_API_TOKEN) // change for the righ enpoint name
            if (response?.success) {
                alert(response.status)
            } else if (!response?.success) {
                alert('Error: ' + response.status)
            } else {
                alert('Ocurrio un problema intente mas tarde.')
            }
            setIsLoading(false);
        } catch (err) {
            alert("Error: " + err.message)
        }
    }

    const users_cols = [
        { field: 'email', headerName: 'ID', width: 220, editable: false },
        { field: 'is_active', headerName: 'Activo', width: 200, editable: false },
        { field: 'registration_status', headerName: 'Estado de Registro', width: 220, editable: false },
        { field: 'max_loan_approved_3months', headerName: 'Monto Aprobado 3M', width: 220, editable: false },
        { field: 'totalPoints', headerName: 'Puntos Krece', width: 220, editable: false },
        { field: 'identification_number', headerName: 'Cedula', width: 210, editable: false },
        { field: 'phone_number', headerName: '# de Telefono', width: 210, editable: false },
        { field: 'interest_rate', headerName: 'Interes', width: 210, editable: false }
    ];

    const payments_cols = [
        { field: 'id', headerName: 'ID', width: 200, editable: false },
        { field: 'user', headerName: 'Usuario', width: 200, editable: false },
        { field: 'is_active', headerName: 'Activo', width: 80, editable: false },
        { field: 'actualAmount', headerName: 'Monto a Pagar', width: 120, editable: false },
        { field: 'amountPaid', headerName: 'Monto Pagado', width: 120, editable: false },
        { field: 'dueDate', headerName: 'Fecha de Pago', width: 200, editable: false },
        { field: 'paid', headerName: 'Pagado', width: 80, editable: false },
        { field: 'checked_by_internal_team', headerName: 'Chequeado por Krece', width: 200, editable: false },
        { field: 'confirmationNumber', headerName: 'Confirmation Number', width: 300, editable: false },
        { field: 'amount_confirmed', headerName: 'Amount Confirmed', width: 300, editable: false },
    ];

    const transactions_cols = [
        { field: 'id', headerName: 'ID', width: 200, editable: false },
        { field: 'user', headerName: 'Usuario', width: 200, editable: false },
        { field: 'paid_to', headerName: 'Pagado a', width: 200, editable: false },
        { field: 'related_payment', headerName: 'Cuota Relacionada', width: 250, editable: false },
        { field: 'amount', headerName: 'Monto Pagado', width: 200, editable: false },
        { field: 'date', headerName: 'Fecha pagado', width: 200, editable: false },
        { field: 'confirmation_number', headerName: 'Referencia Cash', width: 250, editable: false },
        { field: 'reference', headerName: 'Referencia PagoMovil/Transf.', width: 250, editable: false },
    ];

    const devices_cols = [
        { field: 'id', headerName: 'ID', width: 200, editable: false },
        { field: 'date_bought', headerName: 'Fecha de compra', width: 200, editable: false },
        { field: 'device_imei_no', headerName: 'Imei', width: 200, editable: false },
        { field: 'nuovopay_device_id', headerName: 'ID de equipo', width: 120, editable: false },
        { field: 'device_management_provider', headerName: 'Tipo de bloqueo', width: 110, editable: false },
        { field: 'device_manufacturer', headerName: 'Marca', width: 110, editable: false },
        { field: 'device_model', headerName: 'Modelo', width: 180, editable: false },
        { field: 'device_price', headerName: 'Precio', width: 80, editable: false },
        { field: 'amount_financed', headerName: 'Monto Financiado', width: 80, editable: false },
        { field: 'user', headerName: 'Email del Comprador', width: 200, editable: false },
        { field: 'is_released', headerName: 'Liberado', width: 80, editable: false },
        { field: 'is_stolen', headerName: 'Robado', width: 110, editable: false },
        { field: 'device_paid_by_krece', headerName: 'Pagado por Krece', width: 140, editable: false },
        { field: 'seller_store', headerName: 'Tienda', width: 200, editable: false },
        { field: 'sub_store', headerName: 'Local', width: 200, editable: false },
        { field: 'phone_number', headerName: 'Tlf de Recarga', width: 150, editable: false },
    ];

    const late_payments_cols = [
        { field: 'payment_id', headerName: 'ID', width: 220, editable: false },
        { field: 'dueDate', headerName: 'Fecha de Pago', width: 200, editable: true },
        { field: 'identification_number', headerName: 'Cedula', width: 210, editable: true },
        { field: 'first_name', headerName: 'Nombre', width: 200, editable: false },
        { field: 'last_name', headerName: 'Apellido', width: 200, editable: false },
        { field: 'phone_number', headerName: '# de Telefono', width: 210, editable: true },
        { field: 'actualAmount', headerName: 'Monto de Cuota', width: 120, editable: true },
        { field: 'amountPaid', headerName: 'Monto Pagado', width: 120, editable: true },
        { field: 'status', headerName: 'Estatus', width: 750, editable: true },
        {
            field: 'changeStatus',
            headerName: 'Cambiar Estatus',
            width: 400,
            renderCell: (params) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 15, paddingTop: 15, width: 1600 }}>
                        {/* <TextField
                            select
                            label="Estado"
                            name="status"
                            value={params.row.status_change}
                            onChange={(e) => alterStatus(params.payment_id, e.target.value)}
                            className={classes.textFields}
                            margin="normal"
                            fullWidth  InputProps={{
                            style: {
                                color: 'white', // Text color
                                borderBottom: '1px solid white'
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#D3D3D3', // Label color
                            },
                        }}
                        >
                            <MenuItem value="respuesta_concreta">respuesta_concreta</MenuItem>
                            <MenuItem value="celular_incorrecto">celular_incorrecto</MenuItem>
                            <MenuItem value="pagara_pagomovil">pagara_pagomovil</MenuItem>
                            <MenuItem value="pagara_tienda">pagara_tienda</MenuItem>
                            <MenuItem value="default">default</MenuItem>
                        </TextField> */}
                        <TextField
                            label="Estatus"
                            name="status_change"
                            value={params.row.status_change}
                            onChange={(e) => alterStatus(params.row.payment_id, e.target.value)}
                            className={classes.textFields}
                            margin="normal"
                            fullWidth InputProps={{
                                style: {
                                    color: 'white', // Text color
                                    borderBottom: '1px solid white'
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: '#D3D3D3', // Label color
                                },
                            }}
                            onKeyDown={(e) => {
                                if (e.key === ' ') {
                                    e.stopPropagation();
                                }
                            }}
                        ></TextField>
                        <Button
                            variant="contained"
                            className={classes.welcomeButtons}
                            onClick={() => confirmStatus(params.row.payment_id)}
                            style={{ marginTop: '10px', marginLeft: 20 }}
                        >
                            Enviar
                        </Button>
                    </div>
                );
            },
        },
    ];

    const registerIncorrectPayment = async () => {
        setIsLoading(true)
        if (!paymentDate2 || !reportedAmount2 || !referenceNumber2) {
            alert("Por favor rellene todos los datos");
            setIsLoading(false);
            return;
        }
        try {
            const data = {
                reference: referenceNumber2,
                amount: convertToDotDecimal(reportedAmount2),
                date: formatForAPI(paymentDate2),
            }
            const response = await sendPostRequest(data, 'register_incorrect_payment/', process.env.REACT_APP_API_TOKEN);
            if (response?.success) {
                alert('Registro Exitoso')
            } else if (!response?.success) {
                alert('Error: ' + response.status)
            } else {
                alert('Ocurrio un problema intente mas tarde.')
            }
            setIsLoading(false);
        } catch (err) {
            alert('Error: ' + err.message)
        }
    }

    const searchClientData = async () => {
        setIsLoading(true)
        setUsers([])
        setDevices([])
        setTransactions([])
        setPayments([])

        if (!searchKeyType || !searchKey) {
            alert("Por favor rellene todos los datos");
            setIsLoading(false);
            return;
        }
        try {
            const data = {
                KeyType: searchKeyType,
                KeyValue: searchKey.trim()
            }
            const response = await sendPostRequest(data, 'get_user_data/', process.env.REACT_APP_API_TOKEN);

            if (response?.success) {
                const responseData = JSON.parse(response.data.replaceAll('NaN', '""'));

                if (responseData.devices.length > 0) {
                    setDevices(responseData.devices)
                }
                if (responseData.payments.length > 0) {
                    setPayments(responseData.payments)
                }
                if (responseData.transactions.length > 0) {
                    setTransactions(responseData.transactions)
                }

                if (Object.keys(responseData.user).length === 0) {
                    setShowUsers(false)
                } else {
                    setShowUsers(true)
                    const totalPoints = (responseData.user['ontime_payments_ids']?.length + (2 * responseData.user['devices_paid']?.length) - responseData.user['late_payments_ids']?.length) * 10
                    setPoints(totalPoints)
                    responseData.user.totalPoints = totalPoints
                }
                setUsers([responseData.user])
                setShowUserData(true)
                setIsLoading(false);
            } else if (!response?.success) {
                alert("Error: " + response.status)
                setIsLoading(false);
            } else {
                alert("Ocurrio un problema intente mas tarde.")
            }
        } catch (err) {
            alert(err.message)
        }

    }

    const onStorePaymentClick = () => {
        setShowStorePayments(true);
        setShowPaymentsToCheck(false);
        setShowEdit(false);
        setShowDbObjects(false);
    }

    const onCheckPaymentsClick = () => {
        setShowStorePayments(false);
        setShowPaymentsToCheck(true);
        setShowEdit(false);
        setShowDbObjects(false);
    }

    const onDbObjClick = () => {
        setShowStorePayments(false);
        setShowPaymentsToCheck(false);
        setShowEdit(false);
        setShowDbObjects(true);
    }

    const onEditClick = () => {
        setShowStorePayments(false);
        setShowPaymentsToCheck(false);
        setShowEdit(true);
        setShowDbObjects(false);
    }

    const searchDelinquentUser = async () => {
        try {
            if (!cedula6) {
                alert('Por favor rellene la cedula para continuar')
                return;
            }
            setIsLoading(true)
            const data = {
                identification_number: cedula6
            }
            const response = await sendPostRequest(data, 'search_delinquent_payment/', process.env.REACT_APP_API_TOKEN)

            if (response?.success) {
                const dat = JSON.parse(response.data);
                setSearchedData(dat[0]);
            } else if (!response?.success) {
                alert('Error: ' + response.status)
            } else {
                alert('Ocurrio un problema intente mas tarde.')
            }
            setIsLoading(false)
        } catch (err) {
            alert('No se encontro ninguna ficha');
            setIsLoading(false);
        }
    }

    const submitSearchedCase = async () => {
        setIsLoading(true);
        if (clientResponded2 === null || !followUpDate2) {
            alert('Por favor rellene todos los datos para continuar');
            return;
        }
        const folDate = new Date(followUpDate2)
        folDate.setDate(folDate.getDate() + 1)
        const docRef = firestore.collection('Users').doc(searchedData.email)
        await docRef.update({
            cobranza_owner: userData.email,
            last_modified_date: firebase.firestore.Timestamp.fromDate(new Date()),
            next_follow_up_date: firebase.firestore.Timestamp.fromDate(folDate),
            unreachable: !clientResponded,
            comments: firebase.firestore.FieldValue.arrayUnion(clientStatus2)
        });
        setSearchedData(false);
        alert("Data guardada exitosamente.")
        setIsLoading(false);
    }

    const oldOpButtonClicked = async () => {
        try {
            setIsLoading(true)
            setOperationType('old');
            setClientResponded('');
            setClientStatus('');
            if (oldClientData.length === 0) {
                //to-do make API call to get the new client to manage
                // const data = [{ payment_id: "74AJJbe4Ys5cLiDNrFvu", dueDate: '12/12/2023', birth_date: '12/12/1997', amountPaid: 0, actualAmount: 13.0, related_store: "info@movistargtm.com", cobranza_owner: null, payment_status: "No Existe", first_modified_date: "No Existe", last_modified_date: "No Existe", next_follow_up_date: "No Existe", called_answered: "No Existe", email: "avendanodenire@gmail.com", first_name: "CHRISTHIAN", last_name: "LEON", identification_number: "28660207", phone_number: "04242404215" }]
                // setOldClientData(data[0]);
                // setDataToShow(data[0]);
                const data = {
                    cobranza_owner: userData.email
                }
                const response = await sendPostRequest(data, 'get_next_follow_up/', process.env.REACT_APP_API_TOKEN)

                if (response?.success) {
                    const dat = JSON.parse(response.data);
                    setOldClientData(dat[0]);
                    setDataToShow(dat[0]);
                } else if (!response?.success) {
                    alert('Error: ' + response.status);
                } else {
                    alert('Ocurrio un problema intente mas tarde.');
                }
                setIsLoading(false);
            } else {
                setDataToShow(oldClientData);
                setIsLoading(false);
            }
        } catch {
            alert('No se encontro ninguna ficha');
            setIsLoading(false);
        }

    }

    const newOpButtonClicked = async () => {
        try {
            setIsLoading(true)
            setOperationType('new');
            setClientResponded('');
            setClientStatus('');
            if (newClientData.length === 0) {
                //to-do make API call to get the new client to manage
                const data = {
                    cobranza_owner: userData.email,
                    delta_days: userData.delta_days ? userData.delta_days : 15 // revisar esto, cambiar basado en RD vs. Venezuela
                }
                const response = await sendPostRequest(data, 'get_next_delinquent_payment/', process.env.REACT_APP_API_TOKEN)
                if (response?.success) {
                    const dat = JSON.parse(response.data);
                    setNewClientData(dat[0]);
                    setDataToShow(dat[0]);
                } else if (!response?.success) {
                    alert('Error: ' + response.status)
                } else {
                    alert('Ocurrio un problema intente mas tarde.')
                }
                setIsLoading(false)
            } else {
                setDataToShow(newClientData);
                setIsLoading(false)
            }
        } catch {
            alert('No se encontro ninguna ficha');
            setIsLoading(false);
        }

    }

    const showBankAccountInfo = async (idx) => {
        if (dataToShow.bank_details) {
            alert("Transferencia: \nTipo: " + dataToShow.bank_details[idx].transferBs.type + "\nRif: " + dataToShow.bank_details[idx].transferBs.rif + "\n#Cuenta: " + dataToShow.bank_details[idx].transferBs.account + "\nBanco: " + dataToShow.bank_details[idx].transferBs.bank + "\nTitular: " + dataToShow.bank_details[idx].transferBs.titular + "\n\n" + "PagoMovil: \nBanco: " + dataToShow.bank_details[idx].pagomovil.bank + "\nRif: " + dataToShow.bank_details[idx].pagomovil.rif + "\n#Telefono: " + dataToShow.bank_details[idx].pagomovil.phoneNumber)
        } else {
            alert("Datos no disponibles")
        }

    }

    const showBankAccountInfo2 = async (idx) => {
        if (searchedData.bank_details) {
            alert("Transferencia: \nTipo: " + searchedData.bank_details[idx].transferBs.type + "\nRif: " + searchedData.bank_details[idx].transferBs.rif + "\n#Cuenta: " + searchedData.bank_details[idx].transferBs.account + "\nBanco: " + searchedData.bank_details[idx].transferBs.bank + "\nTitular: " + searchedData.bank_details[idx].transferBs.titular + "\n\n" + "PagoMovil: \nBanco: " + searchedData.bank_details[idx].pagomovil.bank + "\nRif: " + searchedData.bank_details[idx].pagomovil.rif + "\n#Telefono: " + searchedData.bank_details[idx].pagomovil.phoneNumber)
        } else {
            alert("Datos no disponibles")
        }

    }

    const resetPassword = async () => {
        setIsLoading(true);
        if (!email2) {
            alert('Por favor rellene todos los datos.')
            setIsLoading(false);
            return;
        }
        try {
            const data = {
                email: email2.trim()
            }
            const response = await sendPostRequest(data, 'change_user_password/', process.env.REACT_APP_API_TOKEN)

            if (response?.success) {
                alert(response.status)
            } else if (!response?.success) {
                alert('Error: ' + response.status)
            } else {
                alert('Ocurrio un problema intente mas tarde.')
            }
            setIsLoading(false);
        } catch (err) {
            alert('Error: ' + err.message)
            setIsLoading(false);
        }

    }

    const changeDeviceName = async () => {
        setIsLoading(true);
        if (!newDeviceName || !deviceID) {
            alert('Por favor rellene todos los datos.')
            setIsLoading(false);
            return;
        }
        try {
            const data = {
                device_id: deviceID,
                device_commercial_name: newDeviceName
            }
            const response = await sendPostRequest(data, 'change_device_commercial_name/', process.env.REACT_APP_API_TOKEN)

            if (response?.success) {
                alert(response.status)
            } else if (!response?.success) {
                alert('Error: ' + response.status)
            } else {
                alert('Ocurrio un problema intente mas tarde.')
            }
            setIsLoading(false);
        } catch (err) {
            alert('Error: ' + err.message)
            setIsLoading(false);
        }
    }

    const getStoreTransactions = async () => {
        try {
            if (!reportedAmount || !paymentDate || !storeEmail) {
                alert("Por favor rellene todos los datos")
                return;
            }
            setIsLoading(true);
            const data = {
                store_email: storeEmail.trim(),
                amount: convertToDotDecimal(reportedAmount),
                date: formatForAPI(paymentDate),
            }
            const response = await sendPostRequest(data, 'find_payment_based_on_price/', process.env.REACT_APP_API_TOKEN) // change for the righ enpoint name
            if (response?.success) {
                alert(response.status + ". Se encontraron los siguientes números de referencia: " + JSON.stringify(response.data.map(obj => obj.reference)))
            } else if (!response?.success) {
                alert('Error: ' + response.status)
            } else {
                alert('Ocurrio un problema intente mas tarde.')
            }
            setIsLoading(false);
        } catch (err) {
            alert("Error: " + err.message)
        }
    }

    const changeFirstName = async () => {
        setIsLoading(true);
        if (!firstName || !cedula2) {
            alert('Por favor rellene todos los datos.')
            setIsLoading(false);
            return;
        }
        try {
            const data = {
                identification_number: cedula2,
                first_name: firstName.trim()
            }
            const response = await sendPostRequest(data, 'change_user_name/', process.env.REACT_APP_API_TOKEN)

            if (response?.success) {
                alert(response.status)
            } else if (!response?.success) {
                alert('Error: ' + response.status)
            } else {
                alert('Ocurrio un problema intente mas tarde.')
            }
            setIsLoading(false);
        } catch (err) {
            alert('Error: ' + err.message)
            setIsLoading(false);
        }
    }

    const changeLastName = async () => {
        setIsLoading(true);
        if (!lastName || !cedula2) {
            alert('Por favor rellene todos los datos.')
            setIsLoading(false);
            return;
        }
        try {
            const data = {
                identification_number: cedula2,
                last_name: lastName.trim()
            }
            const response = await sendPostRequest(data, 'change_user_name/', process.env.REACT_APP_API_TOKEN)

            if (response?.success) {
                alert(response.status)
            } else if (!response?.success) {
                alert('Error: ' + response.status)
            } else {
                alert('Ocurrio un problema intente mas tarde.')
            }
            setIsLoading(false);
        } catch (err) {
            alert('Error: ' + err.message)
            setIsLoading(false);
        }
    }

    const deleteAccount = async () => {
        setIsLoading(true);
        if (!cedula7) {
            alert('Por favor rellene todos los datos.')
            setIsLoading(false);
            return;
        }
        try {
            const data = {
                identification_number: cedula7
            }
            const response = await sendPostRequest(data, 'delete_user/', process.env.REACT_APP_API_TOKEN)

            if (response?.success) {
                alert(response.status)
            } else if (!response?.success) {
                alert('Error: ' + response.status)
            } else {
                alert('Ocurrio un problema intente mas tarde.')
            }
            setIsLoading(false);
        } catch (err) {
            alert('Error: ' + err.message)
            setIsLoading(false);
        }
    }

    const getNextCase = async (type) => {

        if (clientResponded === null || !followUpDate) {
            alert('Por favor rellene todos los datos para continuar');
            return;
        }
        setIsLoading(true);
        try {
            const folDate = new Date(followUpDate)
            folDate.setDate(folDate.getDate() + 1)
            const docRef = firestore.collection('Users').doc(dataToShow.email)
            if (type === 'new') {
                docRef.update({
                    first_modified_date: firebase.firestore.Timestamp.fromDate(new Date()),
                    cobranza_owner: userData.email,
                    last_modified_date: firebase.firestore.Timestamp.fromDate(new Date()),
                    next_follow_up_date: firebase.firestore.Timestamp.fromDate(folDate),
                    unreachable: clientResponded,
                    comments: firebase.firestore.FieldValue.arrayUnion(clientStatus)
                });
                const data = {
                    cobranza_owner: userData.email,
                    delta_days: userData.delta_days ? userData.delta_days : 15 //revisar esto, cambiar basado en RD vs. Venezuela
                }
                const response = await sendPostRequest(data, 'get_next_delinquent_payment/', process.env.REACT_APP_API_TOKEN)

                if (response?.success) {
                    const dat = JSON.parse(response.data);
                    setNewClientData(dat[0]);
                    setDataToShow(dat[0]);
                    setClientResponded('');
                    setClientStatus('');
                    setFollowUpDate('');
                } else if (!response?.success) {
                    alert('Error: ' + response.status)
                } else {
                    alert('Ocurrio un problema intente mas tarde.')
                }
                setIsLoading(false)
            } else {
                docRef.update({
                    cobranza_owner: userData.email,
                    last_modified_date: firebase.firestore.Timestamp.fromDate(new Date()),
                    next_follow_up_date: firebase.firestore.Timestamp.fromDate(folDate),
                    unreachable: clientResponded,
                    comments: firebase.firestore.FieldValue.arrayUnion(clientStatus)
                });
                const data = {
                    cobranza_owner: userData.email
                }
                const response = await sendPostRequest(data, 'get_next_follow_up/', process.env.REACT_APP_API_TOKEN)

                if (response?.success) {
                    const dat = JSON.parse(response.data);
                    setOldClientData(dat[0]);
                    setDataToShow(dat[0]);
                    setClientResponded('');
                    setClientStatus('');
                    setFollowUpDate('');
                } else if (!response?.success) {
                    alert('Error: ' + response.status)
                } else {
                    alert('Ocurrio un problema intente mas tarde.')
                }
                setIsLoading(false)
            }
        } catch (err) {
            alert('Error: ' + err.message)
            setIsLoading(false);
        }

    }

    const formatDate = (timestamp) => {
        const date = timestamp.toDate(); // Convert Firestore timestamp to JavaScript Date object
        const month = date.toLocaleDateString('es-ES', { month: 'short' }); // Get the short month name
        const day = date.getDate(); // Get the day of the 
        return `${day.toString().length == 1 ? '0' : ''}${day} de ${month.charAt(0).toUpperCase() + month.slice(1)}`;
    };

    if (isLoading || !userData) {
        return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 1000, marginTop: 100 }}>
            <BeatLoader color="#45C4D6" />
        </div>
    }

    if (userData?.access_type === 'ata') {
        return (
            <div>
                <AppBar position="static" style={{ backgroundColor: '#2A2E32' }}>
                    <Toolbar style={{ backgroundColor: '#2A2E32', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'flex-end', paddingBottom: 15, paddingTop: 15 }}>
                        <img className="w-64" src={image} alt="Krece logo" />
                        <Typography variant="h5" align="center" style={{ color: '#45C4D6' }}>
                            {userData.store_name}
                        </Typography>
                        <Button2
                            loading={isLoading}
                            onClick={() => {
                                setIsLoading(true);
                                logout();
                            }}
                            className="flex items-center justify-center gap-1 rounded-lg !border-[2px] !border-neutral-800 bg-neutral-900/50 p-1.5 text-sm text-neutral-200 transition-colors hover:bg-neutral-900"
                        >
                            Salir
                            <LogOut className="size-4" strokeWidth={2.5} />
                        </Button2>
                    </Toolbar>
                </AppBar>
                <AdminTable />
            </div>
        )
    }

    return (
        <div>
            <nav className="sticky top-0 z-40 flex w-full items-center justify-between bg-[#2A2E32] px-8 py-5 shadow-lg">
                <Logo className="w-56" />
                <h1 className="text-2xl font-semibold text-primary-400 tracking-tight">
                    {userData.store_name}
                </h1>
                <div className="flex items-center justify-center gap-2">
                    <Link
                        to={'/buscarcliente'}
                        className="flex items-center justify-center gap-2 rounded-lg border-2 border-neutral-800 bg-neutral-900/80 px-4 py-2 text-sm font-medium text-neutral-200 transition-all hover:border-neutral-700 hover:bg-neutral-800 hover:text-white active:scale-95"
                    >
                        <Search className="size-4" strokeWidth={2} />
                        Buscar Cliente
                    </Link>
                    <Button2
                            loading={isLoading}
                            onClick={() => {
                                setIsLoading(true);
                                logout();
                            }}
                            className="flex items-center justify-center gap-1 rounded-lg !border-[2px] !border-neutral-800 bg-neutral-900/50 p-1.5 text-sm text-neutral-200 transition-colors hover:bg-neutral-900"
                        >
                            Salir
                        <LogOut className="size-4" strokeWidth={2.5} />
                    </Button2>
                </div>
            </nav>
            <div className='p-6'>
                <MenuButton showPaymentBut={false} showIcon={false} onItem1Click={onStorePaymentClick} label1={userData.access_type === 'atc' ? "" : "Pagos en Mora"} onItem2Click={onCheckPaymentsClick} label2={userData.access_type === 'atc' ? "Pagos por verificar" : ""} onItem6Click={onDbObjClick} label6={"Base de datos"} onItem5Click={onEditClick} label5={userData.access_type === 'atc' ? 'Acciones administrativas' : ""} />
                {showDbObjects && <div>
                    <h2 className={classes.title} style={{ marginTop: 20 }}>
                        Buscar data de cliente
                    </h2>
                    <TextField
                        select
                        label="Buscar por"
                        name="searchKeyType"
                        value={searchKeyType}
                        onChange={(e) => setSearchKeyType(e.target.value)}
                        className={classes.textFields}
                        margin="normal"
                        fullWidth InputProps={{
                            style: {
                                color: 'white', // Text color
                                borderBottom: '1px solid white'
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#D3D3D3', // Label color
                            },
                        }}
                    >
                        <MenuItem value="identification_number">Cedula</MenuItem>
                        <MenuItem value="email">Email</MenuItem>
                        <MenuItem value="phone_number">#Teléfono</MenuItem>
                        <MenuItem value="imei_no">IMEI</MenuItem>
                        <MenuItem value="reference">Nro. de referencia</MenuItem>
                    </TextField>
                    <TextField
                        label={searchKeyType}
                        name="searchKey"
                        value={searchKey}
                        onChange={(e) => setSearchKey(e.target.value)}
                        className={classes.textFields}
                        margin="normal"
                        fullWidth InputProps={{
                            style: {
                                color: 'white', // Text color
                                borderBottom: '1px solid white'
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#D3D3D3', // Label color
                            },
                        }}
                    />
                    <Button
                        variant="contained"
                        className={classes.welcomeButtons}
                        onClick={searchClientData}
                        style={{ marginTop: '10px', marginLeft: 20 }}
                    >
                        Buscar
                    </Button>
                    {showUserData && <div>
                        {showUsers && <><h2 className={classes.title}>
                            Usuario
                        </h2>
                            <DataGrid
                                rows={users}
                                getRowId={(row) => row.identification_number}
                                columns={users_cols}
                                autoHeight
                                initialState={{
                                    pagination: {
                                        paginationModel: { page: 0, pageSize: 5 },
                                    },
                                }}
                                pageSizeOptions={[5, 10]}
                                getCellClassName={() => classes.cell}
                                sx={{
                                    boxShadow: 2,
                                    borderColor: '#2A2E32',
                                    "& .MuiDataGrid-columnHeaders": { backgroundColor: "#2A2E32", color: "white" },
                                    "& .MuiDataGrid-menuIconButton": { color: "white" },
                                    "& .MuiDataGrid-sortIcon": { color: "white" },
                                    "& .MuiTablePagination-root": {
                                        color: "white", // Change pagination text color
                                    },
                                    "& .MuiTablePagination-actions .MuiIconButton-root": {
                                        color: "white", // Change color of the pagination arrow buttons
                                    },
                                    "& .MuiTablePagination-actions .MuiSvgIcon-root": {
                                        fill: "white", // Change the fill color of the SVG icons (arrows)
                                    },
                                }}
                            /></>}
                        <h2 className={classes.title}>
                            Equipos
                        </h2>
                        <DataGrid
                            rows={devices}
                            columns={devices_cols}
                            autoHeight
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                },
                            }}
                            pageSizeOptions={[5, 10]}
                            getCellClassName={() => classes.cell}
                            sx={{
                                boxShadow: 2,
                                borderColor: '#2A2E32',
                                "& .MuiDataGrid-columnHeaders": { backgroundColor: "#2A2E32", color: "white" },
                                "& .MuiDataGrid-menuIconButton": { color: "white" },
                                "& .MuiDataGrid-sortIcon": { color: "white" },
                                "& .MuiTablePagination-root": {
                                    color: "white", // Change pagination text color
                                },
                                "& .MuiTablePagination-actions .MuiIconButton-root": {
                                    color: "white", // Change color of the pagination arrow buttons
                                },
                                "& .MuiTablePagination-actions .MuiSvgIcon-root": {
                                    fill: "white", // Change the fill color of the SVG icons (arrows)
                                },
                            }}
                        />
                        <h2 className={classes.title}>
                            Cuotas
                        </h2>
                        <DataGrid
                            rows={payments}
                            columns={payments_cols}
                            autoHeight
                            getCellClassName={() => classes.cell}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                },
                            }}
                            pageSizeOptions={[5, 10]}
                            sx={{
                                boxShadow: 2,
                                borderColor: '#2A2E32',
                                "& .MuiDataGrid-columnHeaders": { backgroundColor: "#2A2E32", color: "white" },
                                "& .MuiDataGrid-menuIconButton": { color: "white" },
                                "& .MuiDataGrid-sortIcon": { color: "white" },
                                "& .MuiTablePagination-root": {
                                    color: "white", // Change pagination text color
                                },
                                "& .MuiTablePagination-actions .MuiIconButton-root": {
                                    color: "white", // Change color of the pagination arrow buttons
                                },
                                "& .MuiTablePagination-actions .MuiSvgIcon-root": {
                                    fill: "white", // Change the fill color of the SVG icons (arrows)
                                },
                            }}
                        />
                        <h2 className={classes.title}>
                            Transacciones
                        </h2>
                        <DataGrid
                            rows={transactions}
                            columns={transactions_cols}
                            autoHeight
                            getCellClassName={() => classes.cell}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                },
                            }}
                            pageSizeOptions={[5, 10]}
                            sx={{
                                boxShadow: 2,
                                borderColor: '#2A2E32',
                                "& .MuiDataGrid-columnHeaders": { backgroundColor: "#2A2E32", color: "white" },
                                "& .MuiDataGrid-menuIconButton": { color: "white" },
                                "& .MuiDataGrid-sortIcon": { color: "white" },
                                "& .MuiTablePagination-root": {
                                    color: "white", // Change pagination text color
                                },
                                "& .MuiTablePagination-actions .MuiIconButton-root": {
                                    color: "white", // Change color of the pagination arrow buttons
                                },
                                "& .MuiTablePagination-actions .MuiSvgIcon-root": {
                                    fill: "white", // Change the fill color of the SVG icons (arrows)
                                },
                            }}
                        />
                    </div>}
                </div>}
                {showStorePayments && <div>
                    <h2 className={classes.title}>
                        Pagos en Mora
                    </h2>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 5 }}>
                        <div>
                            <Button
                                variant="contained"
                                onClick={newOpButtonClicked} //newOpButtonClicked
                                className={classes.welcomeButtons}
                                style={{ marginLeft: 20, backgroundColor: operationType === 'new' ? 'gray' : '#45C4D6' }}
                            >
                                Nueva Gestión
                            </Button>
                            <Button
                                variant="contained"
                                className={classes.welcomeButtons}
                                onClick={oldOpButtonClicked}
                                style={{ marginLeft: 20, backgroundColor: operationType === 'old' ? 'gray' : '#45C4D6' }}
                            >
                                Seguimiento
                            </Button>
                            {dataToShow && (
                                <div className={classes.border}>
                                    <h3 className={classes.title} style={{ marginLeft: 0 }}>
                                        {dataToShow.first_name + " " + dataToShow.last_name}
                                    </h3>
                                    <h3 style={{ marginTop: 20, fontSize: 12, color: 'gray', fontWeight: 'bold' }}>
                                        #Teléfono
                                    </h3>
                                    <h3 className={classes.textSmall6}>
                                        {dataToShow.phone_number}
                                    </h3>
                                    <h3 style={{ marginTop: 20, fontSize: 12, color: 'gray', fontWeight: 'bold' }}>
                                        Cédula
                                    </h3>
                                    <h3 className={classes.textSmall6}>
                                        {dataToShow.identification_number}
                                    </h3>
                                    <h3 style={{ marginTop: 20, fontSize: 12, color: 'gray', fontWeight: 'bold' }}>
                                        Fecha de nacimiento
                                    </h3>
                                    <h3 className={classes.textSmall6}>
                                        {dataToShow.birth_date}
                                    </h3>
                                    <h3 style={{ marginTop: 20, fontSize: 12, color: 'gray', fontWeight: 'bold' }}>
                                        Correo
                                    </h3>
                                    <h3 className={classes.textSmall6}>
                                        {dataToShow.email}
                                    </h3>
                                    <h3 style={{ marginTop: 20, fontSize: 12, color: 'gray', fontWeight: 'bold' }}>
                                        Cuotas Vencidas
                                    </h3>
                                    <div className={classes.border2}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 5 }}>
                                            <h3 style={{ marginTop: 20, fontSize: 12, color: 'gray', fontWeight: 'bold' }}>
                                                Cuota Venció
                                            </h3>
                                            <h3 style={{ marginTop: 20, fontSize: 12, color: 'gray', fontWeight: 'bold' }}>
                                                Monto de cuota
                                            </h3>
                                            <h3 style={{ marginTop: 20, fontSize: 12, color: 'gray', fontWeight: 'bold' }}>
                                                Monto Pagado
                                            </h3>
                                            <h3 style={{ marginTop: 20, fontSize: 12, color: 'gray', fontWeight: 'bold' }}>
                                                Mostrar datos bancarios
                                            </h3>
                                        </div>
                                        {dataToShow.dueDate.map((item, index) => (
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 10, paddingTop: 10 }}>
                                                <h3 className={classes.textSmall6}>
                                                    {dataToShow.dueDate[index]}
                                                </h3>
                                                <h3 className={classes.textSmall6}>
                                                    {dataToShow.actualAmount[index]}
                                                </h3>
                                                <h3 className={classes.textSmall6}>
                                                    {dataToShow.amountPaid[index]}
                                                </h3>
                                                <div style={{ width: 80 }}>
                                                    <Button
                                                        variant="contained"
                                                        className={classes.welcomeButtons}
                                                        onClick={() => showBankAccountInfo(index)}
                                                        fullWidth InputProps={{
                                                            style: {
                                                                color: 'white', // Text color
                                                                borderBottom: '1px solid white'
                                                            },
                                                        }}
                                                        InputLabelProps={{
                                                            style: {
                                                                color: '#D3D3D3', // Label color
                                                            },
                                                        }}
                                                    >
                                                        Mostrar
                                                    </Button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <h3 style={{ marginTop: 20, fontSize: 12, color: 'gray', fontWeight: 'bold' }}>
                                        Tienda de compra
                                    </h3>
                                    <h3 className={classes.textSmall6}>
                                        {dataToShow.related_store}
                                    </h3>
                                    <h3 style={{ marginTop: 20, fontSize: 12, color: 'gray', fontWeight: 'bold' }}>
                                        Comentarios
                                    </h3>
                                    {Array.isArray(dataToShow.comments) && dataToShow.comments.map((item, index) => (
                                        <h3 className={classes.textSmall6}>
                                            {item}
                                        </h3>
                                    ))}
                                    <h3 style={{ marginTop: 20, fontSize: 12, color: 'gray', fontWeight: 'bold' }}>
                                        Fecha de última gestion
                                    </h3>
                                    <h3 className={classes.textSmall6}>
                                        {dataToShow.last_modified_date ? dataToShow.last_modified_date : ""}
                                    </h3>
                                    <TextField
                                        select
                                        label="Cliente contestó la llamada?"
                                        name="clientRespondedOld"
                                        value={clientResponded}
                                        onChange={(e) => setClientResponded(e.target.value)}
                                        className={classes.textFields}
                                        margin="normal"
                                        fullWidth InputProps={{
                                            style: {
                                                color: 'white', // Text color
                                                borderBottom: '1px solid white'
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color: '#D3D3D3', // Label color
                                            },
                                        }}
                                    >
                                        <MenuItem value={true}>Si contestó</MenuItem>
                                        <MenuItem value={false}>No contestó</MenuItem>
                                    </TextField>
                                    <TextField
                                        label="Comentarios"
                                        name="clientStatus"
                                        value={clientStatus}
                                        onChange={(e) => setClientStatus(e.target.value)}
                                        className={classes.textFields}
                                        margin="normal"
                                        fullWidth InputProps={{
                                            style: {
                                                color: 'white', // Text color
                                                borderBottom: '1px solid white'
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color: '#D3D3D3', // Label color
                                            },
                                        }}
                                    >
                                    </TextField>
                                    <TextField
                                        label="Fecha de Seguimiento"
                                        name="followUpDate"
                                        type="date"
                                        value={followUpDate}
                                        onChange={(e) => setFollowUpDate(e.target.value)}
                                        className={classes.textFields}
                                        margin="normal"
                                        fullWidth InputProps={{
                                            style: {
                                                color: 'white', // Text color
                                                borderBottom: '1px solid white'
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color: '#D3D3D3', // Label color
                                            },
                                        }}
                                    />
                                    <Button
                                        variant="contained"
                                        className={classes.welcomeButtons}
                                        onClick={() => getNextCase(operationType)}
                                        fullWidth InputProps={{
                                            style: {
                                                color: 'white', // Text color
                                                borderBottom: '1px solid white'
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color: '#D3D3D3', // Label color
                                            },
                                        }}
                                    >
                                        Siguiente Caso
                                    </Button>
                                </div>
                            )}
                        </div>
                        <div>
                            <TextField
                                label="Cedula"
                                name="cedula6"
                                value={cedula6}
                                onChange={(e) => setCedula6(e.target.value)}
                                className={classes.textFields}
                                style={{ marginLeft: 20 }}
                                margin="normal"
                                fullWidth InputProps={{
                                    style: {
                                        color: 'white', // Text color
                                        borderBottom: '1px solid white'
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#D3D3D3', // Label color
                                    },
                                }}
                            />
                            <Button
                                variant="contained"
                                onClick={searchDelinquentUser} //newOpButtonClicked
                                className={classes.welcomeButtons}
                                style={{ marginLeft: 20, backgroundColor: operationType === 'new' ? 'gray' : '#45C4D6' }}
                            >
                                Buscar
                            </Button>
                            {searchedData && (
                                <div className={classes.border}>
                                    <h3 className={classes.title} style={{ marginLeft: 0 }}>
                                        {searchedData.first_name + " " + searchedData.last_name}
                                    </h3>
                                    <h3 style={{ marginTop: 20, fontSize: 12, color: 'gray', fontWeight: 'bold' }}>
                                        #Teléfono
                                    </h3>
                                    <h3 className={classes.textSmall6}>
                                        {searchedData.phone_number}
                                    </h3>
                                    <h3 style={{ marginTop: 20, fontSize: 12, color: 'gray', fontWeight: 'bold' }}>
                                        Cédula
                                    </h3>
                                    <h3 className={classes.textSmall6}>
                                        {searchedData.identification_number}
                                    </h3>
                                    <h3 style={{ marginTop: 20, fontSize: 12, color: 'gray', fontWeight: 'bold' }}>
                                        Fecha de nacimiento
                                    </h3>
                                    <h3 className={classes.textSmall6}>
                                        {searchedData.birth_date}
                                    </h3>
                                    <h3 style={{ marginTop: 20, fontSize: 12, color: 'gray', fontWeight: 'bold' }}>
                                        Correo
                                    </h3>
                                    <h3 className={classes.textSmall6}>
                                        {searchedData.email}
                                    </h3>
                                    <h3 style={{ marginTop: 20, fontSize: 12, color: 'gray', fontWeight: 'bold' }}>
                                        Cuotas Vencidas
                                    </h3>
                                    <div className={classes.border2}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 5 }}>
                                            <h3 style={{ marginTop: 20, fontSize: 12, color: 'gray', fontWeight: 'bold' }}>
                                                Cuota Venció
                                            </h3>
                                            <h3 style={{ marginTop: 20, fontSize: 12, color: 'gray', fontWeight: 'bold' }}>
                                                Monto de cuota
                                            </h3>
                                            <h3 style={{ marginTop: 20, fontSize: 12, color: 'gray', fontWeight: 'bold' }}>
                                                Monto Pagado
                                            </h3>
                                            <h3 style={{ marginTop: 20, fontSize: 12, color: 'gray', fontWeight: 'bold' }}>
                                                Mostrar datos bancarios
                                            </h3>
                                        </div>
                                        {searchedData.dueDate.map((item, index) => (
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 10, paddingTop: 10 }}>
                                                <h3 className={classes.textSmall6}>
                                                    {searchedData.dueDate[index]}
                                                </h3>
                                                <h3 className={classes.textSmall6}>
                                                    {searchedData.actualAmount[index]}
                                                </h3>
                                                <h3 className={classes.textSmall6}>
                                                    {searchedData.amountPaid[index]}
                                                </h3>
                                                <div style={{ width: 80 }}>
                                                    <Button
                                                        variant="contained"
                                                        className={classes.welcomeButtons}
                                                        onClick={() => showBankAccountInfo2(index)}
                                                        fullWidth InputProps={{
                                                            style: {
                                                                color: 'white', // Text color
                                                                borderBottom: '1px solid white'
                                                            },
                                                        }}
                                                        InputLabelProps={{
                                                            style: {
                                                                color: '#D3D3D3', // Label color
                                                            },
                                                        }}
                                                    >
                                                        Mostrar
                                                    </Button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <h3 style={{ marginTop: 20, fontSize: 12, color: 'gray', fontWeight: 'bold' }}>
                                        Tienda de compra
                                    </h3>
                                    <h3 className={classes.textSmall6}>
                                        {searchedData.related_store}
                                    </h3>
                                    <h3 style={{ marginTop: 20, fontSize: 12, color: 'gray', fontWeight: 'bold' }}>
                                        Comentarios
                                    </h3>
                                    {Array.isArray(searchedData.comments) && searchedData.comments.map((item, index) => (
                                        <h3 className={classes.textSmall6}>
                                            {item}
                                        </h3>
                                    ))}
                                    <h3 style={{ marginTop: 20, fontSize: 12, color: 'gray', fontWeight: 'bold' }}>
                                        Fecha de última gestion
                                    </h3>
                                    <h3 className={classes.textSmall6}>
                                        {searchedData.last_modified_date ? searchedData.last_modified_date : ""}
                                    </h3>
                                    <TextField
                                        select
                                        label="Cliente contestó la llamada?"
                                        name="clientRespondedOld"
                                        value={clientResponded2}
                                        onChange={(e) => setClientResponded2(e.target.value)}
                                        className={classes.textFields}
                                        margin="normal"
                                        fullWidth InputProps={{
                                            style: {
                                                color: 'white', // Text color
                                                borderBottom: '1px solid white'
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color: '#D3D3D3', // Label color
                                            },
                                        }}
                                    >
                                        <MenuItem value={true}>Si contestó</MenuItem>
                                        <MenuItem value={false}>No contestó</MenuItem>
                                    </TextField>
                                    <TextField
                                        label="Comentarios"
                                        name="clientStatus"
                                        value={clientStatus2}
                                        onChange={(e) => setClientStatus2(e.target.value)}
                                        className={classes.textFields}
                                        margin="normal"
                                        fullWidth InputProps={{
                                            style: {
                                                color: 'white', // Text color
                                                borderBottom: '1px solid white'
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color: '#D3D3D3', // Label color
                                            },
                                        }}
                                    >
                                    </TextField>
                                    <TextField
                                        label="Fecha de Seguimiento"
                                        name="followUpDate"
                                        type="date"
                                        value={followUpDate2}
                                        onChange={(e) => setFollowUpDate2(e.target.value)}
                                        className={classes.textFields}
                                        margin="normal"
                                        fullWidth InputProps={{
                                            style: {
                                                color: 'white', // Text color
                                                borderBottom: '1px solid white'
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color: '#D3D3D3', // Label color
                                                shrink: true
                                            },
                                        }}
                                    />
                                    <Button
                                        variant="contained"
                                        className={classes.welcomeButtons}
                                        onClick={() => submitSearchedCase()}
                                        fullWidth InputProps={{
                                            style: {
                                                color: 'white', // Text color
                                                borderBottom: '1px solid white'
                                            },
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                color: '#D3D3D3', // Label color
                                            },
                                        }}
                                    >
                                        Guardar
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>}
                {
                    showPaymentsToCheck && <VerifyPayments />
                }
                {
                    showEdit && <div>
                        <button onClick={() => screenShown === 'clientes' ? setScreenShown('') : setScreenShown('clientes')} style={{ display: 'flex', background: 'transparent', border: 'none', cursor: 'pointer', width: '100%', alignItems: 'center' }}>
                            <h2 className={classes.title2} style={{ marginTop: 40 }}>
                                Clientes
                            </h2>
                            <div style={{ justifyContent: 'center', alignItems: 'center', marginTop: 10, marginLeft: 20 }}>
                                {screenShown !== 'clientes' ? (
                                    <FaChevronRight color="#FFFFFF80" size={20} />
                                ) : (
                                    <FaChevronDown color="#FFFFFF80" size={20} />
                                )}
                            </div>
                        </button>
                        {screenShown === 'clientes' && <div style={{ marginLeft: 30 }}>
                            <h2 className={classes.title} style={{ marginTop: 20 }}>
                                Cambiar Email
                            </h2>
                            <TextField
                                label="Cedula"
                                name="cedula"
                                value={cedula}
                                onChange={(e) => setCedula(e.target.value)}
                                className={classes.textFields}
                                margin="normal"
                                fullWidth InputProps={{
                                    style: {
                                        color: 'white', // Text color
                                        borderBottom: '1px solid white'
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#D3D3D3', // Label color
                                    },
                                }}
                            />
                            <TextField
                                label="Nuevo email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className={classes.textFields}
                                margin="normal"
                                fullWidth InputProps={{
                                    style: {
                                        color: 'white', // Text color
                                        borderBottom: '1px solid white'
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#D3D3D3', // Label color
                                    },
                                }}
                            />
                            <Button
                                variant="contained"
                                className={classes.welcomeButtons}
                                onClick={changeUserEmail}
                                style={{ marginTop: '10px', marginLeft: 20 }}
                            >
                                Cambiar
                            </Button>
                            <h2 className={classes.title} style={{ marginTop: 20 }}>
                                Cambiar Cedula
                            </h2>
                            <TextField
                                label="Cedula incorrecta"
                                name="oldCedula"
                                value={oldCedula}
                                onChange={(e) => setOldCedula(e.target.value)}
                                className={classes.textFields}
                                margin="normal"
                                fullWidth InputProps={{
                                    style: {
                                        color: 'white', // Text color
                                        borderBottom: '1px solid white'
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#D3D3D3', // Label color
                                    },
                                }}
                            />
                            <TextField
                                label="Cedula correcta"
                                name="newCedula"
                                value={newCedula}
                                onChange={(e) => setNewCedula(e.target.value)}
                                className={classes.textFields}
                                margin="normal"
                                fullWidth InputProps={{
                                    style: {
                                        color: 'white', // Text color
                                        borderBottom: '1px solid white'
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#D3D3D3', // Label color
                                    },
                                }}
                            />
                            <Button
                                variant="contained"
                                className={classes.welcomeButtons}
                                onClick={changeCedula}
                                style={{ marginTop: '10px', marginLeft: 20 }}
                            >
                                Cambiar
                            </Button>
                            <h2 className={classes.title} style={{ marginTop: 20 }}>
                                Cambiar número de teléfono
                            </h2>
                            <TextField
                                label="Cedula"
                                name="cedula2"
                                value={cedula2}
                                onChange={(e) => setCedula2(e.target.value)}
                                className={classes.textFields}
                                margin="normal"
                                fullWidth InputProps={{
                                    style: {
                                        color: 'white', // Text color
                                        borderBottom: '1px solid white'
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#D3D3D3', // Label color
                                    },
                                }}
                            />
                            <TextField
                                label="Nuevo número"
                                name="phoneNumber"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                className={classes.textFields}
                                margin="normal"
                                fullWidth InputProps={{
                                    style: {
                                        color: 'white', // Text color
                                        borderBottom: '1px solid white'
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#D3D3D3', // Label color
                                    },
                                }}
                            />
                            <Button
                                variant="contained"
                                className={classes.welcomeButtons}
                                onClick={changePhoneNumber}
                                style={{ marginTop: '10px', marginLeft: 20 }}
                            >
                                Cambiar
                            </Button>
                            <h2 className={classes.title} style={{ marginTop: 20 }}>
                                Cambio de contraseña
                            </h2>
                            <TextField
                                label="Email"
                                name="email2"
                                value={email2}
                                onChange={(e) => setEmail2(e.target.value)}
                                className={classes.textFields}
                                margin="normal"
                                fullWidth InputProps={{
                                    style: {
                                        color: 'white', // Text color
                                        borderBottom: '1px solid white'
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#D3D3D3', // Label color
                                    },
                                }}
                            />
                            <Button
                                variant="contained"
                                className={classes.welcomeButtons}
                                onClick={resetPassword}
                                style={{ marginTop: '10px', marginLeft: 20 }}
                            >
                                Resetear
                            </Button>
                            <h2 className={classes.title} style={{ marginTop: 20 }}>
                                Cambio de nombre
                            </h2>
                            <TextField
                                label="Cedula"
                                name="cedula2"
                                value={cedula2}
                                onChange={(e) => setCedula2(e.target.value)}
                                className={classes.textFields}
                                margin="normal"
                                fullWidth InputProps={{
                                    style: {
                                        color: 'white', // Text color
                                        borderBottom: '1px solid white'
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#D3D3D3', // Label color
                                    },
                                }}
                            />
                            <TextField
                                label="Nombre"
                                name="firstName"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                className={classes.textFields}
                                margin="normal"
                                fullWidth InputProps={{
                                    style: {
                                        color: 'white', // Text color
                                        borderBottom: '1px solid white'
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#D3D3D3', // Label color
                                    },
                                }}
                            />
                            <Button
                                variant="contained"
                                className={classes.welcomeButtons}
                                onClick={changeFirstName}
                                style={{ marginTop: '10px', marginLeft: 20 }}
                            >
                                cambiar
                            </Button>
                            <h2 className={classes.title} style={{ marginTop: 20 }}>
                                Cambio de apellido
                            </h2>
                            <TextField
                                label="Cedula"
                                name="cedula2"
                                value={cedula2}
                                onChange={(e) => setCedula2(e.target.value)}
                                className={classes.textFields}
                                margin="normal"
                                fullWidth InputProps={{
                                    style: {
                                        color: 'white', // Text color
                                        borderBottom: '1px solid white'
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#D3D3D3', // Label color
                                    },
                                }}
                            />
                            <TextField
                                label="Apellido"
                                name="lastName"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                className={classes.textFields}
                                margin="normal"
                                fullWidth InputProps={{
                                    style: {
                                        color: 'white', // Text color
                                        borderBottom: '1px solid white'
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#D3D3D3', // Label color
                                    },
                                }}
                            />
                            <Button
                                variant="contained"
                                className={classes.welcomeButtons}
                                onClick={changeLastName}
                                style={{ marginTop: '10px', marginLeft: 20 }}
                            >
                                cambiar
                            </Button>
                            <h2 className={classes.title} style={{ marginTop: 20 }}>
                                Eliminar cuenta
                            </h2>
                            <TextField
                                label="Cedula"
                                name="cedula7"
                                value={cedula7}
                                onChange={(e) => setCedula7(e.target.value)}
                                className={classes.textFields}
                                margin="normal"
                                fullWidth InputProps={{
                                    style: {
                                        color: 'white', // Text color
                                        borderBottom: '1px solid white'
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#D3D3D3', // Label color
                                    },
                                }}
                            />
                            <Button
                                variant="contained"
                                className={classes.welcomeButtons}
                                onClick={deleteAccount}
                                style={{ marginTop: '10px', marginLeft: 20 }}
                            >
                                eliminar
                            </Button>
                        </div>}
                        <button onClick={() => screenShown === 'equipos' ? setScreenShown('') : setScreenShown('equipos')} style={{ display: 'flex', background: 'transparent', border: 'none', cursor: 'pointer', width: '100%', alignItems: 'center' }}>
                            <h2 className={classes.title2} style={{ marginTop: 40 }}>
                                Equipos
                            </h2>
                            <div style={{ justifyContent: 'center', alignItems: 'center', marginTop: 10, marginLeft: 20 }}>
                                {screenShown !== 'equipos' ? (
                                    <FaChevronRight color="#FFFFFF80" size={20} />
                                ) : (
                                    <FaChevronDown color="#FFFFFF80" size={20} />
                                )}
                            </div>
                        </button>
                        {screenShown === 'equipos' && <div>
                            <h2 className={classes.title} style={{ marginTop: 20 }}>
                                Reportar celular como Robado
                            </h2>
                            <TextField
                                label="ID de equipo"
                                name="cedula3"
                                value={cedula3}
                                onChange={(e) => setCedula3(e.target.value)}
                                className={classes.textFields}
                                margin="normal"
                                fullWidth InputProps={{
                                    style: {
                                        color: 'white', // Text color
                                        borderBottom: '1px solid white'
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#D3D3D3', // Label color
                                    },
                                }}
                            />
                            <Button
                                variant="contained"
                                className={classes.welcomeButtons}
                                onClick={reportStolenDevice}
                                style={{ marginTop: '10px', marginLeft: 20 }}
                            >
                                Reportar
                            </Button>
                            <h2 className={classes.title} style={{ marginTop: 20 }}>
                                Desbloquear Celular
                            </h2>
                            <TextField
                                label="ID de equipo"
                                name="cedula4"
                                value={cedula4}
                                onChange={(e) => setCedula4(e.target.value)}
                                className={classes.textFields}
                                margin="normal"
                                fullWidth InputProps={{
                                    style: {
                                        color: 'white', // Text color
                                        borderBottom: '1px solid white'
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#D3D3D3', // Label color
                                    },
                                }}
                            />
                            <Button
                                variant="contained"
                                className={classes.welcomeButtons}
                                onClick={unlockDevice}
                                style={{ marginTop: '10px', marginLeft: 20 }}
                            >
                                Desbloquear
                            </Button>
                            <h2 className={classes.title} style={{ marginTop: 20 }}>
                                Liberar celular ya pagado
                            </h2>
                            <TextField
                                label="ID de equipo"
                                name="devId"
                                value={devId}
                                onChange={(e) => setDevId(e.target.value)}
                                className={classes.textFields}
                                margin="normal"
                                fullWidth InputProps={{
                                    style: {
                                        color: 'white', // Text color
                                        borderBottom: '1px solid white'
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#D3D3D3', // Label color
                                    },
                                }}
                            />
                            <Button
                                variant="contained"
                                className={classes.welcomeButtons}
                                onClick={freeDevice}
                                style={{ marginTop: '10px', marginLeft: 20 }}
                            >
                                Liberar
                            </Button>
                            <h2 className={classes.title} style={{ marginTop: 20 }}>
                                Cambiar nombre comercial de equipo
                            </h2>
                            <TextField
                                label="ID de equipo"
                                name="deviceID"
                                value={deviceID}
                                onChange={(e) => setDeviceID(e.target.value)}
                                className={classes.textFields}
                                margin="normal"
                                fullWidth InputProps={{
                                    style: {
                                        color: 'white', // Text color
                                        borderBottom: '1px solid white'
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#D3D3D3', // Label color
                                    },
                                }}
                            />
                            <TextField
                                label="Nuevo nombre"
                                name="newDeviceName"
                                value={newDeviceName}
                                onChange={(e) => setNewDeviceName(e.target.value)}
                                className={classes.textFields}
                                margin="normal"
                                fullWidth InputProps={{
                                    style: {
                                        color: 'white', // Text color
                                        borderBottom: '1px solid white'
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#D3D3D3', // Label color
                                    },
                                }}
                            />
                            <Button
                                variant="contained"
                                className={classes.welcomeButtons}
                                onClick={changeDeviceName}
                                style={{ marginTop: '10px', marginLeft: 20 }}
                            >
                                Cambiar
                            </Button>
                        </div>}
                        <button onClick={() => screenShown === 'pagos' ? setScreenShown('') : setScreenShown('pagos')} style={{ display: 'flex', background: 'transparent', border: 'none', cursor: 'pointer', width: '100%', alignItems: 'center' }}>
                            <h2 className={classes.title2} style={{ marginTop: 40 }}>
                                Pagos
                            </h2>
                            <div style={{ justifyContent: 'center', alignItems: 'center', marginTop: 10, marginLeft: 20 }}>
                                {screenShown !== 'pagos' ? (
                                    <FaChevronRight color="#FFFFFF80" size={20} />
                                ) : (
                                    <FaChevronDown color="#FFFFFF80" size={20} />
                                )}
                            </div>
                        </button>
                        {screenShown === 'pagos' && <div>
                            <h2 className={classes.title} style={{ marginTop: 20 }}>
                                Buscar pagos de tienda
                            </h2>
                            <TextField
                                label="Monto"
                                name="reportedAmount"
                                type="tel"
                                value={reportedAmount}
                                onChange={(e) => setReportedAmount(e.target.value)}
                                className={classes.textFields}
                                margin="normal"
                                fullWidth InputProps={{
                                    style: {
                                        color: 'white', // Text color
                                        borderBottom: '1px solid white'
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#D3D3D3', // Label color
                                    },
                                }}
                            />
                            <TextField
                                label="Fecha de Pago"
                                name="paymentDate"
                                type="date"
                                value={paymentDate}
                                onChange={(e) => setPaymentDate(e.target.value)}
                                className={classes.textFields}
                                margin="normal"
                                fullWidth InputProps={{
                                    style: {
                                        color: 'white', // Text color
                                        borderBottom: '1px solid white'
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#D3D3D3', // Label color
                                    },
                                    shrink: true
                                }}
                            />
                            <TextField
                                label="Email de la tienda"
                                name="storeEmail"
                                value={storeEmail}
                                onChange={(e) => setStoreEmail(e.target.value)}
                                className={classes.textFields}
                                margin="normal"
                                fullWidth InputProps={{
                                    style: {
                                        color: 'white', // Text color
                                        borderBottom: '1px solid white'
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#D3D3D3', // Label color
                                    },
                                    shrink: true
                                }}
                            />
                            <Button
                                variant="contained"
                                className={classes.welcomeButtons}
                                onClick={getStoreTransactions}
                                style={{ marginTop: '10px', marginLeft: 20 }}
                            >
                                Buscar
                            </Button>
                            <h2 className={classes.title} style={{ marginTop: 20 }}>
                                Buscar pago en Banco
                            </h2>
                            <TextField
                                label="Monto"
                                name="reportedAmount"
                                type="tel"
                                value={reportedAmount}
                                onChange={(e) => setReportedAmount(e.target.value)}
                                className={classes.textFields}
                                margin="normal"
                                fullWidth InputProps={{
                                    style: {
                                        color: 'white', // Text color
                                        borderBottom: '1px solid white'
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#D3D3D3', // Label color
                                    },
                                }}
                            />
                            <TextField
                                label="Referencia"
                                name="referenceNumber"
                                type="tel"
                                value={referenceNumber}
                                onChange={(e) => setReferenceNumber(e.target.value)}
                                className={classes.textFields}
                                margin="normal"
                                fullWidth InputProps={{
                                    style: {
                                        color: 'white', // Text color
                                        borderBottom: '1px solid white'
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#D3D3D3', // Label color
                                    },
                                }}
                            />
                            <TextField
                                label="Fecha de Pago"
                                name="paymentDate"
                                type="date"
                                value={paymentDate}
                                onChange={(e) => setPaymentDate(e.target.value)}
                                className={classes.textFields}
                                margin="normal"
                                fullWidth InputProps={{
                                    style: {
                                        color: 'white', // Text color
                                        borderBottom: '1px solid white'
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#D3D3D3', // Label color
                                    },
                                    shrink: true
                                }}
                            />
                            <TextField
                                label="ID de producto"
                                name="deviceID"
                                value={deviceID}
                                onChange={(e) => setDeviceID(e.target.value)}
                                className={classes.textFields}
                                margin="normal"
                                fullWidth InputProps={{
                                    style: {
                                        color: 'white', // Text color
                                        borderBottom: '1px solid white'
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#D3D3D3', // Label color
                                    },
                                    shrink: true
                                }}
                            />
                            <Button
                                variant="contained"
                                className={classes.welcomeButtons}
                                onClick={searchTransaction}
                                style={{ marginTop: '10px', marginLeft: 20 }}
                            >
                                Buscar
                            </Button>
                            <h2 className={classes.title} style={{ marginTop: 20 }}>
                                Registrar pago reembolsado
                            </h2>
                            <TextField
                                label="Monto"
                                name="reportedAmount2"
                                type="tel"
                                value={reportedAmount2}
                                onChange={(e) => setReportedAmount2(e.target.value)}
                                className={classes.textFields}
                                margin="normal"
                                fullWidth InputProps={{
                                    style: {
                                        color: 'white', // Text color
                                        borderBottom: '1px solid white'
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#D3D3D3', // Label color
                                    },
                                }}
                            />
                            <TextField
                                label="Referencia"
                                name="referenceNumber2"
                                type="tel"
                                value={referenceNumber2}
                                onChange={(e) => setReferenceNumber2(e.target.value)}
                                className={classes.textFields}
                                margin="normal"
                                fullWidth InputProps={{
                                    style: {
                                        color: 'white', // Text color
                                        borderBottom: '1px solid white'
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#D3D3D3', // Label color
                                    },
                                }}
                            />
                            <TextField
                                label="Fecha de Pago"
                                name="paymentDate2"
                                type="date"
                                value={paymentDate2}
                                onChange={(e) => setPaymentDate2(e.target.value)}
                                className={classes.textFields}
                                margin="normal"
                                fullWidth InputProps={{
                                    style: {
                                        color: 'white', // Text color
                                        borderBottom: '1px solid white'
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#D3D3D3', // Label color
                                    },
                                    shrink: true
                                }}
                            />
                            <Button
                                variant="contained"
                                className={classes.welcomeButtons}
                                onClick={registerIncorrectPayment}
                                style={{ marginTop: '10px', marginLeft: 20 }}
                            >
                                Registrar
                            </Button>
                        </div>}
                    </div>
                }
            </div >
        </div>
    );

    function VerifyPayments() {
        const banks = GetBanks(userData?.country);
        const [tableKey, setTableKey] = useState(0);

        const [selectedPayment, setSelectedPayment] = useState(null);
        const formValid = selectedPayment?.date && selectedPayment?.amount && selectedPayment?.bank_id && selectedPayment?.bank_account_number && selectedPayment?.reference && selectedPayment?.unverified_payment_id;

        const [submitForm, setSubmitForm] = useState(false);
        const { error, loading, setPayload, reset, fetched } = useFetch('https://api.krece.app/payments/processunverifiedpayment/', 'PUT', submitForm);

        useEffect(() => {
            if (!selectedPayment) reset();
        }, [selectedPayment]);

        useEffect(() => {
            if (fetched && !error) { setSelectedPayment(null); reset(); setTableKey(prev => prev + 1); }
        }, [fetched]);

        return (<>
            {selectedPayment && <Dialog closable={false} title='Información de Pago (Cliente)' open={true}>
                <form className="space-y-6 max-w-full w-[400px]" onSubmit={(e) => e.preventDefault()}>
                    
                    <div className="flex flex-col gap-2">
                        <div className="flex gap-2">
                            <Input
                                inputClassName='font-semibold'
                                Icon={<span className="text-neutral-500">Fecha: </span>}
                                value={selectedPayment?.date || ""}
                                readonly={true}
                                containerClassName="w-full"
                            />
                            <Input
                                inputClassName='font-semibold'
                                Icon={<span className="text-neutral-500">Monto: </span>}
                                value={selectedPayment?.amount?.toString() || ""}
                                onChange={(value) => setSelectedPayment({ ...selectedPayment, amount: isNaN(parseFloat(value)) ? value : parseFloat(value) })}
                                allowedChars="numeric"
                                containerClassName="w-full"
                            />
                        </div>
                        <Input
                            Icon={<span className="text-neutral-500">Referencia: </span>}
                            value={selectedPayment?.customer_reference || ""}
                            readonly={true}
                            containerClassName="w-full"
                        />
                        <Input
                            Icon={<span className="text-neutral-500">Num. de cuenta: </span>}
                            value={selectedPayment?.customer_bank_account_number || ""}
                            readonly={true}
                            containerClassName="w-full"
                        />

                        <h2 className="text-[1.1rem] select-none font-medium">Verificación de Pago (Krece)</h2>

                        <Select
                            options={banks.map((bank) => ({ label: bank, value: bank }))}
                            onSelectedChange={(value) => setSelectedPayment({ ...selectedPayment, bank_id: value })}
                            placeholder="Seleccionar banco"
                        />
                        <Input
                            allowedChars="numbers_only"
                            placeholder="Número de cuenta"
                            value={selectedPayment?.bank_account_number || ""}
                            onChange={(value) => setSelectedPayment({ ...selectedPayment, bank_account_number: value })}
                            validation={(value) => validate_bank_account_number(value, userData?.country)}
                        />
                        <Input
                            placeholder="Referencia"
                            value={selectedPayment?.reference || ""}
                            onChange={(value) => setSelectedPayment({ ...selectedPayment, reference: value })}
                        />
                    </div>

                    {error && typeof error === 'string' && (
                        <Notice message={error || 'Ocurrió un error'} variant="error" />
                    )}

                    <div className="flex justify-end gap-2 max-w-full">
                        <Button2
                            variant="default"
                            className="bg-neutral-900 hover:bg-neutral-900/70 shrink-0"
                            onClick={() => setSelectedPayment(null)}
                        >
                            Cerrar
                        </Button2>
                        <div className='w-full'></div>

                            <Button2
                                loading={loading}
                                type="submit"
                                variant="default"
                                className={"bg-red-600 hover:bg-red-800 shrink-0"}
                                onClick={() => {
                                    setPayload({ unverified_payment_id: selectedPayment.unverified_payment_id, accept_payment: false });
                                    setSubmitForm(true);
                                }}
                            >
                                No pude verificarlo
                            </Button2>

                        <Button2
                            loading={loading}
                            disabled={!formValid || loading}
                            type="submit"
                            variant="default"
                            className={error ? "bg-yellow-500/70 hover:bg-yellow-800 shrink-0" : "bg-sky-600 hover:bg-sky-800 shrink-0"}
                            onClick={() => {
                                setPayload({ ...selectedPayment });
                                setSubmitForm(true);
                            }}
                        >
                            {error ? 'Reintentar' : 'Verificar'}
                        </Button2>
                    </div>
                </form>
            </Dialog>}
            <div className="mt-6 space-y-4">
                <div className="space-y-0">
                    <h2 className="text-2xl font-semibold text-neutral-100">
                        Verificar Pagos
                    </h2>
                    <p className="text-sm text-neutral-400">
                        Haga click en 'Confirmar' para verificar el pago.
                    </p>
                </div>
                <Table
                    key={tableKey}
                    actionButton={{
                        label: 'Confirmar',
                        onClick: (row) => { setSelectedPayment({ unverified_payment_id: row["ID del pago"], amount: row["Monto"], date: row["fecha"], customer_reference: row["Referencia"], customer_bank_account_number: row["Numero de cuenta"] }) }
                    }}
                    userEmail={userData?.master_store?.id}
                    endpoint="https://api.krece.app/tables/unverified_payments/"
                    pagination={{ default: 100, batchSizes: [100] }}
                    orderBy={{ columnName: 'fecha', columnOrder: 'DESC' }}
                    search_by={{ column: 'Numero de documento', placeholder: 'Número de documento', type: 'numeric' }}
                    hideExport={true}
                    className="rounded-lg border border-neutral-800 bg-neutral-900/50 p-4"
                />
                <Table
                    userEmail={userData?.master_store?.id}
                    endpoint="https://api.krece.app/tables/bank_account_payments/"
                    pagination={{ default: 100, batchSizes: [100] }}
                    orderBy={{ columnName: 'fecha', columnOrder: 'DESC' }}
                    search_by={{ column: 'Monto', placeholder: 'Monto', type: 'numeric' }}
                    hideExport={true}
                    className="rounded-lg border border-neutral-800 bg-neutral-900/50 p-4"
                />
            </div>
        </>)
    }
};



export default Internal;
