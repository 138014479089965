import React, { useState, useEffect, useContext } from 'react';
import firestore from '../firestore';
import { GlobalContext } from '../context/GlobalContext';
import Stepper from '../components/Stepper';
import { ContainerSpinner } from '../components/Spinner';
import { ArrowLeft, CircleCheck, KeyRound, Mail, Phone } from 'lucide-react';
import { Button } from '../shadcn/Button';
import Input from '../components/Input';
import Notice from '../components/Notice';
import classNames from 'classnames';

const ChangeEmail = () => {
	const { authenticated, userData } = useContext(GlobalContext);
	const country = userData?.country;

	const [isLoading, setIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [success, setSuccess] = useState(false);

	const [token, setToken] = useState(process.env.REACT_APP_API_TOKEN);
	const [showTokenSent, setShowTokenSent] = useState(false);
	const [showChangeEmail, setShowChangeEmail] = useState(false);

	const [phoneNumberForAuthCode, setPhoneNumberForAuthCode] = useState('');
	const [emailForAuthCode, setEmailForAuthCode] = useState('');
	const [cedulaForAuthCode, setCedulaForAuthCode] = useState('');
	const [authCodeForAuthCode, setAuthCodeForAuthCode] = useState('');
	const [emailToChange, setEmailToChange] = useState('');
	const [emailToChange2, setEmailToChange2] = useState('');

	var currentDay = new Date().getDate();
	if (currentDay === 31) {
		currentDay = 30;
	}

	const sendPostRequest = async (data, endpoint) => {
		try {
			const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${userData?.firebase_token}`,
					'x-master-store-email': userData.master_store.id,
					'x-user-email': userData.email
				},
				body: JSON.stringify({ ...data, country: userData.country })
			});
			const responseData = await response.json();
			if (response.status === '401') {
				const tokenData = await requestToken();
				setToken('Token ' + tokenData.token);
				const resData = await sendPostRequest(data, endpoint, 'Token ' + tokenData.token);
				return resData;
			}
			return responseData;
		} catch (error) {
			console.error('Error:', error.message);
			return null;
		}
	};

	const requestToken = async () => {
		const loginData = {
			username: process.env.REACT_APP_TOKEN_USERNAME,
			password: process.env.REACT_APP_TOKEN_PASSWORD
		};
		try {
			const response = await fetch(process.env.REACT_APP_API_URL + '/api/token/', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(loginData)
			});

			const responseData = await response.json();
			return responseData;
		} catch (error) {
			console.error('Error:', error);
		}
	};

	const retrievePhoneNumber = async () => {
		try {
			const usersCollection = firestore.collection('Users');

			const queryUser = usersCollection.where('identification_number', '==', cedulaForAuthCode).limit(1);
			await queryUser
				.get()
				.then((querySnapshot) => {
					querySnapshot.forEach(async (doc) => {
						setPhoneNumberForAuthCode(doc.data()['phone_number']);
						setEmailForAuthCode(doc.id);
						sendAuthCodeToUser(doc.data()['phone_number']);
					});
				})
				.catch(() => {
					setIsLoading(false);
					setErrorMessage('Estamos teniendo problemas para conectarnos.');
				});
		} catch (err) {
			setErrorMessage('Estamos teniendo problemas para conectarnos.');
			setIsLoading(false);
		}
	};

	const sendAuthCodeToUser = async (phoneNumberForAuthCode) => {
		try {
			const codeData = {
				phone_number: phoneNumberForAuthCode,
				code_type: 'sms'
			};
			const response = await sendPostRequest(codeData, 'notifications/requestauthcode/', token); //status, success, data

			if (response?.success) {
				setShowTokenSent(true);
			} else if (response?.success === false) {
				setErrorMessage(response.status);
			} else {
				setErrorMessage('Estamos teniendo problemas para conectarnos.');
			}

			setIsLoading(false);
		} catch (error) {
			setErrorMessage('Estamos teniendo problemas para conectarnos.');
			setIsLoading(false);
		}
	};

	const confirmAuthCodeFromUser = async (e) => {
		e.preventDefault();

		setErrorMessage('');
		if (!authCodeForAuthCode) {
			setErrorMessage('Llene todos los datos para continuar');
			return;
		}
		setIsLoading(true);
		try {
			const codeData = {
				confirmation_code: authCodeForAuthCode
			};
			const response = await sendPostRequest(codeData, 'notifications/confirmauthcode/', token); //status, success, data
			if (response?.success) {
				setShowChangeEmail(true);
				setIsLoading(false);
			} else if (response?.success === false) {
				setErrorMessage(response.status);
				setIsLoading(false);
			} else {
				setErrorMessage('Estamos teniendo problemas para conectarnos.');
				setIsLoading(false);
			}
		} catch (error) {
			setErrorMessage('Estamos teniendo problemas para conectarnos.');
			setIsLoading(false);
		}
	};

	const checkCedula = async (e) => {
		e.preventDefault();
		setErrorMessage('');
		if (!cedulaForAuthCode) {
			setErrorMessage('Llene todos los datos para continuar');
			return;
		}
		setIsLoading(true);
		try {
			const cedulaData = {
				identification_number: cedulaForAuthCode
			};
			const response = await sendPostRequest(cedulaData, 'registration/usercheck/', token); //status, success, data
			if (response?.success) {
				if (response.data.is_user_registered) {
					await retrievePhoneNumber();
				} else {
					await setErrorMessage(`No encontramos al cliente asociado a ${country === 'peru' ? 'este DNI' : 'esta cédula'}. Por favor contactar a soporte técnico.`);
					setIsLoading(false);
				}
			} else if (response?.success === false) {
				setErrorMessage('Estamos teniendo problemas para conectarnos.');
				setIsLoading(false);
			} else {
				setErrorMessage('Estamos teniendo problemas para conectarnos.');
				setIsLoading(false);
			}
		} catch (error) {
			setErrorMessage('Estamos teniendo problemas para conectarnos.');
			setIsLoading(false);
		}
	};

	const confirmNewEmail = async (e) => {
		e.preventDefault();
		setErrorMessage('');
		setIsLoading(true);
		//do all the email change logic
		if (!emailToChange || !emailToChange2) {
			setErrorMessage('Por favor rellene todos los datos.');
			setIsLoading(false);
			return;
		}
		if (emailToChange !== emailToChange2) {
			setErrorMessage('Los correos introducidos no coinciden.');
			setIsLoading(false);
			return;
		}
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (!emailRegex.test(emailToChange)) {
			setErrorMessage('Por favor ingrese un email válido.');
			setIsLoading(false);
			return;
		}
		//now we need to change the email and see if we can change the auth email so the user doesn't have to create a new
		try {
			changeUserEmail();
		} catch (err) {
			setErrorMessage('Ocurrion un problema, intente más tarde.');
			setIsLoading(false);
			return;
		}
	};

	const changeUserEmail = async () => {
		setIsLoading(true);
		setErrorMessage('');
		try {
			const usersCollection = firestore.collection('Users');
			const paymentsCollection = firestore.collection('Payments');
			const devicesCollection = firestore.collection('Devices');
			const remindersCollection = firestore.collection('Reminders');
			const transactionsCollection = firestore.collection('Transactions');

			const queryUser = usersCollection.where('identification_number', '==', cedulaForAuthCode.trim()).limit(1);
			await queryUser
				.get()
				.then((querySnapshot) => {
					querySnapshot.forEach(async (doc) => {
						const dataToClone = doc.data();

						if (!dataToClone) {
							setErrorMessage('Documento no encontrado o sin data.');
							setIsLoading(false);
							return;
						}

						// Step 4: Write data to the new document in the destination collection
						if (doc.id === emailToChange) {
							setErrorMessage('El correo introducido es igual al anterior, por favor introduzca un correo distinto.');
							setIsLoading(false);
							return;
						}
						const destinationDocumentRef = firestore
							.collection('Users')
							.doc(emailToChange.toLowerCase().trim())
							.get()
							.then(async (doc2) => {
								if (doc2.exists) {
									// Document exists
									setErrorMessage('Ya existe un usuario con este email.');
									setIsLoading(false);
									return;
								} else {
									await doc2.ref.set(dataToClone);
									if (dataToClone['registration_status'] === 'device_enrolled') {
										//change "user" field in Devices for this user
										const deviceQuery = devicesCollection.where('user', '==', usersCollection.doc(doc.id));

										await deviceQuery.get().then((querySnapshot) => {
											querySnapshot.forEach((doc) => {
												doc.ref.update({
													user: usersCollection.doc(emailToChange.toLowerCase().trim())
												});
											});
										});
										//change payment "user" field to the new user
										const paymentQuery = paymentsCollection.where('user', '==', usersCollection.doc(doc.id));
										await paymentQuery.get().then((querySnapshot) => {
											querySnapshot.forEach((doc) => {
												doc.ref.update({
													user: usersCollection.doc(emailToChange.toLowerCase().trim())
												});
											});
										});
										//change the Reminders "user" field to the new user
										const reminderQuery = remindersCollection.where('user', '==', usersCollection.doc(doc.id));
										await reminderQuery.get().then((querySnapshot) => {
											querySnapshot.forEach((doc) => {
												doc.ref.update({
													user: usersCollection.doc(emailToChange.toLowerCase().trim())
												});
											});
										});
										//change the Transactions "user" field to the new user
										const transactionQuery = transactionsCollection.where('user', '==', usersCollection.doc(doc.id));
										await transactionQuery.get().then((querySnapshot) => {
											querySnapshot.forEach((doc) => {
												doc.ref.update({
													user: usersCollection.doc(emailToChange.toLowerCase().trim())
												});
											});
										});
										doc.ref.delete();
										setSuccess(true);
										setIsLoading(false);
									} else {
										doc.ref.delete();
										setSuccess(true);
										setIsLoading(false);
									}
								}
							})
							.catch((error) => {
								setErrorMessage('Ocurrió un problema, intente de nuevo más tarde.');
								setIsLoading(false);
							});
					});
				})
				.catch((error) => {
					setErrorMessage('Ocurrió un problema, intente de nuevo más tarde.');
					setIsLoading(false);
				});
		} catch (error) {
			setErrorMessage('Estamos teniendo problemas para conectarnos.');
			setIsLoading(false);
		}
	};

	const [page, setPage] = useState(1);

	useEffect(() => {
		if (success) setPage(4);
		else if (showChangeEmail) setPage(3);
		else if (!showTokenSent) setPage(1);
		else if (showTokenSent) setPage(2);
	}, [showTokenSent, showChangeEmail, success]);

	const steps = [
		{ label: country === 'peru' ? 'DNI' : 'Cédula', pages: [1] },
		{ label: 'Verificación', pages: [2] },
		{ label: 'Cambiar Correo', pages: [3] },
		{ label: 'Cambio Exitoso', pages: [4] }
	];

	if (!authenticated || !userData?.country) return <ContainerSpinner size={3} />;

	return (
		<div className="m-auto flex min-h-full min-w-full flex-col items-center justify-start">
			<Stepper currentPage={page} steps={steps} />
			<div className="m-auto flex h-full w-full flex-col items-center justify-center p-4 md:p-6">
				<div
					className={classNames(
						'w-[500px] max-w-full rounded-xl border-[1.5px] border-neutral-900 bg-neutral-1000/25 p-6 shadow-lg duration-300 animate-in fade-in slide-in-from-bottom-5 md:bg-background/50 md:p-8 light:shadow-none light:bg-white',
						{ 'w-[600px]': page === 2 }
					)}
				>
					{page === 1 && (
						<>
							{render_page_header(
								'Cambiar Correo',
								`Por favor ingrese ${country === 'peru' ? 'el DNI' : 'la cédula'} del cliente que desea cambiar su correo`,
								<Mail />
							)}

							<form className="space-y-4" onSubmit={checkCedula}>
								<Input
									name="cedula"
									floatingLabel={`${country === 'peru' ? 'DNI' : 'Cédula'} del cliente`}
									value={cedulaForAuthCode}
									onChange={(value) => setCedulaForAuthCode(value)}
									placeholder={`Ingrese ${country === 'peru' ? 'el DNI' : 'la cédula'} del cliente`}
									allowedChars="numbers_only"
								/>

								{errorMessage && <Notice message={errorMessage} variant="error" />}

								<Button
									disabled={isLoading || !cedulaForAuthCode}
									loading={isLoading}
									type="submit"
									onClick={checkCedula}
									variant="primary"
								>
									Continuar
								</Button>
							</form>
						</>
					)}

					{page === 2 && (
						<>
							{render_page_header('Verificación', 'Por favor ingrese el código de verificación enviado', <KeyRound />)}

							<div className="mb-6 flex flex-wrap items-center justify-center gap-6">
								<div className="flex flex-col items-center gap-2">
									<span className="text-sm text-neutral-400">Email actual del cliente</span>
									<div className="flex items-center gap-2 rounded-lg bg-neutral-800/50 px-4 py-2">
										<Mail className="h-4 w-4 text-primary-500" />
										<span className="font-medium text-neutral-100">{emailForAuthCode}</span>
									</div>
								</div>
								<div className="flex flex-col items-center gap-2">
									<span className="text-sm text-neutral-400">Código de verificación enviado al</span>
									<div className="flex items-center gap-2 rounded-lg bg-neutral-800/50 px-4 py-2">
										<Phone className="h-4 w-4 text-primary-500" />
										<span className="font-medium text-neutral-100">·······{phoneNumberForAuthCode.slice(-4)}</span>
									</div>
								</div>
							</div>

							<form className="space-y-4" onSubmit={confirmAuthCodeFromUser}>
								<Input
									floatingLabel="Código de verificación"
									value={authCodeForAuthCode}
									onChange={(value) => setAuthCodeForAuthCode(value)}
									placeholder="Ingrese el código de verificación"
								/>

								{errorMessage && <Notice message={errorMessage} variant="error" />}

								<div className="flex flex-wrap gap-2 md:flex-row-reverse md:flex-nowrap">
									<Button
										disabled={isLoading || !authCodeForAuthCode}
										loading={isLoading}
										type="submit"
										onClick={confirmAuthCodeFromUser}
										variant="primary"
									>
										Continuar
									</Button>
									<Button
										Icon={<ArrowLeft className="size-5" />}
										disabled={isLoading}
										type="button"
										onClick={() => (window.location.href = '/changeEmail')}
										variant="secondary"
									>
										Atrás
									</Button>
								</div>
							</form>
						</>
					)}

					{page === 3 && (
						<>
							{render_page_header('Nuevo Correo', 'Ingrese el nuevo correo electrónico', <Mail />)}

							<div className="mb-6 flex flex-wrap items-center justify-center gap-6">
								<div className="flex flex-col items-center gap-2">
									<span className="text-sm text-neutral-400">Email actual del cliente</span>
									<div className="flex items-center gap-2 rounded-lg bg-neutral-800/50 px-4 py-2">
										<Mail className="h-4 w-4 text-primary-500" />
										<span className="font-medium text-neutral-100">{emailForAuthCode}</span>
									</div>
								</div>
							</div>

							<form className="space-y-4" onSubmit={confirmNewEmail}>
								<Input
									type="email"
									floatingLabel="Nuevo email"
									value={emailToChange}
									onChange={(value) => setEmailToChange(value)}
									placeholder="Ingrese el nuevo email"
								/>

								<Input
									type="email"
									floatingLabel="Confirmar nuevo email"
									value={emailToChange2}
									onChange={(value) => setEmailToChange2(value)}
									placeholder="Ingrese el nuevo email"
								/>

								{errorMessage && <Notice message={errorMessage} variant="error" />}

								<div className="flex flex-wrap gap-2 md:flex-row-reverse md:flex-nowrap">
									<Button
										disabled={isLoading || !emailToChange || !emailToChange2}
										loading={isLoading}
										type="submit"
										onClick={confirmNewEmail}
										variant="primary"
									>
										Confirmar
									</Button>
									<Button
										Icon={<ArrowLeft className="size-5" />}
										disabled={isLoading}
										type="button"
										onClick={() => (window.location.href = '/changeEmail')}
										variant="secondary"
									>
										Salir
									</Button>
								</div>
							</form>
						</>
					)}

					{page === 4 && (
						<>
							{render_page_header('Cambio Exitoso', '¡El cambio de correo se ha completado correctamente!', <CircleCheck />)}

							<Button
								Icon={<ArrowLeft className="size-5" />}
								onClick={() => (window.location.href = '/changeEmail')}
								variant="primary"
							>
								Cambiar otro correo
							</Button>
						</>
					)}
				</div>
			</div>
		</div>
	);

	function render_page_header(title, description, icon) {
		return (
			<div className="mb-4 flex flex-col items-center gap-2 md:mb-6 md:gap-3">
				<div className="relative">
					<div className="absolute -inset-1 rounded-full bg-gradient-to-r from-primary-400 to-primary-600 opacity-10 blur"></div>
					<div className="relative rounded-full bg-neutral-900 p-4 light:bg-neutral-1000 light:ring-2 light:ring-primary-500/10">
						{icon && React.cloneElement(icon, { className: 'h-6 w-6 text-primary-500' })}
					</div>
				</div>
				<div className="space-y-1 text-center">
					<h1 className="text-2xl font-semibold tracking-tighter text-neutral-0 md:text-3xl">{title}</h1>
					{description && <p className="text-neutral-300">{description}</p>}
				</div>
			</div>
		);
	}
};

export default ChangeEmail;
