import { useEffect, useState } from 'react';
import useFetch from '../hooks/api';
import { Hash, IdCard, Landmark, Lock, Pencil, PlusCircle, Unlock, UserCircle2 } from 'lucide-react';
import { ucfirst } from '../utils/formatters';
import { ContainerSpinner } from './Spinner';
import { Dialog } from './Dialog';
import { Button } from '../shadcn/Button';
import Input from './Input';
import Select from './Select';
import { GetBanks } from '../hooks/firebase';
import { IdentityType, validate_bank_account_number, validate_identity_number, validate_interbank_code } from '../utils/validate';

export default function MetodosDePago({
	email,
	country,
	onSubmit,
	loading,
	setLoading
}: {
	email: string;
	country: string;
	onSubmit: (data: {
		bank: string;
		type: string;
		holder: string;
		account: string;
		interbank_code?: string;
		identity_number: string;
		identity_type: IdentityType;
	}) => void;
	loading: boolean;
	setLoading: (value: boolean) => void;
}) {
	const banks = GetBanks(country);

	const [dialogOpen, setDialogOpen] = useState(false);
	// const { data, fetched } = useFetch('https://api.krece.app/storeprofile/store_payment_methods/', 'GET', true, {
	//     email
	// }, setLoading);

	const [accBank, setAccBank] = useState('');
	const [accType, setAccType] = useState('');
	const [accHolder, setAccHolder] = useState('');
	const [accNumber, setAccNumber] = useState('');
	const [confirmAccNumber, setConfirmAccNumber] = useState('');
	const [accInterbankCode, setAccInterbankCode] = useState('');
	const [confirmInterbankCode, setConfirmInterbankCode] = useState('');
	const [accIdentityType, setAccIdentityType] = useState<IdentityType | ''>('');
	const [accIdentityNumber, setAccIdentityNumber] = useState('');

	// useEffect(() => {
	//     if (data?.transferBs) {
	//         setAccBank(data.transferBs.bank);
	//         setAccType(data.transferBs.type);
	//         setAccHolder(data.transferBs.titular);
	//         setAccIdentityType((data.transferBs as any)?.identity_type || '');
	//         setAccNumber(data.transferBs.account);
	//         setConfirmAccNumber(data.transferBs.account);
	//         setAccIdentityNumber(data.transferBs.rif);
	//     }
	// }, [data]);

	const accHolderValidation = (value: string) => value.trim().length >= 4;
	const identityNumberValidation = (value: string) =>
		accIdentityType ? validate_identity_number(value, accIdentityType, country) : false;

	const [validationError, setValidationError] = useState(false);
	const isFormValid =
		accHolderValidation(accHolder) &&
		accIdentityType &&
		accBank &&
		validate_bank_account_number(accNumber, country) &&
		accNumber === confirmAccNumber &&
		(country === 'peru'
			? validate_interbank_code(accInterbankCode, country) && accInterbankCode === confirmInterbankCode
			: true) &&
		identityNumberValidation(accIdentityNumber) &&
		accType
			? true
			: false;

	if (dialogOpen) {
		return (
			<>
				{validationError && (
					<Dialog title="¡Error!" onClose={() => setValidationError(false)} zIndex={51}>
						<p>Los siguientes campos están vacíos o son inválidos:</p>
						<ul className="text-red-400">
							{!accBank && <li className="list-inside list-disc">Banco</li>}
							{!accType && <li className="list-inside list-disc">Tipo de Cuenta</li>}
							{!accHolderValidation(accHolder) && <li className="list-inside list-disc">Titular</li>}
							{!validate_bank_account_number(accNumber, country) && <li className="list-inside list-disc">Número de Cuenta</li>}
							{accNumber !== confirmAccNumber && <li className="list-inside list-disc">Los números de cuenta no coinciden</li>}
							{country === 'peru' && (!validate_interbank_code(accInterbankCode, country) || accInterbankCode !== confirmInterbankCode) && <li className="list-inside list-disc">Código interbancario</li>}
							{!accIdentityType && <li className="list-inside list-disc">Tipo de documento</li>}
							{accIdentityType && !identityNumberValidation(accIdentityNumber) && (
								<li className="list-inside list-disc">
									{accIdentityType === 'rif'
										? 'RIF'
										: accIdentityType === 'cedula'
											? 'Cédula'
											: accIdentityType === 'ruc'
												? 'Ficha RUC'
												: accIdentityType.toUpperCase()}
								</li>
							)}
						</ul>
					</Dialog>
				)}

				<Dialog title="Información Bancaria" closable={false}>
					{loading && (
						<div className="absolute inset-0 z-50 flex h-full w-full items-center justify-center rounded-xl bg-black/70">
							<ContainerSpinner size={2.75} />
						</div>
					)}

					{/* {fetched && ( */}
					<form className="w-[400px] max-w-full space-y-6 pt-1" onSubmit={(e) => e.preventDefault()}>
						<div className="flex flex-col gap-4">
							<div className="flex max-w-full flex-wrap gap-3 md:flex-row md:flex-nowrap">
								<Select
									options={banks.map((e) => ({ label: e, value: e }))}
									defaultValue={accBank || undefined}
									onSelectedChange={(value) => {
										setAccBank(typeof value === 'string' ? value.trim() : '');
										if (typeof value === 'string' && value.trim().length > 6) {
											const bank_prefix = value.trim().slice(0, 6);
											if (bank_prefix.endsWith(' -') && bank_prefix.slice(0, -2).replace(/\D/g, '').length === 4) {
												setAccNumber(bank_prefix.slice(0, -2).replace(/\D/g, ''));
												setConfirmAccNumber('');
											}
										}
									}}
									Icon={<Landmark className="size-4" />}
									floatingLabel="Banco"
									placeholder="Seleccionar"
									containerClassName="grow"
								/>
								<Select
									containerClassName="min-w-min"
									options={['corriente', 'ahorro'].map((e) => ({ label: ucfirst(e), value: e }))}
									defaultValue={accType || undefined}
									onSelectedChange={(value) => setAccType(typeof value === 'string' ? value.trim() : '')}
									floatingLabel="Tipo de cuenta"
									placeholder="Seleccionar"
								/>
							</div>
							<Input
								allowedChars="alphanumeric"
								floatingLabel="Nombre completo del titular"
								placeholder="Ingresar nombre"
								value={accHolder}
								Icon={<UserCircle2 className="size-4" />}
								validation={accHolderValidation}
								onChange={(value) => setAccHolder(value.trim())}
							/>
							<Input
								allowedChars="numbers_only"
								floatingLabel="Número de cuenta"
								placeholder="Ingresar número"
								value={accNumber}
								Icon={<Hash className="size-4" />}
								validation={(value) => validate_bank_account_number(value, country)}
								onChange={(value) => setAccNumber(value.trim())}
								interpolation={
									country === 'venezuela'
										? [
												{ after: 4, char: '-' },
												{ after: 8, char: '-' },
												{ after: 12, char: '-' },
												{ after: 16, char: '-' }
											]
										: country === 'rd'
											? [
													{ after: 3, char: '-' },
													{ after: 6, char: '-' }
												]
											: country === 'peru'
												? [{ after: 3, char: '-' }]
												: undefined
								}
							/>
							<Input
								key={accBank}
								type="password"
								allowedChars="numbers_only"
								floatingLabel="Confirmar número de cuenta"
								placeholder="Ingresar número"
								value={confirmAccNumber}
								Icon={<Hash className="size-4" />}
								validation={(value) => validate_bank_account_number(value, country) && value === accNumber}
								onChange={(value) => setConfirmAccNumber(value.trim())}
							/>
							{country === 'peru' && (
								<>
									<Input
										allowedChars="numbers_only"
										floatingLabel="Código de cuenta interbancario"
										placeholder="Ingresar código"
										value={accInterbankCode}
										Icon={<span className="text-xs font-semibold tracking-tight">CCI</span>}
										validation={(value) => validate_interbank_code(value, country)}
										onChange={(value) => setAccInterbankCode(value.trim())}
										interpolation={[
											{ after: 3, char: '-' },
											{ after: 6, char: '-' },
											{ after: 18, char: '-' }
										]}
									/>
									<Input
										key={accBank}
										type="password"
										allowedChars="numbers_only"
										floatingLabel="Confirmar código interbancario"
										placeholder="Ingresar código"
										value={confirmInterbankCode}
										Icon={<span className="text-xs font-semibold tracking-tight">CCI</span>}
										validation={(value) => validate_interbank_code(value, country) && value === accInterbankCode}
										onChange={(value) => setConfirmInterbankCode(value.trim())}
									/>
								</>
							)}
							<Select
								Icon={<IdCard className="size-4" />}
								options={
									country === 'venezuela' 
										? ['RIF C.A. (J-)', 'Cédula Natural (V-)', 'RIF F.P. (V-)'].map((e, i) => ({ label: e, value: i === 0 ? 'rif' : i === 1 ? 'cedula' : 'firma_personal' }))
										: country === 'rd'
											? ['Cédula', 'RNC'].map((e, i) => ({ label: e, value: i === 0 ? 'cedula' : 'rnc' }))
											: ['DNI', 'Ficha RUC'].map((e, i) => ({ label: e, value: i === 0 ? 'dni' : 'ruc' }))
								}
								defaultValue={accIdentityType || undefined}
								onSelectedChange={(value) => setAccIdentityType(typeof value === 'string' ? (value.trim() as any) : '')}
								floatingLabel="Tipo de documento"
								placeholder="Seleccionar"
							/>
							<Input
								floatingLabel={`${(accIdentityType === 'rif' || accIdentityType === 'firma_personal') ? 'RIF' : accIdentityType === 'cedula' ? 'Cédula' : accIdentityType === 'ruc' ? 'Ficha RUC'  : accIdentityType ? accIdentityType?.toUpperCase() : 'Número de documento'}`}
								placeholder={`Ingresar ${(accIdentityType === 'rif' || accIdentityType === 'firma_personal') ? 'RIF' : accIdentityType === 'cedula' ? 'Cédula' : accIdentityType === 'ruc' ? 'Ficha RUC'  : accIdentityType?.toUpperCase()}`}
								allowedChars="numbers_only"
								value={accIdentityNumber}
								prefix={
									accIdentityType === 'rif' ? 'J-' : (accIdentityType === 'cedula' || accIdentityType === 'firma_personal') && country === 'venezuela' ? 'V-' : undefined
								}
								Icon={<Hash className="size-4" />}
								validation={identityNumberValidation}
								onChange={(value) => setAccIdentityNumber(value.trim())}
								containerClassName={`${accIdentityType ? 'grow' : 'hidden'}`}
							/>
						</div>
						<div className="flex justify-end gap-2">
							<Button variant="secondary" className="w-auto" onClick={() => setDialogOpen(false)}>
								Cancelar
							</Button>
							<Button
								type="submit"
								variant="primary"
								className="w-auto"
								onClick={() => {
									if (!isFormValid) setValidationError(true);
									else {
										onSubmit({
											bank: accBank,
											type: accType,
											holder: accHolder,
											account: accNumber,
											...(country === 'peru' ? { interbank_code: accInterbankCode } : {}),
											identity_number: accIdentityNumber,
											identity_type: accIdentityType as IdentityType
										});
									}
								}}
							>
								Confirmar
							</Button>
						</div>
					</form>
					{/* )} */}
				</Dialog>
			</>
		);
	} else {
		return (
			<article className="group relative w-full shrink-0 overflow-hidden rounded-2xl bg-gradient-to-br from-neutral-925/60 to-neutral-925/20 p-6 shadow-md backdrop-blur-sm transition-all hover:shadow-lg light:from-neutral-950/100 light:to-neutral-950/20">
				<div className="absolute inset-0 -z-10 bg-gradient-to-br from-emerald-500/10 to-teal-500/10 opacity-0 transition-opacity group-hover:opacity-100" />

				<header className="mb-6 flex items-center justify-between">
					<h1 className="flex items-center gap-2 text-xl font-medium">
						<Landmark className="size-5 text-emerald-400" />
						Transferencia
					</h1>
					<div className="flex items-center gap-2">
						<button
							className={`text-neutral-400 transition-colors hover:text-neutral-200`}
							disabled={false}
							onClick={() => setDialogOpen(true)}
						>
							<Pencil className="size-5" />
						</button>
						<span className="text-neutral-400">
							<Unlock className="size-5" />
						</span>
					</div>
				</header>

				<button
					onClick={() => setDialogOpen(true)}
					className="flex w-full items-center justify-center gap-2 rounded-lg bg-neutral-800/30 p-8 text-neutral-400 transition-colors group-hover:bg-neutral-925/20 group-hover:text-neutral-100"
				>
					<PlusCircle className="size-5 shrink-0" />
					<span className="hidden sm:inline">Ingresar información bancaria</span>
					<span className="sm:hidden">Ingresar banco</span>
				</button>
			</article>
		);
	}
}
// } else {
//     return (<article className="shrink-0 w-full group relative overflow-hidden rounded-2xl bg-gradient-to-br from-neutral-800/50 to-neutral-900/50 p-6 shadow-lg backdrop-blur-sm transition-all hover:shadow-xl">
//         <div className="absolute inset-0 -z-10 bg-gradient-to-br from-emerald-500/10 to-teal-500/10 opacity-0 transition-opacity group-hover:opacity-100" />

//         <header className="mb-6 flex items-center justify-between">
//             <h1 className="flex items-center gap-2 text-xl font-medium">
//                 <Landmark className="size-5 text-emerald-400" />
//                 Transferencia
//             </h1>
//             <div className="flex items-center gap-2">
//                 <button
//                     className={`text-neutral-400 hover:text-neutral-200 transition-colors ${!data?.transferBs?.account && 'opacity-50 cursor-not-allowed'}`}
//                     disabled={!data?.transferBs?.account}
//                     onClick={() => setDialogOpen(true)}
//                 >
//                     <Pencil className="size-5" />
//                 </button>
//                 <span className="text-neutral-400">
//                     {data?.transferBs?.account ? <Unlock className="size-5" /> : <Lock className="size-5" />}
//                 </span>
//             </div>
//         </header>

//         {data?.transferBs?.account ? (
//             <div className="space-y-4">
//                 <div className="rounded-lg bg-neutral-900/50 p-3">
//                     <p className="text-sm text-neutral-400">Banco</p>
//                     <p className="font-medium">{data?.transferBs?.bank}</p>
//                 </div>

//                 <div className="rounded-lg bg-neutral-900/50 p-3">
//                     <p className="text-sm text-neutral-400">Titular</p>
//                     <p className="font-medium">{data?.transferBs?.titular}</p>
//                 </div>

//                 <div className="flex gap-4">
//                     <div className="flex-1 rounded-lg bg-neutral-900/50 p-3">
//                         <p className="text-sm text-neutral-400">Cuenta {ucfirst(data?.transferBs?.type || '')}</p>
//                         <p className="font-mono text-sm">{data?.transferBs?.account}</p>
//                     </div>
//                     <div className="flex-1 rounded-lg bg-neutral-900/50 p-3">
//                         <p className="text-sm text-neutral-400">RIF</p>
//                         <p className="font-mono">{data?.transferBs?.rif}</p>
//                     </div>
//                 </div>
//             </div>
//         ) : (
//             <button
//                 onClick={() => setDialogOpen(true)}
//                 className=" flex w-full items-center justify-center gap-2 rounded-lg bg-neutral-800/50 p-8 text-neutral-400 transition-colors hover:bg-neutral-800 hover:text-neutral-200"
//             >
//                 <PlusCircle className="size-5 shrink-0" />
//                 <span className="hidden sm:inline">Ingresar información bancaria</span>
//                 <span className="sm:hidden">Ingresar banco</span>
//             </button>
//         )}
//     </article>);
// }
