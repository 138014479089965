import * as React from 'react';
import { format } from 'date-fns';
import { Calendar as CalendarIcon, X } from 'lucide-react';
import { DateRange } from 'react-day-picker';
import { Popover, PopoverContent, PopoverTrigger } from './Popover';
import { Button } from './Button';
import { cn } from './utils';
import { Calendar } from './Calendar';
import { es } from 'date-fns/locale';

export function DateRangePicker({
	disabled,
	className,
	onRangeChange
}: {
	disabled?: boolean;
	className?: string;
	onRangeChange: (selected: DateRange | undefined) => any;
}) {
	const [date, setDate] = React.useState<DateRange | undefined>(undefined);

	return (
		<div
			data-disabled={disabled || false}
			className={cn('grid gap-2 data-[disabled=true]:pointer-events-none data-[disabled=true]:opacity-60', className)}
		>
			<Popover>
				<PopoverTrigger asChild>
					<Button
						id="date"
						variant={'default'}
						className={cn('shrink-0 justify-start text-left light:bg-neutral-900/70', !date && 'text-neutral-100', className)}
					>
						<CalendarIcon className="mr-2 h-4 w-4 shrink-0" />
						{date?.from && date?.to ? (
							<>
								{format(date.from, 'LLL dd, y', { locale: es })} - {format(date.to, 'LLL dd, y', { locale: es })}
								<X
									onClick={() => {
										setDate(undefined);
										onRangeChange(undefined);
									}}
									className="ml-2 size-4 shrink-0"
								/>
							</>
						) : (
							<span>Rango de fechas</span>
						)}
					</Button>
				</PopoverTrigger>
				<PopoverContent className="w-auto p-0" align="start">
					<Calendar
						initialFocus
						mode="range"
						defaultMonth={date?.from}
						selected={date}
						onSelect={(selected) => {
							setDate(selected);
							onRangeChange(selected);
						}}
						locale={es}
						numberOfMonths={2}
					/>
				</PopoverContent>
			</Popover>
		</div>
	);
}
