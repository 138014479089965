import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../context/GlobalContext';
import {
	ArrowUpDown,
	Building2,
	ChevronDown,
	Clock,
	Edit,
	ImageUp,
	Mail,
	Map,
	MapPin,
	Plus,
	Power,
	Store,
	Trash2
} from 'lucide-react';
import { Button } from '../shadcn/Button';
import { Dialog } from '../components/Dialog';
import Input from '../components/Input';
import Select from '../components/Select';
import { validate_email, validate_phone_number } from '../utils/validate';
import useFetch, { API } from '../hooks/api';
import Spinner, { ContainerSpinner } from '../components/Spinner';
import { Instagram, WhatsApp } from '@material-ui/icons';
import ErrorRedirectDialog from '../components/ErrorRedirectDialog';
import { Navigate } from 'react-router-dom';
import classNames from 'classnames';
import Firebase from '../firebase_setup';
import { GetCities } from '../hooks/firebase';
import * as Accordion from '@radix-ui/react-accordion';
import PhoneInput from '../components/PhoneInput';

export default function Sucursales({
	onboarding,
	updateRegistrationSteps
}: {
	onboarding?: true;
	updateRegistrationSteps?: () => void;
}) {
	const { userData } = useContext(GlobalContext);
	const { master_store, country } = userData!;

	if (!userData?.master_user) Navigate({ to: '/' });

	const cities = GetCities(userData?.country || null);

	const [loading, setLoading] = useState(false);
	const { data, error, refetch } = useFetch(
		'https://api.krece.app/storeprofile/branch/',
		'GET',
		true,
		{
			email: master_store.id
		},
		setLoading
	);

	const [selectedBrand, setSelectedBrand] = useState<Brand>();
	const [deleteBrand, setDeleteBrand] = useState<string | null>(null);

	const [selectedBranch, setSelectedBranch] = useState<Sucursal>();
	const [deleteBranch, setDeleteBranch] = useState<{ email: string; is_active: boolean } | null>(null);
	const [reactivateBranch, setReactivateBranch] = useState<{ email: string } | null>(null);
	const [moveBranch, setMoveBranch] = useState<{ email: string; brand: string } | null>(null);
	const [addSellerAccount, setAddSellerAccount] = useState<{ brand: string; store_email: string } | null>(null);
	const [deleteSellerAccount, setDeleteSellerAccount] = useState<{
		brand: string;
		store_email: string;
		seller_account: { email: string; first_name: string; last_name: string };
	} | null>(null);
	const [uploadingImage, setUploadingImage] = useState<false | string>(false);
	const [uploadImageError, setUploadingImageError] = useState<null | string>(null);

	const UpsertSucursalDialog = ({ isNew = false, image = false }) => {
		if (selectedBranch && typeof selectedBranch.hours_of_operation !== 'object') {
			selectedBranch.hours_of_operation = {
				Lunes: { active: false, from: 9, to: 5 },
				Martes: { active: false, from: 9, to: 5 },
				Miércoles: { active: false, from: 9, to: 5 },
				Jueves: { active: false, from: 9, to: 5 },
				Viernes: { active: false, from: 9, to: 5 },
				Sábado: { active: false, from: 9, to: 5 },
				Domingo: { active: false, from: 9, to: 5 }
			};
		}

		const [rerender, setRerender] = useState(0);
		const [submit, setSubmit] = useState(false);
		const { loading, error, setPayload, fetched } = useFetch(
			'https://api.krece.app/storeprofile/branch/',
			!isNew ? 'PUT' : 'POST',
			submit
		);

		const [validationError, setValidationError] = useState(false);
		const emailValidation = (value: string) => validate_email(value.trim());
		const nameValidation = (value: string) => value.trim().length >= 3;
		const addressValidation = (value: string) => value.trim().includes(' ') && value.trim().length >= 7;
		const operatingHoursValidation = (value: { [key: string]: { active: boolean; from: number; to: number } }) =>
			Object.values(value).some((day) => day.active);
		const cityValidation = (value: string) => value.trim();
		const phoneValidation = (value: string) => validate_phone_number(value, country);
		const instagramValidation = (value: string) => value.trim().length >= 4;

		function submitChanges() {
			if (!selectedBranch) return;

			if (
				!emailValidation(selectedBranch.store_email) ||
				!nameValidation(selectedBranch.store_name) ||
				!addressValidation(selectedBranch.location) ||
				!operatingHoursValidation(selectedBranch.hours_of_operation) ||
				!cityValidation(selectedBranch.city) ||
				(selectedBranch.whatsapp && !phoneValidation(selectedBranch.whatsapp)) ||
				(selectedBranch.instagram && !instagramValidation(selectedBranch.instagram))
			) {
				setValidationError(true);
			} else {
				setPayload({ email: master_store.id, ...selectedBranch });
				setSubmit(true);
			}
		}

		useEffect(() => {
			if (fetched && !error) {
				setSelectedBranch(undefined);

				if (!onboarding) refetch();
				else updateRegistrationSteps?.();

				if (image) setUploadingImage(false);
			}
		}, [fetched, error]);

		useEffect(() => {
			if (image && selectedBranch) {
				setPayload({ email: master_store.id, ...selectedBranch });
				setSubmit(true);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		if (!selectedBranch) return null;
		if (image) return null;

		return (
			<>
				{validationError && (
					<Dialog title="¡Error!" onClose={() => setValidationError(false)} zIndex={51}>
						<p>Los siguientes campos están vacíos o son inválidos:</p>
						<ul className="text-red-400">
							{!emailValidation(selectedBranch.store_email) && <li className="list-inside list-disc">Email</li>}
							{!nameValidation(selectedBranch.store_name) && <li className="list-inside list-disc">Nombre</li>}
							{!cityValidation(selectedBranch.city) && <li className="list-inside list-disc">Ciudad</li>}
							{!addressValidation(selectedBranch.location) && <li className="list-inside list-disc">Dirección</li>}
							{selectedBranch.whatsapp && !phoneValidation(selectedBranch.whatsapp) && (
								<li className="list-inside list-disc">WhatsApp</li>
							)}
							{selectedBranch.instagram && !instagramValidation(selectedBranch.instagram) && (
								<li className="list-inside list-disc">Instagram</li>
							)}
							{!operatingHoursValidation(selectedBranch.hours_of_operation) && (
								<li className="list-inside list-disc">Horario de Atención</li>
							)}
						</ul>
					</Dialog>
				)}
				{error && (
					<Dialog title="¡Error!" zIndex={52}>
						<p>{typeof error === 'string' ? error : 'Ha ocurrido un error. Intente más tarde.'}</p>
					</Dialog>
				)}

				<Dialog title={isNew ? 'Nuevo Local' : 'Editar Local'} closable={false}>
					{loading && (
						<div className="absolute inset-0 z-50 flex h-full w-full items-center justify-center rounded-xl bg-black/70 light:bg-black/20">
							<ContainerSpinner size={2.75} />
						</div>
					)}

					<form
						className="w-[400px] max-w-full space-y-6"
						onSubmit={(e) => {
							e.preventDefault();
							submitChanges();
						}}
					>
						<div className="mt-1 flex flex-col gap-4">
							<Input
								readonly={!isNew}
								value={selectedBranch.store_email.trim()}
								floatingLabel="Email"
								placeholder="Ingrese el email del local"
								Icon={<Mail className="size-4" />}
								validation={emailValidation}
								onChange={(value) => (selectedBranch.store_email = value.trim())}
							/>
							<Input
								readonly={!isNew}
								value={selectedBranch.store_name.trim()}
								floatingLabel="Nombre"
								placeholder="Ingrese el nombre del local"
								Icon={<Store className="size-4" />}
								validation={nameValidation}
								onChange={(value) => (selectedBranch.store_name = value.trim())}
							/>
							<Select
								options={cities.map((e) => ({ label: e, value: e }))}
								defaultValue={selectedBranch.city || undefined}
								onSelectedChange={(value) => (selectedBranch.city = typeof value === 'string' ? value.trim() : '')}
								Icon={<Map className="size-4" />}
								floatingLabel="Ciudad"
								placeholder="Elige la ciudad"
							/>
							<Input
								floatingLabel="Dirección"
								placeholder="Ingrese la dirección del local"
								value={selectedBranch.location.trim()}
								Icon={<MapPin className="size-4" />}
								validation={addressValidation}
								onChange={(value) => (selectedBranch.location = value.trim())}
							/>
							<PhoneInput
								value={selectedBranch?.whatsapp || data?.[selectedBranch?.brand]?.info?.whatsapp || undefined}
								country={country}
								onChange={(value) => (selectedBranch.whatsapp = value.trim())}
							/>
							<Input
								prefix="@"
								floatingLabel="Instagram"
								placeholder="Ingrese el Instagram del local"
								value={
									selectedBranch?.instagram?.replaceAll('@', '') ||
									data?.[selectedBranch?.brand]?.info?.instagram?.replaceAll('@', '') ||
									undefined
								}
								Icon={<Instagram className="!text-[1rem]" />}
								validation={instagramValidation}
								transformation={(value) => value.replaceAll('@', '')}
								onChange={(value) => (selectedBranch.instagram = value.trim())}
							/>
							<div className="mt-1 space-y-1">
								<header className="flex items-center gap-2">
									<Clock className="size-5 text-neutral-400" />
									<h2 className="font-medium text-neutral-200">Horario de Atención</h2>
								</header>

								<div className="flex flex-col">
									{Object.keys(selectedBranch.hours_of_operation).map((day) => (
										<div
											key={day}
											className="flex select-none items-center justify-between rounded-lg p-1.5 transition-colors hover:bg-neutral-900/50"
										>
											<label className="flex items-center gap-1.5 md:gap-3">
												<div className="relative flex items-center">
													<input
														type="checkbox"
														checked={selectedBranch.hours_of_operation?.[day]?.active}
														className="peer size-4 rounded-md border-2 border-neutral-700 bg-neutral-950 transition-colors checked:border-sky-500 checked:bg-sky-500"
														onChange={(e) => {
															selectedBranch.hours_of_operation[day].active = e.target.checked;
															setRerender(rerender + 1);
														}}
													/>
													<div className="pointer-events-none absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 opacity-0 transition-opacity peer-checked:opacity-100">
														<svg
															className="size-3 text-white"
															viewBox="0 0 24 24"
															fill="none"
															stroke="currentColor"
															strokeWidth="3"
															strokeLinecap="round"
															strokeLinejoin="round"
														>
															<polyline points="20 6 9 17 4 12" />
														</svg>
													</div>
												</div>
												<span className="text-sm font-medium text-neutral-200 md:text-base">{day}</span>
											</label>

											<div className="flex items-center gap-1">
												<Select
													className="!h-8 w-[4rem] bg-neutral-950 disabled:opacity-50 md:w-[4.25rem]"
													options={Array.from({ length: 8 }, (_, i) => ({
														label: String(i + 4).padStart(2, '0'),
														value: String(i + 4)
													}))}
													defaultValue="9"
													onSelectedChange={(value) => (selectedBranch.hours_of_operation[day].from = Number(value))}
													disabled={!selectedBranch.hours_of_operation?.[day]?.active}
												/>
												<span className="text-sm font-medium text-neutral-300 md:text-base">AM</span>
												<span className="mx-1 shrink-0 text-sm text-neutral-500 md:mx-2 md:text-base">a</span>
												<Select
													className="!h-8 w-[4rem] bg-neutral-950 disabled:opacity-50 md:w-[4.25rem]"
													options={Array.from({ length: 11 }, (_, i) => ({
														label: String(i + 1).padStart(2, '0'),
														value: String(i + 1)
													}))}
													defaultValue="5"
													onSelectedChange={(value) => (selectedBranch.hours_of_operation[day].to = Number(value))}
													disabled={!selectedBranch.hours_of_operation?.[day]?.active}
												/>
												<span className="text-sm font-medium text-neutral-300 md:text-base">PM</span>
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
						<div className="flex justify-end gap-2">
							<Button variant="secondary" className="w-auto" onClick={() => setSelectedBranch(undefined)}>
								Cancelar
							</Button>
							<Button type="submit" variant="primary" className="w-auto">
								Confirmar
							</Button>
						</div>
					</form>
				</Dialog>
			</>
		);
	};

	const UpsertBrandDialog = ({ isNew = false, image = false }) => {
		const [counter, setCounter] = useState(0);
		const [submit, setSubmit] = useState(false);
		const { loading, error, setPayload, fetched } = useFetch('https://api.krece.app/storeprofile/brand/', 'POST', submit);

		const isFormValid = () => {
			return (
				selectedBrand &&
				selectedBrand.brand.trim().length >= 4 &&
				validate_phone_number(selectedBrand.whatsapp, country) &&
				((country !== 'peru' || selectedBrand.instagram) ? selectedBrand.instagram.trim().length >= 4 : true)
			);
		};

		function submitChanges() {
			if (!selectedBrand || !data || (!isFormValid() && !image)) return;

			const brands: Brand[] = Object.keys(data).map(
				(brand) =>
					({
						brand,
						whatsapp: data[brand].info?.whatsapp || '',
						instagram: data[brand].info?.instagram?.replaceAll('@', '') || '',
						logo: data[brand].info?.logo || null
					}) satisfies Brand
			);

			const brandIndex = brands.findIndex(({ brand }) => brand === selectedBrand.brand);
			if (brandIndex === -1) {
				brands.push({
					...selectedBrand,
					logo: selectedBrand.logo || null
				});
			} else {
				brands[brandIndex] = selectedBrand;
			}

			setPayload({ email: master_store.id, brands });
			setSubmit(true);
		}

		useEffect(() => {
			if (fetched && !error) {
				setSelectedBrand(undefined);
				refetch();
				if (image) setUploadingImage(false);
			}
		}, [fetched, error]);

		useEffect(() => {
			if (image) submitChanges();
		}, []);

		if (!selectedBrand) return null;
		if (image) return null;

		return (
			<>
				{error && (
					<Dialog title="¡Error!" zIndex={52}>
						<p>{typeof error === 'string' ? error : 'Ha ocurrido un error. Intente más tarde.'}</p>
					</Dialog>
				)}

				<Dialog title={isNew ? 'Nueva Tienda' : 'Editar Tienda'} closable={false}>
					<form
						className="space-y-6"
						onSubmit={(e) => {
							e.preventDefault();
							submitChanges();
						}}
					>
						<div className="mt-1 flex flex-col gap-4">
							<Input
								allowedChars="alphanumeric"
								readonly={!isNew}
								value={selectedBrand.brand.trim()}
								floatingLabel="Nombre"
								placeholder="Ingrese el nombre de la tienda"
								Icon={<Building2 className="size-4" />}
								validation={(value) => value.trim().length >= 4}
								onChange={(value) => {
									setCounter(counter + 1);
									selectedBrand.brand = value.trim();
								}}
							/>
							<PhoneInput
								value={selectedBrand.whatsapp}
								country={country}
								onChange={(value) => {
									setCounter(counter + 1);
									selectedBrand.whatsapp = value.trim();
								}}
							/>
							<Input
								prefix="@"
								floatingLabel="Instagram"
								placeholder="Ingrese el Instagram de la tienda"
								value={selectedBrand.instagram?.replaceAll('@', '') || undefined}
								Icon={<Instagram className="!text-[1.2rem]" />}
								validation={(value) => value.trim().length >= 4}
								transformation={(value) => value.replaceAll('@', '')}
								onChange={(value) => {
									setCounter(counter + 1);
									selectedBrand.instagram = value.trim();
								}}
							/>
						</div>
						<div className="flex justify-end gap-2">
							<Button
								disabled={loading}
								variant="default"
								className="bg-neutral-900 hover:bg-neutral-900/70"
								onClick={() => setSelectedBrand(undefined)}
							>
								Cancelar
							</Button>
							<Button
								disabled={!isFormValid()}
								loading={loading}
								type="submit"
								variant="default"
								className="bg-sky-600 hover:bg-sky-800"
							>
								Confirmar
							</Button>
						</div>
					</form>
				</Dialog>
			</>
		);
	};

	const AddSellerAccountDialog = ({ brand, store_email }: { brand: string; store_email: string }) => {
		const [submit, setSubmit] = useState(false);
		const { loading, error, setPayload, fetched } = useFetch('https://api.krece.app/storeprofile/branch/seller/', 'POST', submit);

		const [email, setEmail] = useState('');
		const [first_name, setFirstName] = useState('');
		const [last_name, setLastName] = useState('');

		const is_form_valid = validate_email(email) && first_name.trim().length >= 3 && last_name.trim().length >= 3;

		useEffect(() => {
			if (fetched && !error) {
				setAddSellerAccount(null);
				refetch();
			}
		}, [fetched, error]);

		return (
			<>
				{error && (
					<Dialog title="¡Error!" zIndex={52}>
						<p>{typeof error === 'string' ? error : 'Ha ocurrido un error. Intente más tarde.'}</p>
					</Dialog>
				)}
				<Dialog title="Agregar Cuenta de Vendedor" closable={false}>
					<form
						className="space-y-6"
						onSubmit={(e) => {
							e.preventDefault();
							setPayload({
								brand,
								store_email,
								seller_account: {
									email: email.trim().toLowerCase(),
									first_name: first_name.trim(),
									last_name: last_name.trim()
								}
							});
							setSubmit(true);
						}}
					>
						<div className="mt-1 flex flex-col gap-3">
							<Input
								type="email"
								floatingLabel="Email de la cuenta"
								placeholder="Email para la cuenta"
								Icon={<Mail className="size-4" />}
								value={email}
								validation={validate_email}
								onChange={(value) => setEmail(value.trim())}
							/>
							<Input
								floatingLabel="Nombre"
								placeholder="Nombre del vendedor"
								value={first_name}
								validation={(value) => value.trim().length >= 3}
								onChange={(value) => setFirstName(value.trim())}
							/>
							<Input
								floatingLabel="Apellidos"
								placeholder="Apellidos del vendedor"
								value={last_name}
								validation={(value) => value.trim().length >= 3}
								onChange={(value) => setLastName(value.trim())}
							/>
						</div>
						<div className="flex justify-end gap-2">
							<Button variant="secondary" className="w-auto" onClick={() => setAddSellerAccount(null)}>
								Cancelar
							</Button>
							<Button type="submit" variant="primary" className="w-auto" disabled={!is_form_valid} loading={loading}>
								Confirmar
							</Button>
						</div>
					</form>
				</Dialog>
			</>
		);
	};

	const DeleteSellerAccountDialog = ({
		brand,
		store_email,
		seller_account
	}: {
		brand: string;
		store_email: string;
		seller_account: { email: string; first_name: string; last_name: string };
	}) => {
		const [submit, setSubmit] = useState(false);
		const { loading, error, fetched } = useFetch('https://api.krece.app/storeprofile/branch/seller/', 'DELETE', submit, {
			brand,
			store_email: store_email,
			seller_account: seller_account
		});

		useEffect(() => {
			if (fetched && !error) {
				setDeleteSellerAccount(null);
				refetch();
			}
		}, [fetched, error]);

		return (
			<>
				{error && (
					<Dialog title="¡Error!" zIndex={52}>
						<p>{typeof error === 'string' ? error : 'Ha ocurrido un error. Intente más tarde.'}</p>
					</Dialog>
				)}

				<Dialog title="Eliminar Tienda" closable={false}>
					<div className="space-y-6">
						<p>
							¿Está seguro que desea eliminar la cuenta de vendedor <strong>{seller_account.email}</strong>?
						</p>
						<div className="flex justify-end gap-2">
							<Button variant="secondary" className="w-auto" onClick={() => setDeleteSellerAccount(null)} disabled={loading}>
								Cancelar
							</Button>
							<Button
								loading={loading}
								type="submit"
								variant="primary"
								className="w-auto !bg-red-500 hover:!bg-red-700"
								onClick={() => setSubmit(true)}
							>
								Eliminar
							</Button>
						</div>
					</div>
				</Dialog>
			</>
		);
	};

	const DeleteBrandDialog = ({ brand }: { brand: string }) => {
		const [submit, setSubmit] = useState(false);
		const { loading, error, fetched } = useFetch('https://api.krece.app/storeprofile/brand/', 'DELETE', submit, {
			brand_name: brand
		});

		useEffect(() => {
			if (fetched && !error) {
				setDeleteBrand(null);
				refetch();
			}
		}, [fetched, error]);

		return (
			<>
				{error && (
					<Dialog title="¡Error!" zIndex={52}>
						<p>{typeof error === 'string' ? error : 'Ha ocurrido un error. Intente más tarde.'}</p>
					</Dialog>
				)}

				<Dialog title="Eliminar Tienda" closable={false}>
					<div className="space-y-6">
						<p>
							¿Está seguro que desea eliminar la tienda <strong>{brand}</strong>?
						</p>
						<div className="flex justify-end gap-2">
							<Button variant="secondary" className="w-auto" onClick={() => setDeleteBrand(null)} disabled={loading}>
								Cancelar
							</Button>
							<Button
								loading={loading}
								type="submit"
								variant="primary"
								className="w-auto !bg-red-500 hover:!bg-red-700"
								onClick={() => setSubmit(true)}
							>
								Eliminar
							</Button>
						</div>
					</div>
				</Dialog>
			</>
		);
	};

	const DeleteSucursalDialog = () => {
		const [submit, setSubmit] = useState(false);
		const { loading, error, fetched } = useFetch('https://api.krece.app/storeprofile/branch/', 'DELETE', submit, {
			store_email: deleteBranch!.email,
			email: master_store.id
		});

		useEffect(() => {
			if (fetched && !error) {
				setDeleteBranch(null);
				refetch();
				// window.location.reload();
			}
		}, [fetched, error]);

		return (
			<>
				{error && (
					<Dialog title="¡Error!" zIndex={52}>
						<p>{typeof error === 'string' ? error : 'Ha ocurrido un error. Intente más tarde.'}</p>
					</Dialog>
				)}
				<Dialog title={deleteBranch?.is_active ? 'Desactivar Local' : 'Eliminar Local'} closable={false}>
					{loading && (
						<div className="absolute inset-0 z-50 flex h-full w-full items-center justify-center rounded-xl bg-black/70">
							<ContainerSpinner size={2.75} />
						</div>
					)}
					<div className="space-y-6">
						<p className="mb-2">
							¿Está seguro que desea {deleteBranch?.is_active ? 'desactivar el local ' : 'eliminar el local y su cuenta '}
							asociada con el email <strong>{deleteBranch?.email}</strong>?
						</p>
						{deleteBranch?.is_active ? (
							<span className="rounded-lg border border-yellow-400/90 bg-yellow-900/20 p-1.5 text-sm font-bold text-yellow-400 light:bg-yellow-500/50 light:text-yellow-100">
								Podrá reactivarla más adelante si desea
							</span>
						) : (
							<span className="rounded-lg border border-red-400/90 bg-red-900/20 p-1.5 text-sm font-bold text-red-400">
								¡Esta acción es irreversible!
							</span>
						)}
						<div className="flex justify-end gap-2">
							<Button variant="default" className="bg-neutral-900 hover:bg-neutral-900/70" onClick={() => setDeleteBranch(null)}>
								Cancelar
							</Button>
							<Button
								type="submit"
								variant="default"
								className={classNames({
									'bg-red-500 text-red-100 hover:bg-red-700': !deleteBranch?.is_active,
									'bg-yellow-500/80 text-yellow-100 hover:bg-yellow-700': deleteBranch?.is_active
								})}
								onClick={() => setSubmit(true)}
							>
								{deleteBranch?.is_active ? 'Desactivar' : 'Eliminar'}
							</Button>
						</div>
					</div>
				</Dialog>
			</>
		);
	};

	const ReactivateSucursalDialog = () => {
		return (
			<Dialog title="Reactivar Local" closable={false}>
				<div className="space-y-6">
					<p className="mb-2">Para reactivar este local, por favor contacte al equipo de Krece.</p>

					<div className="flex justify-end gap-2">
						<Button
							variant="default"
							className="bg-neutral-900 hover:bg-neutral-900/70"
							onClick={() => setReactivateBranch(null)}
						>
							Salir
						</Button>
					</div>
				</div>
			</Dialog>
		);
	};

	const MoveBranchDialog = () => {
		const [newBrand, setNewBrand] = useState<string | null>(null);

		const [submit, setSubmit] = useState(false);
		const { loading, error, setPayload, fetched } = useFetch('https://api.krece.app/storeprofile/branch/move/', 'POST', submit);

		useEffect(() => {
			if (fetched && !error) {
				setMoveBranch(null);
				refetch();
			}
		}, [fetched, error]);

		if (!data || !moveBranch) return null;
		return (
			<>
				{error && (
					<Dialog title="¡Error!" zIndex={52}>
						<p>{typeof error === 'string' ? error : 'Ha ocurrido un error. Intente más tarde.'}</p>
					</Dialog>
				)}
				<Dialog title="Mover Local" closable={false}>
					<div className="space-y-4">
						<p>Seleccione la tienda a la que desea mover este local:</p>
						<Select
							defaultValue={moveBranch.brand}
							options={Object.keys(data).map((brand) => ({ label: brand, value: brand }))}
							onSelectedChange={(value) => setNewBrand(value as string)}
						/>
						<div className="flex justify-end gap-2">
							<Button variant="default" className="bg-neutral-900 hover:bg-neutral-900/70" onClick={() => setMoveBranch(null)}>
								Cancelar
							</Button>
							<Button
								type="submit"
								variant="primary"
								className="w-auto"
								disabled={!newBrand || moveBranch.brand === newBrand}
								loading={loading}
								onClick={() => {
									setSubmit(true);
									setPayload({
										email: master_store.id,
										store_email: moveBranch.email,
										current_brand: moveBranch.brand,
										new_brand: newBrand!
									});
								}}
							>
								Confirmar
							</Button>
						</div>
					</div>
				</Dialog>
			</>
		);
	};

	const Sucursal = ({ data, brand }: { data: Sucursal; brand: Brand }) => (
		<li>
			<article className={classNames('rounded-xl bg-neutral-950/70 light:bg-neutral-1000')}>
				<header className="flex w-full items-center justify-between gap-1 border-b border-neutral-900 p-2 sm:gap-2 md:p-4">
					<div className="flex items-center gap-1 sm:gap-2">
						<div
							onClick={(e) => (e.currentTarget.lastElementChild as HTMLInputElement).click()}
							className={classNames(
								'group relative flex size-10 shrink-0 cursor-pointer items-center justify-center overflow-hidden rounded-full bg-neutral-900 transition-colors hover:bg-primary-500 sm:size-12',
								{
									'pointer-events-none': uploadingImage === data?.store_email || !data?.is_active,
									'hover:!bg-black': data?.logo || brand.logo
								}
							)}
						>
							{uploadingImage === data?.store_email && <Spinner />}
							{uploadingImage !== data?.store_email && (
								<>
									{data?.logo ||
										(brand.logo && (
											<img
												src={data?.logo || brand.logo}
												alt="Logo del Local"
												className={classNames('size-full object-cover transition-opacity group-hover:opacity-40', {
													'opacity-40': !data?.is_active
												})}
											/>
										))}
									<ImageUp
										className={classNames(
											'size-6 transition-all group-hover:scale-110',
											{
												'absolute opacity-0 group-hover:opacity-100 light:text-white': data?.logo || brand.logo
											},
											{
												'opacity-0': !data?.is_active
											}
										)}
									/>
								</>
							)}

							<input
								type="file"
								accept="image/*"
								className="hidden"
								onChange={async (e) => {
									if (uploadingImage) return;
									setUploadingImage(data?.store_email);

									const IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/bmp', 'image/tiff', 'image/webp'];

									try {
										const image = e.currentTarget.files?.[0];
										if (!image) throw new Error();

										const is_image = image.size && IMAGE_TYPES.includes(image.type);
										const extension = image.name.split('.')?.pop()?.toLowerCase();
										const size = image.size;

										if (!is_image || !extension) {
											throw 'Debe subir una imagen válida.';
										} else if (size > 3000000) {
											throw 'El tamaño de la imagen seleccionada no puede superar los 3MB.';
										}

										const storage = Firebase.storage();
										const storageRef = storage.ref();
										const fileName = `${data?.store_email}_${Date.now()}.${extension}`;
										const fileRef = storageRef.child(fileName);
										await fileRef.put(image);

										const downloadURL = await fileRef.getDownloadURL();
										if (downloadURL) {
											setSelectedBranch({
												store_email: data.store_email,
												city: data.city,
												hours_of_operation: data.hours_of_operation,
												location: data.location,
												store_name: data.store_name,
												brand: data.brand,
												logo: downloadURL
											});
										} else {
											throw new Error();
										}
									} catch (error) {
										setUploadingImageError(typeof error === 'string' ? error : 'Error al subir la imagen.');
										setUploadingImage(false);
									}
								}}
							/>
						</div>
						<div className="flex flex-col">
							<h3
								className={classNames('overflow-hidden overflow-ellipsis text-nowrap text-lg font-medium tracking-tight leading-none', {
									'opacity-40': !data?.is_active
								})}
						>
								{data?.store_name}
							</h3>
							<p className="text-sm text-neutral-300/90 overflow-hidden overflow-ellipsis text-nowrap tracking-tight">{data?.city}</p>
						</div>
					</div>
					<div className="flex shrink-0 items-center justify-end gap-2">
						{data.is_active ? (
							<>
								<button
									className="flex size-8 shrink-0 items-center justify-center rounded-lg bg-neutral-900 transition-opacity hover:opacity-60"
									onClick={() => setMoveBranch({ email: data.store_email, brand: data.brand })}
								>
									<ArrowUpDown className="size-[1.1rem]" />
								</button>
								<button
									className="flex size-8 shrink-0 items-center justify-center rounded-lg bg-neutral-900 transition-opacity hover:opacity-60"
									onClick={() =>
										setSelectedBranch({
											store_email: data.store_email,
											city: data.city,
											hours_of_operation: data.hours_of_operation,
											location: data.location,
											store_name: data.store_name,
											brand: data.brand,
											logo: data?.logo || null
										})
									}
								>
									<Edit className="size-[1.1rem]" />
								</button>
								<button
									className="flex size-8 shrink-0 items-center justify-center rounded-lg bg-green-500/60 transition-all hover:bg-yellow-500/70"
									onClick={() => setDeleteBranch({ email: data.store_email, is_active: true })}
								>
									<Power className="size-[1.1rem]" strokeWidth={2.5} />
								</button>
							</>
						) : (
							<>
								<button
									className="flex size-8 shrink-0 items-center justify-center rounded-lg bg-red-500/70 transition-opacity hover:opacity-60"
									onClick={() => setDeleteBranch({ email: data.store_email, is_active: false })}
								>
									<Trash2 className="size-[1.1rem]" />
								</button>
								<button
									className="flex size-8 shrink-0 items-center justify-center rounded-lg bg-yellow-500/70 transition-all hover:bg-green-500/60"
									onClick={() => setReactivateBranch({ email: data.store_email })}
								>
									<Power className="size-[1.1rem]" strokeWidth={2.5} />
								</button>
							</>
						)}
					</div>
				</header>

				<div className="flex flex-col">
					<ul
						className={classNames(
							'col-span-2 hidden w-full grid-cols-2 content-center p-2 text-sm text-neutral-200/90 md:grid md:p-4',
							{
								'opacity-65 grayscale': !data?.is_active
							}
						)}
					>
						<li className="flex items-center gap-2">
							<Mail className="size-4 shrink-0 text-neutral-600" />
							<p className="overflow-hidden overflow-ellipsis text-nowrap">{data.store_email}</p>
						</li>
						<li className="flex items-center gap-2">
							<Instagram className="shrink-0 !text-[1rem] text-neutral-600" />
							<p className="overflow-hidden overflow-ellipsis text-nowrap">
								@{data?.instagram?.replaceAll('@', '') || brand?.instagram?.replaceAll('@', '') || 'N/A'}
							</p>
						</li>
						<li className="flex items-center gap-2">
							<WhatsApp className="shrink-0 !text-[1rem] text-neutral-600" />
							<p className="overflow-hidden overflow-ellipsis text-nowrap">{data?.whatsapp || brand?.whatsapp || 'N/A'}</p>
						</li>
						<li className="flex items-center gap-2">
							<MapPin className="size-4 shrink-0 text-neutral-600" />
							<p className="overflow-hidden overflow-ellipsis text-nowrap">{data.location}</p>
						</li>
					</ul>

					<Accordion.Root type="single" collapsible className="w-full">
						<Accordion.Item value="sellers">
							<Accordion.Trigger className="group flex w-full items-center justify-between border-t border-neutral-900 px-4 py-3 text-sm font-medium text-neutral-400 hover:text-neutral-200">
								<div className="flex items-center gap-2">
									Cuentas de Vendedores
									<span className="rounded-full bg-neutral-900 px-2 py-0.5 text-xs">{data.seller_accounts?.length || 0}</span>
								</div>
								<ChevronDown className="size-4 transition-transform group-data-[state=open]:rotate-180" />
							</Accordion.Trigger>
							<Accordion.Content className="overflow-hidden data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down">
								<div className="space-y-4 p-4 pt-0">
									<ul className="space-y-2">
										{data.seller_accounts?.map((account) => (
											<li key={account.email} className="flex items-center justify-between rounded-lg bg-neutral-925/80 p-2">
												<div>
													<p className="text-sm font-medium">
														{account.first_name?.trim()} {account.last_name?.trim()}
													</p>
													<p className="text-xs text-neutral-400">{account.email}</p>
												</div>
												<button
													className="rounded-lg bg-red-500/20 p-1.5 text-red-400 transition-colors hover:bg-red-500/30"
													onClick={() =>
														setDeleteSellerAccount({ brand: brand.brand, store_email: data.store_email, seller_account: account })
													}
												>
													<Trash2 className="size-4" />
												</button>
											</li>
										))}
										{!data.seller_accounts?.length && (
											<p className="text-center text-neutral-300">No hay cuentas de vendedores aún...</p>
										)}
									</ul>
									<button
										onClick={() => setAddSellerAccount({ brand: brand.brand, store_email: data.store_email })}
										className="flex items-center gap-1 justify-self-end rounded-lg bg-neutral-900 px-2 py-1 text-xs font-medium text-neutral-200 transition-opacity hover:opacity-80"
									>
										<Plus className="size-3" />
										Agregar
									</button>
								</div>
							</Accordion.Content>
						</Accordion.Item>
					</Accordion.Root>
				</div>
			</article>
		</li>
	);

	const Brand = ({
		brand,
		whatsapp,
		instagram,
		sucursales,
		logo
	}: Brand & {
		sucursales: Sucursal[];
	}) => (
		<section key={brand} className="overflow-hidden rounded-xl border border-neutral-900 bg-neutral-925/80">
			<header className="flex shrink-0 items-center justify-between border-b border-neutral-900 bg-neutral-950/80 p-3 sm:p-4 gap-1 md:gap-2">
				<h1 className="flex items-center gap-2 sm:gap-4">
					{
						<div
							onClick={(e) => (e.currentTarget.lastElementChild as HTMLInputElement).click()}
							className={classNames(
								'group relative flex size-14 shrink-0 cursor-pointer items-center justify-center overflow-hidden rounded-full bg-neutral-900 transition-colors hover:bg-primary-500 sm:size-16',
								{ 'pointer-events-none': uploadingImage === brand, 'hover:!bg-black': logo }
							)}
						>
							{uploadingImage === brand && <Spinner />}
							{uploadingImage !== brand && (
								<>
									{logo && (
										<img
											src={logo}
											alt="Logo de la Tienda"
											className="size-full object-cover transition-opacity group-hover:opacity-40"
										/>
									)}
									<ImageUp
										className={classNames('size-7 transition-all group-hover:scale-110', {
											'absolute opacity-0 group-hover:opacity-100 light:text-white': logo
										})}
									/>
								</>
							)}

							<input
								type="file"
								accept="image/*"
								className="hidden"
								onChange={async (e) => {
									if (uploadingImage) return;
									setUploadingImage(brand);

									const IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/bmp', 'image/tiff', 'image/webp'];

									try {
										const image = e.currentTarget.files?.[0];
										if (!image) throw new Error();

										const is_image = image.size && IMAGE_TYPES.includes(image.type);
										const extension = image.name.split('.')?.pop()?.toLowerCase();
										const size = image.size;

										if (!is_image || !extension) {
											throw 'Debe subir una imagen válida.';
										} else if (size > 3000000) {
											throw 'El tamaño de la imagen seleccionada no puede superar los 3MB.';
										}

										const storage = Firebase.storage();
										const storageRef = storage.ref();
										const fileName = `${brand}_${Date.now()}.${extension}`;
										const fileRef = storageRef.child(fileName);
										await fileRef.put(image);

										const downloadURL = await fileRef.getDownloadURL();
										if (downloadURL) {
											setSelectedBrand({
												brand,
												instagram: instagram?.replaceAll('@', '') || '',
												whatsapp: whatsapp || '',
												logo: downloadURL
											});
										} else {
											throw new Error();
										}
									} catch (error) {
										setUploadingImageError(typeof error === 'string' ? error : 'Error al subir la imagen.');
										setUploadingImage(false);
									}
								}}
							/>
						</div>
					}
					<div className="flex flex-col gap-1 md:gap-2 p-1">
						<h2 className="flex items-center gap-3 text-lg md:text-xl lg:text-2xl font-bold tracking-tight text-neutral-0">{brand}</h2>
						<div className="flex flex-wrap md:flex-nowrap items-center gap-1 md:gap-4 text-sm text-neutral-300/90">
							<div className="group flex items-center gap-1 md:gap-2 transition-colors hover:text-neutral-100">
								<div className="flex items-center justify-center rounded-full bg-red-500/10 p-0.5 md:p-1">
									<Instagram className="!text-[1rem] md:!text-[1.25rem] text-red-400" />
								</div>
								{instagram ? (
									<span className="truncate font-medium">@{instagram?.replaceAll('@', '')}</span>
								) : (
									<span className="truncate rounded-md bg-red-500/20 light:bg-red-500/10 px-2 py-0.5 text-xs font-medium text-red-300 light:text-red-400">{country !== 'peru' ? 'Requerido' : 'N/A'}</span>
								)}
							</div>
							<div className="group flex items-center gap-1 md:gap-2 transition-colors hover:text-neutral-100">
								<div className="flex items-center justify-center rounded-full bg-green-500/10 p-0.5 md:p-1">
									<WhatsApp className="!text-[1rem] md:!text-[1.25rem] text-green-400" />
								</div>
								{whatsapp ? (
									<span className="truncate font-medium">{whatsapp}</span>
								) : (
									<span className="truncate rounded-md bg-red-500/20 light:bg-red-500/10 px-2 py-0.5 text-xs font-medium text-red-300 light:text-red-400">Requerido</span>
								)}
							</div>
						</div>
					</div>
				</h1>
				<div className="flex items-center gap-2 shrink-0">
					{!sucursales.length && Object.keys(data || {}).length > 1 && (
						<Button variant="destructive" className="!size-10 !p-0" onClick={() => setDeleteBrand(brand)}>
							<Trash2 className="size-5" />
						</Button>
					)}
					<Button
						className="!size-10 bg-neutral-900 !p-0 shrink-0"
						onClick={() =>
							setSelectedBrand({
								brand: brand || '',
								instagram: instagram?.replaceAll('@', '') || '',
								whatsapp: whatsapp || '',
								logo: logo || ''
							})
						}
					>
						<Edit className="size-5" strokeWidth={2.25} />
					</Button>
					<Button
						className="shrink-0 size-10 bg-primary-500/20 font-medium text-primary-200 transition-colors hover:bg-primary-500/20 light:text-primary-600 light:hover:bg-primary-500/40 md:size-auto md:h-10"
						onClick={() =>
							setSelectedBranch({
								store_email: '',
								city: '',
								hours_of_operation: {
									Lunes: { active: true, from: 9, to: 5 },
									Martes: { active: true, from: 9, to: 5 },
									Miércoles: { active: true, from: 9, to: 5 },
									Jueves: { active: true, from: 9, to: 5 },
									Viernes: { active: true, from: 9, to: 5 },
									Sábado: { active: true, from: 9, to: 5 },
									Domingo: { active: true, from: 9, to: 5 }
								},
								location: '',
								store_name: '',
								brand,
								logo: null
							})
						}
					>
						<Plus className="size-4 scale-[2.75] md:scale-100" strokeWidth={2.5} />
						<span className="hidden md:inline-flex">Local</span>
					</Button>
				</div>
			</header>
			<ol className="flex flex-col gap-4 p-4">
				{sucursales.map((branch) => (
					<Sucursal
						key={branch.store_email}
						data={branch}
						brand={{ brand, logo, instagram: instagram?.replaceAll('@', ''), whatsapp }}
					/>
				))}
				{!sucursales.length && <p className="text-center text-neutral-300">No hay locales aún...</p>}
			</ol>
		</section>
	);

	if (onboarding) {
		return (
			<>
				{loading && <ContainerSpinner size={3} className="mt-4" />}
				{error && <ErrorRedirectDialog />}

				{selectedBranch && <UpsertSucursalDialog isNew={true} />}

				{data && !loading && !error && (
					<div className="mx-auto flex h-full max-w-5xl flex-col items-center justify-center gap-4 p-4 text-center">
						<p className="text-neutral-300">Agregue un local para comenzar</p>
						<Button
							variant="primary"
							className="w-auto gap-1.5"
							onClick={() =>
								setSelectedBranch({
									store_email: '',
									city: '',
									hours_of_operation: {
										Lunes: { active: true, from: 9, to: 5 },
										Martes: { active: true, from: 9, to: 5 },
										Miércoles: { active: true, from: 9, to: 5 },
										Jueves: { active: true, from: 9, to: 5 },
										Viernes: { active: true, from: 9, to: 5 },
										Sábado: { active: true, from: 9, to: 5 },
										Domingo: { active: true, from: 9, to: 5 }
									},
									location: '',
									store_name: '',
									brand: Object.keys(data)[0],
									logo: null
								})
							}
						>
							<Plus className="size-4" strokeWidth={2.5} />
							Añadir Local
						</Button>
					</div>
				)}
			</>
		);
	}

	return (
		<>
			{loading && <ContainerSpinner size={3} />}
			{error && <ErrorRedirectDialog />}
			{uploadImageError && !error && (
				<Dialog title="" onClose={() => setUploadingImageError(null)}>
					{uploadImageError}
				</Dialog>
			)}

			{selectedBrand && <UpsertBrandDialog isNew={selectedBrand.brand ? false : true} image={uploadingImage ? true : false} />}
			{deleteBrand && <DeleteBrandDialog brand={deleteBrand} />}

			{selectedBranch && (
				<UpsertSucursalDialog isNew={selectedBranch.store_name ? false : true} image={uploadingImage ? true : false} />
			)}
			{deleteBranch && <DeleteSucursalDialog />}
			{reactivateBranch && <ReactivateSucursalDialog />}
			{moveBranch && <MoveBranchDialog />}
			{addSellerAccount && <AddSellerAccountDialog brand={addSellerAccount.brand} store_email={addSellerAccount.store_email} />}
			{deleteSellerAccount && (
				<DeleteSellerAccountDialog
					brand={deleteSellerAccount.brand}
					store_email={deleteSellerAccount.store_email}
					seller_account={deleteSellerAccount.seller_account}
				/>
			)}

			{data && !loading && !error && (
				<div className="mx-auto max-w-[52rem] space-y-6 p-4 md:p-[1.25rem]">
					<div className="flex items-center justify-between px-1">
						<h1 className="flex items-center gap-3 text-2xl font-bold tracking-tight text-neutral-0 3xl:text-3xl">
							<Store className="size-7 text-primary-200 light:text-primary-600" strokeWidth={2} />
							Mis Tiendas
						</h1>
						<Button
							variant="default"
							className="flex items-center gap-2 rounded-lg bg-primary-500/20 px-4 py-2.5 font-medium text-primary-200 transition-colors hover:bg-primary-500/30 light:text-primary-600 light:hover:bg-primary-500/40"
							onClick={() =>
								setSelectedBrand({
									brand: '',
									whatsapp: '',
									instagram: '',
									logo: null
								})
							}
						>
							<Plus className="size-4" strokeWidth={2.5} />
							Añadir tienda
						</Button>
					</div>

					{Object.keys(data).map((brand) => (
						<Brand
							key={brand}
							brand={brand}
							whatsapp={data[brand].info.whatsapp}
							instagram={data[brand].info.instagram?.replaceAll('@', '')}
							sucursales={data[brand].sucursales.map((e) => ({ ...e, brand }))}
							logo={data[brand].info.logo || null}
						/>
					))}
				</div>
			)}
		</>
	);
}

type Sucursal = Omit<API['https://api.krece.app/storeprofile/branch/']['POST']['request'], 'email'> &
	API['https://api.krece.app/storeprofile/branch/']['GET']['response'][string]['sucursales'][number];

type Brand = API['https://api.krece.app/storeprofile/brand/']['GET']['response'][number];
