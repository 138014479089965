import ErrorRedirectDialog from '../components/ErrorRedirectDialog';
import { ContainerSpinner } from '../components/Spinner';
import useFetch from '../hooks/api';
import OfflineTable from '../components/OfflineTable';
import { Button } from '../shadcn/Button';
import { useEffect, useState } from 'react';
import auth from '../auth';
import { Navigate, useNavigate } from 'react-router-dom';

export default function LogInAs() {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const { data, error } = useFetch('https://api.krece.app/storeprofile/masteruser/', 'GET', true, undefined, setLoading);

	const [getToken, setGetToken] = useState(false);
	const {
		data: token,
		loading: tokenLoading,
		error: tokenError,
		setPayload
	} = useFetch('https://api.krece.app/storeprofile/get_custom_token/', 'POST', getToken, undefined, setLoading);

	useEffect(() => {
		if (token) {
			setLoading(true);
			auth
				.signInWithCustomToken(token)
				.catch(() => {})
				.finally(() => {
					setTimeout(() => {
						navigate('/', { replace: true });
					}, 2000);
				});
		}
	}, [token]);

	if (loading || !data) return <ContainerSpinner size={3} />;
	if (error || tokenError) return <ErrorRedirectDialog />;

	const headers = ['Email', 'Action'];
	const rows = data.map((email: string) => [
		email,
		<Button
			variant="primary"
			className="w-auto h-auto"
			disabled={tokenLoading}
			onClick={() => {
				setGetToken(true);
				setPayload({ email });
			}}
		>
			Login
		</Button>
	]);

	return (
		<div className="mx-auto flex h-full max-w-5xl flex-col items-center justify-center gap-4 p-4 text-center">
			<OfflineTable headers={headers} rows={rows} />
		</div>
	);
}
