import React, { useState, useContext, useEffect } from 'react';
import { GlobalContext } from '../context/GlobalContext';
import { ContainerSpinner } from '../components/Spinner';
import { ArrowLeft, CircleCheck, DollarSign, KeyRound } from 'lucide-react';
import { Button } from '../shadcn/Button';
import Stepper from '../components/Stepper';
import Input from '../components/Input';
import Notice from '../components/Notice';
import useFetch from '../hooks/api';
import { GetATACountryEmail } from '../hooks/firebase';

const ChangePrice = () => {
	const { authenticated, userData } = useContext(GlobalContext);
	const country = userData?.country;
	const ataEmail = GetATACountryEmail(userData?.country || null);

	const [isLoading, setIsLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [page, setPage] = useState(1);

	const [deviceID, setDeviceID] = useState('');
	const [newPrice, setNewPrice] = useState('');
	const [cedulaForAuthCode, setCedulaForAuthCode] = useState('');
	const [authCodeForAuthCode, setAuthCodeForAuthCode] = useState('');

	const [sendEmail, setSendEmail] = useState(false);
	const {
		data: sendEmailData,
		error: sendEmailError,
		setPayload: setSendEmailPayload
	} = useFetch('https://api.krece.app/notifications/requestauthcode/', 'POST', sendEmail);

	useEffect(() => {
		if (sendEmailError) {
			setErrorMessage(
				'Ocurrió un error al enviar el código de verificación. Vuelva a empezar el proceso desde el inicio. Si el problema persiste, contacte a soporte técnico.'
			);
		}
	}, [sendEmailError]);

	const [verifyDevice, setVerifyDevice] = useState(false);
	const {
		error: verifyDeviceError,
		fetched: verifyDeviceFetched,
		loading: verifyDeviceLoading,
		setPayload: setVerifyDevicePayload
	} = useFetch('https://api.krece.app/registration/is_valid_id/', 'POST', verifyDevice);

	useEffect(() => {
		if (verifyDeviceFetched && !verifyDeviceError) {
			setErrorMessage('');
			setSendEmail(true);
			setSendEmailPayload({
				email: ataEmail || 'ata@krece.app',
				code_type: 'email'
			});
			setPage(3);
		} else if (verifyDeviceError) {
			setErrorMessage(
				typeof verifyDeviceError === 'string' ? verifyDeviceError : 'Ocurrió un error al verificar el número de confirmación'
			);
		}
	}, [verifyDeviceFetched, verifyDeviceError]);

	var currentDay = new Date().getDate();
	if (currentDay === 31) {
		currentDay = 30;
	}

	const sendPostRequest = async (data: any, endpoint: string) => {
		try {
			const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${userData!.firebase_token}`,
					'x-master-store-email': userData!.master_store.id,
					'x-user-email': userData!.email
				},
				body: JSON.stringify({ ...data, country: userData!.country })
			});
			const responseData = await response.json();
			return responseData;
		} catch (error) {
			console.error('Error:', (error as any)?.message);
			return null;
		}
	};

	const sendAction = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setIsLoading(true);
		setErrorMessage('');
		if (!newPrice || !deviceID || !authCodeForAuthCode) {
			setErrorMessage('Llene todos los datos para continuar');
			return;
		}
		let data = {
			confirmation_code: authCodeForAuthCode,
			device_id: deviceID,
			master_store_email: userData!.master_store.id,
			device_price: newPrice
		};
		const response = await sendPostRequest(data, 'change_device_selling_price_with_code/');

		if (response?.success) {
			setErrorMessage('');
			setIsLoading(false);
			setPage(4);
		} else if (response?.success === false) {
			setErrorMessage(response.status);
			setIsLoading(false);
		} else {
			setErrorMessage('Estamos teniendo problemas para conectarnos.');
		}

		setIsLoading(false);
	};

	const checkCedula = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setErrorMessage('');
		if (!cedulaForAuthCode) {
			setErrorMessage('Llene todos los datos para continuar');
			return;
		}
		setIsLoading(true);
		try {
			const cedulaData = {
				identification_number: cedulaForAuthCode
			};
			const response = await sendPostRequest(cedulaData, 'registration/usercheck/'); //status, success, data
			if (response?.success) {
				if (response.data.is_user_registered) {
					setErrorMessage('');
					setPage(2);
					setIsLoading(false);
				} else {
					setErrorMessage(`No encontramos al cliente asociado a ${country === 'peru' ? 'este DNI' : 'esta cédula'}. Por favor contactar a soporte técnico.`);
					setIsLoading(false);
				}
			} else if (response?.success === false) {
				setErrorMessage('Estamos teniendo problemas para conectarnos.');
				setIsLoading(false);
			} else {
				setErrorMessage('Estamos teniendo problemas para conectarnos.');
				setIsLoading(false);
			}
		} catch (error) {
			setErrorMessage('Estamos teniendo problemas para conectarnos.');
			setIsLoading(false);
		}
	};

	const steps = [
		{ label: country === 'peru' ? 'DNI' : 'Cédula', pages: [1] },
		{ label: 'Precio', pages: [2] },
		{ label: 'Verificación', pages: [3] },
		{ label: 'Cambio Exitoso', pages: [4] }
	];

	if (!authenticated || !userData?.country || !userData?.master_store?.id || !userData?.email || !userData?.firebase_token)
		return <ContainerSpinner size={3} />;

	return (
		<div className="m-auto flex min-h-full min-w-full flex-col items-center justify-start">
			<Stepper currentPage={page} steps={steps} />
			<div className="m-auto flex h-full w-full flex-col items-center justify-center overflow-x-hidden p-4 md:p-6">
				<div className="w-[500px] max-w-full rounded-xl border-[1.5px] border-neutral-900 bg-neutral-1000/25 p-6 shadow-lg duration-300 animate-in fade-in slide-in-from-bottom-5 light:bg-white light:shadow-none md:bg-background/50 md:p-8">
					{page === 1 && (
						<>
							{render_page_header(
								'Cambiar Precio de Venta',
								`Por favor ingrese ${country === 'peru' ? 'el DNI' : 'la cédula'} del cliente para cambiar el precio de venta`,
								<DollarSign />
							)}

							<form className="space-y-4" onSubmit={checkCedula}>
								<Input
									name="cedula"
									floatingLabel={`${country === 'peru' ? 'DNI' : 'Cédula'} del cliente`}
									value={cedulaForAuthCode}
									onChange={(value) => setCedulaForAuthCode(value.toLowerCase().trim())}
									placeholder={`Ingrese ${country === 'peru' ? 'el DNI' : 'la cédula'} del cliente`}
									allowedChars="numbers_only"
								/>

								{errorMessage && <Notice message={errorMessage} variant="error" />}

								<Button
									disabled={isLoading || !cedulaForAuthCode}
									loading={isLoading}
									type="submit"
									variant="primary"
								>
									Continuar
								</Button>
							</form>
						</>
					)}

					{page === 2 && (
						<>
							{render_page_header('Precio', 'Por favor ingrese el nuevo precio de venta', <DollarSign />)}

							<form
								className="space-y-4"
								onSubmit={(e) => {
									e.preventDefault();
									setVerifyDevice(true);
									setVerifyDevicePayload({
										id: deviceID,
										object_type: 'Devices'
									});
								}}
							>
								<Input
									floatingLabel="ID del Equipo"
									value={deviceID}
									onChange={(value) => setDeviceID(value)}
									placeholder="Ingrese el ID del equipo"
								/>

								<Input
									floatingLabel="Nuevo Precio"
									value={newPrice}
									onChange={(value) => setNewPrice(value)}
									placeholder="Ingrese el nuevo precio"
									allowedChars="numeric"
								/>

								{errorMessage && <Notice message={errorMessage} variant="error" />}

								<div className="flex flex-wrap gap-2 md:flex-row-reverse md:flex-nowrap">
									<Button
										disabled={isLoading || !deviceID || !newPrice || verifyDeviceLoading}
										loading={isLoading || verifyDeviceLoading}
										type="submit"
										variant="primary"
									>
										Continuar
									</Button>

									<Button
										Icon={<ArrowLeft className="size-5" />}
										disabled={isLoading || verifyDeviceLoading}
										type="button"
										onClick={() => (window.location.href = '/changePrice')}
										variant="secondary"
									>
										Atrás
									</Button>
								</div>
							</form>
						</>
					)}

					{page === 3 && (
						<>
							{render_page_header(
								'Verificación',
								'Para continuar con el cambio de precio, por favor contacte a soporte técnico para solicitar un código de verificación',
								<KeyRound />
							)}

							<form className="space-y-4" onSubmit={sendAction}>
								{sendEmailData?.success && <Notice message={'El equipo de Krece ya recibió el código 👍'} variant="success" />}

								<Input
									floatingLabel="Código de verificación"
									value={authCodeForAuthCode}
									onChange={(value) => setAuthCodeForAuthCode(value)}
									placeholder="Ingrese el código de verificación"
								/>

								{errorMessage && <Notice message={errorMessage} variant="error" />}

								<div className="flex flex-wrap gap-2 md:flex-row-reverse md:flex-nowrap">
									<Button disabled={isLoading || !authCodeForAuthCode} loading={isLoading} type="submit" variant="primary">
										Continuar
									</Button>

									<Button
										Icon={<ArrowLeft className="size-5" />}
										disabled={isLoading}
										type="button"
										onClick={() => (window.location.href = '/deletePayment')}
										variant="secondary"
									>
										Atrás
									</Button>
								</div>
							</form>
						</>
					)}

					{page === 4 && (
						<>
							{render_page_header('Cambio Exitoso', '¡El cambio de precio se ha completado correctamente!', <CircleCheck />)}

							<Button
								Icon={<ArrowLeft className="size-5" />}
								onClick={() => (window.location.href = '/changePrice')}
								variant="primary"
							>
								Cambiar otro precio
							</Button>
						</>
					)}
				</div>
			</div>
		</div>
	);

	function render_page_header(title: string, description: string, icon: React.ReactElement) {
		return (
			<div className="mb-4 flex flex-col items-center gap-2 md:mb-6 md:gap-3">
				<div className="relative">
					<div className="absolute -inset-1 rounded-full bg-gradient-to-r from-primary-400 to-primary-600 opacity-10 blur"></div>
					<div className="relative rounded-full bg-neutral-900 p-4 light:bg-neutral-1000 light:ring-2 light:ring-primary-500/10">
						{icon && React.cloneElement(icon, { className: 'h-6 w-6 text-primary-500' })}
					</div>
				</div>
				<div className="space-y-1 text-center">
					<h1 className="text-2xl font-semibold tracking-tighter text-neutral-0 md:text-3xl">{title}</h1>
					{description && <p className="text-neutral-300">{description}</p>}
				</div>
			</div>
		);
	}
};

export default ChangePrice;
