import { AlertCircle, AlertTriangle, CheckCircle2, Info } from 'lucide-react';
import { cn } from '../shadcn/utils';

interface NoticeProps {
    message: string;
    variant?: 'success' | 'warning' | 'error' | 'info';
    className?: string;
}

const Notice = ({ message, variant = 'error', className }: NoticeProps) => {
    const variants = {
        success: {
            bg: 'bg-emerald-500/10',
            text: 'text-emerald-400',
            Icon: CheckCircle2
        },
        warning: {
            bg: 'bg-yellow-500/10',
            text: 'text-yellow-400',
            Icon: AlertTriangle
        },
        error: {
            bg: 'bg-red-500/10',
            text: 'text-red-400',
            Icon: AlertCircle
        },
        info: {
            bg: 'bg-neutral-500/10',
            text: 'text-neutral-400',
            Icon: Info
        }
    };

    const { bg, text, Icon } = variants[variant];

    return (
        <p className={cn('text-sm flex items-center gap-2 rounded-lg px-3 py-2', bg, text, className)}>
            <Icon className="size-4 shrink-0" />
            {message}
        </p>
    );
};

export default Notice;
