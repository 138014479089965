import { useEffect, useState } from 'react';
import useFetch from '../hooks/api';
import Select from './Select';
import { KeyRound, MessageSquare, Phone, SendHorizonal } from 'lucide-react';
import { Button } from '../shadcn/Button';
import Input from './Input';
import Notice from './Notice';
import { validate_phone_number } from '../utils/validate';
import { WhatsApp } from '@material-ui/icons';
import { cn } from '../shadcn/utils';
import PhoneInput from './PhoneInput';

export default function TwoFactorAuth({
	select_phone_numbers,
	country,
	onSuccess
}: {
	select_phone_numbers?: { country: string; phone_number: string }[];
	country: string;
	onSuccess: (phone_number: string) => void;
}) {
	const [selected2faNumber, setSelected2faNumber] = useState<{ country: string; phone_number: string } | null>(null);

	const [code2fa, setCode2fa] = useState('');
	const [errorMessage2fa, setErrorMessage2fa] = useState('');
	const [cooldown, setCooldown] = useState(0);

	const [request2faMethod, setRequest2faMethod] = useState<'sms' | 'whatsapp'>('sms');
	const [request2fa, setRequest2fa] = useState(false);
	const {
		data: request2faResponse,
		setPayload: setRequest2faPayload,
		error: request2faError,
		loading: request2faLoading
	} = useFetch('https://api.krece.app/notifications/requestauthcode/', 'POST', request2fa, undefined, undefined, false);

	const [confirm2fa, setConfirm2fa] = useState(false);
	const {
		data: confirm2faResponse,
		setPayload: setConfirm2faPayload,
		error: confirm2faError,
		loading: confirm2faLoading
	} = useFetch('https://api.krece.app/notifications/confirmauthcode/', 'POST', confirm2fa, undefined, undefined, false);

	useEffect(() => {
		if (request2faError) {
			setErrorMessage2fa('Error enviando token, por favor intente nuevamente.');
		}
	}, [request2faError]);

	useEffect(() => {
		if (confirm2faError) {
			setErrorMessage2fa('Error confirmando token, por favor intente nuevamente.');
			setCode2fa('');
		}
	}, [confirm2faError]);

	useEffect(() => {
		if (code2fa.length === 6 && request2faResponse?.success) {
			setConfirm2fa(true);
			setConfirm2faPayload({
				confirmation_code: parseInt(code2fa),
				country: selected2faNumber?.country || ''
			});
		} else if (code2fa.length === 6 && !request2faResponse?.success) {
			setErrorMessage2fa('Todavía no se ha enviado el token, por favor envíelo primero.');
		}
	}, [code2fa, request2faResponse]);

	useEffect(() => {
		if (confirm2faResponse?.success) {
			onSuccess(selected2faNumber!.phone_number);
		}
	}, [confirm2faResponse]);

	useEffect(() => {
		if (cooldown > 0) {
			const timer = setTimeout(() => setCooldown(cooldown - 1), 1000);
			return () => clearTimeout(timer);
		}
	}, [cooldown]);

	const handleRequestCode = (method: 'sms' | 'whatsapp') => {
		if (cooldown > 0) return;

		setRequest2faMethod(method);
		setRequest2fa(true);
		setRequest2faPayload({
			code_type: method,
			phone_number: selected2faNumber!.phone_number,
			country: selected2faNumber!.country
		});
		setCooldown(30);
	};

	return (
		<div className="flex flex-col gap-2">
			{select_phone_numbers !== undefined ? (
				<Select
					options={select_phone_numbers?.map(({ phone_number }) => ({ label: phone_number, value: phone_number })) || []}
					Icon={<Phone className="size-4 text-neutral-300" />}
					onSelectedChange={(value) =>
						setSelected2faNumber({
							phone_number: value as string,
							country: select_phone_numbers!.find((n) => n.phone_number === value)?.country || country
						})
					}
					placeholder="Selecciona un número"
					floatingLabel="Número de teléfono"
				/>
			) : (
				<PhoneInput
					country={country}
					onChange={(value) => setSelected2faNumber({ country, phone_number: value })}
				/>
			)}
			{((selected2faNumber && (validate_phone_number(selected2faNumber.phone_number, selected2faNumber.country) || select_phone_numbers?.length)) || request2faResponse?.success) && (
				<>
					{!confirm2faResponse?.success && (
						<>
							<p className="select-none text-sm text-neutral-400">
								{cooldown > 0
									? `Debe esperar ${cooldown} segundos para enviar otro código...`
									: 'Selecciona un método para recibir tu código de verificación.'}
							</p>
							<div className={cn('flex gap-2', { 'mb-3.5': request2faResponse?.success && !confirm2faResponse?.success })}>
								<Button
									loading={request2faLoading && request2faMethod === 'sms'}
									disabled={confirm2faResponse?.success || request2faLoading || cooldown > 0}
									variant="primary"
									className="w-1/2 bg-blue-600 text-white hover:bg-blue-700"
									containerClassName="w-full"
									onClick={() => handleRequestCode('sms')}
								>
									<div className="flex w-full items-center justify-between">
										<div className="flex items-center gap-1">
											<MessageSquare className="relative top-[0.5px] size-4 text-blue-200 !hidden min-[420px]:!inline-flex" />
											<span className="text-blue-50">SMS</span>
										</div>
										<SendHorizonal className="size-4 text-blue-200" />
									</div>
								</Button>
								<Button
									loading={request2faLoading && request2faMethod === 'whatsapp'}
									disabled={confirm2faResponse?.success || request2faLoading || cooldown > 0}
									variant="primary"
									className="w-1/2 bg-green-600 text-white hover:bg-green-700"
									onClick={() => handleRequestCode('whatsapp')}
									containerClassName="w-full"
								>
									<div className="flex w-full items-center justify-between">
										<div className="flex items-center gap-1">
											<WhatsApp className="!text-[1rem] text-green-200 !hidden min-[420px]:!inline-flex" />
											<span className="text-green-50">WhatsApp</span>
										</div>
										<SendHorizonal className="size-4 text-green-200" />
									</div>
								</Button>
							</div>
						</>
					)}

					{request2faResponse?.success && !confirm2faResponse?.success && (
						<Input
							readonly={confirm2faResponse?.success}
							disabled={confirm2faLoading || confirm2faResponse?.success}
							containerClassName="w-full"
							Icon={<KeyRound className="size-4 text-primary-500" />}
							value={code2fa}
							onChange={(value) => setCode2fa(value)}
							placeholder="Ingresa el código"
							allowedChars="numbers_only"
							floatingLabel="Código de verificación"
						/>
					)}

					{errorMessage2fa && !confirm2faResponse?.success && (
						<Notice message={errorMessage2fa} variant="error" className="mt-1.5" />
					)}
					{confirm2faResponse?.success && <Notice message="Verificación exitosa 👍" variant="success" />}
				</>
			)}
		</div>
	);
}
