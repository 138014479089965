import classNames from 'classnames';
import { X } from 'lucide-react';
import React, { useState, ReactElement, useRef, ChangeEvent, useEffect } from 'react';
import { numbers_dot, numbers_letters_dot_comma, numbers_only } from '../utils/validate';

const applyInterpolation = (value: string, interpolation: Array<{ after: number; char: string }>) => {
	// First clean the value of any existing interpolation characters
	const cleanValue = interpolation.reduce((acc, { char }) => acc.replaceAll(char, ''), value);
	
	let result = cleanValue;
	let offset = 0;

	interpolation.forEach(({ after, char }) => {
		if (cleanValue.length > after) {
			const insertPosition = after + offset;
			result = result.slice(0, insertPosition) + char + result.slice(insertPosition);
			offset += 1;
		}
	});

	return { displayValue: result, actualValue: cleanValue };
};

export default function Input({
	name,
	placeholder,
	value = '',
	type = 'text',
	readonly = false,
	disabled,
	allowPaste = true,
	validation,
	transformation,
	allowedChars,
	clearable = true,
	maxLength,
	onChange,
	onSubmit,
	className = '',
	containerClassName = '',
	inputClassName = '',
	Icon,
	prefix = '',
	title,
	floatingLabel,
	interpolation,
}: {
	name?: string;
	placeholder: string;
	value?: string;
	type?: React.HTMLInputTypeAttribute;
	readonly?: boolean;
	disabled?: boolean;
	allowPaste?: boolean;
	validation?: (value: string) => boolean;
	transformation?: (value: string) => string;
	allowedChars?: 'numeric' | 'alphanumeric' | 'numbers_only';
	clearable?: boolean;
	maxLength?: number;
	onSubmit?: (value: string) => void;
	onChange?: (value: string) => void;
	className?: string;
	containerClassName?: string;
	inputClassName?: string;
	Icon?: ReactElement;
	prefix?: string;
	title?: string;
	floatingLabel?: string;
	interpolation?: { after: number; char: string }[];
}) {
	const [displayValue, setDisplayValue] = useState<string>(value);
	const [actualValue, setActualValue] = useState<string>(value);
	const inputEl = useRef<null | HTMLInputElement>(null);

	useEffect(() => {
		if (interpolation) {
			const { displayValue: newDisplay, actualValue: newActual } = applyInterpolation(value, interpolation);
			setDisplayValue(newDisplay);
			setActualValue(newActual);
		} else {
			setDisplayValue(value);
			setActualValue(value);
		}
	}, [value, interpolation]);

	const [isValid, setValid] = useState(true);

	const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
		let value = event.target.value;

		// Remove disallowed chars
		if (allowedChars) {
			value =
				allowedChars === 'numeric'
					? value.replaceAll(numbers_dot, '')
					: allowedChars === 'numbers_only'
						? value.replaceAll(numbers_only, '')
						: value.replaceAll(numbers_letters_dot_comma, '');
		}

		let displayValue = value;
		let actualValue = value;

		// Apply interpolation for display only
		if (interpolation) {
			const result = applyInterpolation(value, interpolation);
			displayValue = result.displayValue;
			actualValue = result.actualValue;
		}

		// Apply transformation before validation
		if (transformation) {
			actualValue = transformation(actualValue);
			displayValue = transformation(displayValue);
		}

		// Validate the transformed value
		if (!actualValue.trim() || (validation && validation(actualValue.trim()))) setValid(true);
		else if (validation && !validation(actualValue.trim())) setValid(false);

		setDisplayValue(displayValue);
		setActualValue(actualValue);

		if (onChange) {
			onChange(actualValue);
		}
	};

	const clearInput = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		e.preventDefault();
		setDisplayValue('');
		setActualValue('');
		setValid(true);
		inputEl.current?.focus();

		if (onChange) onChange('');
		if (onSubmit) onSubmit('');
	};

	const handlePaste = (e: React.ClipboardEvent) => {
		if (!allowPaste) {
			e.preventDefault();
		}
	};

	return (
		<div className={classNames(containerClassName, 'space-y-1')}>
			{title && <h3 className="font-medium tracking-tight text-neutral-300">{title}</h3>}
			<div
				data-disabled={disabled || false}
				className={classNames(
					'flex h-10 items-center rounded-lg justify-between gap-1 p-0 px-3  data-[disabled=true]:pointer-events-none data-[disabled=true]:opacity-60',
					{
						'!ring-2 !ring-red-400 !border-red-400': !isValid,
						'!pointer-events-none !select-none': readonly,
						'overflow-hidden bg-neutral-1000 light:bg-neutral-900/70 has-[:focus]:[box-shadow:inset_0_0_0_1.5px_hsl(var(--twc-neutral-800))]': !floatingLabel,
						'!h-12 relative bg-neutral-925 light:bg-neutral-950 border border-neutral-800 has-[:focus]:outline-none has-[:focus]:ring-2 has-[:focus]:ring-primary-500 has-[:focus]:border-primary-500 transition-all': floatingLabel
					},
					className
				)}
			>
				{Icon && onSubmit && <div className="shrink-0 cursor-pointer" onClick={() => onSubmit(actualValue)}>{Icon}</div>}
				{Icon && !onSubmit && <div className="shrink-0">{Icon}</div>}
				{prefix && <span className="shrink-0">{prefix}</span>}

				<input
					name={name}
					autoComplete='on'
					maxLength={maxLength}
					readOnly={readonly}
					type={type}
					className={classNames('peer w-full bg-transparent text-sm outline-none placeholder:text-neutral-400', inputClassName, {
						'!pointer-events-none !select-none': readonly,
						'!text-base krece-input-floating-label': floatingLabel,
						'krece-input': !floatingLabel
					})}
					placeholder={placeholder}
					ref={inputEl}
					value={displayValue}
					onChange={handleOnChange}
					onPaste={handlePaste}
					onKeyUp={(event) => {
						if (event.key === 'Enter' && onSubmit) onSubmit(actualValue);
					}}
					tabIndex={readonly ? -1 : undefined}
				/>

				{floatingLabel && (
					<label className="absolute -top-2 left-2 px-1 text-xs font-medium text-primary-200/80 bg-neutral-1000 rounded light:text-primary-900 light:bg-primary-300 overflow-hidden overflow-ellipsis text-nowrap max-w-[90%]">
						{floatingLabel}
					</label>
				)}

				{clearable && !disabled && !readonly && (
					<button
						type="button"
						tabIndex={-1}
						onClick={clearInput}
						className={classNames('mr-0.5 flex h-full shrink-0 items-center justify-center gap-1', {
							'pointer-events-none opacity-0': !actualValue.trim()
						})}
					>
						<X className="size-[1.125rem]" />
					</button>
				)}
			</div>
		</div>
	);
}
