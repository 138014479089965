import * as ScrollArea from '@radix-ui/react-scroll-area';
import classNames from 'classnames';
import { ChevronDown, ChevronLeft, ChevronRight, Download, Search } from 'lucide-react';
import { useContext, useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { GlobalContext } from '../context/GlobalContext';
import useFetch, { API, TableRequest } from '../hooks/api';
import { DateRangePicker } from '../shadcn/DateRangePicker';
import { Dialog } from './Dialog';
import Select from './Select';
import Spinner, { ContainerSpinner } from './Spinner';
import { Button } from '../shadcn/Button';
import Input from './Input';
import { ucfirst } from '../utils/formatters';

export default function Table({
	userEmail,
	endpoint,
	orderBy,
	pagination,
	filters,
	search_by,
	hideDateFilters = false,
	hideExport = false,
	hidePagination = false,
	actionButton = undefined,
	className = ''
}: {
	userEmail: string;
	endpoint: keyof API;
	orderBy: { columnName: string; columnOrder: 'ASC' | 'DESC' };
	pagination?: { batchSizes: number[]; default: number };
	filters?: {
		column: string;
		options: { label: string; value: string | boolean }[];
		defaultValue: string | boolean;
	}[];
	search_by?: { column: string; placeholder: string; type: 'numeric' | 'alphanumeric' };
	hideDateFilters?: boolean;
	hideExport?: boolean;
	hidePagination?: boolean;
	actionButton?: {
		label: string;
		onClick: (row: { [key: string]: string | number }) => any;
	};
	className?: string;
}) {
	const { userData } = useContext(GlobalContext);
	const { master_user, sub_stores, store_name } = userData!;

	const { data, error, loading, setPayload, payload } = useFetch(
		endpoint as 'https://api.krece.app/tables/payments/',
		'POST',
		true,
		{
			master_store_email: userEmail,
			last_object_id: null,
			...(pagination && { batch_size: pagination.default }),
			order_by: { column_name: orderBy.columnName, column_order: orderBy.columnOrder },
			...(!master_user && !sub_stores && store_name
				? { filter_by: [{ column_name: 'Sucursal', value: store_name }] }
				: endpoint === 'https://api.krece.app/tables/transactions/' && userData?.financed_by_krece
					? { filter_by: [{ column_name: 'Tipo de operación', value: 'Divisas en tienda' }] }
					: null)
		}
	);

	const objectIds = useRef<string[]>([]);
	if (data?.last_object_id && !objectIds.current.includes(data.last_object_id)) objectIds.current.push(data.last_object_id);

	const total = useRef(0);
	total.current = data?.total_count !== undefined && data?.total_count !== null ? data.total_count : total.current;

	const page = useRef(1);
	const batchSize = useRef(pagination?.default || 100);

	const dateRangeKey = useRef(Math.random());

	const [exportDataPayload, setExportDataPayload] = useState<null | TableRequest>(null);

	// Scroll to top of table when data changes
	const scrollAreaRef = useRef<null | HTMLDivElement>(null);
	useEffect(() => {
		if (scrollAreaRef.current) scrollAreaRef.current.scrollTop = 0;
	}, [payload]);

	return (
		<>
			{!data && !error && <ContainerSpinner className={className} size={3} />}
			{!data && error && (
				<div className="flex flex-col items-center justify-center gap-4 p-8 text-center">
					<p className="text-lg text-neutral-300">
						No se encontraron datos para mostrar en la tabla. Es posible que la tabla esté vacía o que no exista.
					</p>
					<div className="flex gap-4">
						<button
							onClick={() => window.location.reload()}
							className="rounded-lg bg-primary-600 px-4 py-2 text-sm font-medium text-white transition-opacity hover:opacity-80"
						>
							Recargar tabla
						</button>
						<button
							onClick={() => (window.location.href = '/')}
							className="rounded-lg bg-neutral-800 px-4 py-2 text-sm font-medium text-white transition-opacity hover:opacity-80"
						>
							Ir al inicio
						</button>
					</div>
				</div>
			)}
			{data && (
				<div className={classNames('flex h-full w-full flex-col gap-4 text-xs md:text-base', className)}>
					{exportDataPayload && (
						<ExportData onClose={() => setExportDataPayload(null)} endpoint={endpoint} payload={exportDataPayload} />
					)}
					{error && <Dialog title="¡Error!">Ha ocurrido un error cargando la tabla con los filtros seleccionados.</Dialog>}
					<div
						className={classNames('no-scrollbar flex min-h-10 w-full items-center justify-between gap-16 overflow-x-auto', {
							hidden: !filters?.length && hideDateFilters && hideExport
						})}
					>
						{filters?.length || search_by ? (
							<>
								<div className="flex shrink-0 gap-2 md:gap-4">
									{filters?.map(
										(filter, idx) =>
											filter.options.length &&
											!(filter.options.length === 1 && filter.options.some((e) => e.value === filter.defaultValue)) && (
												<Select
													disabled={loading}
													className="bg-foreground md:bg-neutral-950"
													key={idx}
													options={filter.options}
													defaultValue={filter.defaultValue}
													onSelectedChange={(selected) => {
														objectIds.current = [];
														page.current = 1;
														setPayload((payload) => {
															const idx = payload?.filter_by?.findIndex((e) => e.column_name === filter.column);
															if (idx !== undefined && idx !== -1) payload?.filter_by?.splice(idx, 1);

															return {
																...payload!,
																last_object_id: null,
																filter_by: [
																	...(payload?.filter_by || []),
																	...(selected !== null
																		? [
																				{
																					column_name: filter.column,
																					value: selected === 'true' ? true : selected === 'false' ? false : selected
																				}
																			]
																		: [])
																]
															} satisfies TableRequest;
														});
													}}
												/>
											)
									)}
									{search_by && (
										<Input
											disabled={loading}
											onSubmit={(value) => {
												objectIds.current = [];
												page.current = 1;
												setPayload((payload) => {
													if (!value.trim() && 'search_by' in payload!) delete payload.search_by;

													return {
														...payload!,
														last_object_id: null,
														...(value.trim() && { search_by: { column_name: search_by.column, value: value.trim() } })
													} satisfies TableRequest;
												});
											}}
											Icon={<Search className="size-4" strokeWidth={2.5} />}
											allowedChars={search_by.type}
											placeholder={search_by.placeholder}
											className="w-56"
										/>
									)}
								</div>
							</>
						) : (
							<div />
						)}

						<div className="flex shrink-0 items-center gap-2 md:gap-4">
							{!hideDateFilters && (
								<>
									<DateRangePicker
										disabled={loading}
										className="rounded-lg bg-foreground md:bg-neutral-950"
										key={dateRangeKey.current}
										onRangeChange={(date_range) => {
											if (date_range?.from && date_range?.to) {
												objectIds.current = [];
												page.current = 1;
												setPayload(
													(payload) =>
														({
															...payload!,
															last_object_id: null,
															date_range: {
																from_date: date_range.from!.toLocaleDateString('en-CA'),
																to_date: date_range.to!.toLocaleDateString('en-CA')
															}
														}) satisfies TableRequest
												);
											} else if (date_range === undefined) {
												objectIds.current = [];
												page.current = 1;
												setPayload(
													(payload) =>
														({
															...payload!,
															last_object_id: null,
															date_range: undefined
														}) satisfies TableRequest
												);
											}
										}}
									/>
									<Select
										disabled={loading}
										className="bg-foreground md:bg-neutral-950"
										options={[
											'Ver todo',
											'Hoy',
											'Ayer',
											'Semana actual',
											'Última semana',
											'Este mes a la fecha',
											'Últimos 30 días'
										].map((e) => ({ label: e, value: e }))}
										defaultValue="Ver todo"
										onSelectedChange={(selected) => {
											dateRangeKey.current = Math.random();
											objectIds.current = [];
											page.current = 1;
											setPayload(
												(payload) =>
													({
														...payload!,
														last_object_id: null,
														...(selected !== null ? { date_range: { custom_range: selected as any } } : { date_range: undefined })
													}) satisfies TableRequest
											);
										}}
									/>
								</>
							)}
							{!hideExport && (
								<button
									data-disabled={!total.current || total.current > 5000 || loading}
									className={classNames(
										'flex size-10 shrink-0 items-center justify-center rounded-lg bg-foreground transition-colors hover:bg-primary-600 data-[disabled=true]:cursor-not-allowed data-[disabled=true]:opacity-60 data-[disabled=true]:hover:bg-background light:bg-neutral-900/70 md:bg-neutral-950',
										{ '!pointer-events-none': loading }
									)}
									onClick={() => {
										if (!total.current || total.current > 5000) {
											const container = document.createElement('div');
											document.body.append(container);
											const root = createRoot(container);
											root.render(
												<Dialog title="¡Error!">
													{total.current > 5000
														? `Se pueden exportar un máximo de 5000 resultados. Actualmente hay ${total.current}, favor reducir.`
														: 'No hay resultados para exportar.'}
												</Dialog>
											);
											return;
										}

										setExportDataPayload({
											...payload!,
											download_file: true
										} satisfies TableRequest);
									}}
								>
									<Download className="size-5" />
								</button>
							)}
						</div>
					</div>

					<ScrollArea.Root type="always" className="relative h-full min-h-[150px] w-full overflow-hidden">
						<ScrollArea.Viewport
							ref={scrollAreaRef}
							className="h-full w-full rounded-lg border-[1.5px] border-neutral-900 bg-foreground light:bg-background md:bg-[none]"
						>
							<article
								style={{ gridTemplateColumns: `repeat(${data.column_names.length + (actionButton ? 1 : 0)},auto)` }}
								className="grid"
							>
								<ol className="contents select-none">
									{actionButton && (
										<li className="group pointer-events-none sticky top-0 z-10 flex h-16 min-w-max select-none items-center justify-between gap-1 bg-neutral-950 px-2 text-left font-medium leading-none text-neutral-400 transition-colors md:px-6">
											<span className="text-nowrap">{actionButton.label}</span>
										</li>
									)}
									{data.column_names.map((th, idx) => (
										<li
											key={idx}
											className={classNames(
												'group pointer-events-none sticky top-0 z-10 flex h-16 min-w-max select-none items-center justify-between gap-1 bg-neutral-950 px-2 text-left font-medium leading-none text-neutral-400 transition-colors light:bg-foreground md:px-6',
												{
													'!pointer-events-auto cursor-pointer !bg-neutral-925 !text-neutral-100 light:!bg-neutral-900':
														th === orderBy.columnName
												}
											)}
											onClick={() => {
												if (th !== orderBy.columnName) return;

												objectIds.current = [];
												page.current = 1;
												setPayload(
													(payload) =>
														({
															...payload!,
															last_object_id: null,
															order_by: {
																column_name: th,
																column_order:
																	data.order_by.column_name !== th
																		? 'DESC'
																		: data.order_by.column_order === 'ASC'
																			? 'DESC'
																			: 'ASC'
															}
														}) satisfies TableRequest
												);
											}}
										>
											<span className="text-nowrap">{th === th?.toLowerCase() ? ucfirst(th) : th}</span>
											{th === orderBy.columnName && (
												<ChevronDown
													className={classNames('size-4 shrink-0 text-neutral-300 transition-all group-hover:text-neutral-100', {
														'rotate-180': data.order_by.column_order === 'ASC'
													})}
													strokeWidth={2.5}
												/>
											)}
										</li>
									))}
								</ol>
								{loading && (
									<div className="absolute z-20 flex h-[calc(100%-3px)] w-[calc(100%-2.5px)] items-center justify-center rounded-lg bg-black/50">
										<Spinner size={2.75} />
									</div>
								)}
								{data.rows.map((tr, rowIdx) => (
									<ol key={rowIdx} className="group contents [&>li]:border-b-[1.5px] [&>li]:border-neutral-900">
										{actionButton && (
											<li
												className={classNames(
													'relative min-w-max gap-[0.15rem] overflow-hidden text-ellipsis text-nowrap px-3 py-4 text-neutral-200 transition-colors group-hover:bg-background md:pr-[6vw]',
													{ '!border-b-0': data.rows.length - 1 === rowIdx }
												)}
											>
												<Button
													className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
													onClick={() =>
														actionButton.onClick(Object.fromEntries(data.column_names.map((name, i) => [name, tr[i]])))
													}
												>
													{actionButton.label}
												</Button>
											</li>
										)}
										{tr.map((td, idx) => (
											<li
												key={idx}
												className={classNames(
													'min-w-max gap-[0.15rem] overflow-hidden text-ellipsis text-nowrap px-3 py-4 text-neutral-200 transition-colors group-hover:bg-background md:pr-[6vw]',
													{ '!border-b-0': data.rows.length - 1 === rowIdx }
												)}
											>
												{typeof td === 'string' && td.trim().match(/^https?:\/\//) ? (
													<a href={td} target="_blank" rel="noopener noreferrer" className="text-primary-500 hover:underline">
														Abrir
													</a>
												) : (
													td
												)}
											</li>
										))}
									</ol>
								))}
								{!data.rows.length && (
									<div className="absolute top-8 z-10 flex h-[calc(100%-5px)] w-[calc(100%-5px)] select-none items-center justify-center text-lg text-neutral-300">
										No se encontraron resultados.
									</div>
								)}
							</article>
						</ScrollArea.Viewport>
						<ScrollArea.Scrollbar
							className="duration-[160ms] flex scale-x-[0.995] touch-none select-none ease-out data-[orientation=horizontal]:h-1.5 data-[orientation=vertical]:w-1.5 data-[orientation=horizontal]:flex-col"
							orientation="horizontal"
						>
							<ScrollArea.Thumb className="relative flex-1 cursor-pointer rounded-[10px] bg-neutral-800 transition-colors before:absolute before:left-1/2 before:top-1/2 before:h-full before:min-h-[44px] before:w-full before:min-w-[44px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-[''] hover:bg-neutral-700" />
						</ScrollArea.Scrollbar>
						<ScrollArea.Scrollbar
							className="duration-[160ms] mt-16 flex h-[calc(100%-4rem)] scale-y-[0.975] touch-none select-none ease-out data-[orientation=horizontal]:h-1.5 data-[orientation=vertical]:w-1.5 data-[orientation=horizontal]:flex-col"
							orientation="vertical"
						>
							<ScrollArea.Thumb className="relative flex-1 cursor-pointer rounded-[10px] bg-neutral-800 transition-colors before:absolute before:left-1/2 before:top-1/2 before:h-full before:min-h-[44px] before:w-full before:min-w-[44px] before:-translate-x-1/2 before:-translate-y-1/2 before:content-[''] hover:bg-neutral-700" />
						</ScrollArea.Scrollbar>
					</ScrollArea.Root>
					{!hidePagination && (
						<div className="flex w-full items-center justify-end gap-4 text-[0.9rem] font-semibold tracking-tight">
							<p className="select-none text-base font-normal text-neutral-300">
								{pagination
									? (page.current - 1) * batchSize.current - total.current > batchSize.current
										? page.current * batchSize.current
										: `${(page.current - 1) * batchSize.current} - ${total.current > batchSize.current ? page.current * batchSize.current : total.current} de ${total.current}`
									: `Mostrando ${total.current} resultados`}
							</p>

							{pagination && (
								<>
									<button
										disabled={page.current === 1 || loading}
										onClick={() => {
											page.current--;
											setPayload(
												(payload) =>
													({
														...payload!,
														last_object_id: objectIds.current?.[page.current - 1] || null
													}) satisfies TableRequest
											);
										}}
										className="flex size-10 select-none items-center gap-1 rounded-lg bg-primary-600 px-3 py-2 transition-opacity hover:opacity-50 disabled:pointer-events-none disabled:bg-black disabled:opacity-30 light:bg-primary-500 light:text-neutral-1000 light:disabled:bg-neutral-900"
									>
										<ChevronLeft className="size-4 scale-110" strokeWidth={3} />
									</button>
									<button
										disabled={page.current * batchSize.current >= total.current || loading}
										onClick={() => {
											page.current++;
											setPayload(
												(payload) =>
													({
														...payload!,
														last_object_id: objectIds.current?.[objectIds.current.length - 1] || null
													}) satisfies TableRequest
											);
										}}
										className="flex size-10 select-none items-center gap-1 rounded-lg bg-primary-600 px-3 py-2 transition-opacity hover:opacity-50 disabled:pointer-events-none disabled:bg-black disabled:opacity-30 light:bg-primary-500 light:text-neutral-1000 light:disabled:bg-neutral-900"
									>
										<ChevronRight className="size-4 scale-110" strokeWidth={3} />
									</button>
								</>
							)}
						</div>
					)}
				</div>
			)}
		</>
	);
}

function ExportData({ endpoint, payload, onClose }: { endpoint: keyof API; payload: TableRequest; onClose: () => any }) {
	const { data, loading } = useFetch(endpoint as 'https://api.krece.app/tables/payments/', 'POST', true, payload);

	return (
		<Dialog onClose={onClose} closable={!loading} title={loading ? 'Exportando...' : data?.download_link ? '¡Listo!' : '¡Error!'}>
			<div className="flex h-full w-full items-center justify-center">
				{loading ? (
					<div className="mb-0">
						<Spinner size={2} />
					</div>
				) : data?.download_link ? (
					<a
						href={data.download_link}
						target="_blank"
						rel="noreferrer"
						className="flex items-center justify-center gap-1 rounded-lg bg-primary-500/20 p-3 font-semibold text-primary-200 transition-colors hover:bg-primary-500/10 hover:text-primary-50 light:bg-primary-500 light:text-white"
					>
						<Download />
						Descargar
					</a>
				) : (
					'Hubo un error generando el archivo.'
				)}
			</div>
		</Dialog>
	);
}
