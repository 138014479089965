import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";

import Dashboard from './pages/dashboard';
import DeleteMyAccount from './pages/deletemyaccount';
import LoginForm from './pages/login';
import Pay from './pages/pay';
import NewSale from './pages/newSale';
import Simulator from './pages/simulator';
import ChangeEmail from './pages/changeEmail';
import ChangePrice from './pages/changePrice';
import CPC from './components/CuentasPorCobrar';
import DeletePayment from './pages/deletePayment';
import DeleteSale from './pages/deleteSale';
import ReceivePayment from './pages/receivePayment';
import Warranty from './pages/warranty';
import Cuotas from './pages/reportes/cuotas';
import Ventas from './pages/reportes/ventas';
import Transacciones from './pages/reportes/transacciones';
import Eliminados from './pages/reportes/registros-eliminados';
import Logout from './pages/logout';
import Layout from './components/Layout';
import DelinquentUsers from './pages/reportes/delincuentes';
import Internal from './components/Internal';
import { Inicio } from "./pages/inicio";
import Sucursales from "./pages/sucursales";
import MetodosDePago from "./pages/metodos-de-pago";
import LogInAs from "./pages/log-in-as";
import Help from "./pages/ayuda";

const getInitialTheme = () => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme === 'dark' || savedTheme === 'light') {
        return savedTheme;
    }
    // If no theme is saved, default to dark
    return 'dark';
};

document.documentElement.classList.remove('dark', 'light');
document.documentElement.classList.add(getInitialTheme());

// main app routes
const App = () => {
	return (
		<Router>
			<Layout>
				<Routes>
					<Route path="/dashboard" element={<Dashboard />} />
					<Route path="/login" element={<LoginForm />} />
					<Route path="/" element={<Inicio />} />
					<Route path="/deletemyaccount" element={<DeleteMyAccount />} />
					<Route path="/buscarcliente" element={<Pay />} />
					<Route path="/newsale" element={<NewSale />} />
					<Route path="/simulator" element={<Simulator />} />
					<Route path="/cpc" element={<CPC />} />
					<Route path="/changeEmail" element={<ChangeEmail />} />
					<Route path="/deletePayment" element={<DeletePayment />} />
					<Route path="/changePrice" element={<ChangePrice />} />
					<Route path="/receivePayment" element={<ReceivePayment />} />
					<Route path="/deleteSale" element={<DeleteSale />} />
					<Route path="/warranty" element={<Warranty />} />
					<Route path="/reportes/cuotas" element={<Cuotas />} />
					<Route path="/reportes/ventas" element={<Ventas />} />
					<Route path="/reportes/transacciones" element={<Transacciones />} />
					<Route path="/reportes/registros-eliminados" element={<Eliminados />} />
					<Route path="/reportes/delincuentes" element={<DelinquentUsers />} />
					<Route path="/sucursales" element={<Sucursales />} />
					<Route path="/metodos-de-pago" element={<MetodosDePago />} />
					<Route path="/logout" element={<Logout />} />
					<Route path="/internal" element={<Internal />} />
					<Route path="/log-in-as" element={<LogInAs />} />
					<Route path="/ayuda" element={<Help />} />
				</Routes>
			</Layout>
		</Router>
	);
};

export default App;
