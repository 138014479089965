import React, { useState, useEffect, useRef } from 'react';
import { MenuItem, Switch } from '@material-ui/core';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import firestore from '../firestore'
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import auth from '../auth';
import { ContainerSpinner } from '../components/Spinner';
import Select from '../components/Select';
import Input from '../components/Input';

// Initialize Firebase
firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FB_APP_ID,
    measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID
});

const Simulator = () => {
    const navigate = useNavigate();
    const [applicationApprovalData, setApplicationApprovalData] = useState({
        threeMonthAmount: 150,
        sixMonthAmount: '',
    });
    const [maxLoan3MonthsKrecePlus, setMaxLoan3MonthsKrecePlus] = useState('');
    const [downPaymentPerc, setDownPaymentPerc] = useState(null);

    //User creation data
    const [allowAnyProduct, setAllowAnyProduct] = useState(false);

    //user application data
    const [level, setLevel] = useState('level_1');

    // device registration data
    const [timeToPay, setTimeToPay] = useState(3);
    const [interestFactor, setInterestFactor] = useState(0.1);

    //webapp data
    const [isLoading, setIsLoading] = useState(true);
    const [amountSlider, setAmountSlider] = useState(50);
    const [productType, setProductType] = useState('');
    const [switchChecked, setSwitchChecked] = useState(false);
    const [switchChecked2, setSwitchChecked2] = useState(false);
    const [country, setCountry] = useState('')
    const [countrySettings, setCountrySettings] = useState('')

    var currentDay = new Date().getDate();
    if (currentDay === 31) {
        currentDay = 30;
    }

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                const storeRef = firestore.collection('Stores').doc(user.email);
                storeRef.get()
                    .then((doc) => {
                        const data = doc.data();
                        if (data.cobranza_access) {
                            navigate('/internal');
                            setIsLoading(false);
                            return;
                        }
                        const masterStoreRef = data.master_store;
                        const userName = masterStoreRef.id === user.email ? "Master" : data.store_name
                        // const storeRef = firestore.collection('Stores').doc(masterStore);
                        masterStoreRef.get()
                            .then((doc) => {
                                const data = doc.data();
                                setCountry(data.country);
                                if (data.country === 'rd') {
                                    setApplicationApprovalData({ threeMonthAmount: 12000 })
                                } else if (data.country === 'peru') {
                                    setApplicationApprovalData({ threeMonthAmount: 800 })
                                }
                                const ref = firestore.collection('AppSetting').doc('CountrySettings');
                                ref.get()
                                    .then((doc) => {
                                        try {
                                            const dat = doc.data();
                                            setCountrySettings(dat[data.country]);
                                            setInterestFactor(dat[data.country].payment_options.level_1[0].factor)
                                            setTimeToPay(dat[data.country].payment_options.level_1[0].installments / 2)
                                            setDownPaymentPerc(dat[data.country].down_payment.level_1);
                                            if (data.allow_any_product === true) {
                                                setAllowAnyProduct(true);
                                            }
                                        } catch (err) {
                                            setIsLoading(false);
                                        } finally {
                                            setIsLoading(false);
                                        }

                                    })

                                // setIsLoading(false);
                                // setDownPaymentPerc(data['down_payment']);
                            });
                        // setIsLoading(false);
                    });
            } else {
                // User is signed out
                // ...
                navigate("/login");
                console.log("user is logged out");
                setIsLoading(false);
            }
        });

    }, [])

    const getFactorByInstallment = (installment, data) => {
        for (const level in data) {
            for (const entry of data[level]) {
                if (entry.installments === installment) {
                    return entry.factor;
                }
            }
        }
        return null; // return null if the installment is not found
    };

    function changeTimeToPay(ttp) {
        setTimeToPay(ttp)
        const factor = getFactorByInstallment(ttp * 2, countrySettings.payment_options)
        setInterestFactor(factor)
    }

    const extractLevelNumber = (levelString) => {
        const match = levelString.match(/level_(\d+)/);
        return match ? parseInt(match[1], 10) : null;
    };

    const renderPaymentOptions = (userLevel, data) => {
        console.log(userLevel, data)
        const levelNum = extractLevelNumber(userLevel)
        let componentsToRender = [];

        for (let level = 1; level <= levelNum; level++) {
            const levelKey = `level_${level}`;
            if (data[levelKey]) {
                data[levelKey].forEach(item => {
                    componentsToRender.push(
                        <MenuItem key={`${levelKey}-${item.installments}`} value={item.installments / 2}>{item.installments}</MenuItem>
                    );
                });
            }
        }

        return componentsToRender;
    };

    function renderDownPayments(start) {
        const result = [];
        for (let i = start; i <= 50; i += 5) {
            result.push(i);
        }
        return result;
    }

    if (isLoading) return <ContainerSpinner size={3} />

    return (
        <div className="min-h-full min-w-full flex flex-col items-center justify-center p-4 md:p-6">
            <div className="w-full max-w-5xl animate-in fade-in duration-500 space-y-8">
                {country === 'venezuela' && (
                    <div className="w-full max-w-sm mx-auto">
                        <div className="group relative overflow-hidden rounded-xl bg-gradient-to-r from-blue-900/30 to-blue-800/20 backdrop-blur-sm border border-blue-500/20 p-4 transition-all duration-300 hover:border-blue-500/40">
                            <div className="flex items-center justify-between">
                                <div className="flex items-center gap-2">
                                    <span className="text-neutral-100 font-medium">Activar promo</span>
                                    <span className="text-[#8194ff] font-bold">SAMSUNG</span>
                                </div>
                                <Switch
                                    checked={switchChecked}
                                    onChange={() => {
                                        setSwitchChecked(!switchChecked)
                                        setSwitchChecked2(false)
                                    }}
                                    color={switchChecked ? "primary" : "default"}
                                />
                            </div>
                        </div>
                    </div>
                )}

                <div className="light:shadow-none w-full bg-neutral-900/30 light:bg-neutral-950/80 backdrop-blur-md rounded-2xl border border-neutral-700/50 p-6 md:p-8 shadow-xl animate-in fade-in slide-in-from-bottom-4 duration-700">
                    <div className="space-y-8">
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
                            <Select
                                title="Nivel"
                                options={[
                                    { label: "Azul", value: "level_1" },
                                    { label: "Plata", value: "level_2" },
                                    { label: "Oro", value: "level_3" },
                                    { label: "Platino", value: "level_4" }
                                ]}
                                defaultValue={level}
                                onSelectedChange={(value) => {
                                    setLevel(value)
                                    setDownPaymentPerc(countrySettings.down_payment[value])
                                }}
                                className="w-full"
                            />

                            <div className="space-y-1">
                                <label className="block font-medium tracking-tight text-neutral-300">
                                    Línea de compra
                                </label>
                                <Input
                                    placeholder='0.00'
                                    allowedChars='numeric'
                                    value={applicationApprovalData.threeMonthAmount?.toString()}
                                    onChange={(value) => setApplicationApprovalData({ threeMonthAmount: value ? Number.parseFloat(value) : 0 })}
                                    Icon={countrySettings?.currency_code ? <span className="text-sm text-neutral-500">{countrySettings.currency_code}</span> : undefined}
                                />
                            </div>

                            {!switchChecked && !switchChecked2 && (
                                <Select
                                    title="Inicial deseada"
                                    options={renderDownPayments(countrySettings.down_payment[level] * 100).map(item => ({
                                        label: item + "%",
                                        value: item / 100
                                    }))}
                                    defaultValue={downPaymentPerc?.toString()}
                                    onSelectedChange={(value) => setDownPaymentPerc(value ? Number.parseFloat(value) : 0)}
                                    className="w-full"
                                />
                            )}

                            <Select
                                title="Número de cuotas"
                                options={productType === 'other' ? [{ label: "4", value: "2" }] : renderPaymentOptions(level, countrySettings.payment_options).map(item => ({
                                    label: item.props.children,
                                    value: item.props.children / 2
                                }))}
                                defaultValue={timeToPay?.toString()}
                                onSelectedChange={(value) => changeTimeToPay(Number.parseInt(value))}
                                className="w-full"
                            />
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 pt-4">
                            <div className="light:bg-neutral-1000 bg-background rounded-xl p-6 border border-neutral-800 transition-all duration-300 hover:border-primary-500/50">
                                <span className="text-sm font-medium text-neutral-400 block mb-3">
                                    Precio del equipo
                                </span>
                                <div className="relative">
                                    <Input
                                        className="!bg-transparent !shadow-none"
                                        placeholder='0.00'
                                        allowedChars='numeric'
                                        value={amountSlider?.toString()}
                                        onChange={(value) => setAmountSlider(value ? Number.parseFloat(value) : 0)}
                                        validation={(value) => (!value || !Number.parseFloat(value) || (switchChecked && Number.parseFloat(value) < 100)) ? false : true}
                                        inputClassName="border-b-[1.5px] border-neutral-800 !text-3xl md:!text-4xl !font-bold !text-primary-500"
                                        Icon={countrySettings?.currency_code ? <span className="!text-3xl md:!text-4xl !font-bold !text-primary-500">{countrySettings.currency_code}</span> : undefined}
                                    />

                                    {switchChecked && amountSlider < 100 && (
                                        <p className="text-sm text-red-500 absolute -bottom-[1.25rem] left-0">
                                            El monto debe ser mayor a 100
                                        </p>
                                    )}
                                </div>

                            </div>

                            <div className="light:bg-neutral-1000 bg-background rounded-xl p-6 border border-neutral-800 transition-all duration-300 hover:border-primary-500/50">
                                <span className="text-sm font-medium text-neutral-400 block mb-3">
                                    {!switchChecked ? `Cuota inicial (${productType !== 'other'
                                        ? (
                                            !amountSlider ? 0 : (
                                                (switchChecked2 ? 300 : applicationApprovalData.threeMonthAmount) / (1 - downPaymentPerc) >= amountSlider
                                                    ? (amountSlider * downPaymentPerc)
                                                    : (
                                                        ((switchChecked2 ? 300 : applicationApprovalData.threeMonthAmount) / (1 - downPaymentPerc) * downPaymentPerc) +
                                                        (amountSlider - ((switchChecked2 ? 300 : applicationApprovalData.threeMonthAmount) / (1 - downPaymentPerc)))
                                                    )
                                            ) * 100 / amountSlider
                                        ).toFixed(0)
                                        : (
                                            !amountSlider ? 0 : (
                                                maxLoan3MonthsKrecePlus / (1 - 0.5) >= amountSlider
                                                    ? (amountSlider * 0.5)
                                                    : (
                                                        (maxLoan3MonthsKrecePlus / (1 - 0.5) * 0.5) +
                                                        (amountSlider - maxLoan3MonthsKrecePlus / (1 - 0.5))
                                                    )
                                            ) * 100 / amountSlider
                                        ).toFixed(0)
                                        }%)` : 'Cuota inicial (50%)'}
                                </span>
                                <p className="text-3xl md:text-4xl font-bold bg-gradient-to-r from-primary-400 to-primary-600 bg-clip-text text-transparent">
                                    {countrySettings.currency_code}
                                    {!switchChecked
                                        ? (productType !== 'other'
                                            ? ((switchChecked2 ? 300 : applicationApprovalData.threeMonthAmount) / (1 - downPaymentPerc) >= amountSlider
                                                ? (amountSlider * downPaymentPerc)
                                                : (((switchChecked2 ? 300 : applicationApprovalData.threeMonthAmount) / (1 - downPaymentPerc) * downPaymentPerc) + (amountSlider - (switchChecked2 ? 300 : applicationApprovalData.threeMonthAmount) / (1 - downPaymentPerc)))).toFixed(0)
                                            : (maxLoan3MonthsKrecePlus / (1 - 0.5) >= amountSlider
                                                ? (amountSlider * 0.5)
                                                : ((maxLoan3MonthsKrecePlus / (1 - 0.5) * 0.5) + (amountSlider - maxLoan3MonthsKrecePlus / (1 - 0.5)))).toFixed(2))
                                        : Math.round(amountSlider / 2).toFixed(0)}
                                </p>
                            </div>

                            <div className="light:bg-neutral-1000 bg-background rounded-xl p-6 border border-neutral-800 transition-all duration-300 hover:border-primary-500/50">
                                <span className="text-sm font-medium text-neutral-400 block mb-3">
                                    {timeToPay * 2} cuotas quincenales de
                                </span>
                                <p className="text-3xl md:text-4xl font-bold bg-gradient-to-r from-primary-400 to-primary-600 bg-clip-text text-transparent">
                                    {countrySettings.currency_code}
                                    {!switchChecked
                                        ? (productType !== 'other'
                                            ? Math.round(((((switchChecked2 ? 300 : applicationApprovalData.threeMonthAmount) / (1 - downPaymentPerc) >= amountSlider
                                                ? (amountSlider * (1 - downPaymentPerc))
                                                : ((switchChecked2 ? 300 : applicationApprovalData.threeMonthAmount) / (1 - downPaymentPerc) * (1 - downPaymentPerc)))) * interestFactor) / (parseInt(timeToPay) * 2)).toFixed(0)
                                            : ((((maxLoan3MonthsKrecePlus / (1 - 0.5) >= amountSlider
                                                ? (amountSlider * (1 - 0.5))
                                                : (maxLoan3MonthsKrecePlus / (1 - 0.5) * (1 - 0.5))))) / (parseInt(timeToPay) * 2)).toFixed(2))
                                        : Math.round((amountSlider / 2) * (1.25) / (timeToPay * 2))}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};


export default Simulator;