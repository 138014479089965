// import React, { useState, useEffect, useContext } from 'react';
// import qrCode from '../images/enrollment_qr.png';
// import paso1 from '../images/paso1.png';
// import paso1dot2 from '../images/paso1.2.png';
// import paso2 from '../images/paso2.png';
// import paso3 from '../images/paso3.png';
// import paso4 from '../images/paso4.png';
// import paso5 from '../images/paso5.png';
// import paso6 from '../images/paso6.png';
// import paso7 from '../images/paso7.png';
// import paso8 from '../images/paso8.png';
// import paso13 from '../images/paso13.png';
// import paso16 from '../images/paso16.png';
// import homeScreen from '../images/kreceScreen.png';
// import { ContainerSpinner } from '../components/Spinner';
// import { GlobalContext } from '../context/GlobalContext';
// import { ArrowLeft, ArrowRight, CircleCheck, Mail, Phone, Search, Settings, Shield } from 'lucide-react';
// import Notice from '../components/Notice';
// import 'firebase/compat/firestore';
// import firestore from '../firestore';
// import classNames from 'classnames';
// import Input from '../components/Input';
// import { Button } from '../shadcn/Button';
// import Stepper from '../components/Stepper';

// const Warranty = () => {
// 	const { authenticated, userData } = useContext(GlobalContext);

// 	const [isLoading, setIsLoading] = useState(false);
// 	const [errorMessage, setErrorMessage] = useState('');

// 	const [securityProvider, setSecurityProvider] = useState('');
// 	useEffect(() => {
// 		if (!securityProvider && userData.security_provider) setSecurityProvider(userData.security_provider);
// 	}, [userData]);

// 	const [cedula, setCedula] = useState('');
// 	const [isGuarantee, setIsGuarantee] = useState(false);

// 	const [token, setToken] = useState(process.env.REACT_APP_API_TOKEN);
// 	const [deviceID, setDeviceID] = useState('');
// 	const [imeiNumber, setImeiNumber] = useState('');
// 	const [confNumber, setConfNumber] = useState('');
// 	const [imeiToRegister, setImeiToRegister] = useState('');
// 	const [imeiToRegister2, setImeiToRegister2] = useState('');
// 	const [appToken, setAppToken] = useState('');
// 	const [tokenGenerated, setTokenGenerated] = useState(false);

// 	const [loadingText, setLoadingText] = useState(false);
// 	const [loadingSubText, setLoadingSubText] = useState(false);
// 	const [showImei, setShowImei] = useState(false);
// 	const [showConfNumber, setShowConfNumber] = useState(false);
// 	const [page, setPage] = useState(2);
// 	const [switchChecked, setSwitchChecked] = useState(false);
// 	const [switchChecked2, setSwitchChecked2] = useState(false);

// 	var currentDay = new Date().getDate();
// 	if (currentDay === 31) {
// 		currentDay = 30;
// 	}

// 	const sendPostRequest = async (data, endpoint) => {
// 		try {
// 			const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
// 				method: 'POST',
// 				headers: {
// 					'Content-Type': 'application/json',
// 					Authorization: `Bearer ${userData.firebase_token}`,
// 					'x-master-store-email': userData.master_store.id,
// 					'x-user-email': userData.email
// 				},
// 				body: JSON.stringify({ ...data, country: userData.country })
// 			});
// 			const responseData = await response.json();
// 			if (response.status === '401') {
// 				const tokenData = await requestToken();
// 				await setToken('Token ' + tokenData.token);
// 				const resData = await sendPostRequest(data, endpoint, 'Token ' + tokenData.token);
// 				return resData;
// 			}
// 			return responseData;
// 		} catch (error) {
// 			console.error('Error:', error.message);
// 			return null;
// 		}
// 	};

// 	const requestToken = async () => {
// 		const loginData = {
// 			username: process.env.REACT_APP_TOKEN_USERNAME,
// 			password: process.env.REACT_APP_TOKEN_PASSWORD
// 		};
// 		try {
// 			const response = await fetch(process.env.REACT_APP_API_URL + '/api/token/', {
// 				method: 'POST',
// 				headers: {
// 					'Content-Type': 'application/json'
// 				},
// 				body: JSON.stringify(loginData)
// 			});

// 			const responseData = await response.json();
// 			return responseData;
// 		} catch (error) {
// 			console.error('Error:', error);
// 		}
// 	};

// 	const sendAppToken = async (e) => {
// 		setErrorMessage('');
// 		setIsLoading(true);
// 		const data = {
// 			identification_number: cedula,
// 			code_type: 'sms'
// 		};
// 		const resp2 = await sendPostRequest(data, 'notifications/requestauthcode/', token); //to-do: Necesito mandar la cedula? ese token como se va a guardar en la base de datos?
// 		if (resp2?.success) {
// 			alert('Token generado en app exitosamente.');
// 			setTokenGenerated(true);
// 			setIsLoading(false);
// 		} else {
// 			setErrorMessage('Ocurrió un problema generando el token de confirmación del app, por favor vuelva a intentar.');
// 			setIsLoading(false);
// 		}
// 	};

// 	const handleSendDeviceID = async (e) => {
// 		if (isGuarantee) {
// 			await handleDeviceIdGuaranteeSubmit();
// 			return;
// 		}
// 	};

// 	const goToPage = (x) => {
// 		setErrorMessage('');
// 		setErrorMessage('');
// 		setPage(x);
// 	};

// 	const searchConfirmationNumber = async () => {
// 		setConfNumber('');
// 		setShowConfNumber(false);
// 		setErrorMessage('');
// 		const data = {
// 			store_name: userData.store_name,
// 			imei: imeiNumber
// 		};
// 		const response = await sendPostRequest(data, 'registration/finddevice/', token);
// 		if (response?.success) {
// 			setConfNumber(response.data.nuovo_id);
// 			setShowConfNumber(true);
// 		} else if (response?.success === false) {
// 			setErrorMessage(response.status);
// 			setIsLoading(false);
// 		} else {
// 			setErrorMessage('Estamos teniendo problemas para conectarnos.');
// 			setIsLoading(false);
// 		}
// 	};

// 	const checkDeviceReady = async () => {
// 		let breakLoop = false;
// 		let status = 'waiting';

// 		for (let i = 0; i < 10; i++) {
// 			if (breakLoop) {
// 				break;
// 			}
// 			const devDat = {
// 				device_imei: imeiToRegister
// 			};
// 			await new Promise((resolve) => {
// 				setTimeout(async () => {
// 					try {
// 						const resp = await sendPostRequest(devDat, 'registration/imeistatus/', token);

// 						if (resp?.success) {
// 							if (resp.device_ready) {
// 								setIsLoading(false);
// 								setLoadingText('');
// 								setLoadingSubText('');
// 								setPage(18);
// 								breakLoop = true;
// 								status = 'success';
// 							}
// 						} else if (resp?.success === false) {
// 							if (resp?.invalid_device) {
// 								// setErrorMessage("Este modelo de equipo no puede ser configurado, por favor elija otro modelo.");
// 								if (switchChecked) {
// 									setErrorMessage(
// 										'Ocurrió un problema al registrar el equipo, por favor asegurese que el equipo es marca Samsung, si el problema persiste contacte a soporte técnico.'
// 									);
// 									setIsLoading(false);
// 									setLoadingText('');
// 									setLoadingSubText('');
// 									return;
// 								} else if (userData.only_allow_tonic === true ? true : false) {
// 									setErrorMessage(
// 										'Ocurrió un problema al registrar el equipo, por favor asegurese que el equipo es marca Samsung, Infinix, Tecno, Realme, Itel, o Motorola, si el problema persiste contacte a soporte técnico.'
// 									);
// 									setIsLoading(false);
// 									setLoadingText('');
// 									setLoadingSubText('');
// 									return;
// 								}
// 								setSecurityProvider('nuovo');
// 								setIsLoading(false);
// 								setLoadingText('');
// 								setLoadingSubText('');
// 								return;
// 							} else {
// 								setErrorMessage(resp.status);
// 							}
// 							setIsLoading(false);
// 							setLoadingText('');
// 							setLoadingSubText('');
// 							breakLoop = true;
// 							status = 'fail';
// 						} else {
// 							setErrorMessage('Estamos teniendo problemas para conectarnos.');
// 							setIsLoading(false);
// 							setLoadingText('');
// 							setLoadingSubText('');
// 							breakLoop = true;
// 							status = 'fail';
// 						}
// 					} catch (error) {
// 						console.error('Error occurred:', error);
// 						setErrorMessage('Error al realizar la solicitud.');
// 						setIsLoading(false);
// 						setLoadingText('');
// 						setLoadingSubText('');
// 						breakLoop = true;
// 						status = 'fail';
// 					}
// 					resolve();
// 				}, 15000);
// 			});
// 		}
// 		if (status === 'waiting') {
// 			setErrorMessage('Se ha alcanzado el tiempo máximo de espera, por favor vuelva a intentar o contacte a servicio técnico.');
// 			setIsLoading(false);
// 			setLoadingText('');
// 			setLoadingSubText('');
// 		}
// 	};

// 	const registerWithTonic = async () => {
// 		setErrorMessage('');
// 		if (!imeiToRegister || !imeiToRegister2) {
// 			setErrorMessage('Por favor rellene todas las casillas para continuar.');
// 			return;
// 		}
// 		if (imeiToRegister.length < 15 || imeiToRegister2.length < 15) {
// 			setErrorMessage('IMEI ivalido, asegurese de que el IMEI tenga 15 caracteres.');
// 			return;
// 		}
// 		if (imeiToRegister !== imeiToRegister2) {
// 			setErrorMessage('Los IMEIs introducidos no coinciden.');
// 			return;
// 		}
// 		try {
// 			setIsLoading(true);
// 			setLoadingText('Registrando equipo');
// 			setLoadingSubText('Esto puede tardar unos segundos, por favor NO encienda el teléfono aún.');
// 			const devData = {
// 				device_imei: imeiToRegister
// 			};
// 			let response;
// 			if (switchChecked2) {
// 				response = await sendPostRequest(devData, 'registration/promo2/', token);
// 			} else {
// 				response = await sendPostRequest(devData, 'registration/registerimei/', token);
// 			}

// 			if (response?.success) {
// 				await checkDeviceReady();
// 			} else if (response?.success === false) {
// 				setErrorMessage(response.status);
// 				setIsLoading(false);
// 				setLoadingText('');
// 				setLoadingSubText('');
// 			} else {
// 				setErrorMessage('Estamos teniendo problemas para conectarnos.');
// 				setIsLoading(false);
// 				setLoadingText('');
// 				setLoadingSubText('');
// 			}
// 		} catch (err) {
// 			setErrorMessage('Error: ' + err.message);
// 			setIsLoading(false);
// 			setLoadingText('');
// 			setLoadingSubText('');
// 		}
// 	};

// 	const callDevReg = async () => {
// 		try {
// 			setIsLoading(true);
// 			setLoadingText('Finalizando registro');
// 			if (isGuarantee) {
// 				await handleDeviceIdGuaranteeSubmit();
// 				return;
// 			}
// 		} catch (err) {
// 			setErrorMessage('Error: ' + err.message);
// 			setIsLoading(false);
// 			setLoadingText('');
// 			setLoadingSubText('');
// 		}
// 	};

// 	const handleDeviceIdGuaranteeSubmit = async () => {
// 		if (securityProvider !== 'tonic' && !deviceID) {
// 			await setErrorMessage('Por favor complete todas las casillas.');
// 			return;
// 		}
// 		if (!appToken) {
// 			setErrorMessage('Por favor introduzca el token.');
// 			return;
// 		}

// 		setErrorMessage('');
// 		setIsLoading(true);
// 		const usersCollection = firestore.collection('Users');
// 		const query = usersCollection.where('identification_number', '==', cedula).limit(1);
// 		await query
// 			.get()
// 			.then((querySnapshot) => {
// 				querySnapshot.forEach(async (doc) => {
// 					// to-do mandar app_token
// 					let deviceData = {
// 						device_id: deviceID,
// 						user_document_id: doc.id,
// 						device_management_provider: 'nuovo',
// 						app_token: appToken,
// 						store_user: userData.email
// 					};
// 					if (securityProvider === 'tonic') {
// 						//to-do mandar app_token
// 						deviceData = {
// 							device_imei: imeiToRegister,
// 							user_document_id: doc.id,
// 							device_management_provider: 'tonic',
// 							app_token: appToken,
// 							store_user: userData.email
// 						};
// 					}
// 					const response = await sendPostRequest(deviceData, 'registration/replacedevice/', token); //status, success, data
// 					if (response?.success) {
// 						setPage(15);
// 						setIsLoading(false);
// 						setLoadingText('');
// 						setLoadingSubText('');
// 					} else if (response?.success === false) {
// 						setErrorMessage(response.status);
// 						setIsLoading(false);
// 						setLoadingText('');
// 						setLoadingSubText('');
// 					} else {
// 						setErrorMessage('Estamos teniendo problemas para conectarnos. ');
// 						setIsLoading(false);
// 						setLoadingText('');
// 						setLoadingSubText('');
// 					}
// 				});
// 			})
// 			.catch((error) => {
// 				console.log(error.message);
// 				setErrorMessage('Estamos teniendo problemas para conectarnos. ');
// 				setIsLoading(false);
// 				setLoadingText('');
// 				setLoadingSubText('');
// 				return;
// 			});
// 	};

// 	const checkTonicStatus = async () => {
// 		setErrorMessage('');
// 		let breakLoop = false;
// 		let status = 'waiting';
// 		setIsLoading(true);
// 		setLoadingText('Chequeando estatus del equipo');
// 		setLoadingSubText('Esto puede tardar unos minutos.');
// 		for (let i = 0; i < 10; i++) {
// 			if (breakLoop) {
// 				break;
// 			}
// 			const devDat = {
// 				device_imei: imeiToRegister
// 			};
// 			await new Promise((resolve) => {
// 				setTimeout(async () => {
// 					try {
// 						const resp = await sendPostRequest(devDat, 'registration/activeimei/', token);
// 						if (resp?.success) {
// 							if (resp.device_active) {
// 								setIsLoading(false);
// 								setLoadingText('');
// 								setLoadingSubText('');
// 								setPage(19);
// 								status = 'success';
// 								breakLoop = true;
// 							}
// 						} else if (resp?.success === false) {
// 							if (resp?.invalid_device) {
// 								setErrorMessage('Este modelo de equipo no puede ser configurado, por favor elija otro modelo.');
// 							} else {
// 								setErrorMessage(resp.status);
// 							}
// 							setIsLoading(false);
// 							setLoadingText('');
// 							setLoadingSubText('');
// 							breakLoop = true;
// 							status = 'fail';
// 						} else {
// 							setErrorMessage('Estamos teniendo problemas para conectarnos.');
// 							setIsLoading(false);
// 							setLoadingText('');
// 							setLoadingSubText('');
// 							breakLoop = true;
// 							status = 'fail';
// 						}
// 					} catch (error) {
// 						console.error('Error occurred:', error);
// 						setErrorMessage('Error al realizar la solicitud.');
// 						setIsLoading(false);
// 						setLoadingText('');
// 						setLoadingSubText('');
// 						breakLoop = true;
// 						status = 'fail';
// 					}
// 					resolve();
// 				}, 15000);
// 			});
// 		}
// 		if (status === 'waiting') {
// 			// setErrorMessage("Error: El celular no fue encendido o configurado correctamente. Si cree que es un error vuelva a hacer clic en Siguiente, de lo contrario debe formatear el equipo de fabrica, prenderlo, configurarlo, y luego hacer clic en Siguiente.");
// 			setSecurityProvider('nuovo');
// 			setPage(10);
// 			setIsLoading(false);
// 			setLoadingText('');
// 			setLoadingSubText('');
// 			alert('El equipo no se pudo configurar correctamente. Porfavor reseteelo de fabrica y prosiga con la instalación con QR.');
// 		}
// 	};

// 	const handleContinueGuarantee = async () => {
// 		setIsLoading(true);
// 		// Simulating API call delay
// 		try {
// 			const cedulaData = {
// 				identification_number: cedula
// 			};
// 			const response = await sendPostRequest(cedulaData, 'user_check_warranty/', token); //status, success, data
// 			if (response?.success) {
// 				if (response.data.is_user_registered && response.data.is_elegible_for_warranty) {
// 					//se manda a un screen para que introduzca el nuevo IMEI y despues se manda un API call a replace_old_device
// 					setPage(9);
// 					setIsLoading(false);
// 					setErrorMessage('');
// 				} else if (!response.data.is_user_registered) {
// 					await setErrorMessage('El cliente no existe.');
// 					setIsLoading(false);
// 				} else if (response.data.is_user_registered && !response.data.is_elegible_for_warranty) {
// 					await setErrorMessage('Este cliente no tiene deudas con Krece. No tiene que reportar el cambio de teléfono.');
// 					setIsLoading(false);
// 				}
// 			} else if (response?.success === false) {
// 				setErrorMessage(response.status);
// 				setIsLoading(false);
// 			} else {
// 				setErrorMessage('Estamos teniendo problemas para conectarnos. ');
// 				setIsLoading(false);
// 			}
// 		} catch (error) {
// 			setErrorMessage('Estamos teniendo problemas para conectarnos. ');
// 			setIsLoading(false);
// 		}
// 	};

// 	const handleConfigureGuarantee = (x) => {
// 		setIsGuarantee(true);
// 		goToPage(7);
// 	};

// 	const steps = [
// 		{ label: 'Inicio', pages: [2] },
// 		{ label: 'Verificación', pages: [9] },
// 		{ label: 'Configuración', pages: [7, 10, 11, 12, 13, 14] },
// 		{ label: 'Activación', pages: [18, 19] },
// 		{ label: 'Completado', pages: [15] }
// 	];

// 	if (!authenticated || !userData?.country) return <ContainerSpinner size={3} />;

// 	return (
// 		<div className="m-auto flex min-h-full min-w-full flex-col items-center justify-start">
// 			<Stepper currentPage={page} steps={steps} />

// 			{(loadingText || loadingSubText) && (
// 				<div className="fixed bottom-20 right-4 z-10 flex items-center gap-3 rounded-lg bg-neutral-900/90 p-4 shadow-lg md:bottom-8 md:right-8 md:gap-4 md:p-6">
// 					<div className="relative">
// 						<div className="absolute -inset-0.5 animate-ping rounded-full bg-primary-500 opacity-75" />
// 						<div className="relative size-3 rounded-full bg-primary-500" />
// 					</div>
// 					<div className="space-y-0.5">
// 						{loadingText && <p className="text-base font-medium text-neutral-0">{loadingText}</p>}
// 						{loadingSubText && <p className="text-sm text-neutral-300">{loadingSubText}</p>}
// 					</div>
// 				</div>
// 			)}

// 			<div className="m-auto flex h-full w-full flex-col items-center justify-center p-4 md:p-6">
// 				<div
// 					className={classNames(
// 						'w-[500px] max-w-full rounded-xl border-[1.5px] border-neutral-900 bg-neutral-1000/25 p-6 shadow-lg duration-300 animate-in fade-in slide-in-from-bottom-5 md:bg-background/50 md:p-8 light:shadow-none light:bg-white'
// 					)}
// 				>
// 					{page === 2 && (
// 						<>
// 							{render_page_header(
// 								'Cambio por Garantía',
// 								'Por favor ingrese la cédula del cliente para realizar el cambio por garantía',
// 								<Mail />
// 							)}

// 							<form className="space-y-4" onSubmit={handleContinueGuarantee}>
// 								<Input
// 									name="cedula"
// 									floatingLabel="Cédula del aplicante"
// 									value={cedula}
// 									onChange={(value) => setCedula(value)}
// 									placeholder="Ingrese la cédula del cliente"
// 									allowedChars="numbers_only"
// 								/>

// 								{errorMessage && <Notice message={errorMessage} variant="error" />}

// 								<Button
// 									disabled={isLoading || !cedula}
// 									loading={isLoading}
// 									type="submit"
// 									onClick={handleContinueGuarantee}
// 									variant="primary"
// 								>
// 									Continuar
// 								</Button>
// 							</form>
// 						</>
// 					)}
// 					{page === 9 && (
// 						<>
// 							{render_page_header(
// 								'Celular con Garantía',
// 								'Por favor realice la configuración del equipo que va a reemplazar al original',
// 								<Phone />
// 							)}

// 							<form className="space-y-4" onSubmit={handleConfigureGuarantee}>
// 								{errorMessage && <Notice message={errorMessage} variant="error" />}

// 								<div className="flex flex-wrap gap-2 md:flex-row-reverse md:flex-nowrap">
// 									<Button
// 										disabled={isLoading}
// 										loading={isLoading}
// 										type="submit"
// 										onClick={handleConfigureGuarantee}
// 										variant="primary"
// 									>
// 										Continuar
// 									</Button>
// 									<Button
// 										Icon={<ArrowLeft className="size-5" />}
// 										disabled={isLoading}
// 										type="button"
// 										onClick={() => (window.location.href = '/warranty')}
// 										variant="secondary"
// 									>
// 										Atrás
// 									</Button>
// 								</div>
// 							</form>
// 						</>
// 					)}
// 					{page === 7 && (
// 						<>
// 							{render_page_header(
// 								'Configuración de Seguridad',
// 								'Ahora, el encargado(a) en la tienda va a configurar nuestro app de seguridad en el dispositivo, lo cual le dará permiso a Krece para bloquear y desbloquear el dispositivo en caso de que se atrase con sus pagos.',
// 								<Shield />
// 							)}

// 							<form
// 								className="space-y-4"
// 								onSubmit={(e) => {
// 									e.preventDefault();
// 									goToPage(10);
// 								}}
// 							>
// 								<div className="flex flex-wrap gap-2 md:flex-row-reverse md:flex-nowrap">
// 									<Button disabled={isLoading} loading={isLoading} type="submit" variant="primary">
// 										Comenzar Configuración
// 									</Button>
// 									<Button
// 										Icon={<ArrowLeft className="size-5" />}
// 										disabled={isLoading}
// 										type="button"
// 										onClick={() => (window.location.href = '/warranty')}
// 										variant="secondary"
// 									>
// 										Atrás
// 									</Button>
// 								</div>
// 							</form>
// 						</>
// 					)}
// 					{page === 10 && (
// 						<>
// 							{render_page_header(
// 								'Configuración de Seguridad',
// 								securityProvider === 'tonic'
// 									? 'Para comenzar, asegurese que el equipo se encuentre apagado'
// 									: 'Escanee el código QR para iniciar la instalación del app de seguridad',
// 								<Shield />
// 							)}

// 							{securityProvider === 'tonic' ? (
// 								<form
// 									className="space-y-4"
// 									onSubmit={(e) => {
// 										e.preventDefault();
// 										registerWithTonic();
// 									}}
// 								>
// 									<div className="space-y-4">
// 										<Input
// 											type="password"
// 											floatingLabel="IMEI del equipo"
// 											name="imeiToRegister"
// 											value={imeiToRegister}
// 											onChange={(value) => setImeiToRegister(value)}
// 											placeholder="Ingrese el IMEI del equipo"
// 											allowedChars="numbers_only"
// 										/>

// 										<Input
// 											type="password"
// 											floatingLabel="Confirme el IMEI del equipo"
// 											name="imeiToRegister2"
// 											value={imeiToRegister2}
// 											onChange={(value) => setImeiToRegister2(value)}
// 											placeholder="Confirme el IMEI del equipo"
// 											allowedChars="numbers_only"
// 										/>
// 									</div>

// 									{errorMessage && <Notice message={errorMessage} variant="error" />}

// 									<div className="flex flex-wrap gap-2 md:flex-row-reverse md:flex-nowrap">
// 										<Button disabled={isLoading} loading={isLoading} type="submit" variant="primary">
// 											Siguiente
// 										</Button>
// 										<Button
// 											Icon={<ArrowLeft className="size-5" />}
// 											disabled={isLoading}
// 											type="button"
// 											onClick={() => setPage(7)}
// 											variant="secondary"
// 										>
// 											Atrás
// 										</Button>
// 									</div>
// 								</form>
// 							) : (
// 								<form
// 									className="space-y-4"
// 									onSubmit={(e) => {
// 										e.preventDefault();
// 										goToPage(11);
// 									}}
// 								>
// 									<div className="space-y-4">
// 										<div className="space-y-2">
// 											<p className="text-neutral-300">
// 												1. Encienda el dispositivo nuevo y en la primera pantalla cambie el idioma a Español. En esa misma
// 												pantalla, toque en cualquier lugar de la pantalla 6 veces para ver un escáner de código QR.
// 											</p>
// 											<div className="flex flex-wrap items-start justify-center gap-4">
// 												<img className="w-1/3" src={paso1} alt="Paso 1" />
// 												<img className="w-1/3" src={paso1dot2} alt="Paso 1.2" />
// 											</div>
// 										</div>

// 										<div className="space-y-2">
// 											<p className="text-neutral-300">
// 												2. Escanee el siguiente código QR para iniciar la instalación del app de seguridad.
// 											</p>
// 											<div className="flex flex-wrap items-start justify-center gap-4">
// 												<img className="w-[200px]" src={qrCode} alt="Enrollment QR" />
// 											</div>
// 										</div>
// 									</div>

// 									<div className="space-y-2">
// 										<div className="flex flex-row-reverse gap-2">
// 											<Button disabled={isLoading} loading={isLoading} type="submit" variant="primary">
// 												Seguir
// 											</Button>
// 											<Button
// 												Icon={<ArrowLeft className="size-5" />}
// 												disabled={isLoading}
// 												type="button"
// 												onClick={() => setPage(7)}
// 												variant="secondary"
// 											>
// 												Atrás
// 											</Button>
// 										</div>
// 										<Button disabled={isLoading} type="button" onClick={() => goToPage(14)} variant="secondary">
// 											Saltar Instrucciones <ArrowRight className="size-5" />
// 										</Button>
// 									</div>
// 								</form>
// 							)}
// 						</>
// 					)}
// 					{page === 18 && (
// 						<>
// 							{render_page_header(
// 								'Configuración de seguridad',
// 								'Por favor proceda a encender el equipo, unirlo con el WIFI de la tienda, aceptar todas las condiciones y realizar la configuración hasta que vea la siguiente pantalla.',
// 								<Phone />
// 							)}

// 							<form className="space-y-4" onSubmit={checkTonicStatus}>
// 								<div className="space-y-4">
// 									<p className="text-center text-neutral-300">
// 										IMPORTANTE (NO APLICA PARA SAMSUNG): SI NO SE LOGRA LLEGAR A LA PANTALLA QUE MOSTRAMOS ABAJO, TERMINE DE
// 										CONFIGURAR EL CELULAR E INTENTE RESTAURAR DE FABRICA. SI EL EQUIPO LE PERMITE RESTAURAR DE FABRICA QUIERE
// 										DECIR QUE NO SE CONFIGURO CORRECTAMENTE Y SE DEBE RESTAURAR DE FABRICA Y VOLVER A COMENZAR. DE LO CONTRARIO,
// 										SI SE CONFIGURO CORRECTAMENTE Y PUEDE HACER CLIC EN SIGUIENTE.
// 									</p>

// 									<div className="flex justify-center">
// 										<img className="h-[448px] w-[200px]" src={homeScreen} alt="Pantalla de inicio" />
// 									</div>
// 								</div>

// 								{errorMessage && <Notice message={errorMessage} variant="error" />}

// 								<div className="flex flex-wrap gap-2 md:flex-row-reverse md:flex-nowrap">
// 									<Button disabled={isLoading} loading={isLoading} type="submit" onClick={checkTonicStatus} variant="primary">
// 										Siguiente
// 									</Button>
// 									<Button
// 										Icon={<ArrowLeft className="size-5" />}
// 										disabled={isLoading}
// 										type="button"
// 										onClick={() => setPage(10)}
// 										variant="secondary"
// 									>
// 										Atrás
// 									</Button>
// 								</div>
// 							</form>
// 						</>
// 					)}
// 					{page === 19 && (
// 						<>
// 							{render_page_header(
// 								'Código de App Krece',
// 								'Ahora proceda a descargar la app Krece desde internet o Play Store en el equipo en venta. (Este paso NO es necesario para equipos SAMSUNG)',
// 								<Phone />
// 							)}

// 							<form className="space-y-4" onSubmit={callDevReg}>
// 								<div className="space-y-4">
// 									<p className="text-center text-neutral-300">
// 										Ahora haga clic en "Generar Token" para que el cliente reciba un Token por SMS.
// 									</p>

// 									{!tokenGenerated ? (
// 										<Button disabled={isLoading} loading={isLoading} type="button" onClick={sendAppToken} variant="primary">
// 											Generar Token
// 										</Button>
// 									) : (
// 										<div className="space-y-4">
// 											<Input
// 												name="token"
// 												floatingLabel="Token"
// 												value={appToken}
// 												onChange={(value) => setAppToken(value)}
// 												placeholder="Ingrese el token recibido"
// 											/>

// 											<Button disabled={isLoading} loading={isLoading} type="button" onClick={sendAppToken} variant="secondary">
// 												Generar Otro Token
// 											</Button>
// 										</div>
// 									)}
// 								</div>

// 								{errorMessage && <Notice message={errorMessage} variant="error" />}

// 								<div className="flex flex-wrap gap-2 md:flex-row-reverse md:flex-nowrap">
// 									<Button disabled={isLoading} loading={isLoading} type="submit" onClick={callDevReg} variant="primary">
// 										Siguiente
// 									</Button>
// 									<Button
// 										Icon={<ArrowLeft className="size-5" />}
// 										disabled={isLoading}
// 										type="button"
// 										onClick={() => setPage(18)}
// 										variant="secondary"
// 									>
// 										Atrás
// 									</Button>
// 								</div>
// 							</form>
// 						</>
// 					)}
// 					{page === 11 && (
// 						<>
// 							{render_page_header(
// 								'Instrucciones de configuración',
// 								'3. Haga clic en aceptar cuando se le solicite.',
// 								<Settings />
// 							)}

// 							<form
// 								className="space-y-6"
// 								onSubmit={(e) => {
// 									e.preventDefault();
// 									goToPage(12);
// 								}}
// 							>
// 								<div className="space-y-4">
// 									<div className="grid grid-cols-2 gap-4">
// 										<img className="w-full" src={paso2} alt="Paso 2" />
// 										<img className="w-full" src={paso3} alt="Paso 3" />
// 										<img className="w-full" src={paso4} alt="Paso 4" />
// 										<img className="w-full" src={paso5} alt="Paso 5" />
// 									</div>
// 								</div>

// 								<div className="flex flex-wrap gap-2 md:flex-row-reverse md:flex-nowrap">
// 									<Button disabled={isLoading} loading={isLoading} type="submit" variant="primary">
// 										Seguir
// 									</Button>
// 									<Button
// 										Icon={<ArrowLeft className="size-5" />}
// 										disabled={isLoading}
// 										type="button"
// 										onClick={() => goToPage(10)}
// 										variant="secondary"
// 									>
// 										Atrás
// 									</Button>
// 								</div>
// 							</form>
// 						</>
// 					)}
// 					{page === 12 && (
// 						<>
// 							{render_page_header(
// 								'Instrucciones de configuración',
// 								'4. Haga clic en "Ajustes adicionales" y después en "PERMITR".',
// 								<Settings />
// 							)}

// 							<form
// 								className="space-y-6"
// 								onSubmit={(e) => {
// 									e.preventDefault();
// 									goToPage(13);
// 								}}
// 							>
// 								<div className="space-y-4">
// 									<div className="grid grid-cols-2 gap-4">
// 										<img className="w-full" src={paso6} alt="Paso 6" />
// 										<img className="w-full" src={paso7} alt="Paso 7" />
// 									</div>
// 								</div>

// 								<div className="flex flex-wrap gap-2 md:flex-row-reverse md:flex-nowrap">
// 									<Button disabled={isLoading} loading={isLoading} type="submit" variant="primary">
// 										Seguir
// 									</Button>
// 									<Button
// 										Icon={<ArrowLeft className="size-5" />}
// 										disabled={isLoading}
// 										type="button"
// 										onClick={() => goToPage(11)}
// 										variant="secondary"
// 									>
// 										Atrás
// 									</Button>
// 								</div>
// 							</form>
// 						</>
// 					)}
// 					{page === 13 && (
// 						<>
// 							{render_page_header(
// 								'Instrucciones de configuración',
// 								'5. Siga las instrucciones que se le muestran en la pantalla y cuando esté listo haga clic en "Configuración Completa"',
// 								<Settings />
// 							)}

// 							<form
// 								className="space-y-6"
// 								onSubmit={(e) => {
// 									e.preventDefault();
// 									goToPage(14);
// 								}}
// 							>
// 								<div className="space-y-4">
// 									<div className="grid grid-cols-2 gap-4">
// 										<img className="w-full" src={paso8} alt="Paso 8" />
// 										<img className="w-full" src={paso13} alt="Paso 13" />
// 									</div>
// 								</div>

// 								<div className="flex flex-wrap gap-2 md:flex-row-reverse md:flex-nowrap">
// 									<Button disabled={isLoading} loading={isLoading} type="submit" variant="primary">
// 										Seguir
// 									</Button>
// 									<Button
// 										Icon={<ArrowLeft className="size-5" />}
// 										disabled={isLoading}
// 										type="button"
// 										onClick={() => goToPage(12)}
// 										variant="secondary"
// 									>
// 										Atrás
// 									</Button>
// 								</div>
// 							</form>
// 						</>
// 					)}
// 					{page === 14 && (
// 						<>
// 							{render_page_header(
// 								'Instrucciones de configuración',
// 								'6. Por favor introduzca el código de confirmación de 7 digitos en la siguiente casilla.',
// 								<Settings />
// 							)}

// 							<form
// 								className="space-y-4"
// 								onSubmit={(e) => {
// 									e.preventDefault();
// 									handleSendDeviceID();
// 								}}
// 							>
// 								<Input
// 									name="deviceID"
// 									floatingLabel="Código de confirmación"
// 									value={deviceID}
// 									onChange={(value) => setDeviceID(value)}
// 									placeholder="Ingrese el código de confirmación"
// 								/>

// 								<Button variant="text" onClick={() => setShowImei(true)}>
// 									¿No encuentra el código de confirmación?
// 								</Button>

// 								{showImei && (
// 									<div className="space-y-4 rounded-lg border border-neutral-800 p-4">
// 										<Input
// 											name="imeiNumber"
// 											floatingLabel="Número IMEI del equipo"
// 											value={imeiNumber}
// 											onChange={(value) => setImeiNumber(value)}
// 											placeholder="Ingrese el número IMEI"
// 										/>

// 										<Button Icon={<Search className="size-5" />} onClick={() => searchConfirmationNumber()} variant="secondary">
// 											Buscar
// 										</Button>

// 										{showConfNumber && (
// 											<Notice message={`El código de confirmación de este equipo es: ${confNumber}`} variant="success" />
// 										)}
// 									</div>
// 								)}

// 								<div className="flex justify-center">
// 									<img className="w-[200px]" src={paso16} alt="Paso16" />
// 								</div>

// 								<div className="text-lg font-medium text-neutral-300">
// 									7. Para finalizar, haga clic en "Generar Token" para que el cliente reciba un Token por SMS.
// 								</div>

// 								{!tokenGenerated ? (
// 									<Button onClick={sendAppToken} variant="secondary">
// 										Generar Token
// 									</Button>
// 								) : (
// 									<div className="space-y-4">
// 										<Input
// 											name="appToken"
// 											floatingLabel="Token"
// 											value={appToken}
// 											onChange={(value) => setAppToken(value)}
// 											placeholder="Ingrese el token"
// 										/>

// 										<Button onClick={sendAppToken} variant="secondary">
// 											Generar Otro Token
// 										</Button>
// 									</div>
// 								)}

// 								{errorMessage && <Notice message={errorMessage} variant="error" />}

// 								<div className="flex flex-wrap gap-2 md:flex-row-reverse md:flex-nowrap">
// 									<Button disabled={isLoading} loading={isLoading} type="submit" variant="primary">
// 										Enviar
// 									</Button>
// 									<Button
// 										Icon={<ArrowLeft className="size-5" />}
// 										disabled={isLoading}
// 										type="button"
// 										onClick={() => goToPage(13)}
// 										variant="secondary"
// 									>
// 										Atrás
// 									</Button>
// 								</div>
// 							</form>
// 						</>
// 					)}
// 					{page === 15 && (
// 						<>
// 							{render_page_header(
// 								'¡Cambio por garantía exitoso!',
// 								'IMPORTANTE: Antes de que el cliente se lleve su celular asegurese que le llegó un email de confirmación a la tienda, eso se utilizará como comprobante en caso de cualquier problema que ocurra.',
// 								<CircleCheck />
// 							)}

// 							<form
// 								className="space-y-6"
// 								onSubmit={(e) => {
// 									e.preventDefault();
// 									window.location.href = '/warranty';
// 								}}
// 							>
// 								<Button Icon={<ArrowLeft className="size-5" />} type="submit" variant="primary">
// 									Volver al inicio
// 								</Button>
// 							</form>
// 						</>
// 					)}
// 				</div>
// 			</div>
// 		</div>
// 	);

// 	function render_page_header(title, description, icon) {
// 		return (
// 			<div className="mb-4 flex flex-col items-center gap-2 md:mb-6 md:gap-3">
// 				<div className="relative">
// 					<div className="absolute -inset-1 rounded-full bg-gradient-to-r from-primary-400 to-primary-600 opacity-10 blur"></div>
// 					<div className="relative rounded-full bg-neutral-900 p-4 light:bg-neutral-1000 light:ring-2 light:ring-primary-500/10">
// 						{icon && React.cloneElement(icon, { className: 'h-6 w-6 text-primary-500' })}
// 					</div>
// 				</div>
// 				<div className="space-y-1 text-center">
// 					<h1 className="text-2xl font-semibold tracking-tighter text-neutral-0 md:text-3xl">{title}</h1>
// 					{description && <p className="text-neutral-300">{description}</p>}
// 				</div>
// 			</div>
// 		);
// 	}
// };

// export default Warranty;

import React, { useState, useEffect, useRef } from 'react';
import { Typography, Container, Button, TextField, makeStyles, withStyles } from '@material-ui/core';
import { ArrowBack, ArrowForward, Search } from '@material-ui/icons';
import { BeatLoader } from 'react-spinners';
import qrCode from '../images/enrollment_qr.png';
import paso1 from '../images/paso1.png';
import paso1dot2 from '../images/paso1.2.png';
import paso2 from '../images/paso2.png';
import paso3 from '../images/paso3.png';
import paso4 from '../images/paso4.png';
import paso5 from '../images/paso5.png';
import paso6 from '../images/paso6.png';
import paso7 from '../images/paso7.png';
import paso8 from '../images/paso8.png';
import paso13 from '../images/paso13.png';
import paso16 from '../images/paso16.png';
import homeScreen from '../images/kreceScreen.png';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import firestore from '../firestore'
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import auth from '../auth';

// Initialize Firebase
firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FB_APP_ID,
    measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID
});

const Warranty = () => {
    const classes = useStyles();
    const navigate = useNavigate();

    const [storeName, setStoreName] = useState('');
    const [securityProvider, setSecurityProvider] = useState('');
    //User creation data
    const [cedula, setCedula] = useState('');
    const [formError, setFormError] = useState('');
    const [storeUser, setStoreUser] = useState('');
    const [isGuarantee, setIsGuarantee] = useState(false);
    const [storeEmail, setStoreEmail] = useState('');

    // device registration data
    const [loginError, setLoginError] = useState('');
    const [token, setToken] = useState(process.env.REACT_APP_API_TOKEN);
    const [deviceID, setDeviceID] = useState('');
    const [imeiNumber, setImeiNumber] = useState('');
    const [confNumber, setConfNumber] = useState('');
    const [imeiToRegister, setImeiToRegister] = useState('');
    const [imeiToRegister2, setImeiToRegister2] = useState('');
    const [appToken, setAppToken] = useState('');
    const [tokenGenerated, setTokenGenerated] = useState(false);

    //webapp data
    const [isLoading, setIsLoading] = useState(false);
    const [country, setCountry] = useState('')
    const [loadingText, setLoadingText] = useState(false);
    const [loadingSubText, setLoadingSubText] = useState(false);
    const [showImei, setShowImei] = useState(false);
    const [showConfNumber, setShowConfNumber] = useState(false);
    const [page, setPage] = useState(2);
    const [switchChecked, setSwitchChecked] = useState(false);
    const [switchChecked2, setSwitchChecked2] = useState(false);
    const [blockNuovo, setBlockNuovo] = useState(false);
    const [isOnline, setIsOnline] = useState(false);

    var currentDay = new Date().getDate();
    if (currentDay === 31) {
        currentDay = 30;
    }

    useEffect(() => {
        setIsLoading(true);
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                const storeRef = firestore.collection('Stores').doc(user.email);
                setStoreUser(user.email)
                storeRef.get()
                    .then((doc) => {
                        const data = doc.data();
                        if (data.cobranza_access) {
                            navigate('/');
                            setIsLoading(false);
                            return;
                        }
                        const masterStoreRef = data.master_store;
                        setStoreEmail(masterStoreRef.id)
                        const userName = masterStoreRef.id === user.email ? "Master" : data.store_name
                        masterStoreRef.get()
                            .then((doc) => {
                                const data = doc.data();
                                setCountry(data.country)
                                const storeName = data.store_name;
                                setBlockNuovo(data.only_allow_tonic === true ? true : false);
                                setSecurityProvider(data.security_provider);
                                setStoreName(storeName);
                                setIsLoading(false);
                            });
                        setIsLoading(false);
                    });
            } else {
                // User is signed out
                // ...
                navigate("/login");
                console.log("user is logged out");
                setIsLoading(false);
            }
        });

    }, [])

    const sendPostRequest = async (data, endpoint, token2) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${await firebase.auth().currentUser.getIdToken(true)}`,
                    'x-master-store-email': storeEmail,
                    'x-user-email': storeUser
                },
                body: JSON.stringify({ ...data, country: country })
            });
            const responseData = await response.json();
            if (response.status === '401') {
                const tokenData = await requestToken()
                await setToken('Token ' + tokenData.token)
                const resData = await sendPostRequest(data, endpoint, 'Token ' + tokenData.token)
                return resData
            }
            return responseData;
        } catch (error) {
            console.error('Error:', error.message);
            return null
        }
    };

    const requestToken = async () => {
        const loginData = {
            username: process.env.REACT_APP_TOKEN_USERNAME,
            password: process.env.REACT_APP_TOKEN_PASSWORD
        }
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/token/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(loginData)
            });

            const responseData = await response.json();
            return responseData
        } catch (error) {
            console.error('Error:', error);
        }
    };


    function convertDateFormat(dateString) {
        // Split the input string into day, month, and year components
        const [day, month, year] = dateString.split('/');

        // Create a new Date object with the parsed components
        const parsedDate = new Date(`${year}-${month}-${day}`);

        // Extract the year, month, and day components in yyyy-MM-dd format
        const formattedDate = parsedDate.toISOString().split('T')[0];

        return formattedDate;
    }

    function getUserInput() {
        function getInputRecursive() {
            const userInput = window.prompt('No pudimos identificar la cédula por favor introduzca el número aquí:');
            if (userInput === null) {
                // User clicked cancel or closed the prompt
                return getInputRecursive(); // Ask again
            } else if (!/^[0-9]+$/.test(userInput)) {
                // Invalid input (doesn't match the regex)
                window.alert('Por favor introduzca un número de cédula válido.');
                return getInputRecursive(); // Ask again
            } else {
                // Valid input
                return userInput;
            }
        }

        return getInputRecursive();
    }

    const sendAppToken = async (e) => {
        setFormError('')
        setIsLoading(true);
        const data = {
            identification_number: cedula,
            code_type: 'sms'
        }
        const resp2 = await sendPostRequest(data, 'notifications/requestauthcode/', token); //to-do: Necesito mandar la cedula? ese token como se va a guardar en la base de datos?
        if (resp2?.success) {
            alert('Token generado en app exitosamente.')
            setTokenGenerated(true);
            setIsLoading(false);
        } else {
            setFormError("Ocurrió un problema generando el token de confirmación del app, por favor vuelva a intentar.");
            setIsLoading(false);
        }
    }

    const handleSendDeviceID = async (e) => {
        if (isGuarantee) {
            await handleDeviceIdGuaranteeSubmit();
            return;
        }
    }

    const goToPage = (x) => {
        setFormError('')
        setLoginError('')
        setPage(x);
    }

    const backToWelcomePage = () => {
        window.location.reload();
    };

    const searchConfirmationNumber = async () => {
        setConfNumber('');
        setShowConfNumber(false)
        setLoginError('')
        const data = {
            store_name: storeName,
            imei: imeiNumber
        }
        const response = await sendPostRequest(data, 'registration/finddevice/', token)
        if (response?.success) {
            setConfNumber(response.data.nuovo_id);
            setShowConfNumber(true)
        } else if (response?.success === false) {
            setLoginError(response.status);
            setIsLoading(false);
        } else {
            setLoginError('Estamos teniendo problemas para conectarnos.');
            setIsLoading(false);
        }
    }

    const checkDeviceReady = async () => {
        let breakLoop = false
        let status = 'waiting'

        for (let i = 0; i < 10; i++) {
            if (breakLoop) {
                break;
            }
            const devDat = {
                device_imei: imeiToRegister
            };
            await new Promise((resolve) => {
                setTimeout(async () => {
                    try {
                        const resp = await sendPostRequest(devDat, 'registration/imeistatus/', token);

                        if (resp?.success) {
                            if (resp.device_ready) {
                                setIsLoading(false);
                                setLoadingText('');
                                setLoadingSubText('');
                                setPage(18);
                                breakLoop = true
                                status = 'success'
                            }
                        } else if (resp?.success === false) {
                            if (resp?.invalid_device) {
                                // setFormError("Este modelo de equipo no puede ser configurado, por favor elija otro modelo.");
                                if (switchChecked) {
                                    setFormError("Ocurrió un problema al registrar el equipo, por favor asegurese que el equipo es marca Samsung, si el problema persiste contacte a soporte técnico.");
                                    setIsLoading(false);
                                    setLoadingText('');
                                    setLoadingSubText('');
                                    return;
                                } else if (blockNuovo) {
                                    setFormError("Ocurrió un problema al registrar el equipo, por favor asegurese que el equipo es marca Samsung, Infinix, Tecno, Realme, Itel, o Motorola, si el problema persiste contacte a soporte técnico.");
                                    setIsLoading(false);
                                    setLoadingText('');
                                    setLoadingSubText('');
                                    return;
                                }
                                setSecurityProvider("nuovo");
                                setIsLoading(false);
                                setLoadingText('');
                                setLoadingSubText('');
                                return;
                            } else {
                                setFormError(resp.status);
                            }
                            setIsLoading(false);
                            setLoadingText('');
                            setLoadingSubText('');
                            breakLoop = true
                            status = 'fail'
                        } else {
                            setFormError("Estamos teniendo problemas para conectarnos.");
                            setIsLoading(false);
                            setLoadingText('');
                            setLoadingSubText('');
                            breakLoop = true
                            status = 'fail'
                        }
                    } catch (error) {
                        console.error('Error occurred:', error);
                        setFormError("Error al realizar la solicitud.");
                        setIsLoading(false);
                        setLoadingText('');
                        setLoadingSubText('');
                        breakLoop = true
                        status = 'fail'
                    }
                    resolve();
                }, 15000);
            });
        }
        if (status === "waiting") {
            setFormError("Se ha alcanzado el tiempo máximo de espera, por favor vuelva a intentar o contacte a servicio técnico.");
            setIsLoading(false);
            setLoadingText('');
            setLoadingSubText('');
        }
    };

    const registerWithTonic = async () => {
        setFormError('')
        if (!imeiToRegister || !imeiToRegister2) {
            setFormError('Por favor rellene todas las casillas para continuar.')
            return;
        }
        if (imeiToRegister.length < 15 || imeiToRegister2.length < 15) {
            setFormError('IMEI ivalido, asegurese de que el IMEI tenga 15 caracteres.')
            return;
        }
        if (imeiToRegister !== imeiToRegister2) {
            setFormError('Los IMEIs introducidos no coinciden.')
            return;
        }
        try {
            setIsLoading(true);
            setLoadingText('Registrando equipo');
            setLoadingSubText('Esto puede tardar unos segundos, por favor NO encienda el teléfono aún.');
            const devData = {
                device_imei: imeiToRegister
            }
            let response
            if (switchChecked2) {
                response = await sendPostRequest(devData, 'registration/promo2/', token)
            } else {
                response = await sendPostRequest(devData, 'registration/registerimei/', token)
            }

            if (response?.success) {
                await checkDeviceReady();
            } else if (response?.success === false) {
                setFormError(response.status)
                setIsLoading(false);
                setLoadingText('');
                setLoadingSubText('');
            } else {
                setFormError("Estamos teniendo problemas para conectarnos.")
                setIsLoading(false);
                setLoadingText('');
                setLoadingSubText('');
            }
        } catch (err) {
            setFormError("Error: " + err.message);
            setIsLoading(false);
            setLoadingText('');
            setLoadingSubText('');
        }
    }

    const callDevReg = async () => {
        try {
            setIsLoading(true);
            setLoadingText('Finalizando registro');
            if (isGuarantee) {
                await handleDeviceIdGuaranteeSubmit();
                return;
            }
        } catch (err) {
            setFormError("Error: " + err.message);
            setIsLoading(false);
            setLoadingText('');
            setLoadingSubText('');
        }
    }

    const handleDeviceIdGuaranteeSubmit = async () => {
        if (securityProvider !== "tonic" && !deviceID) {
            await setFormError('Por favor complete todas las casillas.');
            return;
        }
        if (!appToken) {
            setFormError('Por favor introduzca el token.');
            return;
        }

        setFormError('');
        setIsLoading(true);
        const usersCollection = firestore.collection('Users');
        const query = usersCollection
            .where('identification_number', '==', cedula)
            .limit(1);
        await query.get().then((querySnapshot) => {
            querySnapshot.forEach(async (doc) => {
                // to-do mandar app_token
                let deviceData = {
                    device_id: deviceID,
                    user_document_id: doc.id,
                    device_management_provider: "nuovo",
                    app_token: appToken,
                    store_user: storeUser
                }
                if (securityProvider === "tonic") { //to-do mandar app_token
                    deviceData = {
                        device_imei: imeiToRegister,
                        user_document_id: doc.id,
                        device_management_provider: "tonic",
                        app_token: appToken,
                        store_user: storeUser
                    }
                }
                const response = await sendPostRequest(deviceData, 'registration/replacedevice/', token) //status, success, data
                if (response?.success) {
                    setPage(15)
                    setIsLoading(false);
                    setLoadingText('');
                    setLoadingSubText('');
                } else if (response?.success === false) {
                    setFormError(response.status);
                    setIsLoading(false);
                    setLoadingText('');
                    setLoadingSubText('');
                } else {
                    setFormError('Estamos teniendo problemas para conectarnos. ');
                    setIsLoading(false);
                    setLoadingText('');
                    setLoadingSubText('');
                }
            });
        }).catch((error) => {
            console.log(error.message)
            setFormError('Estamos teniendo problemas para conectarnos. ')
            setIsLoading(false);
            setLoadingText('');
            setLoadingSubText('');
            return
        })
    }

    const checkTonicStatus = async () => {
        setFormError('');
        let breakLoop = false;
        let status = 'waiting';
        setIsLoading(true);
        setLoadingText('Chequeando estatus del equipo');
        setLoadingSubText('Esto puede tardar unos minutos.');
        for (let i = 0; i < 10; i++) {
            if (breakLoop) {
                break;
            }
            const devDat = {
                device_imei: imeiToRegister
            };
            await new Promise((resolve) => {
                setTimeout(async () => {
                    try {
                        const resp = await sendPostRequest(devDat, 'registration/activeimei/', token);
                        if (resp?.success) {
                            if (resp.device_active) {
                                setIsLoading(false);
                                setLoadingText('');
                                setLoadingSubText('');
                                setPage(19);
                                status = "success";
                                breakLoop = true;
                            }
                        } else if (resp?.success === false) {
                            if (resp?.invalid_device) {
                                setFormError("Este modelo de equipo no puede ser configurado, por favor elija otro modelo.");
                            } else {
                                setFormError(resp.status);
                            }
                            setIsLoading(false);
                            setLoadingText('');
                            setLoadingSubText('');
                            breakLoop = true;
                            status = "fail";
                        } else {
                            setFormError("Estamos teniendo problemas para conectarnos.");
                            setIsLoading(false);
                            setLoadingText('');
                            setLoadingSubText('');
                            breakLoop = true;
                            status = "fail";
                        }
                    } catch (error) {
                        console.error('Error occurred:', error);
                        setFormError("Error al realizar la solicitud.");
                        setIsLoading(false);
                        setLoadingText('');
                        setLoadingSubText('');
                        breakLoop = true;
                        status = "fail";
                    }
                    resolve();
                }, 15000);
            });
        }
        if (status === "waiting") {
            // setFormError("Error: El celular no fue encendido o configurado correctamente. Si cree que es un error vuelva a hacer clic en Siguiente, de lo contrario debe formatear el equipo de fabrica, prenderlo, configurarlo, y luego hacer clic en Siguiente.");
            setSecurityProvider('nuovo');
            setPage(10);
            setIsLoading(false);
            setLoadingText('');
            setLoadingSubText('');
            alert('El equipo no se pudo configurar correctamente. Porfavor reseteelo de fabrica y prosiga con la instalación con QR.')
        }
    }

    const handleContinueGuarantee = async () => {
        setIsLoading(true);
        // Simulating API call delay
        try {
            const cedulaData = {
                identification_number: cedula
            }
            const response = await sendPostRequest(cedulaData, 'user_check_warranty/', token) //status, success, data
            if (response?.success) {
                if (response.data.is_user_registered && response.data.is_elegible_for_warranty) {
                    //se manda a un screen para que introduzca el nuevo IMEI y despues se manda un API call a replace_old_device
                    setPage(9)
                    setIsLoading(false);
                } else if (!response.data.is_user_registered) {
                    await setLoginError('El cliente no existe.');
                    setIsLoading(false);
                } else if (response.data.is_user_registered && !response.data.is_elegible_for_warranty) {
                    await setLoginError('Este cliente no tiene deudas con Krece. No tiene que reportar el cambio de teléfono.');
                    setIsLoading(false);
                }
            } else if (response?.success === false) {
                setLoginError(response.status);
                setIsLoading(false);
            } else {
                setLoginError('Estamos teniendo problemas para conectarnos. ');
                setIsLoading(false);
            }
        } catch (error) {
            setLoginError('Estamos teniendo problemas para conectarnos. ');
            setIsLoading(false);
        }
    }

    const handleConfigureGuarantee = (x) => {
        setIsGuarantee(true);
        goToPage(7);
    }

    return (
        <div>
            <Container maxWidth="sm" style={{
                marginHorizontal: 10,
                boxShadow: 'rgb(0 0 0 / 16%) 1px 1px 10px',
                paddingTop: 35,
                paddingBottom: 35,
                marginBottom: 30,
                borderRadius: '8px',
                paddingRight: 50,
                paddingLeft: 50,
                backgroundColor: '#1C1C1E',
                marginTop: 100
            }}>
                {isLoading && (
                    <div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 100, marginTop: 100 }}>
                            <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                                {loadingText}
                            </Typography>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 100, marginTop: 100 }}>
                            <BeatLoader color="#45C4D6" />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 100, marginTop: 100 }}>
                            <Typography variant="h6" className={classes.title2} gutterBottom>
                                {loadingSubText}
                            </Typography>
                        </div>
                    </div>
                )}

                {page === 2 && !isLoading && (
                    <div className={classes.containerWelcome}>
                        <div className={classes.topButton}>
                            <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                                {"Cambio por garantía"}
                            </Typography>
                            <p className={classes.approvalMsg}>Por favor ingresa la cédula del cliente para realizar el cambio por garantía</p>
                            <> <TextField
                                label="Cédula del aplicante"
                                name="cedula"
                                value={cedula}
                                onChange={(e) => setCedula(e.target.value)}
                                // variant="outlined"
                                className={classes.textFields}
                                margin="normal"
                                fullWidth
                                error={loginError !== ''}
                                helperText={loginError}
                                InputProps={{
                                    style: {
                                        color: 'white', // Text color
                                        borderBottom: '1px solid white'
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#D3D3D3', // Label color
                                    },
                                }}
                            />
                                <Button variant="contained" size="small" margin="normal" className={classes.welcomeButtons} onClick={handleContinueGuarantee}>
                                    Continuar
                                </Button> </>
                        </div>
                    </div>
                )}
                {page === 9 && !isLoading && (
                    <div style={{ alignItems: 'center' }}>
                        <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                            Celular con garantía
                        </Typography>
                        <Typography variant="h5" color="secondary" className={classes.approvalMsg} gutterBottom>
                            Por favor realice la configuración del equipo que va a reemplazar al original.
                        </Typography>
                        <Button variant="contained" className={classes.welcomeButtons} fullWidth onClick={handleConfigureGuarantee}>
                            Siguiente
                        </Button>
                        <Button
                            variant="contained"
                            startIcon={<ArrowBack />}
                            onClick={backToWelcomePage}
                            className={classes.welcomeButtons}
                        >
                            Atrás
                        </Button>
                    </div>
                )}
                {page === 7 && !isLoading && (
                    <div style={{ alignItems: 'center' }}>
                        <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                            Configuración de seguridad
                        </Typography>
                        <Typography variant="h5" color="secondary" className={classes.approvalMsg} gutterBottom>
                            Ahora, el encargado(a) en la tienda va a configurar nuestro app de seguridad en el dispositivo, lo cual le dará permiso a Krece para bloquear y desbloquear el dispositivo en caso de que se atrase con sus pagos.
                        </Typography>
                        <Button variant="contained" className={classes.welcomeButtons} fullWidth onClick={() => goToPage(10)}>
                            Comenzar Configuración
                        </Button>
                        <Button
                            variant="contained"
                            className={classes.welcomeButtons}
                            startIcon={<ArrowBack />}
                            onClick={backToWelcomePage}
                            style={{ marginTop: '10px' }}
                        >
                            Atrás
                        </Button>
                    </div>
                )}
                {page === 10 && !isLoading && (
                    <div style={{ alignItems: 'center' }}>
                        {securityProvider === "tonic" ?
                            <>
                                <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                                    Configuración de seguridad
                                </Typography>
                                <Typography variant="h5" color="secondary" className={classes.approvalMsg} style={{ textAlign: 'left' }} gutterBottom>
                                    Para comenzar, asegurese que el equipo se encuentre apagado.
                                </Typography>
                                <TextField
                                    label="IMEI del equipo"
                                    name="imeiToRegister"
                                    value={imeiToRegister}
                                    type="password"
                                    onChange={(e) => setImeiToRegister(e.target.value)}
                                    className={classes.textFields}
                                    margin="normal"
                                    fullWidth
                                    InputProps={{
                                        style: {
                                            color: 'white', // Text color
                                            borderBottom: '1px solid white'
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: '#D3D3D3', // Label color
                                        },
                                    }}
                                />
                                <TextField
                                    label="Confirme el IMEI del equipo"
                                    name="imeiToRegister2"
                                    value={imeiToRegister2}
                                    type="password"
                                    onPaste={(e) => e.preventDefault()}
                                    onChange={(e) => setImeiToRegister2(e.target.value)}
                                    className={classes.textFields}
                                    margin="normal"
                                    fullWidth
                                    InputProps={{
                                        style: {
                                            color: 'white', // Text color
                                            borderBottom: '1px solid white'
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: '#D3D3D3', // Label color
                                        },
                                    }}
                                />
                                {formError && <p className={classes.formError}>{formError}</p>}
                                <Button variant="contained" className={classes.welcomeButtons} fullWidth onClick={registerWithTonic}>
                                    Siguiente
                                </Button>
                                <Button
                                    variant="contained"
                                    className={classes.welcomeButtons}
                                    startIcon={<ArrowBack />}
                                    onClick={() => setPage(7)}
                                    style={{ marginTop: '10px' }}
                                >
                                    Atrás
                                </Button>
                            </> :
                            <>
                                <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                                    Configuración de seguridad
                                </Typography>
                                <Typography variant="h5" color="secondary" className={classes.approvalMsg} style={{ textAlign: 'left' }} gutterBottom>
                                    1. Encienda el dispositivo nuevo y en la primera pantalla cambie el idioma a Español. En esa misma pantalla, toque en cualquier lugar de la pantalla 6 veces para ver un escáner de código QR.
                                </Typography>
                                <img style={{ width: 150, marginRight: 50 }} src={paso1} alt="Paso 1" />
                                <img style={{ width: 150, marginRight: 50 }} src={paso1dot2} alt="Paso 1.2" />
                                <Typography variant="h5" color="secondary" className={classes.approvalMsg} style={{ textAlign: 'left' }} gutterBottom>
                                    2. Escanee el siguiente código QR para iniciar la instalación del app de seguridad.
                                </Typography>
                                <img style={{ width: 200 }} src={qrCode} alt="Enrollment QR" />
                                <Button variant="contained" className={classes.welcomeButtons} fullWidth onClick={() => goToPage(11)}>
                                    Seguir
                                </Button>
                                <div style={{ justifyContent: 'space-between', flexDirection: 'row', display: 'flex' }}>
                                    <Button
                                        variant="contained"
                                        className={classes.welcomeButtons}
                                        startIcon={<ArrowBack />}
                                        onClick={() => goToPage(7)}
                                        style={{ marginTop: '10px' }}
                                    >
                                        Atrás
                                    </Button>
                                    <Button
                                        variant="contained"
                                        className={classes.welcomeButtons}
                                        endIcon={<ArrowForward />}
                                        onClick={() => goToPage(14)}
                                        style={{ marginTop: '10px' }}
                                    >
                                        Saltar Instrucciones
                                    </Button>
                                </div></>}
                    </div>
                )}
                {
                    page === 18 && !isLoading && (
                        <>
                            <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                                Configuración de seguridad
                            </Typography>
                            <Typography variant="h5" color="secondary" align="center" className={classes.approvalMsg} style={{ textAlign: 'center' }} gutterBottom>
                                Por favor proceda a encender el equipo, unirlo con el WIFI de la tienda, aceptar todas las condiciones y realizar la configuración hasta que vea la siguiente pantalla.
                            </Typography>
                            <Typography variant="h5" color="secondary" align="center" className={classes.approvalMsg} style={{ textAlign: 'center' }} gutterBottom>
                                {'IMPORTANTE (NO APLICA PARA SAMSUNG): SI NO SE LOGRA LLEGAR A LA PANTALLA QUE MOSTRAMOS ABAJO, TERMINE DE CONFIGURAR EL CELULAR E INTENTE RESTAURAR DE FABRICA. SI EL EQUIPO LE PERMITE RESTAURAR DE FABRICA QUIERE DECIR QUE NO SE CONFIGURO CORRECTAMENTE Y SE DEBE RESTAURAR DE FABRICA Y VOLVER A COMENZAR. DE LO CONTRARIO, SI SE CONFIGURO CORRECTAMENTE Y PUEDE HACER CLIC EN SIGUIENTE.'}
                            </Typography>
                            <img style={{ height: 448, width: 200, marginBottom: 10, marginTop: 10, alignSelf: 'center' }} src={homeScreen} alt="homeScreen" />
                            {formError && <p className={classes.formError}>{formError}</p>}
                            <Button variant="contained" className={classes.welcomeButtons} fullWidth onClick={checkTonicStatus}>
                                Siguiente
                            </Button>
                            <Button
                                variant="contained"
                                startIcon={<ArrowBack />}
                                onClick={() => setPage(10)}
                                className={classes.welcomeButtons}
                            >
                                Atrás
                            </Button>
                        </>
                    )
                }
                {
                    page === 19 && !isLoading && (
                        <>
                            <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                                Código de App Krece
                            </Typography>
                            <Typography variant="h5" color="secondary" className={classes.approvalMsg} style={{ textAlign: 'left' }} gutterBottom>
                                {'Ahora proceda a descargar la app Krece desde internet o Play Store en el equipo en venta. (Este paso NO es necesario para equipos SAMSUNG)'}
                            </Typography>
                            {!isOnline && <>
                                <Typography variant="h5" color="secondary" align="center" className={classes.approvalMsg} style={{ textAlign: 'center' }} gutterBottom>
                                    Ahora haga clic en "Generar Token" para que el cliente reciba un Token por SMS.
                                </Typography>
                                {!tokenGenerated ? <Button variant="contained" className={classes.welcomeButtons} onClick={sendAppToken}>
                                    Generar Token
                                </Button> : <><TextField
                                    label="Token"
                                    name="appToken"
                                    value={appToken}
                                    onChange={(e) => setAppToken(e.target.value)}
                                    className={classes.textFields}
                                    margin="normal"
                                    fullWidth
                                    InputProps={{
                                        style: {
                                            color: 'white', // Text color
                                            borderBottom: '1px solid white'
                                        },
                                    }}
                                    InputLabelProps={{
                                        style: {
                                            color: '#D3D3D3', // Label color
                                        },
                                    }}
                                />
                                    <Button variant="contained" className={classes.welcomeButtons} onClick={sendAppToken}>
                                        Generar Otro Token
                                    </Button></>}
                            </>}
                            {formError && <p className={classes.formError}>{formError}</p>}
                            <Button variant="contained" className={classes.welcomeButtons} fullWidth onClick={callDevReg}>
                                Siguiente
                            </Button>
                            <Button
                                variant="contained"
                                startIcon={<ArrowBack />}
                                onClick={() => setPage(18)}
                                className={classes.welcomeButtons}
                            >
                                Atrás
                            </Button>
                        </>
                    )
                }
                {page === 11 && !isLoading && (
                    <div style={{ alignItems: 'center' }}>
                        <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                            Instrucciones de configuración
                        </Typography>
                        <Typography variant="h5" color="secondary" className={classes.approvalMsg} style={{ textAlign: 'left' }} gutterBottom>
                            3. Haga clic en aceptar cuando se le solicite.
                        </Typography>
                        <img style={{ width: 200, marginRight: 10, marginBottom: 10 }} src={paso2} alt="Paso2" />
                        <img style={{ width: 200, marginRight: 10, marginBottom: 10 }} src={paso3} alt="Paso3" />
                        <img style={{ width: 200, marginRight: 10, marginBottom: 10 }} src={paso4} alt="Paso4" />
                        <img style={{ width: 200, marginRight: 10, marginBottom: 10 }} src={paso5} alt="Paso5" />
                        <Button variant="contained" className={classes.welcomeButtons} fullWidth onClick={() => goToPage(12)}>
                            Seguir
                        </Button>
                        <Button
                            variant="contained"
                            className={classes.welcomeButtons}
                            startIcon={<ArrowBack />}
                            onClick={() => goToPage(10)}
                            style={{ marginTop: '10px' }}
                        >
                            Atrás
                        </Button>
                    </div>
                )}
                {page === 12 && !isLoading && (
                    <div style={{ alignItems: 'center' }}>
                        <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                            Instrucciones de configuración
                        </Typography>
                        <Typography variant="h5" color="secondary" className={classes.approvalMsg} style={{ textAlign: 'left' }} gutterBottom>
                            4. Haga clic en "Ajustes adicionales" y después en "PERMITR".
                        </Typography>
                        <img style={{ width: 200, marginRight: 10, marginBottom: 10 }} src={paso6} alt="Paso6" />
                        <img style={{ width: 200, marginRight: 10, marginBottom: 10 }} src={paso7} alt="Paso7" />
                        <Button variant="contained" className={classes.welcomeButtons} fullWidth onClick={() => goToPage(13)}>
                            Seguir
                        </Button>
                        <Button
                            variant="contained"
                            className={classes.welcomeButtons}
                            startIcon={<ArrowBack />}
                            onClick={() => goToPage(11)}
                            style={{ marginTop: '10px' }}
                        >
                            Atrás
                        </Button>
                    </div>
                )}
                {page === 13 && !isLoading && (
                    <div style={{ alignItems: 'center' }}>
                        <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                            Instrucciones de configuración
                        </Typography>
                        <Typography variant="h5" color="secondary" className={classes.approvalMsg} style={{ textAlign: 'left' }} gutterBottom>
                            5. Siga las inctrucciones que se le muestran en la pantalla y cuando esté listo haga clic en "Configuración Completa"
                        </Typography>
                        <img style={{ width: 200, marginRight: 30, marginBottom: 10 }} src={paso8} alt="Paso8" />
                        <img style={{ width: 200, marginBottom: 10 }} src={paso13} alt="Paso13" />
                        <Button variant="contained" className={classes.welcomeButtons} fullWidth onClick={() => goToPage(14)}>
                            Seguir
                        </Button>
                        <Button
                            variant="contained"
                            className={classes.welcomeButtons}
                            startIcon={<ArrowBack />}
                            onClick={() => goToPage(12)}
                            style={{ marginTop: '10px' }}
                        >
                            Atrás
                        </Button>
                    </div>
                )}
                {page === 14 && !isLoading && (
                    <div style={{ alignItems: 'center' }}>
                        <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                            Instrucciones de configuración
                        </Typography>
                        <Typography variant="h5" color="secondary" className={classes.approvalMsg} style={{ textAlign: 'left' }} gutterBottom>
                            6. Por favor introduzca el código de confirmación de 7 digitos en la siguiente casilla.
                        </Typography>
                        <TextField
                            label="Código de confirmación"
                            name="deviceID"
                            value={deviceID}
                            onChange={(e) => setDeviceID(e.target.value)}
                            className={classes.textFields}
                            margin="normal"
                            fullWidth
                            InputProps={{
                                style: {
                                    color: 'white', // Text color
                                    borderBottom: '1px solid white'
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: '#D3D3D3', // Label color
                                },
                            }}
                        />
                        <Button variant="text" className={classes.welcomeButtonsNoBorder} fullWidth onClick={() => setShowImei(true)} gutterBottom>
                            ¿No encuentra el código de confirmación?
                        </Button>
                        {showImei && <div className={classes.border}><TextField
                            label="Número IMEI del equipo"
                            name="imeiNumber"
                            value={imeiNumber}
                            onChange={(e) => setImeiNumber(e.target.value)}
                            error={loginError !== ''}
                            helperText={loginError}
                            className={classes.textFields}
                            margin="normal"
                            fullWidth
                            InputProps={{
                                style: {
                                    color: 'white', // Text color
                                    borderBottom: '1px solid white'
                                },
                            }}
                            InputLabelProps={{
                                style: {
                                    color: '#D3D3D3', // Label color
                                },
                            }}
                        />
                            <Button
                                variant="contained"
                                className={classes.welcomeButtons}
                                startIcon={<Search />}
                                onClick={() => searchConfirmationNumber()}
                                style={{ marginTop: '10px' }}
                            >
                                Buscar
                            </Button>
                            {showConfNumber && <p style={{ color: 'white' }}>{"El código de confirmación de este equipo es: " + confNumber}</p>}
                        </div>}
                        <img style={{ width: 200, marginBottom: 10, marginTop: 30 }} src={paso16} alt="Paso16" />
                        {!isOnline && <> <Typography variant="h5" color="secondary" className={classes.approvalMsg} style={{ textAlign: 'left' }} gutterBottom>
                            7. Para finalizar, haga clic en "Generar Token" para que el cliente reciba un Token por SMS.
                        </Typography>

                            {!tokenGenerated ? <Button variant="contained" className={classes.welcomeButtons} onClick={sendAppToken}>
                                Generar Token
                            </Button> : <><TextField
                                label="Token"
                                name="appToken"
                                value={appToken}
                                onChange={(e) => setAppToken(e.target.value)}
                                className={classes.textFields}
                                margin="normal"
                                fullWidth
                                InputProps={{
                                    style: {
                                        color: 'white', // Text color
                                        borderBottom: '1px solid white'
                                    },
                                }}
                                InputLabelProps={{
                                    style: {
                                        color: '#D3D3D3', // Label color
                                    },
                                }}
                            />
                                <Button variant="contained" className={classes.welcomeButtons} onClick={sendAppToken}>
                                    Generar Otro Token
                                </Button></>}
                        </>}
                        {formError && <p className={classes.formError}>{formError}</p>}
                        <Button variant="contained" className={classes.welcomeButtons} fullWidth onClick={handleSendDeviceID}>
                            Enviar
                        </Button>
                        <Button
                            variant="contained"
                            className={classes.welcomeButtons}
                            startIcon={<ArrowBack />}
                            onClick={() => goToPage(13)}
                            style={{ marginTop: '10px' }}
                        >
                            Atrás
                        </Button>
                    </div>
                )}
                {page === 15 && !isLoading && (
                    <div style={{ alignItems: 'center' }}>
                        <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                            ¡Cambio por garantía exitoso!
                        </Typography>
                        <Typography variant="h5" color="secondary" className={classes.approvalMsg} style={{ textAlign: 'left' }} gutterBottom>
                            IMPORTANTE: Antes de que el cliente se lleve su celular asegurese que le llegó un email de confirmación a la tienda, eso se utilizará como comprobante en caso de cualquier problema que ocurra.
                        </Typography>
                        <Button
                            variant="contained"
                            className={classes.welcomeButtons}
                            startIcon={<ArrowBack />}
                            onClick={backToWelcomePage}
                            style={{ marginTop: '10px' }}
                        >
                            Volver al inicio
                        </Button>
                    </div>
                )}
            </Container>
        </div >
    );
};

const useStyles = makeStyles((theme) => ({
    approvalMsg: {
        textAlign: 'center', color: "silver", fontSize: 16
    },
    switch_track: {
        backgroundColor: "gray",
    },
    switch_base: {
        color: "gray",
        "&.Mui-disabled": {
            color: "gray"
        },
        "&.Mui-checked": {
            color: "gray"
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "gray",
        }
    },
    switch_primary: {
        "&.Mui-checked": {
            color: "#65C466",
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#65C466",
        },
    },
    totalAmt: {
        marginBottom: theme.spacing(2), // Adjust the padding as needed
        textAlign: 'center',
        color: 'white',
        fontSize: 20,
        fontWeight: 'bold'
    },
    welcomeButtons: {
        backgroundColor: '#45C4D6',
        color: '#2A2E32',
        marginTop: 10,
        marginBottom: 10
    },
    welcomeButtons2: {
        backgroundColor: '#45C4D6',
        color: '#2A2E32',
        marginTop: 15,
        marginLeft: 15,
        height: 45,
        width: 200
    },
    textFields: {
        backgroundColor: '#1C1C1E',
        color: 'white',
        borderColor: "white",
        borderBottomColor: "white",
        borderRadius: 5,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        paddingLeft: 5
    },
    textFields2: {
        color: 'white',
    },
    textLabel: {
        color: 'white'
    },
    textInput: {
        backgroundColor: '#2A2E32',
        color: 'white',
        border: "1px solid #ccc",
        borderRadius: '8px',
        padding: 8,
        fontSize: 16,
        width: 100
    },
    paymentItem: {
        width: '100%',
        paddingTop: 5,
        paddingBottom: 10,
        justifyContent: 'space-between',
        flexDirection: 'row',
        marginBottom: 10,
    },
    containerWelcome: {
        display: 'flex',
        flexDirection: 'column',
        // height: 700,
    },
    topButton: {
        flex: 1
    },
    formError: {
        color: "#e84e4f"
    },
    title: {
        color: 'white',
        marginBottom: 20,
        fontSize: 26,
        fontWeight: 'bold'
    },
    title2: {
        color: 'white',
        fontSize: 16,
        marginBottom: 10
    },
    highlighted: {
        color: '#45C4D6',
    },
    appBarButton: {
        width: 200
    },
    border: {
        borderRadius: '8px',
        border: "1px solid #ccc",
        padding: "10px",
        marginBottom: 20,
    },
    border2: {
        borderRadius: '8px',
        border: "1px solid #ccc",
        paddingRight: "15px",
        paddingLeft: "15px",
        paddingBottom: 5,
        marginBottom: 20,
        display: 'flex',
        justifyContent: 'space-between'
    },
    welcomeButtonsNoBorder: {
        backgroundColor: '#1C1C1E',
        color: '#45C4D6',
        marginTop: 10,
        marginBottom: 10
    },
}));

export default Warranty;